<template>
  <div class="inline-flex">
    <RangeSelectors
      class="place-items-center"
      size="md"
      :items="items"
      :selectedValue="selectedValue"
      @selected="(e) => $emit('groupBySelected', e)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import RangeSelectors from "@/components/atoms/selectors/RangeSelectors.vue";

export default defineComponent({
  name: "GroupBySelector",
  components: { RangeSelectors },
  props: {
    selectedValue: String,
  },
  emits: ["groupBySelected"],
  data() {
    return {
      items: [
        {
          value: "collaborators",
          text: this.$t("collaborators"),
          title: this.$t("collaborators"),
        },
        {
          value: "apps",
          text: this.$t("apps"),
          title: this.$t("apps"),
        },
      ],
    };
  },
});
</script>

<i18n>
  en:
    apps: "Apps"
    collaborators: "Collaborators"
    groupBy: "Group by"
    placeholder: "Search"
  fr:
    app: "App"
    collaborators: "Collaborateurs"
    groupBy: "Grouper par"
    placeholder: "Rechercher"
</i18n>
