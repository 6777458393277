<template>
  <div class="flex py-2 justify-between space-x-1">
    <div class="flex w-1/2 pv:3/4">
      <AppStatusCircle :status="appInfo.status" />
      <div class="flex items-center">
        <router-link
          class="hover:underline"
          :to="appRoute"
        >
          {{ appInfo.name }}
        </router-link>
        <font-awesome-icon
          v-if="displayHDS"
          icon="staff-snake"
          class="ml-2 text-success"
          :title="$t('hdsResource')"
        />
        <Region
          v-if="displayFlag"
          class="ml-2"
          :regionCode="appInfo.region"
        />
      </div>
    </div>
    <router-link
      class="w-1/2 pv:1/4 hover:underline flex justify-end"
      :to="deploymentsToute"
    >
      <SimpleDate
        class="text-scale-6"
        :value="appInfo.deployedOrCreatedAt"
      />
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SimpleDate from "@/components/atoms/presenters/SimpleDate.vue";
import Region from "@/components/organisms/app/Region.vue";
import AppStatusCircle from "@/components/parts/home/overview/AppStatusCircle.vue";
import { defaultRegion } from "@/lib/scalingo/client";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "AppItem",
  components: { AppStatusCircle, SimpleDate, Region },
  props: {
    appInfo: Object,
  },
  computed: {
    displayHDS() {
      return this.appInfo.hds_resource;
    },
    displayFlag() {
      return this.appInfo.region !== defaultRegion.code;
    },
    appRoute() {
      return {
        name: Routes.App.Root,
        params: { region: this.appInfo.region, id: this.appInfo.name },
      };
    },
    deploymentsToute() {
      return {
        name: Routes.App.Deploy.List.All,
        params: { region: this.appInfo.region, id: this.appInfo.name },
      };
    },
  },
});
</script>

<i18n>
en:
  hdsResource: "HDS application"
fr:
  hdsResource: "Application norme HDS"
</i18n>
