<template>
  <div class="container mx-auto px-4 pv:px-0">
    <Card class="mt-8">
      <template #header>
        <CardHeader
          :title="$t('title')"
          :subtitle="$t('subtitle')"
        />
      </template>
      <template #body>
        <div class="py-4">
          <div class="divide-y divide-scale-2">
            <div
              v-for="(licence, key) in licences"
              :key="key"
              class="py-2 flex justify-between"
            >
              <a
                :href="licence.link"
                class="cursor-pointer hover:underline"
              >{{ licence.name }}
                <span class="text-scale-6">({{ licence.version }})</span>
              </a>

              <span>{{ licence.licence }}</span>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import licensingData from "@/components/parts/misc/about/licencing.json";

export default defineComponent({
  name: "About",
  components: { Card, CardHeader },
  data() {
    return {
      licences: licensingData,
    };
  },
});
</script>

<i18n>
en:
  title: "Licencing"
  subtitle: "Licences used during the application conception"
fr:
  title: "Licences"
  subtitle: "Licences utilisées durant la conception de l'application"
</i18n>
