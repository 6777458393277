<template>
  <div>
    <label
      :class="labelClass"
      class="block text-sm font-medium leading-5 text-scale-10"
    >
      {{ label }}
    </label>
    <TextInputAtom
      v-model="inputValue"
      :state="inputState"
      :type="inputType"
      :withButton="true"
      class="py-2"
      @button-clicked="showPassword = !showPassword"
    >
      <template #buttonIcon>
        <StrikeEyeGlyph />
      </template>
    </TextInputAtom>
    <p
      v-if="errors.length > 0"
      class="text-sm text-scale-10 mb-4"
    >
      {{ errors[0] }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import StrikeEyeGlyph from "@/components/atoms/glyphs/StrikeEyeGlyph.vue";
import TextInputAtom, {
  STATE_ERROR,
  STATE_NONE,
  TYPE_PASSWORD,
  TYPE_STRING,
} from "@/components/atoms/inputs/TextInput.vue";

export default defineComponent({
  name: "PasswordInput",
  components: { StrikeEyeGlyph, TextInputAtom },
  props: {
    label: String,
    labelClass: String,
    modelValue: String,
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    inputState: function () {
      if (this.errors.length > 0) {
        return STATE_ERROR;
      }
      return STATE_NONE;
    },
    inputType: function () {
      if (this.showPassword === true) {
        return TYPE_STRING;
      }
      return TYPE_PASSWORD;
    },
  },
});
</script>
