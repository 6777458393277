<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :notifiers="notifiers"
    :deletionHandler="deletionHandler"
    @start-notifier-deletion="startNotifierDeletion"
    @cancel-notifier-deletion="cancelNotifierDeletion"
    @comfirm-notifier-deletion="(e) => deletionHandler.submit(e)"
    @update-notifier="updateNotifier"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/notifiers/List.vue";
import {
  DeleteNotifierHandler,
  EditNotifierActivityHandler,
} from "@/lib/handlers";
import { ensureNotifiers, listNotifiers } from "@/store/notifiers";

export default defineComponent({
  name: "NotifiersContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deletionHandler: null,
    };
  },
  computed: {
    notifiers() {
      return listNotifiers(this.$store);
    },
  },
  created() {
    ensureNotifiers(this.$store);
  },
  methods: {
    startNotifierDeletion({ notifier }) {
      this.deletionHandler = new DeleteNotifierHandler(this, notifier);
    },
    cancelNotifierDeletion() {
      this.deletionHandler = null;
    },
    // Only use to toggle `notifier.active` in this context
    updateNotifier({ notifier, active }) {
      const handler = new EditNotifierActivityHandler(this, notifier);
      handler.submit(active);
    },
  },
});
</script>
