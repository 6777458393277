<template>
  <Form
    ref="form"
    v-slot="{ meta, handleSubmit }"
    as="div"
  >
    <OCDDescription :manifest="manifest" />

    <form @submit.prevent="handleSubmit(submit)">
      <SCAlert
        v-if="noEnv"
        class="flex-grow"
        kind="info"
        :title="$t('noEnv.title')"
      >
        {{ $t("noEnv.content", { name: manifest.name }) }}
      </SCAlert>

      <Field
        v-for="variable in variables"
        v-else
        v-slot="{ field, errors, handleChange }"
        :key="variable.name"
        v-model="form[variable.name]"
        :name="variable.name"
        :rules="variable.rules"
      >
        <TextInput
          :name="field.name"
          :modelValue="field.value"
          :label="variable.name"
          :errors="errors"
          :placeholder="variable.placeholder"
          :required="variable.rules.required"
          @update:model-value="handleChange"
        />
      </Field>

      <div class="flex">
        <SCButton
          block
          kind="primary"
          type="submit"
          size="lg"
          class="flex-grow mt-8"
          :disabled="!meta.valid"
          @click="$emit('submit', { form })"
        >
          {{ $t("action") }}
        </SCButton>
      </div>
    </form>
  </Form>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import OCDDescription from "@/components/parts/create/app/OCDDescription.vue";
import { generateVariableValue } from "@/store/variables";

export default defineComponent({
  name: "OCDEnvVarsForm",
  components: {
    SCButton,
    SCAlert,
    TextInput,
    Field,
    Form,
    OCDDescription,
  },
  props: {
    manifest: Object,
    dryRunnedApp: Object,
  },
  emits: ["formSubmitted", "submit"],
  data() {
    return {
      noEnv: false,
      variables: [],
      form: {},
    };
  },
  beforeMount() {
    this.prepareEnvVars();
  },
  methods: {
    prepareEnvVars() {
      const env = this.manifest.env;

      if (!env) {
        this.noEnv = true;
        return;
      }

      // For each variable declared in the manifest...
      Object.keys(env).forEach((name) => {
        // We create a template variable with the name, no validations rules, and a placeholder
        const variableDef = {
          name,
          rules: {},
          placeholder: env[name].description,
        };

        // We configure the required status
        if (env[name].required) {
          variableDef.rules.required = true;
        }

        // If there's a generator, we pre-populate the form object
        if (env[name].generator) {
          this.form[name] = generateVariableValue(this.dryRunnedApp, env[name]);
        } else if (env[name].value) {
          this.form[name] = env[name].value;
        }

        this.variables.push(variableDef);
      });
    },
    submit() {
      this.$emit("formSubmitted", this.form);
    },
  },
});
</script>

<i18n>
en:
  action: "Next step"
  noEnv:
    title: "All good!"
    content: "{name} does not expect any environment variable to be configured."
fr:
  action: "Étape suivante"
  noEnv:
    title: "Tout est bon!"
    content: "{name} n'attends aucune variable d'environnement pour être configuré."
</i18n>
