<template>
  <SCTable
    layout="auto"
    :headers="headers"
    :items="vouchers.items"
    :paginated="true"
    :searchable="true"
    :searchFn="searchFn"
  >
    <template #item.expiration="{ item }">
      <div class="flex items-center">
        <TwoStatesIndicator
          class="mr-2"
          :isOn="!item.expire_at || item.expire_at >= currentDate"
          :alternativeColors="true"
        />
        <div
          v-if="item.expire_at < currentDate"
          class="text-scale-5"
        >
          {{ $t("expiredSince") }}
          {{ humanizedMidDateTime(item.expire_at) }}
        </div>
        <div v-else-if="item.expire_at >= currentDate">
          {{ $t("validUntil") }}
          {{ humanizedMidDateTime(item.expire_at) }}
        </div>

        <template v-else>
          {{ $t("never") }}
        </template>
      </div>
    </template>

    <template #item.redeemed="{ item }">
      <SimpleDate
        :class="{ 'text-scale-5': item.expire_at <= currentDate }"
        :value="item.redeemed_at"
      />
    </template>

    <template #item.code="{ item }">
      <div :class="{ 'text-scale-5': item.expire_at <= currentDate }">
        {{ item.code }}
      </div>
    </template>

    <template #item.amounts="{ item }">
      <template v-if="item.type === 'money_voucher'">
        <div :class="{ 'text-scale-5': item.expire_at <= currentDate }">
          <span class="capitalize">
            {{ $ta("voucher", "initialAmount") }}
          </span>
          :
          {{ formatAmount(item.content.amount) }}
          <br>
          <div v-if="item.content.amount_per_month">
            <span class="capitalize">
              {{ $ta("voucher", "maxMonthlyAmount") }}
            </span>
            :
            {{ formatAmount(item.content.amount_per_month) }}
            <br>
          </div>
          <span class="capitalize">
            {{ $ta("voucher", "remainingAmount") }}
          </span>
          :
          {{ formatAmount(item.content.remaining_amount) }}
        </div>
      </template>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SimpleDate from "@/components/atoms/presenters/SimpleDate.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import { formatAmount } from "@/lib/utils/currency";
import { humanizedMidDateTime } from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "VouchersListBody",
  components: {
    SimpleDate,
    SCTable,
    TwoStatesIndicator,
  },
  mixins: [ModelsTranslation],
  props: {
    vouchers: Object,
  },
  data() {
    return {
      currentDate: new Date().toISOString(),
      headers: [
        {
          value: "expiration",
          text: this.$ta("voucher", "expiration"),
          class: "humanize w-3/12",
        },
        {
          value: "redeemed",
          text: this.$ta("voucher", "redeemed"),
          class: "humanize w-2/12",
        },
        {
          value: "code",
          text: this.$ta("voucher", "voucherCode"),
          class: "humanize w-3/12",
        },
        {
          value: "amounts",
          text: this.$i18n.t("amounts"),
          class: "humanize w-4/12",
        },
      ],
      searchFn(row, search) {
        return !![row.code].find((col) => {
          return (
            col
              .toString()
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase()) !== -1
          );
        });
      },
    };
  },
  methods: {
    formatAmount,
    humanizedMidDateTime,
  },
});
</script>

<i18n>
en:
  amounts: "amounts"
  never: "Doesn't expire"
  expiredSince: "Expired since"
  validUntil: "Valid until"
fr:
  amounts: "montants"
  never: "N'expire jamais"
  expiredSince: "Expiré depuis"
  validUntil: "Valide jusqu'au"
</i18n>
