<template>
  <ConfirmModal
    :immediate="false"
    kind="danger"
    :promiseInfo="context.info"
    :strongConfirmation="context.user.name"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-xl"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <p class="mt-2">
      {{ $t("disclaimer") }}
    </p>

    <SCAlert
      v-if="context.info?.isError"
      class="my-4"
      kind="error"
    >
      <p class="px-4 font-bold">
        {{ context.error }}
      </p>
    </SCAlert>
  </ConfirmModal>
</template>

<script>
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";

export default defineComponent({
  name: "PasswordResetModal",
  components: { ConfirmModal, SCAlert },
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "confirm"],
});
</script>

<i18n>
en:
  title: "Reset password"
  disclaimer: "A secure password will be generated automatically."
  button: "Reset"
fr:
  title: "Réinitialiser le mot de passe"
  disclaimer: "Un mot de passe sécurisé sera généré automatiquement."
  button: "Réinitialiser"
</i18n>
