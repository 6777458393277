<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :events="events"
    :eventsComplements="eventsComplements"
    @view-more="viewMore"
    @body-opened="bodyOpened"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/activity/All.vue";
import { ensureAuditLog, findAuditLogs } from "@/store/audit-logs";
import { ensureEvents, fetchMoreEvents, listEvents } from "@/store/events";

export default defineComponent({
  name: "ActivityAllContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { eventsComplements: [] };
  },
  computed: {
    events() {
      return listEvents(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
      });
    },
  },
  created() {
    ensureEvents(this.$store, { staleAfter: "always" });
  },
  methods: {
    bodyOpened(eventArgs) {
      if (eventArgs.event.type === "run") {
        this.complementRunEvent(eventArgs);
      }
    },
    async complementRunEvent(eventArgs) {
      if (!("audit_log_id" in eventArgs.event.type_data)) {
        return;
      }
      const auditLogId = eventArgs.event.type_data.audit_log_id;

      const eventComplement = { eventId: eventArgs.event.id, data: null };

      const operation = await ensureAuditLog(
        this.$store,
        eventArgs.event.type_data.audit_log_id,
      );

      try {
        await operation.promise;
        await this.$nextTick();

        eventComplement.data = findAuditLogs(this.$store, auditLogId);
      } catch (e) {
        eventComplement.error = e;
      } finally {
        this.eventsComplements.push(eventComplement);
      }
    },
    viewMore() {
      fetchMoreEvents(this.$store);
    },
  },
});
</script>
