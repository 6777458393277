<template>
  <ViewComponent
    :tokens="tokens"
    :shownToken="shownToken"
    :createTokenHandler="createTokenHandler"
    :deleteTokenHandler="deleteTokenHandler"
    :renewTokenHandler="renewTokenHandler"
    @dismiss-shown-token="dismissShownToken"
    @start-token-creation="startTokenCreation"
    @cancel-token-creation="cancelTokenCreation"
    @confirm-token-creation="(e) => createTokenHandler.submit(e)"
    @start-token-deletion="startTokenDeletion"
    @cancel-token-deletion="cancelTokenDeletion"
    @confirm-token-deletion="(e) => deleteTokenHandler.submit(e)"
    @start-token-renewal="startTokenRenewal"
    @cancel-token-renewal="cancelTokenRenewal"
    @confirm-token-renewal="(e) => renewTokenHandler.submit(e)"
  />
</template>

<script>
import { sortBy } from "lodash";
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/account/Tokens.vue";
import {
  CreateTokenHandler,
  DeleteTokenHandler,
  RenewTokenHandler,
} from "@/lib/handlers";
import { ensureTokens, allTokens } from "@/store/tokens";

export default defineComponent({
  name: "TokensCtrl",
  components: { ViewComponent },
  data() {
    return {
      createTokenHandler: null,
      deleteTokenHandler: null,
      renewTokenHandler: null,
      shownToken: null,
    };
  },
  computed: {
    tokens() {
      return allTokens(this.$store, {
        transform(collection) {
          return sortBy(
            collection,
            (item) => item.last_used_at || item.created_at,
          ).reverse();
        },
      });
    },
  },
  beforeMount() {
    ensureTokens(this.$store);
  },
  methods: {
    startTokenDeletion({ token }) {
      this.deleteTokenHandler = new DeleteTokenHandler(this, token);
    },
    cancelTokenDeletion() {
      this.deleteTokenHandler = null;
    },
    startTokenCreation() {
      this.createTokenHandler = new CreateTokenHandler(this);

      this.createTokenHandler.on("success", (token) => {
        this.shownToken = {
          context: "creation",
          token: token.token,
        };
      });
    },
    cancelTokenCreation() {
      this.createTokenHandler = null;
    },
    startTokenRenewal({ token }) {
      this.renewTokenHandler = new RenewTokenHandler(this, token);

      this.renewTokenHandler.on("success", (token) => {
        this.shownToken = {
          context: "renewal",
          token: token.token,
        };
      });
    },
    cancelTokenRenewal() {
      this.renewTokenHandler = null;
    },
    dismissShownToken() {
      this.shownToken = null;
    },
  },
});
</script>
