<template>
  <div class="flex flex-col">
    <router-link
      v-slot="{ href, navigate }"
      custom
      :to="withDeployConfigLink ? deployConfigRoute : {}"
    >
      <component
        :is="withDeployConfigLink ? 'a' : 'div'"
        :href="withDeployConfigLink ? href : null"
        class="text-sm font-medium pt-2 uppercase tracking-wide text-scale-5"
        :class="{
          'hover:underline': withDeployConfigLink,
          'cursor-pointer': withDeployConfigLink,
        }"
        @click="navigate"
      >
        <template v-if="scmRepoLink && scmRepoLink._isGithub">
          {{ $t("deployMethod.github") }}
          <font-awesome-icon
            :icon="['fab', 'github']"
            class="ml-1"
          />
        </template>
        <template v-else-if="scmRepoLink && scmRepoLink._isGitlab">
          {{ $t("deployMethod.gitlab") }}
          <font-awesome-icon
            :icon="['fab', 'gitlab']"
            class="ml-1"
          />
        </template>
        <template v-else-if="gitURL">
          {{ $t("deployMethod.git") }}
          <font-awesome-icon
            :icon="['fab', 'git-alt']"
            class="ml-1"
          />
        </template>
      </component>
    </router-link>

    <div
      v-if="gitURL"
      class="font-medium flex flex-row items-center mt-1"
    >
      <a
        class="hover:underline cursor-pointer"
        @click="(e) => $emit('copyGitRemote', e)"
      >
        {{ gitURL }}
      </a>
    </div>
    <div
      v-else-if="scmRepoLink"
      class="font-medium truncate group"
      :class="{ 'mt-1': moreSpacing }"
      :title="`${scmRepoLink.owner}/${scmRepoLink.repo}`"
    >
      <a
        :href="scmRepoLink._ownerUrl"
        class="hover:underline"
        target="_blank"
      >{{ this.scmRepoLink.owner }}</a>
      /
      <a
        :href="scmRepoLink._repoUrl"
        target="_blank"
        class="hover:underline"
      >
        {{ this.scmRepoLink.repo }}
      </a>
      <template v-if="app._isReviewApp">
        <a
          :href="prLink"
          class="hover:underline"
          target="_blank"
        >
          {{ prPrefix }}{{ app._prNum }}
        </a>
      </template>
      <span>
        <font-awesome-icon
          class="ml-1 invisible group-hover:visible"
          icon="external-link-alt"
        />
      </span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

import { Routes } from "@/router/names";

export default defineComponent({
  name: "SCMIntegrationLinks",
  components: {},
  props: {
    app: { type: Object },
    scmRepoLink: { type: Object },
    moreSpacing: {
      type: Boolean,
      default: false,
    },
    withDeployConfigLink: {
      type: Boolean,
      default: false,
    },
    gitURL: String,
  },
  emits: ["copyGitRemote"],
  computed: {
    deployConfigRoute() {
      return {
        name: Routes.App.Deploy.Configuration,
        params: { region: this.app.region, id: this.app.name },
      };
    },
    prPrefix() {
      if (!this.app._isReviewApp) {
        return null;
      }

      return this.scmRepoLink?._isGithub ? "#" : "!";
    },
    prLink() {
      if (!this.app._isReviewApp) {
        return null;
      }

      if (this.scmRepoLink?._isGithub) {
        return `${this.scmRepoLink._repoUrl}/pull/${this.app._prNum}`;
      }

      return `${this.scmRepoLink._repoUrl}/-/merge_requests/${this.app._prNum}`;
    },
    repoLinkLabel() {
      if (this.scmRepoLink?._isGithub) {
        return this.$i18n.t("deployMethod.github");
      }
      return this.$i18n.t("deployMethod.gitlab");
    },
  },
});
</script>

<i18n>
en:
  deployMethod:
    github: "Github integration"
    gitlab: "Gitlab integration"
    git: "Git push"
fr:
  deployMethod:
    github: "Integration github"
    gitlab: "Integration gitlab"
    git: "Git push"
</i18n>
