export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "emptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data for the selected period"])},
        "label": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App name"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
          "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
          "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon"])}
        },
        "notInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document is not an invoice, but a breakdown of your consumption excluding VAT. It takes neither the free trial period nor any vouchers or credits into account."])},
        "exclVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. VAT"])},
        "overusage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overusage"])},
        "count": {
          "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 app"]), _normalize(["1 app"]), _normalize([_interpolate(_named("count")), " apps"])])},
          "resource": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 resource"]), _normalize(["1 resource"]), _normalize([_interpolate(_named("count")), " resources"])])}
        }
      },
      "fr": {
        "emptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée pour la période sélectionnée"])},
        "label": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'app"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
          "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressource"])},
          "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
          "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addon"])}
        },
        "notInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce document n'est pas une facture, mais un décompte de vos consommations hors TVA. Il ne tient pas compte de la période d'essai gratuite ni des éventuels bons ou crédits."])},
        "exclVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. TVA"])},
        "overusage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surconsommation"])},
        "count": {
          "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 app"]), _normalize(["1 app"]), _normalize([_interpolate(_named("count")), " apps"])])},
          "resource": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 ressource"]), _normalize(["1 ressource"]), _normalize([_interpolate(_named("count")), " ressources"])])}
        }
      }
    }
  })
}
