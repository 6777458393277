export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators of your applications"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the list of all collaborators of your applications."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although this view presents a consolidated list, collaborators are managed individually in each application."])},
        "apps": {
          "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App name"])}
        },
        "collaborators": {
          "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator username"])}
        }
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs de vos applications"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez la liste de tous les collaborateurs de vos applications."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien que cette vue présente une liste consolidée, la gestion des collaborateurs s’effectue individuellement pour chaque application."])},
        "apps": {
          "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'app"])}
        },
        "collaborators": {
          "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur du collaborateur"])}
        }
      }
    }
  })
}
