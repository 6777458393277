<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')">
        <template
          v-if="userIsOwner"
          #buttons
        >
          <SCButton :to="collaboratorsRoute">
            {{ $t("manage") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <template v-if="userIsOwner">
        <p>{{ $t("allowed") }}</p>

        <FormAlert
          v-if="
            collaborators.latestFetch.isFinished &&
              collaborators.items.length === 0
          "
          class="mt-4"
          styling="warning"
        >
          <template #text>
            {{ $t("noCollaborators") }}
          </template>
        </FormAlert>

        <SCButton
          v-if="!userIsC3Customer"
          class="mt-4"
          :loading="collaborators.latestFetch.isLoading"
          :disabled="collaborators.items.length === 0"
          @click="$emit('click')"
        >
          {{ $t("action") }}
        </SCButton>
      </template>

      <FormAlert
        v-else
        class="mt-4"
        styling="warning"
      >
        <template #text>
          {{ $t("notAllowed", { name: app.owner.username }) }}
        </template>
      </FormAlert>

      <SCAlert
        v-if="userIsC3Customer"
        class="mt-4"
        kind="warning"
        :title="$t('c3_warning.title')"
      >
        {{ $t("c3_warning.message") }}
      </SCAlert>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { isC3Customer } from "@/lib/utils/billing-profile";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "TransferAppCard",
  components: {
    SCAlert,
    CardHeader,
    Card,
    SCButton,
    FormAlert,
  },
  props: {
    app: Object,
    user: Object,
    billingProfile: Object,
    collaborators: Object,
  },
  emits: ["click"],
  data() {
    return {};
  },
  computed: {
    collaboratorsRoute() {
      return { name: Routes.App.Collaborators };
    },
    userIsOwner() {
      return this.user.id === this.app.owner.id;
    },
    userIsC3Customer() {
      return isC3Customer(this.billingProfile);
    },
  },
});
</script>

<i18n>
en:
  title: "Transfer app ownership"
  action: "Transfer ownership"
  manage: "Manage collaborators"
  noCollaborators: "No collaborators for this application."
  allowed: "As the owner of this application, you are the only one who has the right to transfer its ownership to an existing collaborator."
  notAllowed: "Only {name}, owner of the app is allowed to transfer its ownership."
  c3_warning:
    title: "Please contact our support team"
    message: "It is not possible to transfer an app outside the UGAP billing account."
fr:
  title: "Transférer la propriété de l'application"
  action: "Transférer la propriété"
  manage: "Gérer les collaborateurs"
  noCollaborators: "Aucun collaborateur pour cette application."
  allowed: "En tant que propriétaire de cette application, vous seul·e êtes autorisé·e à transférer sa propriété à un collaborateur ou une collaboratrice existante."
  notAllowed: "Seul·e {name}, propriétaire de l'application, peut en transférer la propriété."
  c3_warning:
    title: "Merci de contacter notre équipe support"
    message: "Il n’est pas possible de transférer une application en dehors du compte de facturation lié à l’UGAP."
</i18n>
