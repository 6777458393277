<template>
  <div class="flex mt-4 items-center">
    <div class="w-1/3 flex place-items-center">
      <CountPager
        :itemTotal="items.length"
        :itemsShowed="itemsShowed"
        :itemName="itemName"
      />

      <Spinner
        v-if="loading"
        class="ml-2"
      />
    </div>
    <div class="w-1/3 flex justify-center">
      <PagesPager
        v-if="kind === 'pager'"
        :data="pagerData"
        @page-changed="(e) => $emit('pageChanged', e)"
      />

      <template v-if="kind === 'more'">
        <LinkButton
          v-if="hasMore"
          @clicked="$emit('fetchMore')"
        >
          {{ $t("fetchMore") }}
        </LinkButton>
      </template>
    </div>
    <div class="w-1/3" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LinkButton from "@/components/atoms/buttons/LinkButton.vue";
import CountPager from "@/components/atoms/pagers/CountPager.vue";
import PagesPager from "@/components/atoms/pagers/PagesPager.vue";
import Spinner from "@/components/atoms/spinners/Spinner.vue";
import { paginate } from "@/lib/utils/pagination";

export default defineComponent({
  name: "SCPagination",
  components: { CountPager, PagesPager, LinkButton, Spinner },
  props: {
    kind: {
      type: String,
      default: "pager",
      validator(value) {
        return ["pager", "more"].includes(value);
      },
    },
    items: Array,
    perPage: Number, // only relevant for "pager"
    currentPage: Number, // only relevant for "pager"
    itemName: {
      type: String,
      default: null,
    },
    hasMore: Boolean, // only relevant for "more"
    loading: Boolean, // only relevant for "more"
  },
  emits: ["fetchMore", "pageChanged"],
  computed: {
    pagerData() {
      return paginate(this.items.length, this.currentPage, this.perPage);
    },
    pageCount() {
      return this.pagerData.totalPages;
    },
    itemsShowed() {
      if (this.kind === "more") {
        return this.items.length;
      }

      if (this.items.length === 0 || this.items.length === 1) {
        return this.items.length;
      }

      const onlyOnePageInPager =
        this.pagerData.startPage === this.pagerData.endPage;

      if (onlyOnePageInPager) {
        return this.items.length;
      }

      return (
        `${this.pagerData.startIndex + 1} ` +
        this.$t("to") +
        ` ${this.pagerData.endIndex + 1}`
      );
    },
  },
});
</script>

<i18n>
en:
  fetchMore: "Fetch more"
  to: "to"
fr:
  fetchMore: "Charger plus"
  to: "à"
</i18n>
