<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      />
    </template>
    <template #body>
      <div class="mt-4">
        <table class="w-full bg-scale-0 table-auto">
          <thead>
            <tr class="text-sm h-8 text-scale-6 font-normal text-left rounded">
              <th class="px-4 font-normal bg-scale-1 rounded-l">
                {{ $t("headers.type") }}
              </th>
              <th class="px-4 font-normal bg-scale-1">
                {{ $t("headers.status") }}
              </th>
              <th class="px-4 font-normal bg-scale-1 rounded-r">
                {{ $t("headers.applyDate") }}
              </th>
            </tr>
          </thead>
          <tbody
            v-if="
              dbMaintenance.promiseInfo.isSuccess === true &&
                dbMaintenance.items.length > 0
            "
          >
            <template
              v-for="(maintenance, key) in dbMaintenance.items"
              :key="key"
            >
              <tr @click="toggleDescription(maintenance)">
                <td class="px-4 py-3 text-scale-10 text-base font-normal">
                  {{ maintenance.type }}
                </td>
                <td class="px-4 py-3">
                  <div class="flex flex-row space-x-1.5 items-center">
                    <FiveStatesIndicator
                      :isOk="maintenance.status === 'done'"
                      :isKo="
                        ['failed', 'cancelled'].includes(maintenance.status)
                      "
                      :isNo="maintenance.status === 'scheduled'"
                      :isNotified="
                        ['notified', 'queued'].includes(maintenance.status)
                      "
                      :isOngoing="maintenance.status === 'running'"
                    />
                    <span>{{ $t("status." + maintenance.status) }}</span>
                  </div>
                </td>
                <td
                  class="px-4 py-3 flex flex-row items-center justify-between w-full"
                >
                  <div>
                    <span v-if="maintenance.started_at">{{
                      formatDateTime(maintenance.started_at)
                    }}</span>
                    <span v-if="maintenance.ended_at">
                      {{ $t("to") }}
                      {{ formatDateTime(maintenance.ended_at) }}</span>
                    <span
                      v-if="!maintenance.started_at && !maintenance.ended_at"
                    >-</span>
                  </div>
                  <div v-if="maintenance.description">
                    <font-awesome-icon
                      v-if="expandedMaintenances[maintenance.id]"
                      icon="chevron-up"
                    />
                    <font-awesome-icon
                      v-else
                      icon="chevron-down"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="expandedMaintenances[maintenance.id] === true">
                <td
                  colspan="3"
                  class="px-4 py-2 bg-scale-1"
                >
                  <div class="flex flex-col">
                    <div class="my-2.5">
                      {{ $t("headers.description") }}
                    </div>
                    <div class="text-scale-6 text-sm text-base font-medium">
                      {{ maintenance.description }}
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr class="text-center">
              <td
                class="text-scale-6 font-medium pt-4"
                colspan="3"
              >
                {{ $t("empty") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Card>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import FiveStatesIndicator from "@/components/atoms/indicators/FiveStatesIndicator.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { formatDateTime } from "@/lib/utils/time";

export default {
  name: "MaintenanceListCard",
  components: { FiveStatesIndicator, FontAwesomeIcon, Card, CardHeader },
  props: {
    dbMaintenance: Object,
  },
  data() {
    return {
      expandedMaintenances: {},
    };
  },
  methods: {
    formatDateTime,
    toggleDescription(maintenance) {
      if (!this.dbMaintenance.description) {
        return;
      }
      this.expandedMaintenances[maintenance.id] =
        !this.expandedMaintenances[maintenance.id];
    },
  },
};
</script>

<i18n>
en:
  title: "Maintenance operations list"
  subtitle: "Maintenance operations scheduled or carried out in the last 12 months."
  headers:
    type: "Type"
    status: "Status"
    applyDate: "Apply date"
    description: "Description"
  status:
    done: "Done"
    failed: "Failed"
    cancelled: "Cancelled"
    scheduled: "Scheduled"
    notified: "Confirmed"
    queued: "Confirmed"
    running: "Running"
  empty: "No maintenance operations have been carried out on this database."
  to: " to "
fr:
  title: "Liste des opérations de maintenance"
  subtitle: "Opérations de maintenance planifiées ou effectuées au cours des 12 derniers mois."
  headers:
    type: "Type"
    status: "Statut"
    applyDate: "Date d'application"
    description: "Description"
  status:
    done: "Terminée"
    failed: "Echouée"
    cancelled: "Annulée"
    scheduled: "Planifiée"
    notified: "Confirmée"
    queued: "Confirmée"
    running: "En cours"
  empty: "Aucune opération de maintenance n'a été effectuée sur cette base de données."
  to: " à "
</i18n>
