<template>
  <div
    class="border rounded-sm p-4"
    :class="{
      'bg-primary-2-10': current,
      'border-primary-2': current,
      'border-primary-2-60': !current,
    }"
  >
    <div class="h-24 md:h-36 lg:h-24">
      <h3 class="text-lg font-bold leading-6">
        {{ title }}
      </h3>
      <p class="text-scale-6 text-sm mt-2">
        {{ description }}
      </p>
    </div>
    <SCButton
      kind="primary"
      :disabled="current"
      @click="openHomepageSupportForm"
    >
      {{ current ? $t("selectedPlan") : $t("notSelectedPlan") }}
    </SCButton>

    <hr class="my-4">

    <div class="text-sm md:h-24 lg:h-12 mb-5">
      <slot name="pricing" />
    </div>
    <ul class="text-scale-9 text-sm space-y-5">
      <li
        v-for="feature in features"
        :key="feature.title"
      >
        <span class="font-bold">{{ feature.title }}</span>
        <p v-html="feature.description" />
      </li>
    </ul>
  </div>
</template>

<script>
import SCButton from "@/components/molecules/buttons/SCButton.vue";

export default {
  name: "SupportPlanCard",
  components: {
    SCButton,
  },
  props: {
    title: String,
    description: String,
    current: Boolean,
    features: Array,
  },
  methods: {
    openHomepageSupportForm() {
      const BLOG_HOST = process.env.VUE_APP_SCALINGO_BLOG_HOST;
      const url =
        this.$i18n.locale === "fr"
          ? `https://${BLOG_HOST}/fr/offres-support/nous-contacter`
          : `https://${BLOG_HOST}/support-plans/contact-us`;

      window.location = url;
    },
  },
};
</script>

<style scoped>
.border-primary-2-60 {
  border-color: rgba(61, 90, 241, 0.6);
}
</style>

<i18n>
  en:      
    selectedPlan: "Current plan"
    notSelectedPlan: "Contact us"
  fr:      
    selectedPlan: "Plan actuel"
    notSelectedPlan: "Contactez-nous"
</i18n>
