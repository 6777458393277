<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :scmRepoLink="scmRepoLink"
    :scmBranches="scmBranches"
    :deployHandler="deployHandler"
    :listBranchesInfo="listBranchesInfo"
    @manual-deploy="(e) => deployHandler.submit(e)"
    @fetch-scm-repo-link-branches="fetchScmRepoLinkBranches"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/deploy/Manual.vue";
import { CreateDeploymentHandler } from "@/lib/handlers";
import { promiseInfo } from "@/lib/promises/info";
import { scalingoClient } from "@/lib/scalingo/client";
import {
  listBranchesForAppRepoLink,
  orderedBranches,
} from "@/lib/scm-repo-links";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DeployManualContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    scmRepoLink: Object,
  },
  data() {
    return {
      listBranchesInfo: null,
      deployHandler: new CreateDeploymentHandler(this),
    };
  },
  computed: {
    scmBranches() {
      return orderedBranches(this.listBranchesInfo?.data || []);
    },
  },
  beforeMount() {
    if (!this.scmRepoLink.value) {
      this.$router.push({ name: Routes.App.Deploy.Root });
    }

    if (!this.app.parent_app_name) {
      this.fetchScmRepoLinkBranches();
    }
  },
  methods: {
    fetchScmRepoLinkBranches() {
      this.listBranchesInfo = promiseInfo(
        listBranchesForAppRepoLink(
          scalingoClient(this.$store, this.app.region),
          this.app,
        ),
      );
    },
  },
});
</script>
