<template>
  <main class="container px-4 mt-6 pv:mt-0">
    <SideMenuLayout>
      <template #side>
        <Menu
          :billingProfile="billingProfile"
          :hasCredits="hasCredits"
          :hasOrders="hasOrders"
        />
      </template>
      <template #router>
        <router-view />
      </template>
    </SideMenuLayout>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SideMenuLayout from "@/components/layouts/SideMenuLayout.vue";
import Menu from "@/components/organisms/menus/BillingMenu.vue";

export default defineComponent({
  name: "Root",
  components: { SideMenuLayout, Menu },
  props: {
    billingProfile: Object,
    hasCredits: Boolean,
    hasOrders: Boolean,
  },
});
</script>
