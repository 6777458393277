export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support plans"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the coverage and support levels you need to ensure the performance and resilience of your projects."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When we set up your plan, don’t forget to send us the list of team members from your apps with whom you’d like to share the benefits of your subscription. This will allow us to activate their access without delay."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres de support"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez les garanties et niveaux d’assistance dont vous avez besoin pour assurer la performance et la résilience de vos projets."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la mise en place de votre plan, pensez à nous envoyer la liste des collaborateurs de vos applications avec qui vous souhaitez partager les bénéfices de votre souscription. Cela nous permettra d’activer leur accès sans délai."])}
      }
    }
  })
}
