<template>
  <div
    v-if="reviewApps.latestFetch"
    class="bg-scale-0 rounded px-8 py-6"
  >
    <CardHeader
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    >
      <template #buttons>
        <SCButton
          class="place-self-center"
          :to="manageRouteArgs"
        >
          {{ $t("action") }}
        </SCButton>
      </template>
    </CardHeader>

    <template v-if="reviewApps.latestFetch.isLoading">
      <LoadingCardState />
    </template>

    <template v-if="reviewApps.latestFetch.isFinished">
      <div v-if="listItems.length !== 0">
        <div
          v-for="(item, index) in listItems"
          :key="index"
          class="py-3 flex justify-between flex-col pv:flex-row"
          :class="{ 'border-t border-scale-2': index > 0 }"
        >
          <div
            class="flex items-center flex-col pv:flex-row space-y-2 pv:space-y-0"
          >
            <div class="flex items-center">
              <ThreeStatesIndicator
                :isOk="item.last_deployment.status === 'success'"
                :isNo="item.last_deployment.status !== 'success'"
              />
              <div class="ml-2 whitespace-nowrap">
                <router-link
                  :to="appRouteFor(item)"
                  class="hover:underline cursor-pointer"
                >
                  {{ item.app_name }}
                </router-link>
              </div>
            </div>
            <div class="ml-4 truncate text-scale-6">
              <a
                :href="item.pull_request.html_url"
                class="hover:underline cursor-pointer"
              >
                <font-awesome-icon :icon="scmIcon" />
                {{ item.pull_request.title }}
              </a>
            </div>
          </div>
          <SimpleDate
            v-if="item.last_deployment && item.last_deployment.created_at"
            class="mt-2 pv:mt-0 text-center"
            :value="item.last_deployment.created_at"
          />
        </div>

        <CardFooter
          v-if="listItems.length !== 0"
          :withViewAllButton="true"
          :routeArgs="listRouteArgs"
        />
      </div>
      <EmptyCardState v-if="listItems.length === 0">
        <template #icon>
          <BoxGlyph class="h-8 text-scale-5" />
        </template>

        {{ $t("noReviewApps") }}
      </EmptyCardState>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BoxGlyph from "@/components/atoms/glyphs/BoxGlyph.vue";
import ThreeStatesIndicator from "@/components/atoms/indicators/ThreeStatesIndicator.vue";
import SimpleDate from "@/components/atoms/presenters/SimpleDate.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ReviewAppsCard",
  components: {
    EmptyCardState,
    LoadingCardState,
    BoxGlyph,
    CardHeader,
    CardFooter,
    SCButton,
    ThreeStatesIndicator,
    SimpleDate,
  },
  props: {
    reviewApps: Object,
    scmRepoLink: Object,
    app: Object,
  },
  data() {
    return {
      manageRouteArgs: {
        name: Routes.App.ReviewApps.Configuration,
        args: {
          region: this.app.region,
          id: this.app.name,
        },
      },
      listRouteArgs: {
        name: Routes.App.ReviewApps.List,
        args: {
          region: this.app.region,
          id: this.app.name,
        },
      },
    };
  },
  computed: {
    listItems() {
      return this.reviewApps.items;
    },
    scmIcon() {
      if (["github", "github-enterprise"].includes(this.scmRepoLink.scm_type)) {
        return ["fab", "github"];
      } else {
        return ["fab", "gitlab"];
      }
    },
  },
  methods: {
    appRouteFor(reviewApp) {
      return {
        name: Routes.App.Overview,
        params: { id: reviewApp.app_name, region: this.app.region },
      };
    },
  },
});
</script>

<i18n>
en:
  title: "Review apps"
  subtitle: "Latest review apps."
  noReviewApps: "No review apps yet"
  action: "Configure"
fr:
  title: "Review apps"
  subtitle: "Dernières review apps."
  noReviewApps: "Aucune review app"
  action: "Configurer"
</i18n>
