<template>
  <StepCard
    :step="1"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-5xl"
  >
    <LoadingCardState v-if="isLoading" />
    <MetricsChoiceForm
      v-else
      :containers="containers"
      :formHandler="formHandler"
      @form-submitted="(e) => $emit('formSubmitted', e)"
    />
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import MetricsChoiceForm from "@/components/parts/appSettings/alertsCreation/MetricsChoiceForm.vue";

export default defineComponent({
  name: "MetricsChoiceStep",
  components: { LoadingCardState, MetricsChoiceForm, StepCard },
  props: {
    enabled: Boolean,
    formHandler: Object,
    containers: Object,
  },
  emits: ["formSubmitted"],
  computed: {
    isLoading() {
      return !this.formHandler || this.containers.latestFetch.isLoading;
    },
  },
});
</script>

<i18n>
  en:
    title: "Trigger conditions"
    subtitle: "Configure when the alert will be triggered."
  fr:
    title: "Conditions de déclenchement"
    subtitle: "Configurez les conditions de déclenchement de l'alerte."
</i18n>
