<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title') + scmRepoLink.scm_type"
        :subtitle="$t('subtitle')"
      >
        <template #buttons>
          <SCButton
            v-if="!linkedByUser"
            :loading="isChangingLinker"
            @click="$emit('changeLinker')"
          >
            {{ $t("setMe") }}
          </SCButton>
          <SCButton
            class="ml-2"
            :loading="isUnlinking"
            @click="$emit('unlinkRepository')"
          >
            {{ $t("unlink") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="py-3">
        {{ $t("linkedTo") }}
        <a
          class="hover:underline"
          :href="url"
        >
          <span class="font-bold">
            {{ scmRepoLink.owner }} / {{ scmRepoLink.repo }}
          </span>
        </a>
      </div>
      <div class="py-3">
        <div
          v-if="operationIsLoading"
          class="flex items-center"
        >
          <Spinner />
          <span class="ml-2">
            {{ $t("updating") }}
          </span>
        </div>
        <template v-else>
          {{ $t("linkedBy") }}
          <template v-if="linkedByUser">
            <span class="font-bold">{{ $t("generic.you") }}</span>
          </template>
          <template v-else>
            <span class="font-bold">{{ scmRepoLink.linker.username }}</span>
            <span class="text-scale-7 pl-1">
              ({{ scmRepoLink.linker.email }})
            </span>
          </template>
        </template>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Spinner from "@/components/atoms/spinners/Spinner.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "DeploymentsViaCard",
  components: { SCButton, Card, CardHeader, Spinner },
  props: {
    user: Object,
    scmRepoLink: Object,
    repoLinkOperation: Object,
    deleteSCMRepoLinkHandler: Object,
    editSCMRepoLinkerHandler: Object,
  },
  emits: ["changeLinker", "unlinkRepository"],
  computed: {
    url() {
      return (
        this.scmRepoLink.url +
        "/" +
        this.scmRepoLink.owner +
        "/" +
        this.scmRepoLink.repo
      );
    },
    operationIsLoading() {
      return this.repoLinkOperation?.isLoading || false;
    },
    isUnlinking() {
      return this.deleteSCMRepoLinkHandler?.isSubmitting;
    },
    isChangingLinker() {
      return this.editSCMRepoLinkerHandler?.isSubmitting;
    },
    linkedByUser() {
      return this.scmRepoLink.linker.id === this.user.id;
    },
  },
});
</script>

<i18n>
en:
  title: "Deployments via "
  subtitle: "Deployed code configuration."
  linkedTo: "Linked to"
  linkedBy: "Linked by"
  setMe: "Set me as linker"
  unlink: "Unlink"
  updating: "Configuration is being updated..."
fr:
  title: "Déploiements via "
  subtitle: "Configuration du code à déployer."
  linkedTo: "Associé à"
  linkedBy: "Associé par"
  setMe: "Utiliser mon compte pour associer le dépot"
  unlink: "Désassocier"
  updating: "Configuration en cours de mise à jour..."
</i18n>
