<template>
  <apexchart
    ref="apex"
    type="line"
    :options="chartOptions"
    :series="chartData"
    height="300px"
  />
</template>

<script>
import { defineComponent } from "vue";

import Common from "@/components/organisms/apex/Common.vue";

/** Rounds a number to the closest multiple */
function roundUp(source, multiple) {
  if (multiple == 0) return source;

  const remainder = source % multiple;
  if (remainder == 0) return source;

  return source + multiple - remainder;
}

export default defineComponent({
  name: "CPUGraph",
  mixins: [Common],
  computed: {
    maxCpu() {
      let max = 100;
      const series = this.chartData && this.chartData[0];

      if (series && series.data) {
        max = Math.max(max, ...series.data.map((x) => x[1]));
      }

      // Always round by thresholds of 50%
      return roundUp(max, 50);
    },
    title() {
      return this.hasIndex
        ? `CPU – ${this.container.name}-${this.index}`
        : `CPU – ${this.container.name} ${this.container.amount}:${this.container.size}`;
    },
    chartOptions() {
      return {
        ...this.chartDefaults(),
        colors: ["rgba(24,59,238,1)"],
        fill: {
          type: "solid",
          opacity: 1,
          colors: ["#d7dcf8"],
        },
        yaxis: {
          min: 0,
          max: this.maxCpu,
          labels: {
            minWidth: 60,
            maxWidth: 60,
            formatter: (value) => `${value.toFixed(0)}%`,
          },
        },
      };
    },
  },
});
</script>
