<template>
  <main>
    <PaymentMethodsList
      :billingProfile="billingProfile"
      :paymentMethods="paymentMethods"
      :deleteHandler="deletePaymentMethodHandler"
      :defaultHandler="defaultPaymentMethodHandler"
      @add-sepa="sepaModalOpened"
      @add-card="cardModalOpened"
      @delete-payment-method="deletePaymentMethod"
      @make-payment-method-default="makePaymentMethodDefault"
    />

    <SepaFormModal
      v-if="sepaPaymentMethodHandler"
      :formHandler="sepaPaymentMethodHandler"
      @form-submitted="sepaFormSubmitted"
      @modal-closed="sepaModalClosed"
    />

    <CardFormModal
      v-if="cardPaymentMethodHandler"
      :formHandler="cardPaymentMethodHandler"
      :validPaymentMethods="validPaymentMethods"
      @form-submitted="cardFormSubmitted"
      @modal-closed="cardModalClosed"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardFormModal from "@/components/parts/billing/showBilling/CardFormModal.vue";
import PaymentMethodsList from "@/components/parts/billing/showBilling/PaymentMethodsList.vue";
import SepaFormModal from "@/components/parts/billing/showBilling/SepaFormModal.vue";

export default defineComponent({
  name: "PaymentMethods",
  components: {
    PaymentMethodsList,
    SepaFormModal,
    CardFormModal,
  },
  props: {
    user: Object,
    billingProfile: Object,
    paymentMethods: Object,
    cardPaymentMethodHandler: Object,
    sepaPaymentMethodHandler: Object,
    deletePaymentMethodHandler: Object,
    defaultPaymentMethodHandler: Object,
  },
  emits: [
    "cardFormSubmitted",
    "cardModalClosed",
    "cardModalOpened",
    "deletePaymentMethod",
    "makePaymentMethodDefault",
    "sepaFormSubmitted",
    "sepaModalClosed",
    "sepaModalOpened",
  ],
  data() {
    return {};
  },
  computed: {
    validPaymentMethods() {
      return this.paymentMethods.items.some(this.isValidItem);
    },
  },
  methods: {
    isValidItem(element) {
      return element.valid_for_payment;
    },
    sepaModalOpened(eventArgs) {
      this.$emit("sepaModalOpened", eventArgs);
    },
    sepaModalClosed(eventArgs) {
      this.$emit("sepaModalClosed", eventArgs);
    },
    sepaFormSubmitted(eventArgs) {
      this.$emit("sepaFormSubmitted", eventArgs);
    },
    cardModalOpened(eventArgs) {
      this.$emit("cardModalOpened", eventArgs);
    },
    cardModalClosed(eventArgs) {
      this.$emit("cardModalClosed", eventArgs);
    },
    cardFormSubmitted(eventArgs) {
      this.$emit("cardFormSubmitted", eventArgs);
    },
    makePaymentMethodDefault(paymentMethod) {
      this.$emit("makePaymentMethodDefault", paymentMethod);
    },
    deletePaymentMethod(paymentMethod) {
      this.$emit("deletePaymentMethod", paymentMethod);
    },
  },
});
</script>
