export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "noCollaborators": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven’t invited any collaborators"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo is designed to facilitate collaboration around your code. Invite your team members to join your applications to work more efficiently."])}
        },
        "noApps": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your first app and invite collaborators"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're starting from scratch. Create your first app, then invite collaborators to work as a team."])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an app"])}
        },
        "noAppsButCollab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your own apps and invite collaborators"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already collaborate on applications, but only collaborators from applications you own are displayed here."])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an app"])}
        },
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborate efficiently on Scalingo"])}
      },
      "fr": {
        "noCollaborators": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez invité aucun collaborateur"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scalingo est conçu pour faciliter la collaboration autour de votre code. Invitez les membres de votre équipe à rejoindre vos applications pour travailler plus efficacement."])}
        },
        "noApps": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre première application et invitez des collaborateurs"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous partez de zéro. Créez votre première application, puis invitez des collaborateurs pour travailler en équipe."])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une application"])}
        },
        "noAppsButCollab": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez vos propres applications et invitez des collaborateurs"])},
          "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous collaborez déjà sur des applications, mais seuls les collaborateurs des applications dont vous êtes propriétaire sont affichés ici."])},
          "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une application"])}
        },
        "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborer efficacement sur Scalingo"])}
      }
    }
  })
}
