<template>
  <SimpleModal
    v-if="show"
    @modal-closed="modalClosed"
  >
    <template #title>
      <div class="flex">
        <template v-if="isValidated">
          <div class="self-center">
            <CheckGlyph
              class="text-color-1"
              :size="4"
            />
          </div>
          <h3 class="font-bold text-lg ml-2">
            {{ $t("validated.title") }}
          </h3>
        </template>
        <template v-else>
          <h3 class="font-bold text-lg">
            {{ $t("initiate.title") }}
          </h3>
        </template>
      </div>
    </template>
    <template #subtitle>
      <div class="text-scale-7">
        <template v-if="isValidated">
          {{ $t("validated.subtitle") }}
        </template>
        <template v-else>
          {{ $t("initiate.subtitle") }}
        </template>
      </div>
    </template>
    <template #body>
      <template v-if="isValidated">
        <div class="flex border-t-2 border-scale-1 mt-4 flex-col max-w-xl">
          <p class="text-scale-7 text-justify mt-2">
            {{ $t("validated.instructions") }}
          </p>

          <div
            class="font-mono border-scale-3 border text-sm text-center mt-2 p-2 rounded"
          >
            <span
              v-for="(line, index) in codes"
              :key="index"
            >
              {{ line }}
              <br>
            </span>
          </div>

          <SCButton
            block
            kind="primary"
            type="submit"
            size="lg"
            class="flex-grow mt-8"
            @click="modalClosed"
          >
            {{ $t("validated.close") }}
          </SCButton>
        </div>
      </template>
      <template v-else>
        <div class="flex border-t-2 border-scale-1 mt-4 flex-col max-w-xl">
          <p class="text-scale-7 text-justify mt-2">
            {{ $t("initiate.qrCodeInstructions") }}
          </p>

          <div class="mt-4 p-2 bg-white self-center">
            <QrCode
              v-if="showQrCode"
              class="text-center"
              :value="tfa.value.uri"
              :size="255"
            />
          </div>

          <p class="text-scale-7 text-justify mt-2">
            {{ $t("initiate.keyInstructions") }}
          </p>

          <p
            class="font-mono border-scale-3 border text-sm text-center mt-2 p-2 justify-center rounded flex items-center"
          >
            {{ tfaSecret }}
            <span
              :title="$t('clipboard')"
              class="float-right ml-2"
            >
              <CopyButton @click="copyToClipboard" />
            </span>
          </p>

          <div class="mt-4 p-2">
            <Form
              ref="form"
              v-slot="{ meta, handleSubmit }"
              as=""
            >
              <form @submit.prevent="handleSubmit(submit)">
                <Field
                  v-slot="{ field, errors, handleChange }"
                  v-model="tfaAttempt"
                  name="attempt"
                  rules="required"
                >
                  <TextInput
                    :name="field.name"
                    :modelValue="field.value"
                    :label="$t('initiate.tfaAttemptLabel')"
                    :errors="errors"
                    :placeholder="$t('initiate.tfaAttemptPlaceholder')"
                    @update:model-value="handleChange"
                  />
                </Field>
              </form>

              <div class="flex">
                <SCButton
                  block
                  kind="primary"
                  type="submit"
                  size="lg"
                  class="flex-grow mt-8"
                  :loading="isSubmitting"
                  :disabled="!meta.valid"
                  @click="handleSubmit(submit)"
                >
                  {{ $t("initiate.submit") }}
                </SCButton>
              </div>
            </Form>
          </div>
        </div>
      </template>
    </template>
  </SimpleModal>
</template>

<script>
import QrCode from "qrcode.vue";
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import CopyButton from "@/components/organisms/buttons/CopyButton.vue";
import { copyToClipboard } from "@/lib/clipboard";

export default defineComponent({
  name: "TwoFactorActivate",
  components: {
    CopyButton,
    CheckGlyph,
    QrCode,
    SCButton,
    SimpleModal,
    TextInput,
    Field,
    Form,
  },
  props: {
    tfa: Object,
    isSubmitting: Boolean,
    codes: Array,
    validateErrors: Object,
  },
  emits: ["twoFactorModalClosed", "twoFactorPinSubmitted"],
  data() {
    return {
      show: true,
      tfaAttempt: null,
    };
  },
  computed: {
    tfaSecret() {
      const uri = this.tfa?.value?.uri;

      if (uri) {
        const urlObject = new URL(uri);
        return urlObject.searchParams.get("secret");
      } else {
        return null;
      }
    },
    showQrCode() {
      const uri = this.tfa?.value?.uri;

      return uri !== null && uri !== undefined;
    },
    isValidated() {
      return this.codes && this.codes.length > 0;
    },
  },
  watch: {
    validateErrors: function (value) {
      this.$refs.form?.setErrors(value);
    },
  },
  methods: {
    submit() {
      this.$emit("twoFactorPinSubmitted", { attempt: this.tfaAttempt });
    },
    modalClosed() {
      this.show = false;
      this.$emit("twoFactorModalClosed");
    },
    copyToClipboard() {
      copyToClipboard(this.tfaSecret);
    },
  },
});
</script>

<i18n>
en:
  initiate:
    title: "Initiate two-factor authentication"
    subtitle: "Scan this barcode with your app"
    qrCodeInstructions: "To activate two-factor authentication for you app, scan the QRCode below with a two factor authentication application like Google Authenticator or Authy, then type the authentication code in the field below."
    keyInstructions: "If you cannot scan the QRCode, you can manually enter the following key instead:"
    submit: "Validate"
    tfaAttemptLabel: "PIN given by your authenticator app"
    tfaAttemptPlaceholder: "123456"
  validated:
    title: "Two-factor authentication is enabled!"
    subtitle: "Store your recovery codes safely"
    instructions: "Those codes are you recovery codes. Save them in a safe place. If you don't have access to your authentication device, these recovery codes will let you login to your account. Each code can only be used once."
    close: "Close"
  clipboard: "Copy to clipboard"
fr:
  initiate:
    title: "Activer l'authentification deux facteurs"
    subtitle: "Scannez ce QR code avec votre app"
    qrCodeInstructions: "To activate two-factor authentication for you app, scan the QRCode below with a two factor authentication application like Google Authenticator or Authy, then type the authentication code in the field below."
    keyInstructions: "If you cannot scan the QRCode, you can manually enter the following key instead:"
    submit: "Valider"
    tfaAttemptLabel: "PIN donné par votre application d'authentification"
    tfaAttemptPlaceholder: "123456"
  validated:
    title: "L'authentification deux facteurs est activée!"
    subtitle: "Conservez vos codes de récupérations"
    instructions: "Those codes are you recovery codes. Save them in a safe place. If you don't have access to your authentication device, these recovery codes will let you login to your account. Each code can only be used once."
    close: "Fermer"
  clipboard: "Copier dans le presse papier"
</i18n>
