<template>
  <div
    class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
  >
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 8.30362L1.08334 10.9735C0.964246 11.106 0.970115 11.3087 1.09667 11.4341L1.56334 11.9013C1.62593 11.9645 1.71112 12 1.8 12C1.88888 12 1.97408 11.9645 2.03667 11.9013L4.70334 9.2314C4.77709 9.1575 4.84403 9.07707 4.90334 8.99111L5.40334 8.32364C6.23063 8.98506 7.25797 9.34518 8.31667 9.34487C10.4983 9.35245 12.394 7.84573 12.8815 5.71674C13.369 3.58774 12.3183 1.40466 10.3513 0.459942C8.38427 -0.484772 6.02602 0.0610084 4.67267 1.77417C3.31932 3.48732 3.33205 5.91064 4.70334 7.60945L4.03667 8.07C3.93256 8.13672 3.83638 8.21509 3.75 8.30362ZM11.65 4.67255C11.65 6.51572 10.1576 8.0099 8.31668 8.0099C7.43262 8.0099 6.58478 7.65829 5.95966 7.03241C5.33453 6.40654 4.98335 5.55767 4.98335 4.67255C4.98335 2.82938 6.47573 1.3352 8.31668 1.3352C10.1576 1.3352 11.65 2.82938 11.65 4.67255Z"
        fill="#7B8794"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SearchGlyph",
});
</script>
