<template>
  <ConfirmModal
    :immediate="false"
    kind="danger"
    :promiseInfo="context.info"
    :strongConfirmation="context.user.name"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-xl"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <p>
      {{ $t("confirm") }}
    </p>

    <p class="mt-2">
      {{ $t("disclaimer") }}
    </p>

    <SCAlert
      v-if="context.info?.isError"
      class="my-4"
      kind="error"
    >
      <p class="px-4 font-bold">
        {{ context.error }}
      </p>
    </SCAlert>
  </ConfirmModal>
</template>

<script>
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";

export default defineComponent({
  name: "DeleteUserModal",
  components: { ConfirmModal, SCAlert },
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "confirm"],
});
</script>

<i18n>
en:
  title: "Delete user"
  confirm: "Please confirm the name of the user you want to delete."
  disclaimer: "Deleting a user is a permanent action that cannot be undone."
  button: "Delete this user"
fr:
  title: "Supprimer l'utilisateur"
  confirm: "Veuillez confirmer le nom de l'utilisateur que vous souhaitez supprimer."
  disclaimer: "La suppression d'un utilisateur est une action permanente qui ne peut être annulée."
  button: "Supprimer cet utilisateur"
</i18n>
