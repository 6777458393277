import { defineStore } from "pinia";
import { Collaborator } from "scalingo/lib/models/regional";

import { useModelsStore } from "@/lib/pinia/use-models-store-next";
import { useSessionStore } from "@/stores/session";
import { availableRegionCodes } from "@/stores/user";

export interface RegionalCollaborator extends Collaborator {
  regionCode: string;
}

export const useCollaboratorsStore = defineStore("collaborators", () => {
  const { client } = useSessionStore();

  return useModelsStore({
    async fetchPromise(): Promise<Collaborator[]> {
      const regionCodes = await availableRegionCodes();
      const collaboratorPromises = regionCodes.map((regionCode) => {
        return new Promise<RegionalCollaborator[]>((resolve, reject) => {
          client(regionCode)
            .Collaborators.all({})
            .then((response) => {
              resolve(
                response.collaborators.map((collaborator) => {
                  return <RegionalCollaborator>{ ...collaborator, regionCode };
                }),
              );
            })
            .catch((error) => {
              reject(error);
            });
        });
      });

      return Promise.allSettled(collaboratorPromises).then((results) => {
        const regionalCollaborators: RegionalCollaborator[] = [];

        for (const result of results) {
          if (result.status == "fulfilled") {
            regionalCollaborators.push(...result.value);
          }
        }

        return regionalCollaborators;
      });
    },
  });
});
