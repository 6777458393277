<template>
  <StepCard
    :step="2"
    :title="title"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-3xl"
  >
    <LoadingCardState v-if="manifestInfo.isLoading" />
    <OCDEnvVarsForm
      v-else-if="manifestInfo.isSuccess"
      class="mt-6"
      :manifest="manifest"
      :dryRunnedApp="dryRunnedApp"
      @form-submitted="(e) => $emit('variablesSubmitted', e)"
    />
    <div v-else>
      uh ?
    </div>
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import OCDEnvVarsForm from "@/components/parts/create/app/OCDEnvVarsForm.vue";

export default defineComponent({
  name: "OCDEnvVarsStepCard",
  components: {
    StepCard,
    OCDEnvVarsForm,
    LoadingCardState,
  },
  props: {
    user: Object,
    enabled: Boolean,
    manifestInfo: Object,
    dryRunnedApp: Object,
  },
  emits: ["variablesSubmitted"],
  computed: {
    title() {
      const name = this.manifestInfo.data ? this.manifest.name : "app";

      return this.$t("title", { name });
    },
    manifest() {
      return this.manifestInfo.isSuccess ? this.manifestInfo.data : null;
    },
  },
});
</script>

<i18n>
en:
  title: "Configure your {name}"
  subtitle: "Fill the required environment variables"
fr:
  title: "Configurez votre {name}"
  subtitle: "Saisissez les variables d'environnement requises"
</i18n>
