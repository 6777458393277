<template>
  <ViewComponent
    :app="app"
    :user="currentUser"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/SettingsRoot.vue";

export default defineComponent({
  name: "SettingsRootContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
});
</script>
