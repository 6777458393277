<template>
  <div
    v-if="withViewAllButton"
    class="flex justify-center"
  >
    <LinkButton :routeArgs="routeArgs">
      {{ $t("text") }}
    </LinkButton>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LinkButton from "@/components/atoms/buttons/LinkButton.vue";

export default defineComponent({
  name: "CardFooter",
  components: { LinkButton },
  props: {
    withViewAllButton: Boolean,
    routeArgs: {
      type: Object,
      default: null,
    },
  },
});
</script>

<i18n>
en:
  text: "View all"
fr:
  text: "Tout voir"
</i18n>
