<template>
  <section>
    <PersistenceCard />
    <RDBCard
      class="mt-6"
      :db="db"
      :promiseInfo="RDBInfo"
      :disableRDB="disableRDB"
      :enableRDB="enableRDB"
    />
    <AOFCard
      class="mt-6"
      :db="db"
      :promiseInfo="AOFInfo"
      :disableAOF="disableAOF"
      :enableAOF="enableAOF"
    />
    <CacheCard
      class="mt-6"
      :db="db"
      :promiseInfo="cacheInfo"
      :disableCache="disableCache"
      :enableCache="enableCache"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import AOFCard from "@/components/parts/dbSettings/redis/AOFCard.vue";
import CacheCard from "@/components/parts/dbSettings/redis/CacheCard.vue";
import PersistenceCard from "@/components/parts/dbSettings/redis/PersistenceCard.vue";
import RDBCard from "@/components/parts/dbSettings/redis/RDBCard.vue";

export default defineComponent({
  name: "RedisPersistence",
  components: {
    PersistenceCard,
    RDBCard,
    AOFCard,
    CacheCard,
  },
  props: {
    db: Object,
    cacheInfo: Object,
    // eslint-disable-next-line vue/prop-name-casing
    RDBInfo: Object,
    // eslint-disable-next-line vue/prop-name-casing
    AOFInfo: Object,
    enableCache: Function,
    disableCache: Function,
    enableAOF: Function,
    disableAOF: Function,
    enableRDB: Function,
    disableRDB: Function,
  },
});
</script>
