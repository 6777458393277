<template>
  <DeleteModal
    :formHandler="formHandler"
    @form-submitted="$emit('formSubmitted')"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      {{ $t("question") }}
    </template>
    <template #button>
      {{ $t("button") }} {{ formHandler.token.name }}
    </template>
  </DeleteModal>
</template>

<script>
import { defineComponent } from "vue";

import DeleteModal from "@/components/molecules/modalsCollection/DeleteModal.vue";

export default defineComponent({
  name: "RemoveTokenModal",
  components: { DeleteModal },
  props: {
    formHandler: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
});
</script>

<i18n>
en:
  title: "Delete a token"
  question: "Do you really want delete this token?"
  button: "Delete"
fr:
  title: "Supprimer un token"
  question: "Souhaitez-vous vraiment supprimer ce token?"
  button: "Supprimer"
</i18n>
