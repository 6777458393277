<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" />
    </template>
    <template #body>
      <template v-if="isBillable">
        <p class="mt-2 text-scale-7">
          {{ $t("disclaimer") }}
        </p>

        <SCButton
          class="mt-4"
          kind="warning"
          @click="$emit('click')"
        >
          {{ $t("action") }}
        </SCButton>
      </template>

      <FormAlert
        v-else
        class="mt-4"
        styling="warning"
      >
        <template #text>
          <p>
            {{ $t("noPaymentMethod") }}

            <router-link
              :to="paymentMethodsRoute"
              class="underline"
            >
              {{ $t("add.paymentMethod") }}
            </router-link>
            {{ $t("or") }}
            <router-link
              :to="vouchersRoute"
              class="underline"
            >
              {{ $t("add.voucher") }}
            </router-link>.
          </p>
        </template>
      </FormAlert>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "StopFreeTrialCard",
  components: {
    CardHeader,
    Card,
    SCButton,
    FormAlert,
  },
  props: {
    user: Object,
    billingProfile: Object,
  },
  emits: ["click"],
  data() {
    return {};
  },
  computed: {
    paymentMethodsRoute() {
      return { name: Routes.Billing.PaymentMethods };
    },
    vouchersRoute() {
      return { name: Routes.Billing.Vouchers };
    },
    isBillable() {
      return !!this.billingProfile?.payment_method;
    },
  },
});
</script>

<i18n>
en:
  title: "Stop free trial"
  action: "Stop free trial"
  disclaimer: "Some features (having many apps, using review apps, etc) are only available to accounts that are out of free trial."
  noPaymentMethod: "You need to add "
  add:
    paymentMethod: "a payment method"
    or: " or "
    voucher: "a voucher"
fr:
  title: "Mettre fin a la période d'essai"
  action: "Mettre fin a la période d'essai"
  disclaimer: "Certaines fonctionnalités (avoir de nombreuses applications, utiliser des review apps, etc.) sont disponibles uniquement pour les comptes qui ne sont plus en période d'essai."
  noPaymentMethod: "Vous devez d'abord ajouter "
  add:
    paymentMethod: "un moyen de paiement"
    or: " ou "
    voucher: "un voucher"
</i18n>
