import { RouteRecordRaw } from "vue-router";

import BillingBalance from "@/controllers/billing/Balance.vue";
import BillingCredits from "@/controllers/billing/Credits.vue";
import BillingInvoicesDetailed from "@/controllers/billing/invoices/Detailed.vue";
import BillingInvoicesList from "@/controllers/billing/invoices/List.vue";
import BillingInvoicesRoot from "@/controllers/billing/invoices/Root.vue";
import BillingInvoicesShow from "@/controllers/billing/invoices/Show.vue";
import BillingOrdersList from "@/controllers/billing/orders/List.vue";
import BillingOrdersRoot from "@/controllers/billing/orders/Root.vue";
import BillingOrdersShow from "@/controllers/billing/orders/Show.vue";
import BillingPaymentMethods from "@/controllers/billing/PaymentMethods.vue";
import BillingProfile from "@/controllers/billing/Profile.vue";
import BillingRoot from "@/controllers/billing/Root.vue";
import BillingSupportPlans from "@/controllers/billing/SupportPlans.vue";
import BillingVouchers from "@/controllers/billing/Vouchers.vue";
import i18n from "@/i18n";
import { BillingRoutes as R } from "@/router/billing/names";

export const billingRoutes: RouteRecordRaw = {
  path: "billing",
  component: BillingRoot,
  name: R.Root,
  redirect: (to) => {
    const { params, query } = to;
    return { name: R.Profile, params, query };
  },
  children: [
    {
      path: "",
      name: R.Profile,
      component: BillingProfile,
      meta: {
        title: i18n.global.t("titles.billing.profile"),
      },
    },
    {
      path: "support-plans",
      name: R.SupportPlans,
      component: BillingSupportPlans,
      meta: {
        title: i18n.global.t("titles.billing.supportPlans"),
      },
    },
    {
      path: "vouchers",
      name: R.Vouchers,
      component: BillingVouchers,
      meta: {
        title: i18n.global.t("titles.billing.vouchers"),
      },
    },
    {
      path: "credits",
      name: R.Credits,
      component: BillingCredits,
      meta: {
        title: i18n.global.t("titles.billing.credits"),
      },
    },
    {
      path: "balance",
      name: R.Balance,
      component: BillingBalance,
      meta: {
        title: i18n.global.t("titles.billing.balance"),
      },
    },
    {
      path: "payment_methods",
      name: R.PaymentMethods,
      component: BillingPaymentMethods,
      meta: {
        title: i18n.global.t("titles.billing.paymentMethods"),
      },
    },
    {
      path: "orders",
      name: R.Orders.Root,
      component: BillingOrdersRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Orders.List, params, query };
      },
      children: [
        {
          path: "",
          name: R.Orders.List,
          component: BillingOrdersList,
          meta: {
            title: i18n.global.t("titles.billing.order"),
          },
        },
        {
          path: ":id",
          name: R.Orders.Show,
          component: BillingOrdersShow,
          meta: {
            title: i18n.global.t("titles.billing.order"),
          },
        },
      ],
    },
    {
      path: "invoices",
      name: R.Invoices.Root,
      component: BillingInvoicesRoot,
      redirect: (to) => {
        const { params, query } = to;
        return { name: R.Invoices.List, params, query };
      },
      children: [
        {
          path: "",
          name: R.Invoices.List,
          component: BillingInvoicesList,
          meta: {
            title: i18n.global.t("titles.billing.invoices"),
          },
        },
        {
          path: "detailed",
          name: R.Invoices.Detailed,
          component: BillingInvoicesDetailed,
          meta: {
            title: i18n.global.t("titles.billing.operations"),
          },
        },
        {
          path: ":id",
          name: R.Invoices.Show,
          component: BillingInvoicesShow,
          meta: {
            title: i18n.global.t("titles.billing.invoice"),
          },
        },
      ],
    },
  ],
};
