<template>
  <div class="border-t-2 border-scale-2 mt-4 pt-6 flex items-center">
    <div class="flex overflow-hidden text-scale-10">
      <GitHubGraphic
        v-if="githubSCM"
        class="h-16 w-16 shadow-solid"
      />
      <GitLabGraphic
        v-if="gitlabSCM"
        class="h-16 w-16 shadow-solid"
      />
      <Portrait
        class="-ml-8 inline-block h-6 w-6 rounded-full text-scale-0 shadow-solid"
        :source="integration.avatar_url"
        size="16"
      />
    </div>

    <div class="flex-grow ml-6">
      <div class="pb-2 font-medium">
        {{ $t("connectedAs") }}
        <a
          :href="integration.profile_url"
          target="_blank"
          class="text-primary-2"
        >
          {{ integration.username }}
        </a>
        {{ $t("connectedOn") }}

        {{ integration.url }}
      </div>
      <div class="text-scale-7">
        {{ integration.email }}
      </div>
    </div>

    <SCButton @click="disconnectSCMIntegration(integration)">
      {{ $t("actions.disconnect") }}
    </SCButton>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import GitHubGraphic from "@/components/atoms/graphics/GitHubGraphic.vue";
import GitLabGraphic from "@/components/atoms/graphics/GitLabGraphic.vue";
import Portrait from "@/components/atoms/graphics/Portrait.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";

export default defineComponent({
  name: "HostedSCMIntegration",
  components: {
    Portrait,
    GitHubGraphic,
    GitLabGraphic,
    SCButton,
  },
  props: {
    integration: Object,
  },
  emits: ["disconnectSCMIntegration"],
  computed: {
    githubSCM() {
      return this.integration.scm_type === "github-enterprise";
    },
    gitlabSCM() {
      return this.integration.scm_type === "gitlab-self-hosted";
    },
  },
  methods: {
    disconnectSCMIntegration(scmIntegration) {
      this.$emit("disconnectSCMIntegration", { scmIntegration });
    },
  },
});
</script>

<style scoped>
.shadow-solid {
  box-shadow: 0 0 0 4px currentColor;
}
</style>

<i18n>
en:
  connectedAs: "Connected as"
  connectedOn: "to"
  actions:
    disconnect: "Disconnect"
fr:
  connectedAs: "Connecté en tant que"
  connectedOn: "à"
  actions:
    disconnect: "Se déconnecter"
</i18n>
