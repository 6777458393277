<template>
  <DeleteModal
    :formHandler="formHandler"
    @form-submitted="$emit('formSubmitted')"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      {{ $t("question") }}
    </template>
    <template #button>
      {{ $t("button") }}
      <span class="ml-1">{{ formHandler.notifier.name }}</span>
    </template>
  </DeleteModal>
</template>

<script>
import { defineComponent } from "vue";

import DeleteModal from "@/components/molecules/modalsCollection/DeleteModal.vue";

export default defineComponent({
  name: "RemoveNotifierModal",
  components: { DeleteModal },
  props: {
    formHandler: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
});
</script>

<i18n>
en:
  title: "Delete a notifier"
  question: "Do you really want delete this notifier?"
  button: "Delete"
fr:
  title: "Supprimer un notifieur"
  question: "Souhaitez-vous vraiment supprimer ce notifieur?"
  button: "Supprimer"
</i18n>
