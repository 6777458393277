<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <KeyAddGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("name") }}:
      <span class="font-medium ml-1">{{ event.type_data.name }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import KeyAddGlyph from "@/components/atoms/glyphs/KeyAddGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "NewKeyEvent",
  components: { KeyAddGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "SSH key added"
  name: "Name"
fr:
  title: "Clé SSH ajoutée"
  name: "Nom"
</i18n>
