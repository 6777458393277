<template>
  <section>
    <InternetAccessBanner
      :db="db"
      :app="app"
    />
    <ForceTLSCard
      class="mt-6"
      :db="db"
      :promiseInfo="forceTLSInfo"
      :forceTLSPending="forceTLSPending"
      :forceTLSEnabled="forceTLSEnabled"
      :internetAccessEnabled="internetAccessEnabled"
      @disable-force-t-l-s="$emit('disableForceTLS')"
      @enable-force-t-l-s="$emit('enableForceTLS')"
    />
    <InternetAccessibilityCard
      class="mt-6"
      :db="db"
      :promiseInfo="internetAccessInfo"
      :internetAccessPending="internetAccessPending"
      :forceTLSEnabled="forceTLSEnabled"
      :internetAccessEnabled="internetAccessEnabled"
      @enable-internet-access="$emit('enableInternetAccess')"
      @disable-internet-access="$emit('disableInternetAccess')"
    />
    <CaCertificateCard
      class="mt-6"
      @download-ca-certificate="$emit('downloadCaCertificate')"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import InternetAccessBanner from "@/components/organisms/banners/InternetAccessBanner.vue";
import CaCertificateCard from "@/components/parts/dbSettings/CaCertificateCard.vue";
import ForceTLSCard from "@/components/parts/dbSettings/ForceTLSCard.vue";
import InternetAccessibilityCard from "@/components/parts/dbSettings/InternetAccessibilityCard.vue";

export default defineComponent({
  name: "InternetAccess",
  components: {
    InternetAccessBanner,
    CaCertificateCard,
    ForceTLSCard,
    InternetAccessibilityCard,
  },
  props: {
    db: Object,
    app: Object,
    forceTLSInfo: Object,
    internetAccessInfo: Object,
    forceTLSPending: Boolean,
    internetAccessPending: Boolean,
    forceTLSEnabled: Boolean,
    internetAccessEnabled: Boolean,
  },
  emits: [
    "disableForceTLS",
    "enableForceTLS",
    "enableInternetAccess",
    "disableInternetAccess",
    "downloadCaCertificate",
  ],
});
</script>
