<template>
  <div class="flex md:flex-row flex-col">
    <div class="md:w-1/2">
      <div class="text-xl flex">
        <h2 class="text-scale-10 font-bold">
          {{ title }}
        </h2>
        <div
          v-if="withCount"
          class="text-scale-4 pl-1"
        >
          ({{ count }})
        </div>
      </div>
      <div class="text-scale-7">
        {{ subtitle }}
      </div>
    </div>
    <div class="md:w-1/2 md:mt-0 mt-4">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DividedSectionTitle",
  props: {
    title: String,
    withCount: Boolean,
    count: [Number, String],
    subtitle: String,
  },
});
</script>
