<template>
  <SupportPlanCard
    :title="$t('title')"
    :description="$t('description')"
    :current="current"
    :features="features"
  >
    <template #pricing>
      <span class="font-bold text-lg text-scale-9">{{ $t("pricing") }}</span>
    </template>
  </SupportPlanCard>
</template>

<script>
import SupportPlanCard from "./SupportPlanCard.vue";

export default {
  name: "BasicSupportPlanCard",
  components: {
    SupportPlanCard,
  },
  props: {
    title: String,
    description: String,
    current: Boolean,
  },
  data() {
    return {
      features: [
        {
          title: this.$t("features.supportFunctional.title"),
          description: this.$t("features.supportFunctional.description"),
        },
        {
          title: this.$t("features.priorityLine.title"),
          description: this.$t("features.priorityLine.description"),
        },
        {
          title: this.$t("features.responseTime.title"),
          description: this.$t("features.responseTime.description"),
        },
        {
          title: this.$t("features.accompaniment.title"),
          description: this.$t("features.accompaniment.description"),
        },
        {
          title: this.$t("features.documentation.title"),
          description: this.$t("features.documentation.description"),
        },
        {
          title: this.$t("features.commitment.title"),
          description: this.$t("features.commitment.description"),
        },
      ],
    };
  },
};
</script>

<i18n>
en:
  pricing: "Included"
  title: "Basic Support"
  description: "Projects in development."
  features:
    supportFunctional:
      title: "Platform support"
      description: "Business days, 9am-6pm"
    priorityLine:
      title: "Priority line during incidents"
      description: "-"
    responseTime:
      title: "Guaranteed response time"
      description: "-"
    accompaniment:
      title: "Assistance & resources"
      description: "Access to online documentation, guides, and tutorials."
    documentation:
      title: "Provided documents"
      description: "-"
    commitment:
      title: "Commitment"
      description: "-"
fr:
  pricing: "Inclus"
  title: "Support Basic"
  description: "Pour les projets en développement."
  features:
    supportFunctional:
      title: "Support fonctionnel"
      description: "Jours ouvrés, 9h-18h"
    priorityLine:
      title: "Ligne prioritaire lors d’incidents"
      description: "-"
    responseTime:
      title: "Temps de réponse garanti"
      description: "-"
    accompaniment:
      title: "Accompagnement"
      description: "Accès à la documentation en ligne, à nos guides et à nos tutoriels."
    documentation:
      title: "Documents fournis"
      description: "-"
    commitment:
      title: "Engagement"
      description: "-"
</i18n>
