import { Addon } from "scalingo/lib/models/regional";

const addonDashboardAvailable: { [key: string]: boolean } = {
  "scalingo-docker-image": false,
  postgresql: true,
  mysql: true,
  "tcp-gateway": true,
  mongodb: true,
  "scalingo-openvpn": true,
  "scalingo-vpn-ipsec": true,
  elasticsearch: true,
  opensearch: true,
  redis: true,
  influxdb: true,
};

export function isAddonDashboardAvailable(addon: Addon): boolean {
  return addonDashboardAvailable[addon.addon_provider.id] || false;
}

export function canAddonChangePlan(addon: Addon): boolean {
  return addon.status == "running";
}
