<template>
  <section>
    <ForceHTTPSCard
      :value="app.force_https"
      @setting-updated="(e) => $emit('settingUpdated', e)"
    />

    <StickySessionCard
      class="mt-6"
      :value="app.sticky_session"
      @setting-updated="(e) => $emit('settingUpdated', e)"
    />

    <RouterLogsCard
      class="mt-6"
      :value="app.router_logs"
      @setting-updated="(e) => $emit('settingUpdated', e)"
    />

    <CanonicalDomainCard
      class="mt-6"
      :domains="domains"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import CanonicalDomainCard from "@/components/parts/appSettings/routingSettings/CanonicalDomainCard.vue";
import ForceHTTPSCard from "@/components/parts/appSettings/routingSettings/ForceHTTPSCard.vue";
import RouterLogsCard from "@/components/parts/appSettings/routingSettings/RouterLogsCard.vue";
import StickySessionCard from "@/components/parts/appSettings/routingSettings/StickySessionCard.vue";

export default defineComponent({
  name: "RoutingSettings",
  components: {
    ForceHTTPSCard,
    StickySessionCard,
    CanonicalDomainCard,
    RouterLogsCard,
  },
  props: {
    app: Object,
    user: Object,
    domains: Object,
    routerLogsEnabled: Boolean,
  },
  emits: ["settingUpdated"],
});
</script>
