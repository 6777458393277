<template>
  <a
    class="underline group"
    :href="href"
    target="_blank"
  >
    <slot />

    <font-awesome-icon
      class="ml-1"
      :class="{
        'group-hover:visible invisible': !hidden,
        'group-hover:block hidden': hidden,
      }"
      icon="external-link-alt"
    />
  </a>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExtLink",
  props: {
    href: {
      type: String,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
