<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4C7.26333 4 6.66667 4.61023 6.66667 5.36364V6.04545H10V5.36364C10 4.61023 9.40333 4 8.66667 4H8ZM15.3333 4C14.5967 4 14 4.61023 14 5.36364V6.04545H17.3333V5.36364C17.3333 4.61023 16.7367 4 16 4H15.3333ZM5.33333 7.40909C4.59667 7.40909 4 8.01932 4 8.77273V17.6364C4 18.3898 4.59667 19 5.33333 19H18.6667C19.4033 19 20 18.3898 20 17.6364V8.77273C20 8.01932 19.4033 7.40909 18.6667 7.40909H5.33333Z"
      fill="currentColor"
    />
    <path
      d="M23 17C23 19.7614 20.7614 22 18 22C15.2386 22 13 19.7614 13 17C13 14.2386 15.2386 12 18 12C20.7614 12 23 14.2386 23 17Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 17C24 20.3137 21.3137 23 18 23C14.6863 23 12 20.3137 12 17C12 13.6863 14.6863 11 18 11C21.3137 11 24 13.6863 24 17ZM18 22C20.7614 22 23 19.7614 23 17C23 14.2386 20.7614 12 18 12C15.2386 12 13 14.2386 13 17C13 19.7614 15.2386 22 18 22Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1951 14.6219L20.3781 14.8049C20.456 14.8828 20.4999 14.9885 20.5 15.0988V15.3095C20.5014 15.4216 20.4574 15.5296 20.3781 15.6089L19.8653 16.1134C19.8393 16.1396 19.8039 16.1544 19.767 16.1544C19.73 16.1544 19.6946 16.1396 19.6686 16.1134L18.8898 15.3233C18.837 15.2694 18.837 15.1832 18.8898 15.1293L19.3914 14.6219C19.4707 14.5426 19.5786 14.4986 19.6907 14.5H19.9014C20.0116 14.5001 20.1173 14.5439 20.1951 14.6219ZM19.3388 16.6347C19.3916 16.5808 19.3916 16.4945 19.3388 16.4406L18.5461 15.6588C18.5201 15.6326 18.4847 15.6178 18.4477 15.6178C18.4108 15.6178 18.3754 15.6326 18.3494 15.6588L16.1128 17.896C16.0587 17.9496 16.0163 18.0138 15.9881 18.0845L15.5114 19.2877C15.4844 19.3501 15.5067 19.4229 15.5641 19.4595C15.6033 19.4988 15.6622 19.5108 15.7137 19.49L16.9165 19.0021C16.9872 18.9739 17.0514 18.9315 17.105 18.8774L19.3388 16.6347Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddonEditGlyph",
});
</script>
