<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <EnvVarEditGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #body>
      <div class="text-scale-0 flex flex-col text-scale-10">
        <span class="bg-color-2-10 text-color-2 p-1 line-through">- {{ event.type_data.name }}={{ event.type_data.old_value }}</span>
        <span class="bg-color-1-10 p-1 text-color-1">+ {{ event.type_data.name }}={{ event.type_data.value }}</span>
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import EnvVarEditGlyph from "@/components/atoms/glyphs/EnvVarEditGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "EditVariableEvent",
  components: { EnvVarEditGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
  en:
    title: "Environment variable edited"
    openTerminalTitle: "Show edited variables"
  fr:
    title: "Variable d'environnement éditée"
    openTerminalTitle: "Afficher les variables éditées"
</i18n>
