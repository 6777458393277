<template>
  <div>
    <span
      role="checkbox"
      tabindex="0"
      :class="{ 'bg-selected': toggled, 'bg-scale-5': !toggled }"
      class="relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring"
      @click="toggle"
    >
      <span
        :class="{ 'translate-x-4': toggled, 'translate-x-0': !toggled }"
        aria-hidden="true"
        class="inline-block h-3 w-3 rounded-full bg-scale-0 shadow transform transition ease-in-out duration-200"
      />
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SmallToggle",
  props: {
    modelValue: Boolean,
    topDownOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      toggled: !!this.modelValue,
    };
  },
  computed: {
    // Hack because watch on Boolean from prop fails
    valueTmp() {
      return this.modelValue;
    },
  },
  watch: {
    // Hack because watch on Boolean from prop fails
    valueTmp(value) {
      this.toggled = !!value;
    },
  },
  methods: {
    toggle() {
      if (this.topDownOnly === true) {
        return;
      }

      const toggled = !this.toggled;
      this.toggled = toggled;
      this.$emit("update:modelValue", toggled);
    },
  },
});
</script>
