<template>
  <main class="flex flex-col space-y-4 mt-8">
    <Card>
      <template #header>
        <CardHeader
          :title="$t('title')"
          :subtitle="subtitle"
          :withCount="backupsDisplayed.length > 1"
          :count="backupsDisplayed.length"
        >
          <template #buttons>
            <RetentionPolicySelector
              class="mr-4"
              :selectedValue="retentionPolicy"
              :disabled="!hasBackupInPlan"
              :dbPlan="dbPlan"
              @range-selected="(e) => (retentionPolicy = e)"
            />

            <SCButton
              :disabled="backupRunningOrScheduled || !hasBackupInPlan"
              :loading="manualBackupInfo.isLoading"
              @click="requestMakeManualBackup"
            >
              {{ $t("manualBackup") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <BackupsTable
          v-if="hasBackup"
          :db="db"
          :backups="backupsDisplayed"
          :downloadInfos="backupDownloadInfos"
          :backupRunningOrScheduled="backupRunningOrScheduled"
          @start-backup-restoration="(e) => $emit('startBackupRestoration', e)"
          @download-backup="(e) => $emit('downloadBackup', e)"
        />
        <EmptyState
          v-else
          :app="app"
          :addon="addon"
          :dbPlan="dbPlan"
          :addonProviderId="addon?.addon_provider.id"
          @request-make-manual-backup="requestMakeManualBackup"
        />
        <template v-if="backups.promiseInfo.isLoading">
          <LoadingCardState />
        </template>
      </template>
    </Card>
    <div class="flex flex-col mid-xl:grid mid-xl:grid-cols-2 gap-4 mt-6">
      <div class="flex flex-col space-y-4">
        <BackupsSettings
          :db="db"
          :hasBackupInPlan="hasBackupInPlan"
          @start-backups-configuration="$emit('startBackupsConfiguration')"
        />
        <PitrInformations
          v-if="db.type_name === 'postgresql' && !isSandbox"
          :db="db"
          :hasBackupInPlan="hasBackupInPlan"
          :callPitr="callPitr"
          :firstPitrHour="firstPitrHour"
          :firstPitrMinute="firstPitrMinute"
          :backupError="backupError"
          :backupRunning="backupRunning"
          @clear-backup-error="$emit('clearBackupError')"
        />
      </div>
      <PlanInformation
        :hasBackupInPlan="hasBackupInPlan"
        :dbPlan="dbPlan"
        :backups="backups"
        :app="app"
        :addon="addon"
      />
    </div>
    <ConfirmBackupRestoration
      v-if="restoreCtx"
      :context="restoreCtx"
      @close="$emit('cancelBackupRestoration')"
      @confirm="$emit('confirmBackupRestoration')"
    />
    <ConfirmMakeManualBackupModal
      v-if="makeManualBackupRequested"
      :dbPlan="dbPlan"
      :app="app"
      :addon="addon"
      @confirm="confirmMakeManualBackup"
      @close="cancelMakeManualBackup"
    />
    <EditBackupsSettingsModal
      v-if="updateCtx"
      :db="db"
      :context="updateCtx"
      @submit="(e) => $emit('updateBackupsConfiguration', e)"
      @close="$emit('cancelBackupsConfiguration')"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ConfirmBackupRestoration from "@/components/organisms/modals/ConfirmBackupRestoration.vue";
import ConfirmMakeManualBackupModal from "@/components/organisms/modals/ConfirmMakeManualBackupModal.vue";
import BackupsSettings from "@/components/parts/db/backups/BackupsSettings.vue";
import BackupsTable from "@/components/parts/db/backups/BackupsTable.vue";
import EditBackupsSettingsModal from "@/components/parts/db/backups/EditBackupsSettingsModal.vue";
import EmptyState from "@/components/parts/db/backups/EmptyState.vue";
import PitrInformations from "@/components/parts/db/backups/PitrInformations.vue";
import PlanInformation from "@/components/parts/db/backups/PlanInformation.vue";
import RetentionPolicySelector from "@/components/parts/db/backups/RetentionPolicySelector.vue";
import { closeCurrentModal } from "@/lib/modals";
import { formatDateTime, relativeDateTime } from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "BackupsList",
  components: {
    EditBackupsSettingsModal,
    BackupsSettings,
    PlanInformation,
    EmptyState,
    PitrInformations,
    BackupsTable,
    ConfirmMakeManualBackupModal,
    LoadingCardState,
    CardHeader,
    RetentionPolicySelector,
    ConfirmBackupRestoration,
    SCButton,
    Card,
  },
  mixins: [ModelsTranslation],
  props: {
    backups: Object,
    dbPlan: Object,
    db: Object,
    updateCtx: Object,
    manualBackupInfo: Object,
    backupDownloadInfos: Object,
    restoreCtx: Object,
    app: Object,
    addon: Object,
    callPitr: Function,
    firstPitrHour: Number,
    firstPitrMinute: Number,
    backupError: String,
    backupRunning: Boolean,
  },
  emits: [
    "startManualBackup",
    "startBackupRestoration",
    "startBackupsConfiguration",
    "confirmBackupRestoration",
    "cancelBackupRestoration",
    "cancelBackupsConfiguration",
    "downloadBackup",
    "clearBackupError",
    "updateBackupsConfiguration",
  ],
  data() {
    return {
      makeManualBackupRequested: false,
      retentionPolicy: "all",
    };
  },
  computed: {
    isSandbox() {
      return (
        this.dbPlan.promiseInfo.isSuccess &&
        this.dbPlan.item?.id?.includes("sandbox")
      );
    },
    hasBackup() {
      return (
        this.backups.items?.length !== 0 && this.backups.promiseInfo.isSuccess
      );
    },
    hasBackupInPlan() {
      return this.dbPlan.promiseInfo.isSuccess && this.dbPlan.item?.backup;
    },
    subtitle() {
      if (!this.backups.items) return;
      if (this.backups.items.length === 0) return;

      const doneBackups = this.backups.items.filter(
        (backup) => backup.status === "done",
      );
      if (doneBackups.length < 1) {
        return "";
      }

      const last = doneBackups[doneBackups.length - 1].created_at;

      return this.$t("subtitle", {
        date: formatDateTime(last, "DD"),
      });
    },
    backupsDisplayed() {
      return (this.backups.items || []).filter(
        (item) =>
          this.retentionPolicy === "all" ||
          item.method === this.retentionPolicy,
      );
    },
    backupRunningOrScheduled() {
      if (!this.backups.items) return false;

      return !!this.backups.items.find(
        (item) => item.status === "scheduled" || item.status === "running",
      );
    },
    backupUserLimitReached() {
      if (!this.backups.items || !this.dbPlan.item) return false;
      const userBackupsCount = this.backups.items.filter(
        (item) => item.method === "manual",
      ).length;
      const userBackupsPlan = this.dbPlan.item.manual_backups_count;
      return userBackupsCount >= userBackupsPlan;
    },
  },
  methods: {
    relativeDateTime,
    confirmMakeManualBackup() {
      this.$emit("startManualBackup");
      closeCurrentModal();
    },
    cancelMakeManualBackup() {
      this.makeManualBackupRequested = false;
    },
    requestMakeManualBackup() {
      if (this.backupUserLimitReached) {
        this.makeManualBackupRequested = true;
        return;
      }
      this.$emit("startManualBackup");
    },
  },
});
</script>

<i18n>
en:
  title: "Backups"
  subtitle: "Oldest available: {date}"
  manualBackup: "Trigger manual backup"
  chooseRetentionPolicy: "Choose a filter"
  backups: "backup | backup | backups"
  noBackup: "No backup yet."
  configureBackups: "Create your first manual backup or configure scheduled backups."
fr:
  title: "Backups"
  subtitle: "Le plus ancien réussi: {date}"
  backups: "backup | backup | backups"
  manualBackup: "Déclencher un backup manuel"
  chooseRetentionPolicy: "Choisissez un filtre"
  noBackup: "Aucun backup n'a encore été effectué."
  configureBackups: "Créez votre premier backup manuel ou configurez la planification des backups."
</i18n>
