<template>
  <ViewComponent
    :app="app"
    :scmRepoLink="scmRepoLink"
    :findAppInfo="findAppInfo"
    :scmIntegrations="scmIntegrations"
    :currentCartridge="currentCartridge"
    :currentSCMIntegration="currentSCMIntegration"
    :scmOrgs="scmOrgs"
    :hasMoreOrgs="hasMoreOrgs"
    :scmRepos="scmRepos"
    :scmBranches="scmBranches"
    :connectIntegrationHandler="connectIntegrationHandler"
    :repoLinkOperation="repoLinkOperation"
    :listOrgsInfo="listOrgsInfo"
    :listReposInfo="listReposInfo"
    :listBranchesInfo="listBranchesInfo"
    @deploy-form-submitted="deployFormSubmitted"
    @cartridge-selected="cartridgeSelected"
    @connect-s-c-m-integration="connectSCMIntegration"
    @start-hosted-s-c-m-connection="startHostedSCMConnection"
    @cancel-hosted-s-c-m-connection="cancelHostedSCMConnection"
    @confirm-hosted-s-c-m-connection="
      (e) => connectIntegrationHandler.submit(e)
    "
    @start-s-c-m-link="startSCMLink"
    @stop-s-c-m-link="stopSCMLink"
    @unlink-repo="unlinkRepo"
    @scm-org-selected="scmOrgSelected"
    @scm-repo-searched="scmRepoSearched"
    @scm-repo-selected="scmRepoSelected"
    @scm-branch-selected="scmBranchSelected"
    @finish-s-c-m-repo-link="finishSCMRepoLink"
    @load-more-organizations="loadMoreOrganizations"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/configure/App.vue";
import { CreateSCMIntegrationHandler } from "@/lib/handlers";
import { promiseInfo } from "@/lib/promises/info";
import { scalingoClient } from "@/lib/scalingo/client";
import {
  handlingLinkingFeedback,
  performQuickOAuthLink,
} from "@/lib/scm-integrations";
import {
  listOrganizations,
  listMatchingRepos,
  listBranchesForRepo,
} from "@/lib/scm-repo-links";
import { Routes } from "@/router/names";
import { findApp } from "@/store/apps";
import {
  ensureSCMIntegrations,
  listSCMIntegrations,
} from "@/store/scm-integrations";
import {
  createScmRepoLink,
  ensureScmRepoLink,
  removeSCMRepoLink,
  scmRepoLink,
} from "@/store/scm-repo-link";
import { useAppInfosStore } from "@/stores/app-infos";

export default defineComponent({
  name: "ConfigureAppCtrl",
  components: { ViewComponent },
  setup() {
    const appInfosStore = useAppInfosStore();

    return { appInfosStore };
  },
  data() {
    return {
      currentCartridge: "git-push",
      findAppInfo: null,
      connectIntegrationHandler: null,
      repoLinkOperation: null,
      unlinkOperation: null,
      currentSCMIntegration: null,
      listOrgsInfo: null,
      listReposInfo: null,
      listBranchesInfo: null,
      currentOrgsPage: 1,
    };
  },
  computed: {
    app() {
      return this.findAppInfo?.data;
    },
    scmRepoLink() {
      return scmRepoLink(this.$store).value;
    },
    scmIntegrations() {
      return listSCMIntegrations(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
      });
    },
    scmOrgs() {
      return this.listOrgsInfo?.data?.organizations || [];
    },
    hasMoreOrgs() {
      return this.listOrgsInfo?.data?.meta.pagination.next_page !== null;
    },
    scmRepos() {
      return this.listReposInfo?.data || [];
    },
    scmBranches() {
      return this.listBranchesInfo?.data || [];
    },
  },
  watch: {
    scmIntegrations(newVal) {
      if (newVal && newVal.items[0]) {
        this.currentCartridge = newVal.items[0].scm_type;
      }
    },
    scmRepoLink(newVal) {
      if (newVal) {
        this.currentCartridge = newVal.scm_type;
      }
    },
    "unlinkOperation.isSuccess": function (newVal) {
      if (newVal) {
        this.repoLinkOperation = null;
      }
    },
    "repoLinkOperation.isSuccess": function (newVal) {
      if (newVal) {
        this.stopSCMLink();
      }
    },
  },
  async created() {
    this.handleAppLoading(this.$route.params);
    this.handleSCMCallback(this.$route.query);
  },
  beforeMount() {
    ensureSCMIntegrations(this.$store);

    handlingLinkingFeedback(this.$router, this.$route, this.$store, this.$i18n);
  },
  methods: {
    /**
     * App can be passed in the params (when coming from creation),
     * but it can also be absent (when coming back from the SCM oauth flow).
     * This handles both cases as gracefully as possible.
     */
    async handleAppLoading(params) {
      // Simulating operation here
      const promise = params.app
        ? Promise.resolve(params.app)
        : findApp(this.$store, params.region, params.id);

      this.findAppInfo = promiseInfo(promise);

      promise.then(
        (app) => {
          ensureScmRepoLink(this.$store, {
            staleAfter: "always",
            payload: { app },
          });
        },
        // No app? Redirect to app listing
        () => this.$router.push({ name: Routes.Projects }),
      );
    },
    async handleSCMCallback(query) {
      if (query.scmType) {
        this.currentCartridge = query.scmType;
      }
    },
    deployFormSubmitted({ app }) {
      this.appInfosStore.ensure({ staleAfter: "always" });

      this.$router.push({
        name: Routes.App.Root,
        params: { id: app.name, region: app.region },
      });
    },
    cartridgeSelected(eventArgs) {
      this.currentCartridge = eventArgs.cartridge;
    },
    connectSCMIntegration({ scmType }) {
      const pathname = this.$router.resolve({
        name: Routes.Configure.App,
        params: { id: this.app.name, region: this.app.region },
      }).href;

      performQuickOAuthLink(this.$store, scmType, pathname);
    },
    startHostedSCMConnection({ scmType }) {
      this.connectIntegrationHandler = new CreateSCMIntegrationHandler(
        this,
        scmType,
      );
    },
    cancelHostedSCMConnection() {
      this.connectIntegrationHandler = null;
    },
    startSCMLink({ scmIntegration }) {
      this.currentSCMIntegration = scmIntegration;
      this.currentOrgsPage = 1;
      this.listOrgsInfo = promiseInfo(
        listOrganizations(
          scalingoClient(this.$store, this.app.region, this.currentOrgsPage),
          scmIntegration.id,
        ),
      );
    },
    async loadMoreOrganizations() {
      this.listOrgsInfo.isLoading = true;
      const nextPage = this.currentOrgsPage + 1;
      const newOrgs = await listOrganizations(
        scalingoClient(this.$store, this.app.region),
        this.currentSCMIntegration.id,
        nextPage,
      );
      this.listOrgsInfo.data.organizations = [
        ...this.scmOrgs,
        ...newOrgs.organizations,
      ];
      this.listOrgsInfo.data.meta.pagination = newOrgs.meta.pagination;
      this.currentOrgsPage = nextPage;
      this.listOrgsInfo.isLoading = false;
    },
    stopSCMLink() {
      this.currentSCMIntegration = null;
      this.listOrgsInfo = null;
      this.listReposInfo = null;
      this.listBranchesInfo = null;
    },
    async unlinkRepo() {
      this.unlinkOperation = await removeSCMRepoLink(this.$store, this.app);
    },
    scmOrgSelected({ scmOrg }) {
      this.listReposInfo = promiseInfo(
        listMatchingRepos(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration,
          scmOrg,
        ),
      );
    },
    scmRepoSearched({ term, org }) {
      this.listReposInfo = promiseInfo(
        listMatchingRepos(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration,
          org,
          term,
        ),
      );
    },
    scmRepoSelected({ scmRepo }) {
      const repo = this.scmRepos.find((repo) => {
        return repo.url == scmRepo;
      });

      this.listBranchesInfo = promiseInfo(
        listBranchesForRepo(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration.id,
          repo.fullName,
        ),
      );
    },
    scmBranchSelected() {
      // Nothing to do here
    },
    async finishSCMRepoLink({ form }) {
      const payload = {
        source: form.repository,
        branch: form.branch,
        auth_integration_uuid: this.currentSCMIntegration.id,
        auto_deploy_enabled: form.autoDeploy,
      };

      this.repoLinkOperation = await createScmRepoLink(
        this.$store,
        this.app,
        payload,
      );
    },
  },
});
</script>
