export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "tooSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Window is too small to display this element. Turn your phone"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "toggleSecretiveMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide values that may contain sensitive data such as passwords."])},
        "headerNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visible on hover"])},
        "blurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide values"])},
        "exportCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export as CSV"])},
        "exportJson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export as JSON"])}
      },
      "fr": {
        "tooSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'affichage est trop petit pour cet élément. Tourner votre téléphone"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
        "toggleSecretiveMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher les valeurs qui peuvent contenir des données sensibles telles que des mots de passe."])},
        "headerNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visible au survol"])},
        "blurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher les valeurs"])},
        "exportCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en CSV"])},
        "exportJson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en JSON"])}
      }
    }
  })
}
