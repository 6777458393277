<template>
  <div>
    <ContractUpdateBanner
      v-for="contract in contractsToAccept.items"
      :key="`contract-${contract.id}`"
      :contract="contract"
      @start-contract-acceptance="(e) => $emit('startContractAcceptance', e)"
    />
    <ContactNearlyOutdatedBanner
      v-if="contactNearlyOutdated && bannerIsDisplayed"
      :contactLastUpdate="contactLastUpdate"
      @dismiss="$emit('dismissBanner')"
    />
    <ContactOutdatedBanner v-if="contactOutdated" />

    <main class="container px-4">
      <UnconfirmedUserAlert
        v-if="!user.confirmed"
        class="mt-4"
      />
    </main>

    <router-view class="container px-4" />

    <AcceptContractModal
      v-if="acceptContractHandler"
      :formHandler="acceptContractHandler"
      @modal-closed="$emit('cancelContractAcceptance')"
      @form-submitted="(e) => $emit('submitContractAcceptance', e)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import UnconfirmedUserAlert from "@/components/organisms/alerts/UnconfirmedUserAlert.vue";
import ContactNearlyOutdatedBanner from "@/components/organisms/banners/ContactNearlyOutdatedBanner.vue";
import ContactOutdatedBanner from "@/components/organisms/banners/ContactOutdatedBanner.vue";
import ContractUpdateBanner from "@/components/organisms/banners/ContractUpdateBanner.vue";
import AcceptContractModal from "@/components/parts/account/contracts/AcceptContractModal.vue";

export default defineComponent({
  name: "Welcome",
  components: {
    ContactNearlyOutdatedBanner,
    ContactOutdatedBanner,
    UnconfirmedUserAlert,
    ContractUpdateBanner,
    AcceptContractModal,
  },
  props: {
    user: Object,
    contractsToAccept: Object,
    acceptContractHandler: Object,
    contactNearlyOutdated: {
      type: Boolean,
      default: false,
    },
    contactOutdated: {
      type: Boolean,
      default: false,
    },
    bannerIsDisplayed: {
      type: Boolean,
      default: true,
    },
    contactLastUpdate: String,
  },
  emits: [
    "cancelContractAcceptance",
    "startContractAcceptance",
    "submitContractAcceptance",
    "dismissBanner",
  ],
});
</script>
