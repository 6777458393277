export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
        "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators"])},
        "groupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group by"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      },
      "fr": {
        "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
        "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs"])},
        "groupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouper par"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
      }
    }
  })
}
