<template>
  <Card>
    <template #header>
      <div v-if="hdsContact">
        <CardHeader :title="$t('title')">
          <template #buttons>
            <div
              class="ml-2 flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:space-x-2"
            >
              <SCButton
                kind="primary"
                @click="$emit('touchHDSContact')"
              >
                {{ $t("touchButtonName") }}
              </SCButton>
              <SCButton
                :actions="actions"
                @click="
                  $emit('startHDSContactEdition', { contact: hdsContact })
                "
                @action="(e) => actionClicked(e)"
              >
                {{ $t("buttonName.edit") }}
              </SCButton>
            </div>
          </template>
        </CardHeader>
      </div>
      <div v-else>
        <CardHeader :title="$t('title')">
          <template #buttons>
            <SCButton @click="$emit('startHDSContactCreation')">
              {{ $t("buttonName.create") }}
            </SCButton>
          </template>
        </CardHeader>
      </div>
    </template>
    <template #body>
      <div v-if="hdsContact">
        <SCBanner
          v-if="hdsContact.nearly_outdated"
          class="mb-2"
          align="center"
        >
          {{ $t("nearlyOutdatedBanner") }}
          {{ outdatedContactDate }}
        </SCBanner>
        <SCBanner
          v-if="hdsContact.outdated"
          class="mb-2"
          kind="warning"
          align="center"
        >
          {{ $t("outdatedBanner") }}
        </SCBanner>
        <p>
          {{ $t("warningContact") }}

          <DocLink
            class="font-normal"
            href="https://scalingo.com/gtc-appendix-health-data-hosting"
          >
            {{ $t("link") }}
          </DocLink>
        </p>
        <HDSContactInfo :hdsContact="hdsContact" />
        <ProTipAlert class="mt-4">
          {{ $t("tip") }}
          <div>
            {{ $t("lastUpdate") }}
            <span class="font-semibold">{{ formatDateTime(hdsContact.updated_at, "DDD") }}
              <span>({{ relativeDateTime(hdsContact.updated_at) }})</span>
            </span>
          </div>
        </ProTipAlert>
      </div>
      <div v-else>
        <NoHDSContactBanner />
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import NoHDSContactBanner from "@/components/organisms/banners/NoHDSContactBanner.vue";
import HDSContactInfo from "@/components/parts/account/hdsContact/HDSContactInfo.vue";
import {
  formatDateTime,
  relativeDateTime,
  addToDateTime,
} from "@/lib/utils/time";

export default defineComponent({
  name: "HDSContactProfileCard",
  components: {
    SCBanner,
    NoHDSContactBanner,
    ProTipAlert,
    Card,
    CardHeader,
    SCButton,
    HDSContactInfo,
    DocLink,
  },
  props: {
    hdsContact: Object,
  },
  emits: [
    "startHDSContactCreation",
    "startHDSContactEdition",
    "startDeleteHDSContact",
    "touchHDSContact",
  ],
  computed: {
    outdatedContactDate() {
      return addToDateTime(this.hdsContact?.updated_at, { years: 1 }, "D");
    },
    actions() {
      return [
        {
          text: this.$t("actions.delete"),
          key: "delete",
          kind: "warning",
        },
      ];
    },
  },
  methods: {
    relativeDateTime,
    formatDateTime,
    actionClicked({ action }) {
      if (action.key === "delete") {
        this.deleteHDSContact();
      }
    },
    deleteHDSContact() {
      this.$emit("startDeleteHDSContact");
    },
  },
});
</script>

<i18n>
en:
  title: "Point of contact Health professional"
  warningContact: "As part of the hosting of health data (HDH), you must define and keep up to date a point of contact who is able to designate a health professional if necessary: { link }"
  link: "Article 10. CLIENT'S commitments"
  actions:
    delete: "Delete the point of contact"
  buttonName:
    edit: "Update the point of contact"
    create: "Define the point of contact"
  touchButtonName: "Mark as up to date"
  tip: "The point of contact information should be reviewed at least every 12 months and updated if necessary."
  lastUpdate: "Last verification or update:"
  nearlyOutdatedBanner: "You are hosting HDH applications and this implies a periodic verification of the point of contact health professional information. This check must be carried out before"
  outdatedBanner: "In order to continue using your HDS apps, you must review and, if necessary, update the point of contact Health professional information."
fr:
  title: "Point de contact professionnel de santé"
  warningContact: "Dans le cadre de l'hébergement de donnée de santé (HDS), vous devez définir, et maintenir à jour, un point de contact étant en mesure de désigner un professionnel de santé lorsque cela est nécessaire : { link }"
  link: "Article 10. Obligations du Client"
  actions:
      delete: "Supprimer le point de contact"
  buttonName:
    edit: "Mettre à jour le point de contact"
    create: "Définir le point de contact"
  touchButtonName: "Marquer comme à jour"
  tip: "Les informations relatives au point de contact doivent être vérifiées au moins tous les 12 mois et mises à jour si nécessaire."
  lastUpdate: "Dernière vérification ou actualisation :"
  nearlyOutdatedBanner: "Vous hébergez des applications HDS et cela implique une vérification régulière des informations du point de contact professionnel de santé. Cette vérification doit être réalisée avant le"
  outdatedBanner: "Afin de pouvoir continuer à utiliser vos applications HDS, vous devez vérifier, et le cas échéant mettre à jour, les informations du point de contact professionnel de santé."
</i18n>
