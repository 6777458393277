<template>
  <svg
    data-v-2c00b661=""
    stroke="currentColor"
    fill="none"
    viewBox="0 0 24 24"
    class="block h-6 w-6"
  >
    <path
      data-v-2c00b661=""
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuGlyph",
});
</script>
