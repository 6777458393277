<template>
  <BaseModal
    :panelClasses="panelClasses"
    @close="(e) => $emit('close', e)"
  >
    <template #title>
      <slot name="title">
        {{ title }}
      </slot>
    </template>

    <form @submit="onSubmit">
      <slot />

      <div class="mt-6 flex">
        <SCButton
          block
          kind="primary"
          type="submit"
          size="lg"
          class="flex-grow"
          :loading="loading"
          :disabled="disableSubmit"
        >
          <CheckIcon
            v-if="success"
            class="h-6 inline-block"
          />
          <slot
            v-else
            name="submitText"
          >
            {{ submitLabel || $t("submit") }}
          </slot>
        </SCButton>
      </div>
    </form>

    <div
      v-if="withCancelBtn"
      class="flex"
    >
      <SCButton
        block
        kind="neutral"
        size="lg"
        class="flex flex-grow mt-4"
        :disabled="disableCancel"
        @click="close"
      >
        <slot name="cancelText">
          {{ cancelLabel || $t("cancel") }}
        </slot>
      </SCButton>
    </div>
  </BaseModal>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/24/outline";
import { useForm } from "vee-validate";
import { computed, watch, defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import BaseModal from "@/components/molecules/modals/BaseModal.vue";
import { closeCurrentModal } from "@/lib/modals";

export default defineComponent({
  components: {
    BaseModal,
    CheckIcon,
    SCButton,
  },
  props: {
    panelClasses: { type: String },
    title: { type: String },
    promiseInfo: { type: Object },
    fieldErrors: { type: Object },
    submitLabel: { type: String },
    withCancelBtn: { type: Boolean, default: true },
    cancelLabel: { type: String },
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const { handleSubmit, isSubmitting, meta, setErrors } = useForm();

    const loading = computed(
      () => isSubmitting.value || props.promiseInfo?.isLoading || false,
    );
    const success = computed(() => props.promiseInfo?.isSuccess || false);
    const disableSubmit = computed(() => !meta.value.valid);
    const disableCancel = computed(() => loading.value || success.value);

    // These lines feeds the server side errors to vee-validate.
    // When the field errors change, set the errors on the form.
    watch(
      () => props.fieldErrors,
      (errors) => setErrors(errors),
    );

    // Emit a `submit` event if the form is valid
    const onSubmit = handleSubmit(() => emit("submit"));

    return {
      disableSubmit,
      disableCancel,
      loading,
      success,
      onSubmit,
      close: closeCurrentModal,
    };
  },
});
</script>

<i18n>
en:
  submit: "Submit"
  cancel: "Cancel"
fr:
  submit: "Soumettre"
  cancel: "Annuler"
</i18n>
