<template>
  <ConfirmModal
    :immediate="false"
    :promiseInfo="context.info"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-lg"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <p>
      {{
        $t(`question.${integration.scm_type}`, {
          server: integration.url,
        })
      }}
    </p>

    <SCAlert
      v-if="context.info?.isError"
      class="mt-4"
      kind="error"
      icon="title"
      :title="$t('error.title')"
    >
      <template #icon>
        <ExclamationTriangleIcon class="h-6" />
      </template>

      <p>{{ $t("error.message") }}</p>

      <ul class="mt-2 list-disc list-inside">
        <li
          v-for="link in formattedErrors"
          :key="link.id"
        >
          <router-link
            :to="routeFor(link)"
            target="_blank"
            class="underline"
          >
            {{ link.app_name }}
          </router-link>
        </li>
      </ul>
    </SCAlert>
  </ConfirmModal>
</template>

<script>
import { ExclamationTriangleIcon } from "@heroicons/vue/24/solid";
import { computed, defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  components: { SCAlert, ConfirmModal, ExclamationTriangleIcon },
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "confirm"],
  setup(props) {
    function routeFor(link) {
      return {
        name: Routes.App.Deploy.Configuration,
        params: {
          id: link.app_name,
          region: link.region,
        },
      };
    }

    const integration = computed(() => props.context.scmIntegration);

    const formattedErrors = computed(() => {
      if (!props.context?.errors) return null;

      return props.context.errors.scmRepoLinks;
    });

    return { integration, formattedErrors, routeFor };
  },
});
</script>

<i18n>
en:
  title: "Disconnect from SCM provider"
  question:
    github: "Are you sure you want to disconnect your GitHub account?"
    gitlab: "Are you sure you want to disconnect your GitLab account?"
    github-enterprise: "Are you sure you want to disconnect your GitHub account on {server}?"
    gitlab-self-hosted: "Are you sure you want to disconnect your GitLab account on {server}?"
  error:
    title: "This SCM integration is still used by the following applications"
    message: "Unlink them or use another Scalingo account as linker before disconnecting your account."
  button: "Disconnect"
fr:
  title: "Se déconnecter d'un SCM"
  button: "Déconnecter"
  question:
    github: "Êtes vous sur de vouloir déconnecter votre compte GitHub?"
    gitlab: "Êtes vous sur de vouloir déconnecter votre compte GitLab?"
    github-enterprise: "Êtes vous sur de vouloir déconnecter votre compte GitHub de {server}?"
    gitlab-self-hosted: "Êtes vous sur de vouloir déconnecter votre compte GitLab de {server}?"
  error:
    title: "Cette intégration SCM est utilisée par les applications suivantes"
    message: "Déliez les, ou bien utilisez un autre compte Scalingo en tant que linker avant de déconnecter votre compte."
</i18n>
