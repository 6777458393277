<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <GithubAddGlyph
        v-if="isGithub"
        class="h-6 w-6 text-scale-6"
      />
      <GitlabAddGlyph
        v-else
        class="h-6 w-6 text-scale-6"
      />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("repository") }}:
      <ExtLink
        :href="event.type_data.source"
        class="font-medium ml-1"
      >
        {{ event.type_data.repo_name }}
      </ExtLink>
    </template>
    <template #body>
      <ScmBody :event="event" />
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import GithubAddGlyph from "@/components/atoms/glyphs/GithubAddGlyph.vue";
import GitlabAddGlyph from "@/components/atoms/glyphs/GitlabAddGlyph.vue";
import ExtLink from "@/components/molecules/links/ExtLink.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";
import ScmBody from "@/components/parts/events/ScmBody.vue";

export default defineComponent({
  name: "LinkScmEvent",
  components: { ScmBody, ExtLink, GitlabAddGlyph, GithubAddGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isGithub() {
      const source = this.event.type_data.source;
      return !!source.startsWith("https://github.com/");
    },
  },
});
</script>

<i18n>
en:
  title: "SCM integration linked"
  repository: "Repository"
fr:
  title: "Intégration SCM liée"
  repository: "Dépôt"
</i18n>
