<template>
  <OnClickOutside
    class="relative place-self-stretch"
    @trigger="hideActions"
  >
    <BaseButton
      class="min-h-full self-center text-scale-4 hover:text-scale-6"
      :size="false"
      @click="toggleActions"
    >
      <span class="sr-only">{{ $t("openLabel") }}</span>
      <HorizontalDotsGlyph />
    </BaseButton>

    <DropdownMenu
      class="w-64"
      :align="align"
      :show="displayActions"
      :items="actions"
      @click="actionClicked"
    />
  </OnClickOutside>
</template>

<script>
import { OnClickOutside } from "@vueuse/components";
import { defineComponent } from "vue";

import BaseButton from "@/components/atoms/Button.vue";
import HorizontalDotsGlyph from "@/components/atoms/glyphs/HorizontalDotsGlyph.vue";
import DropdownMenu from "@/components/molecules/dropdowns/DropdownMenu.vue";

export default defineComponent({
  name: "HorizontalDottedDropdown",
  components: { OnClickOutside, HorizontalDotsGlyph, BaseButton, DropdownMenu },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    align: {
      type: String,
      default: "alongside-right",
    },
  },
  emits: ["action"],
  data() {
    return {
      displayActions: false,
    };
  },

  methods: {
    toggleActions() {
      this.displayActions = !this.displayActions;
    },
    actionClicked(e) {
      this.hideActions();
      this.$emit("action", e);
    },
    hideActions() {
      this.displayActions = false;
    },
  },
});
</script>
