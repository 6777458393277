<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :container="container"
    :containerSizes="containerSizes"
    :since="since"
    :containersInfos="containersInfos"
    :containersRawData="containersRawData"
    @range-selected="rangeSelected"
  />
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/metrics/PerContainer.vue";
import { upsertDataPoints } from "@/lib/metrics";
import { pendingPromiseInfo, promiseInfo } from "@/lib/promises/info";
import { scalingoClient } from "@/lib/scalingo/client";
import { Routes } from "@/router/names";
import {
  ensureContainerSizes,
  listContainerSizes,
} from "@/store/container-sizes";
import { listContainers, ensureContainers } from "@/store/containers";

export default defineComponent({
  name: "MetricsPerContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Config
      since: 3,
      last: false,
      intervalID: null,
      refreshInterval: null,
      // Operations
      referenceInfo: pendingPromiseInfo(),
      containersInfos: [],
      // Raw datasets
      containersRawData: [],
    };
  },
  computed: {
    scalingo() {
      return scalingoClient(this.$store, this.app.region);
    },
    containers() {
      return listContainers(this.$store);
    },
    container() {
      return this.containers.items.find(
        (c) => c.name === this.$route.params.containerType,
      );
    },
    containerSizes() {
      return listContainerSizes(this.$store, this.app.region);
    },
    containersPerName() {
      const res = {};

      this.containers.items.forEach(
        (container) => (res[container.name] = container),
      );

      return res;
    },
    commonGraphOptions() {
      if (this.last) {
        return { last: true };
      } else {
        return { since: this.since };
      }
    },
  },
  watch: {
    container(newVal) {
      if (newVal) {
        this.prepareContainersMetrics();
        this.refreshContainersMetrics();
      }
    },
    "referenceInfo.isSuccess": function (newVal) {
      if (newVal) {
        const datapoints = this.referenceInfo.data;
        const first = datapoints[0];
        const second = datapoints[1];

        if (first && second) {
          const diff = DateTime.fromISO(second.time).diff(
            DateTime.fromISO(first.time),
          );

          this.refreshInterval = diff.as("milliseconds");

          return;
        }
      }

      this.refreshInterval = null;
    },
    // @note no refresh for now. it's almost always no-data and it screws up the display.
    // refreshInterval(newVal) {
    //   if (this.intervalID) {
    //     clearInterval(this.intervalID);
    //   }

    //   if (newVal) {
    //     this.intervalID = setInterval(() => {
    //       this.refreshContainersMetrics();
    //     }, newVal);
    //   }
    // },
  },
  mounted() {
    this.refreshContainers();
  },
  unmounted() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  },
  methods: {
    async refreshContainers() {
      ensureContainers(this.$store, { staleAfter: "always" });
      ensureContainerSizes(this.$store, this.app.region);

      await this.containers.latestFetch.settled;
      await this.$nextTick();

      if (!this.container) {
        this.$router.push({
          name: Routes.App.Metrics.Root,
        });
      }
    },
    prepareContainersMetrics() {
      this.containersInfos = new Array(this.container.amount);
      this.containersRawData = new Array(this.container.amount);

      for (let index = 0; index < this.container.amount; index++) {
        this.containersInfos[index] = {
          cpu: pendingPromiseInfo(),
          memory: pendingPromiseInfo(),
          swap: pendingPromiseInfo(),
          allCpu: pendingPromiseInfo(),
          allMemory: pendingPromiseInfo(),
        };

        this.containersRawData[index] = {
          cpu: [],
          memory: [],
          swap: [],
        };
      }
    },
    refreshContainersMetrics() {
      const containerName = this.container.name;
      this.referenceInfo = pendingPromiseInfo();

      for (let index = 0; index < this.container.amount; index++) {
        const containerPromises = {};

        Object.keys(this.containersRawData[index]).forEach((statisticsType) => {
          containerPromises[statisticsType] = this.refreshContainerMetrics(
            containerName,
            index,
            statisticsType,
          );
        });

        if (!this.last) {
          // There is only one dataset for cpu
          this.containersInfos[index].allCpu = promiseInfo(
            containerPromises.cpu,
          );

          // But there is two for the memory
          this.containersInfos[index].allMemory = promiseInfo(
            Promise.all([containerPromises.memory, containerPromises.swap]),
          );
        }
      }

      this.last = true;
    },
    async refreshContainerMetrics(
      containerName,
      containerIndex,
      statisticsType,
    ) {
      const promise = this.scalingo.Metrics.get(this.app.id, statisticsType, {
        ...this.commonGraphOptions,
        containerType: containerName,
        containerIndex: containerIndex + 1,
      });

      this.containersInfos[containerIndex][statisticsType] =
        promiseInfo(promise);

      if (!this.referenceInfo) {
        this.referenceInfo =
          this.containersInfos[containerIndex][statisticsType];
      }

      promise.then(
        (data) => {
          upsertDataPoints(
            this.containersRawData[containerIndex][statisticsType],
            data,
          );
        },
        () => {}, // no-op rejection
      );

      return promise;
    },
    rangeSelected(since) {
      this.last = false;
      this.since = since;
      this.containersRawData = [];
      this.refreshInterval = null;
      this.intervalID = null;

      this.refreshContainers();
    },
  },
});
</script>
