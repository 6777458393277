<template>
  <main>
    <PlatformCard
      :enabled="!selectedPlatform"
      :notificationPlatforms="notificationPlatforms"
      @platform-selected="(e) => $emit('platformSelected', e)"
    />
    <CardSeparator />
    <NotifierInfoCard
      :owner="app.owner"
      :enabled="!!selectedPlatform"
      :collaborators="collaborators"
      :notifierType="selectedPlatform && selectedPlatform.name"
      :formHandler="formHandler"
      @submit="(e) => $emit('submit', e)"
    />
    <CardSeparator />
    <EventsCard :enabled="false" />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CardSeparator from "@/components/molecules/card/CardSeparator.vue";
import EventsCard from "@/components/parts/app/notifiers/creation/EventsCard.vue";
import NotifierInfoCard from "@/components/parts/app/notifiers/creation/NotifierInfoCard.vue";
import PlatformCard from "@/components/parts/app/notifiers/creation/PlatformCard.vue";

export default defineComponent({
  name: "NotifiersCreationRoot",
  components: {
    PlatformCard,
    CardSeparator,
    NotifierInfoCard,
    EventsCard,
  },
  props: {
    app: {
      type: Object,
      required: true,
    },
    notificationPlatforms: {
      type: Object,
      required: true,
    },
    collaborators: Object,
    selectedPlatform: Object,
    formHandler: Object,
  },
  emits: ["platformSelected", "submit"],
});
</script>
