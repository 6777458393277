<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      >
        <template #buttons>
          <SCButton @click="$emit('startReviewAppConfiguration')">
            {{ $t("editButton") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="py-3 flex items-center">
        <TwoStatesIndicator
          class="mr-2"
          :isOn="scmRepoLink.deploy_review_apps_enabled"
          :alternativeColors="true"
        />
        <span v-if="scmRepoLink.deploy_review_apps_enabled">
          {{ $t("enabled") }}
        </span>
        <span v-if="!scmRepoLink.deploy_review_apps_enabled">
          {{ $t("disabled") }}
        </span>
      </div>

      <div v-if="scmRepoLink.deploy_review_apps_enabled">
        <div class="ml-8">
          {{ $t("allowForkTitle") }}
        </div>
        <div class="py-2 flex items-center ml-16">
          <TwoStatesIndicator
            class="mr-2"
            :isOn="scmRepoLink.automatic_creation_from_forks_allowed"
            :alternativeColors="true"
          />
          {{ $t("allowForkText") }}
        </div>

        <div class="ml-8">
          {{ $t("autoDestroy") }}
        </div>
        <div class="py-2 flex items-center ml-16">
          <TwoStatesIndicator
            class="mr-2"
            :isOn="scmRepoLink.delete_on_close_enabled"
            :alternativeColors="true"
          />
          {{ $t("autoDestroyPrLabel")
          }}<span v-if="scmRepoLink.delete_on_close_enabled">,
            {{
              $tc("after", scmRepoLink.hours_before_delete_on_close, {
                count: scmRepoLink.hours_before_delete_on_close,
              })
            }}</span>
        </div>
        <div class="flex items-center ml-16">
          <TwoStatesIndicator
            class="mr-2"
            :isOn="scmRepoLink.delete_stale_enabled"
            :alternativeColors="true"
          />
          {{ $t("autoDestroyNoEventLabel") }}
          <span v-if="scmRepoLink.delete_stale_enabled">
            ,
            {{
              $tc("during", scmRepoLink.hours_before_delete_stale, {
                count: scmRepoLink.hours_before_delete_stale,
              })
            }}
          </span>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "AutomaticReviewAppsCard",
  components: {
    SCButton,
    TwoStatesIndicator,
    Card,
    CardHeader,
  },
  props: {
    scmRepoLink: Object,
  },
  emits: ["startReviewAppConfiguration"],
});
</script>

<i18n>
en:
  title: "Automatic deployment of review apps"
  subtitle: "Create a new review app for each new pull request."
  enabled: "Enabled"
  disabled: "Disabled"
  editButton: "Edit"
  autoDestroy: "Auto destroy review apps when"
  allowForkTitle: "Auto deploy review apps from forks"
  allowForkText: "Allow automatic deployment of review apps from forks"
  autoDestroyPrLabel: "The pull request is closed"
  autoDestroyNoEventLabel: "No deploy or commits events had happened"
  after: "delete instantly | delete after 1 hour | delete after {count} hours"
  during: "during 0 hour | during 1 hour | during {count} hours"
fr:
  title: "Déploiement automatique des review apps"
  subtitle: "Créer une nouvelle review app pour chaque nouvelle pull request."
  enabled: "Activé"
  disabled: "Désactivé"
  editButton: "Editer"
  autoDestroy: "Auto détruit les review apps quand"
  allowForkTitle: "Auto déploiement des review apps provenant de forks"
  allowForkText: "Autoriser le déployment automatique de review apps provenant de forks"
  autoDestroyPrLabel: "La pull request est fermée"
  autoDestroyNoEventLabel: "Aucun deploiement ou commits ne s'est passé"
  after: "supprimer immédiatement | supprimer après une 1 heure | supprimer après {count} heures"
  during: "pendant 0 heure | pendant 1 heure | pendant {count} heures"
</i18n>
