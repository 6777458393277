<template>
  <FormModal
    :promiseInfo="context.info"
    :title="$t('title')"
    :fieldErrors="errors"
    panelClasses="sm:w-full sm:max-w-lg"
    :submitLabel="$t('action')"
    class="text-scale-10"
    @close="(e) => $emit('close', e)"
    @submit="onSubmit"
  >
    <template #title>
      <div class="flex flex-grow items-center py-4">
        <GitHubGraphic v-if="scmType === 'github-enterprise'" />
        <GitLabGraphic v-if="scmType === 'gitlab-self-hosted'" />

        <div class="px-4 text-lg font-bold">
          {{ title }}
        </div>
      </div>
    </template>

    <CreateHostedSCMInstructions :scmType="scmType" />

    <div class="mt-6">
      <Field
        v-slot="{ field, fieldErrors, handleChange }"
        v-model="form.url"
        name="url"
        rules="required|url"
        mode="eager"
      >
        <TextInput
          :name="field.name"
          :modelValue="field.value"
          :label="$ta('scmIntegration', 'url')"
          labelClass="uppercase"
          :errors="fieldErrors"
          @update:model-value="handleChange"
        />
      </Field>
    </div>
    <div class="mt-6">
      <Field
        v-slot="{ field, fieldErrors, handleChange }"
        v-model="form.access_token"
        name="access_token"
        rules="required"
      >
        <TextInput
          :name="field.name"
          :modelValue="field.value"
          :label="$ta('scmIntegration', 'accessToken')"
          labelClass="capitalize"
          :errors="fieldErrors"
          @update:model-value="handleChange"
        />
      </Field>
    </div>
  </FormModal>
</template>

<script>
import { Field } from "vee-validate";
import { computed, defineComponent, reactive } from "vue";

import GitHubGraphic from "@/components/atoms/graphics/GitHubGraphic.vue";
import GitLabGraphic from "@/components/atoms/graphics/GitLabGraphic.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import FormModal from "@/components/molecules/modals/FormModal.vue";
import CreateHostedSCMInstructions from "@/components/organisms/helps/CreateHostedSCMInstructions.vue";
import ModelsTranslation from "@/mixins/models_translation";

export function initForm(extra) {
  return {
    url: "https://",
    access_token: null,
    ...extra,
  };
}

export default defineComponent({
  components: {
    GitHubGraphic,
    GitLabGraphic,
    Field,
    FormModal,
    CreateHostedSCMInstructions,
    TextInput,
  },
  mixins: [ModelsTranslation],
  props: {
    context: { type: Object, required: true },
  },
  emits: ["close", "submit"],
  setup(props, { emit }) {
    const form = reactive(initForm({ scm_type: props.context.scmType }));

    const integration = computed(() => props.context.scmIntegration);

    const formattedErrors = computed(() => {
      if (!props.context?.info?.err) return null;

      return props.context.info.err.data.scm_repo_links;
    });

    const onSubmit = () => {
      emit("submit", form);
    };

    const errors = computed(() => props.context.errors);

    return {
      form,
      errors,
      integration,
      formattedErrors,
      onSubmit,
      scmType: props.context.scmType,
    };
  },
  computed: {
    title() {
      if (this.scmType === "github-enterprise") {
        return this.$t("github.title");
      } else if (this.scmType === "gitlab-self-hosted") {
        return this.$t("gitlab.title");
      }

      return null;
    },
    subtitle() {
      if (this.scmType === "github-enterprise") {
        return this.$t("github.subtitle");
      } else if (this.scmType === "gitlab-self-hosted") {
        return this.$t("gitlab.subtitle");
      }

      return null;
    },
  },
});
</script>

<i18n>
en:
  action: "Connect"
  github:
    title: "Connect to a GitHub Enterprise"
    instructions:
      url: "Fill with the domain used to access your Github Enteprise, eg. {url}."
      token: "The token you need is a personal access token: {doc}"
      doc: "here is the GitHub documentation on how to create one"
      rights: "The only scope you need on Scalingo is {scope}."
  gitlab:
    title: "Connect to a self-hosted Gitlab"
    instructions:
      url: "Fill with the domain used to access your GitLab instance, eg. {url}."
      token: "The token you need is a personal access token: {doc}"
      doc: "here is the GitLab documentation on how to create one"
      rights: "The scopes you need on Scalingo are {scope}. Your user account must also have {roles}"
      roles: "a sufficient role, maintainer or owner"
fr:
  action: "Connecter"
  github:
    title: "Connexion a un GitHub Enterprise"
    instructions:
      url: "Remplissez avec le nom de domaine utilisé par votre GitHub Enterprise, par exemple {url}."
      token: |
        Le token nécessaire est un "personal access token": {doc}
      doc: "voici la documentation GitHub sur comment en créer un"
      rights: "Le seul scope nécessaire pour Scalingo est {scope}."
  gitlab:
    title: "Connexion a une instance GitLab"
    instructions:
      url: "Remplissez avec le nom de domaine utilisé par votre instance GitLab, par exemple {url}."
      token: |
        Le token nécessaire est un "personal access token": {doc}
      doc: "voici la documentation GitLab sur comment en créer un"
      rights: "Les scopes nécessaires pour Scalingo sont {scope}. Votre compte doit également avoir {roles}"
      roles: "un rôle tel quel maintainer ou owner"
</i18n>
