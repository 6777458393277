import "regenerator-runtime/runtime"; // Must be imported before AuthPlugin

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createPinia } from "pinia";
import Rollbar from "rollbar";
import { createApp, h } from "vue";
import vFlagIcon from "vue-flag-icon";
import { Translation } from "vue-i18n";
import ShortKey from "vue-three-shortkey";
import VueApexCharts from "vue3-apexcharts";

import i18n from "@/i18n";
import i18nComposition from "@/i18nComposition";
import { StoreAccessors } from "@/lib/plugins/store-accessors";
import router from "@/router";
import store from "@/store";

import App from "./App.vue";

import "@/lib/validations";
import "@/lib/intercom";
import "@/assets/styles/index.css";

import "@/config/icons";

type ThemePreference = "light" | "dark";
type ThemeScheme = "theme-light" | "theme-dark";

type Data = {
  userTheme: ThemePreference | null;
  systemTheme: ThemePreference | null;
};

type CustomPayload = {
  [key: string]: { error: string };
};

const SKIPPABLE_ERRORS = [
  // following errors are used as part of the authentication middlewares as control-flow instructions
  // login_required is raised in LoginFromOAuthCallback middleware
  "login_required",
  //oauth-callback/no-token is raised in LoginFromOAuthCallback middleware
  "oauth-callback/no-token",
  // oauth-assisted/message-timeout is raised in LoginFromOAuthAssisted middleware
  "oauth-assisted/message-timeout",
  // oauth-assisted/iframe-timeout is raised in LoginFromOAuthAssisted middleware
  "oauth-assisted/iframe-timeout",
  // oauth-assisted/expired is raised in LoginFromOAuthAssisted middleware
  "oauth-assisted/expired",
  //cache/expired is raised in LoginFromCache middleware
  "cache/expired",
  //cache/empty is raised in LoginFromCache middleware
  "cache/empty",
];

const isSkippableError = (payloadCustom: CustomPayload) => {
  return Object.values(payloadCustom).some(({ error }) =>
    SKIPPABLE_ERRORS.includes(error),
  );
};

const isBrowserExtensionError = (payload: Rollbar.Dictionary): boolean => {
  const body = payload.body;

  if (!body || typeof body !== "object") {
    return false;
  }

  return hasBrowserExtensionFrames((body as { trace?: unknown }).trace);
};

const hasBrowserExtensionFrames = (trace: unknown): boolean => {
  if (!trace || typeof trace !== "object") {
    return false;
  }

  const frames = (trace as { frames?: unknown[] }).frames;

  if (!Array.isArray(frames)) {
    return false;
  }

  return frames.some((frame) => {
    if (typeof frame !== "object" || frame === null) {
      return false;
    }
    const filename = (frame as { filename?: string }).filename ?? "";
    return (
      filename.startsWith("chrome-extension://") ||
      filename.startsWith("moz-extension://")
    );
  });
};


const app = createApp({
  data() {
    const data: Data = {
      userTheme: null,
      systemTheme: null,
    };

    return data;
  },
  computed: {
    appTheme(): ThemeScheme {
      if (this.userTheme === "dark") {
        return "theme-dark";
      } else if (this.userTheme === "light") {
        return "theme-light";
      } else if (this.systemTheme === "dark") {
        return "theme-dark";
      }

      return "theme-light";
    },
  },
  created() {
    const darkModeQuery = matchMedia
      ? matchMedia("(prefers-color-scheme: dark)")
      : null;

    const darkMode = darkModeQuery?.matches;

    const eventCallback = (e: MediaQueryListEvent): void => {
      this.systemTheme = e.matches ? "dark" : "light";
    };

    // Listen for changes. Second condition is a fallback for Safari,
    // who apparently has not switched to `addEventListener` yet.
    if (darkModeQuery?.addEventListener) {
      darkModeQuery.addEventListener("change", eventCallback);
    } else if (darkModeQuery?.addListener) {
      darkModeQuery.addListener(eventCallback);
    }

    // Initial value
    this.systemTheme = darkMode ? "dark" : "light";
  },
  render: () => h(App),
});

const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(store);
app.use(i18n);
app.use(i18nComposition);
app.use(ShortKey);
app.use(VueApexCharts);

app.component("Apexchart", VueApexCharts);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.component("I18nT", Translation);
app.use(vFlagIcon);

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app's existing
// behavior while also reporting uncaught errors to Rollbar.

app.use(StoreAccessors);

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
app.config.globalProperties.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === "production",
  verbose: true,
  environment: process.env.VUE_APP_ENVIRONMENT,
  payload: {
    client: {
      javascript: {
        code_version: "1.0",
        source_map_enabled: true,
      },
    },
  },
  checkIgnore: function (isUncaught, args, payload) {
    if (payload.custom && isSkippableError(payload.custom as CustomPayload)) {
      return true;
    }  

    return isBrowserExtensionError(payload);
  },
});

app.config.errorHandler = (err, instance /*, info */) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((instance as any)?.$rollbar as Rollbar).error(err as any);
  throw err; // rethrow
};

app.mount("#app");
