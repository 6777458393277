<template>
  <ViewComponent
    :user="currentUser"
    :billingProfile="billingProfile"
    :balanceOperations="balanceOperations"
    @fetch-more-operations="fetchMoreOperations"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/billing/invoices/Detailed.vue";
import {
  listOperations,
  ensureOperations,
  fetchMoreOperations,
} from "@/store/balance-operations";

const OPERATIONS_PER_PAGE = 20;

export default defineComponent({
  name: "DetailedContainer",
  components: { ViewComponent },
  props: {
    billingProfile: Object,
  },
  computed: {
    balanceOperations() {
      return listOperations(this.$store);
    },
  },
  beforeMount() {
    if (this.currentUser) {
      ensureOperations(this.$store, this.currentUser.id, OPERATIONS_PER_PAGE);
    }
  },
  methods: {
    fetchMoreOperations() {
      fetchMoreOperations(
        this.$store,
        this.currentUser.id,
        OPERATIONS_PER_PAGE,
      );
    },
  },
});
</script>
