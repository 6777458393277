<template>
  <SCTable
    :headers="headers"
    :items="queriesListActive"
    :showFooter="queriesListActive.length > 0"
  >
    <template #item.pid="{ item }">
      <div class="truncate w-full">
        {{ item.pid }}
      </div>
    </template>
    <template #item.started="{ item }">
      <div class="truncate w-full">
        {{ formatDateTime(item.query_start) }}
      </div>
    </template>
    <template #item.duration="{ item }">
      <div class="truncate w-full">
        {{ formatDurationFromNanoseconds(item.query_duration) }}
      </div>
    </template>
    <template #item.username="{ item }">
      <div class="truncate w-full">
        {{ item.username }}
      </div>
    </template>
    <template #item.query="{ item }">
      <QueryContainer
        class="max-w-lg"
        :query="item.query"
        :isOpened="expandedContainer === item"
        @expand="expandContainer(item)"
      />
    </template>
    <template #item.actions="{ item }">
      <div class="flex justify-end">
        <SCTableAction
          :title="$t('delete')"
          type="delete"
          class="ml-2 bg-scale-2 h-8 p-auto align-middle"
          noPadding="true"
          @click="cancel(item)"
        >
          <div class="text-scale-8 flex my-auto px-2">
            <BinGlyph class="h-3 w-3 mr-1 my-auto" />
            {{ $t("buttons.cancel") }}
          </div>
        </SCTableAction>
        <SCTableAction
          :title="$t('delete')"
          type="delete"
          class="ml-2 bg-scale-2 h-8"
          noPadding="true"
          @click="kill(item)"
        >
          <div class="text-scale-8 flex my-auto px-2">
            <CrossGlyph
              class="mr-1 my-auto"
              style="height: 12px !important; width: 12px !important"
            />
            {{ $t("buttons.kill") }}
          </div>
        </SCTableAction>
      </div>
    </template>
  </SCTable>
  <div
    v-if="queriesListActive.length == 0"
    class="w-full text-center"
  >
    <div class="text-scale-6 font-medium pt-4">
      {{ $t("noRunningQueries") }}
    </div>
  </div>

  <RunningQueriesModal
    v-if="openModal"
    :type="modalInfos.type"
    :item="modalInfos.item"
    :method="modalInfos.method"
    @close="openModal = false"
  />
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import QueryContainer from "@/components/atoms/tables/QueryContainer.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";
import RunningQueriesModal from "@/components/parts/db/runningQueries/RunningQueriesModal.vue";
import {
  formatDateTime,
  formatDurationFromNanoseconds,
} from "@/lib/utils/time";

export default defineComponent({
  name: "RunningQueriesList",
  components: {
    SCTable,
    SCTableAction,
    QueryContainer,
    BinGlyph,
    CrossGlyph,
    RunningQueriesModal,
  },
  props: {
    queriesListActive: {
      type: Array,
      required: true,
    },
    cancelQuery: {
      type: Function,
      required: true,
    },
    terminateQuery: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          value: "pid",
          text: this.$i18n.t("tableHeaders.pid"),
          class: "w-1/12",
        },
        {
          value: "started",
          text: this.$i18n.t("tableHeaders.started"),
          class: "w-3/12",
        },
        {
          value: "duration",
          text: this.$i18n.t("tableHeaders.duration"),
          class: "w-1/12",
        },
        {
          value: "username",
          text: this.$i18n.t("tableHeaders.username"),
          class: "w-1/12",
        },
        {
          value: "query",
          text: this.$i18n.t("tableHeaders.sqlQuery"),
          class: "w-5/12",
        },
        { value: "actions", text: "", align: "right", class: "w-2/12" },
      ],
      tableContainerWidth: 0,
      openModal: false,
      modalInfos: {},
      expandedContainer: null,
    };
  },
  methods: {
    formatDurationFromNanoseconds,
    formatDateTime,
    cancel(item) {
      this.openModal = true;
      this.modalInfos = {
        item: item,
        type: "cancel",
        method: this.cancelQuery,
      };
    },
    kill(item) {
      this.openModal = true;
      this.modalInfos = {
        item: item,
        type: "kill",
        method: this.terminateQuery,
      };
    },
    expandContainer(item) {
      const isAlreadyExpanded = this.expandedContainer === item;

      this.expandedContainer = isAlreadyExpanded ? null : item;
    },
  },
});
</script>

<i18n>
  en:
    tableHeaders:
      sqlQuery: "Query"
      pid: PID
      started: Started on
      duration: Duration
      username: Username
    buttons:
      cancel: Cancel
      kill: Terminate
    noRunningQueries: "No queries are currently running."
  fr:
    tableHeaders:
      sqlQuery: "Requête"
      pid: PID
      started: Démarrée le
      duration: Durée
      username: Utilisateur
    buttons:
      cancel: Annuler
      kill: Interrompre
    noRunningQueries: "Aucune requête n'est actuellement en cours d'exécution."
</i18n>
