<template>
  <SideMenuLayout>
    <template #side>
      <SideMenu
        :app="app"
        :scmRepoLink="scmRepoLink.value"
      />
    </template>
    <template #router>
      <router-view
        v-if="scmRepoLink.latestFetch.isFinished"
        :app="app"
        :scmRepoLink="scmRepoLink"
      />
    </template>
  </SideMenuLayout>
</template>

<script>
import { defineComponent } from "vue";

import SideMenuLayout from "@/components/layouts/SideMenuLayout.vue";
import SideMenu from "@/components/organisms/menus/ReviewAppsMenu.vue";

export default defineComponent({
  name: "ReviewAppsRoot",
  components: {
    SideMenuLayout,
    SideMenu,
  },
  props: {
    app: Object,
    scmRepoLink: Object,
  },
});
</script>
