<template>
  <SCTable
    :headers="headers"
    :items="variables.items"
    :searchable="true"
    :searchFn="searchFn"
    :loading="variables.latestFetch.isLoading"
  >
    <template #item.name="{ item }">
      <span class="font-mono text-sm">
        {{ item.name }}
      </span>
    </template>

    <template #item.value="{ item, secretive }">
      <div
        class="w-full"
        :class="{
          'blur hover:blur-none hover:text-scale-10 filter transition transition-filter ease-in-out duration-100':
            secretive,
        }"
      >
        <span class="font-mono text-sm variable-value">
          <template
            v-for="(line, i) in linesForVar(item.value)"
            :key="`${item.id}-${i}`"
          >
            <template
              v-for="(str, j) in partsForVar(line)"
              :key="`${item.id}-${i}-${j}`"
            >{{ str }}<wbr></template>

            <template v-if="i + 1 !== nunOfLinesForVar(item.value)">
              <span
                :key="`break-${i}`"
                data-unselectable="\n"
                class="text-scale-3"
              ><br></span>
            </template>
          </template>
        </span>
      </div>
    </template>

    <template #item.actions="{ item, index }">
      <div class="flex justify-end">
        <SCTableAction
          :title="$t('actions.copy')"
          :rowIndex="index"
          @click="$emit('copyVariable', { variable: item })"
        >
          <CopyTableButton />
        </SCTableAction>

        <SCTableAction
          :title="$t('actions.edit')"
          :rowIndex="index"
          class="ml-2"
          @click="$emit('startVariableEdition', { variable: item })"
        >
          <EditGlyph class="h-4 w-4" />
        </SCTableAction>

        <SCTableAction
          :title="$t('actions.delete')"
          :rowIndex="index"
          class="ml-2"
          type="delete"
          @click="$emit('startVariableDeletion', { variable: item })"
        >
          <BinGlyph class="h-4 w-4" />
        </SCTableAction>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import EditGlyph from "@/components/atoms/glyphs/EditGlyph.vue";
import CopyTableButton from "@/components/organisms/buttons/CopyTableButton.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";

function searchFn(row, search) {
  const cols = [row.name, row.value];
  return cols.find((col) => {
    return (
      col.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !==
      -1
    );
  });
}

export default defineComponent({
  name: "VariablesTable",
  components: {
    CopyTableButton,
    SCTable,
    SCTableAction,
    EditGlyph,
    BinGlyph,
  },
  props: {
    variables: Object,
  },
  emits: ["copyVariable", "startVariableDeletion", "startVariableEdition"],
  data() {
    return {
      searchFn: searchFn,
      headers: [
        { value: "name", text: this.$t("headers.name") },
        { value: "value", text: this.$t("headers.value"), secretive: true },
        { value: "actions", text: "" },
      ],
    };
  },
  methods: {
    linesForVar(string) {
      return string.split("\n");
    },
    nunOfLinesForVar(string) {
      return this.linesForVar(string).length;
    },
    partsForVar(string) {
      // This allows to split the string without removing the character(s) we're splitting on
      // In this case, we split on non-words chars.
      // The goal is to give hints to the browser to do a word-wrap where it's most appropriate.
      return string.split(/(?=\W)/g);
    },
  },
});
</script>

<style scoped>
.variable-value {
  white-space: pre-wrap;
  hyphens: auto;
  overflow-wrap: anywhere;
}

[data-unselectable]::before {
  content: attr(data-unselectable);
}
</style>

<i18n>
en:
  headers:
    name: "Name"
    value: "Value"
  actions:
    copy: "Copy to clipboard"
    edit: "Edit variable"
    delete: "Delete variable"
fr:
  headers:
    name: "Nom"
    value: "Valeur"
  actions:
    copy: "Copy to clipboard"
    edit: "Edit variable"
    delete: "Delete variable"
</i18n>
