<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    />
    <CreditsListBody
      v-if="displayCredits"
      :credits="credits"
    />
    <EmptyCardState v-else>
      <template #icon>
        <BillGlyph class="h-8 text-scale-6" />
      </template>
      <span class="text-scale-6 text-lg font-medium">
        {{ $t("none") }}
      </span>
    </EmptyCardState>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import CreditsListBody from "@/components/parts/billing/showBilling/CreditsListBody.vue";

export default defineComponent({
  name: "CreditsList",
  components: {
    CardHeader,
    CreditsListBody,
    BillGlyph,
    EmptyCardState,
  },
  props: {
    credits: Object,
  },
  emits: ["redeemVoucher"],
  computed: {
    displayCredits() {
      const op = this.credits.latestFetch;

      return op.isLoading || this.credits.items.length > 0;
    },
  },
  methods: {
    redeemVoucher(eventArgs) {
      this.$emit("redeemVoucher", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "Credits"
  subtitle: "List of credits that help pay for your monthly consumption."
  none: "no credits redeemed"
fr:
  title: "Credits"
  subtitle: "Liste des crédits qui participent au paiement de votre consommation mensuelle."
  none: "Aucun crédit utilisé"
</i18n>
