<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <GithubDelGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import GithubDelGlyph from "@/components/atoms/glyphs/GithubDelGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "Event",
  components: { BaseEvent, GithubDelGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Github revoked"
fr:
  title: "Github révoqué"
</i18n>
