<template>
  <div class="border-t-2 border-scale-2 mt-4 pt-6 flex items-center">
    <div class="flex overflow-hidden text-scale-10 svg-img">
      <GitHubGraphic
        v-if="githubSCM"
        class="h-16 w-16"
      />
      <GitLabGraphic
        v-if="gitlabSCM"
        class="h-16 w-16"
      />

      <transition
        enterActiveClass="ease-out duration-300 transition"
        enterClass="opacity-0"
        enterToClass="opacity-100"
        leaveActiveClass="transition ease-in duration-100"
        leaveClass="opacity-100"
        leaveToClass="opacity-0"
      >
        <Portrait
          v-if="connected && integration.avatar_url"
          class="-ml-8 inline-block h-6 w-6 rounded-full text-scale-0 shadow-solid"
          :source="integration.avatar_url"
          size="16"
        />
      </transition>
    </div>

    <div class="flex-grow ml-6">
      <template v-if="connected">
        <div class="pb-2 font-medium">
          {{ $t("connectedAs") }}
          <a
            :href="integration.profile_url"
            target="_blank"
            class="text-primary-2"
          >
            {{ integration.username }}
          </a>
        </div>
        <div class="text-scale-7">
          {{ integration.email }}
        </div>
      </template>
      <template v-else>
        <div class="ml-8 pb-2 font-medium">
          {{ title }}
        </div>
      </template>
    </div>

    <div v-if="connected">
      <SCButton
        v-if="gitlabSCM"
        class="mr-4"
        @click="connectSCM(scmType)"
      >
        {{ $t("actions.renew") }}
      </SCButton>
      <SCButton @click="disconnectSCMIntegration(integration)">
        {{ $t("actions.disconnect") }}
      </SCButton>
    </div>

    <div v-else>
      <SCButton @click="connectSCM(scmType)">
        {{ $t("actions.connect") }}
      </SCButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import GitHubGraphic from "@/components/atoms/graphics/GitHubGraphic.vue";
import GitLabGraphic from "@/components/atoms/graphics/GitLabGraphic.vue";
import Portrait from "@/components/atoms/graphics/Portrait.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";

export default defineComponent({
  name: "SaasSCMIntegration",
  components: {
    Portrait,
    GitHubGraphic,
    GitLabGraphic,
    SCButton,
  },
  props: {
    scmIntegrations: Object,
    scmType: String,
  },
  emits: ["connectSCM", "disconnectSCMIntegration"],
  computed: {
    githubSCM() {
      return this.scmType === "github";
    },
    gitlabSCM() {
      return this.scmType === "gitlab";
    },
    title() {
      if (this.githubSCM) {
        return this.$t("title.github");
      } else if (this.gitlabSCM) {
        return this.$t("title.gitlab");
      }

      return null;
    },
    loading() {
      return this.scmIntegrations.info.isLoading;
    },
    integration() {
      if (this.scmIntegrations.none) return null;

      return this.scmIntegrations.items.find((integ) => {
        return integ.scm_type === this.scmType;
      });
    },
    connected() {
      return this.integration !== null && this.integration !== undefined;
    },
  },
  methods: {
    disconnectSCMIntegration(scmIntegration) {
      this.$emit("disconnectSCMIntegration", { scmIntegration });
    },
    connectSCM(scmType) {
      this.$emit("connectSCM", { scmType });
    },
  },
});
</script>

<style scoped>
.shadow-solid {
  box-shadow: 0 0 0 4px currentColor;
}
</style>

<i18n>
en:
  connectedAs: "Connected as"
  title:
    github: "Connect with GitHub"
    gitlab: "Connect with GitLab"
  actions:
    connect: "Connect"
    renew: "Renew"
    disconnect: "Disconnect"
fr:
  connectedAs: "Connecté en tant que"
  title:
    github: "Se connecter a github.com"
    gitlab: "Se connecter a gitlab.com"
  actions:
    connect: "Se connecter"
    renew: "Renouveler"
    disconnect: "Se déconnecter"
</i18n>
