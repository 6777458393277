<template>
  <span
    class="inline-flex place-items-center"
    :title="title"
  >
    <BaseButton
      :class="mainBtnClasses"
      :size="size"
      :loading="loading"
      :disabled="disabled"
      :type="type"
      :block="block"
      v-bind="otherAttrs"
      @click="(e) => $emit('click', e)"
    >
      <slot />

      <template #loading>
        <div class="flex flex-col">
          <div
            style="height: 0px"
            class="invisible"
          >
            <slot />
          </div>

          <Spinner
            :immediate="true"
            class="self-center"
            :class="{ 'my-1': block }"
          />
        </div>
      </template>
    </BaseButton>

    <OnClickOutside
      v-if="hasActions"
      as="span"
      class="border-l border-scale-0 relative place-self-stretch"
      @trigger="hideActions"
    >
      <BaseButton
        :class="actionsBtnClasses"
        :size="false"
        :disabled="actionsDisabled || loading"
        @click="toggleActions"
      >
        <span class="sr-only">{{ $t("openLabel") }}</span>
        <Chevron
          :direction="displayActions ? 'up' : 'down'"
          :class="chevronClasses"
        />
      </BaseButton>

      <DropdownMenu
        class="w-64"
        align="right"
        :show="displayActions"
        :items="actions"
        @click="actionClicked"
      />
    </OnClickOutside>
  </span>
</template>

<script>
import { OnClickOutside } from "@vueuse/components";
import { omit } from "lodash";
import { defineComponent } from "vue";

import BaseButton from "@/components/atoms/Button.vue";
import Chevron from "@/components/atoms/glyphs/Chevron.vue";
import Spinner from "@/components/atoms/spinners/Spinner.vue";
import DropdownMenu from "@/components/molecules/dropdowns/DropdownMenu.vue";

const BUTTON_KIND_REGULAR = "regular";
const BUTTON_KIND_PRIMARY = "primary";
const BUTTON_KIND_WARNING = "warning";
const BUTTON_KIND_DANGER = "danger";
const BUTTON_KIND_NEUTRAL = "neutral";
const BUTTON_KIND_TABLE = "table";
const BUTTON_KIND_EMPTY = "empty";

const BUTTON_CLASSES_FOR_KIND = {
  [BUTTON_KIND_REGULAR]: "font-medium bg-primary-2-10 text-primary-2",
  [BUTTON_KIND_PRIMARY]: "font-medium bg-primary-special text-primary-4",
  [BUTTON_KIND_WARNING]: "font-medium bg-tertiary-special text-primary-4",
  [BUTTON_KIND_DANGER]: "font-medium bg-color-2 text-primary-4",
  [BUTTON_KIND_NEUTRAL]: "font-medium bg-scale-1 text-scale-8",
  [BUTTON_KIND_TABLE]: "font-medium text-scale-8",
  [BUTTON_KIND_EMPTY]: "bg-scale-0 text-scale-10 border border-scale-2",
};

const BUTTON_HOVER_CLASSES_FOR_KIND = {
  [BUTTON_KIND_REGULAR]: "hover:bg-primary-2-15",
  [BUTTON_KIND_PRIMARY]: "",
  [BUTTON_KIND_WARNING]: "",
  [BUTTON_KIND_DANGER]: "",
  [BUTTON_KIND_NEUTRAL]: "hover:bg-scale-2",
  [BUTTON_KIND_TABLE]: "hover:bg-scale-2",
  [BUTTON_KIND_EMPTY]: "hover:bg-scale-1",
};

const BUTTON_KINDS = Object.keys(BUTTON_CLASSES_FOR_KIND);

const BUTTON_KIND_DEFAULT = BUTTON_KIND_REGULAR;

export default defineComponent({
  name: "SCButton",
  components: { OnClickOutside, Chevron, BaseButton, DropdownMenu, Spinner },
  props: {
    size: BaseButton.props.size,
    loading: BaseButton.props.loading,
    type: BaseButton.props.type,
    block: BaseButton.props.block,
    disabled: BaseButton.props.disabled,
    kind: {
      type: String,
      validator(value) {
        return BUTTON_KINDS.includes(value);
      },
      default: BUTTON_KIND_DEFAULT,
    },
    actionsDisabled: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    title: String,
  },
  emits: ["click", "action"],
  data() {
    return {
      displayActions: false,
    };
  },
  computed: {
    otherAttrs() {
      return omit(this.$attrs, ["class", "style"]);
    },
    hasActions() {
      return this.actions.length > 0;
    },
    mainBtnClasses() {
      let classValue = BUTTON_CLASSES_FOR_KIND[this.kind] + " ";

      if (!this.disabled && !this.loading) {
        classValue += " ";
        classValue += BUTTON_HOVER_CLASSES_FOR_KIND[this.kind];
      }

      classValue += this.hasActions ? " rounded-l-md " : " rounded-md ";

      return classValue;
    },
    actionsBtnClasses() {
      let classValue = "min-h-full self-center rounded-r-md ";

      if (this.size === "lg") {
        classValue += "px-2 ";
      } else {
        classValue += "px-1 ";
      }

      if (this.size != "sm") {
        classValue += "py-1 ";
      }

      classValue += BUTTON_CLASSES_FOR_KIND[this.kind];

      if (this.actionsDisabled) {
        classValue += " opacity-50 cursor-not-allowed";
      } else if (this.loading) {
        classValue += " cursor-wait animate-pulse";
      } else {
        classValue += " ";
        classValue += BUTTON_HOVER_CLASSES_FOR_KIND[this.kind];
      }

      return classValue;
    },
    chevronClasses() {
      return this.size === "sm" ? "h-4 w-4" : "h-5 w-5";
    },
  },
  methods: {
    toggleActions() {
      this.displayActions = !this.displayActions;
    },
    actionClicked(args) {
      this.hideActions();
      this.$emit("action", args);
    },
    hideActions() {
      this.displayActions = false;
    },
  },
});
</script>

<style scoped>
/*This classes exist because they have special color not present in the default palette */

.bg-primary-special {
  background: linear-gradient(
    180deg,
    var(--primary-2-125) 0%,
    var(--primary-2-90) 100%
  );
}
.bg-primary-special:not([disabled]):not(.cursor-wait):hover {
  background: var(--primary-2-135);
}
.bg-tertiary-special {
  background: #f17a3d;
}
.bg-tertiary-special:not([disabled]):not(.cursor-wait):hover {
  background: #e95a11;
}

.text-tertiary-special {
  color: #f17a3d;
}
.text-tertiary-special:not([disabled]):not(.cursor-wait):hover {
  color: #e95a11;
}
</style>

<i18n>
  en:
    openLabel: Open options
  fr:
    openLabel: Open options
</i18n>
