<template>
  <VueLine
    :data="{ datasets }"
    :options="options"
    :plugins="plugins"
  />
</template>

<script>
import CrosshairPlugin from "chartjs-plugin-crosshair";
import { defineComponent } from "vue";
import { Line as VueLine } from "vue-chartjs";

import { crosshairConfig } from "@/lib/metrics";

/** Rounds a number to the closest multiple */
function roundUp(source, multiple) {
  if (multiple == 0) return source;

  const remainder = source % multiple;
  if (remainder == 0) return source;

  return source + multiple - remainder;
}

export default defineComponent({
  name: "ContainerCPU",
  components: { VueLine },
  props: {
    datasets: Array,
  },
  data() {
    return {
      options: {
        animation: false,
        aspectRatio: 3,
        hover: { intersect: false },
        scales: {
          x: {
            type: "time",
            bounds: "ticks",
            offset: false,
            offsetAfterAutoskip: true,
            ticks: {
              source: "auto",
              autoSkip: true,
              autoSkipPadding: 60,
            },
          },
          y: {
            offset: true,
            min: 0,
            max: 100,
            stepSize: 50,
            grid: {
              drawBorder: false,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              callback(value) {
                return `${value}%`;
              },
            },
          },
        },
        plugins: {
          legend: {
            align: "end",
            position: "bottom",
          },
          tooltip: {
            intersect: false,
            mode: "index",
            position: "nearest",
            callbacks: {
              label(context) {
                const dataset = context.dataset;

                const label = dataset.label || "";

                return `${label}: ${context.raw.y.toFixed(2)}%`;
              },
            },
          },
          crosshair: crosshairConfig,
        },
      },
    };
  },
  computed: {
    maxCpu() {
      let max = 100;
      const series = this.datasets && this.datasets[0];

      if (series && series.data) {
        max = Math.max(max, ...series.data.map(({ y }) => y));
      }

      // Always round by thresholds of 50%
      return roundUp(max, 50);
    },
    plugins() {
      return [CrosshairPlugin];
    },
  },
  watch: {
    maxCpu: {
      immediate: true,
      handler(newVal) {
        if (!newVal) return;

        this.options.scales.y.max = newVal;
      },
    },
  },
});
</script>
