<template>
  <div
    :class="wrapperClasses"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @dragover.prevent
    @drop="onDrop"
  >
    <Spinner
      v-if="readInProgress"
      class="w-12 h-12 my-6"
    />

    <div
      v-else
      class="space-y-1 text-center"
    >
      <slot />

      <div
        v-if="askForInput"
        class="flex text-sm text-scale-7 justify-center pt-4"
      >
        <label
          :for="uid"
          class="relative cursor-pointer bg-scale-0 rounded-md font-medium text-primary-2 hover:text-primary-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-2"
        >
          <span>
            {{ $t("actions.select") }}
          </span>
          <input
            :id="uid"
            :name="uid"
            type="file"
            class="sr-only"
            @change="onFileChange"
          >
        </label>
        <p class="pl-1">
          {{ $t("actions.orDragAndDrop") }}
        </p>
      </div>

      <div
        v-if="errorMessage"
        class="text-error"
      >
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Spinner from "@/components/atoms/spinners/Spinner.vue";
import { readFile } from "@/lib/file-reader";

export default defineComponent({
  name: "FileDropzone",
  components: {
    Spinner,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
    askForInput: {
      type: Boolean,
      default: true,
    },
    errors: Array,
  },
  emits: ["readError", "readSuccess"],
  data() {
    return {
      dropInProgress: false,
      readInProgress: false,
    };
  },
  computed: {
    wrapperClasses() {
      let classes = "flex justify-center p-6 rounded-md ";
      classes += "border-2 border-dashed ";

      if (this.errors?.length) {
        classes += "border-error";
      } else if (this.dropInProgress) {
        classes += "border-primary-2";
      } else {
        classes += "border-scale-3";
      }

      return classes;
    },
    errorMessage() {
      if (this.errors?.length) {
        return this.errors.join(", ");
      }

      return null;
    },
  },
  methods: {
    onDragEnter(e) {
      if (!this.$el.contains(e.relatedTarget)) {
        this.dropInProgress = true;
      }
    },
    onDragLeave(e) {
      if (!this.$el.contains(e.relatedTarget)) {
        this.dropInProgress = false;
      }
    },
    onDrop(e) {
      e.preventDefault();
      this.dropInProgress = false;

      const selectedItem = e.dataTransfer?.items?.[0];

      if (!selectedItem) return;
      if (selectedItem.kind !== "file") return;

      const selectedFile = selectedItem.getAsFile();

      this.readFile(selectedFile);
    },
    onFileChange(e) {
      const selectedFile = e.target?.files?.[0];

      this.readFile(selectedFile);
    },
    async readFile(file) {
      if (!file) return;

      this.readInProgress = true;

      try {
        const content = await readFile(file);

        this.$emit("readSuccess", {
          name: file.name,
          content,
        });
      } catch (e) {
        this.$emit("readError", e);
      } finally {
        this.readInProgress = false;
      }
    },
  },
});
</script>

<i18n>
en:
  actions:
    select: "Select a file"
    orDragAndDrop: "or drag and drop"
fr:
  actions:
    select: "Selectionner un fichier"
    orDragAndDrop: "ou bien glisser-déposer"
</i18n>
