<template>
  <StepCard
    :step="1"
    :title="title"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-3xl"
  >
    <LoadingCardState v-if="manifestInfo.isLoading" />

    <template v-else-if="manifestInfo.isSuccess">
      <OCDDescription :manifest="manifest" />
      <InfosForm
        class="mt-6"
        :formHandler="formHandler"
        :regions="regions"
        :user="user"
        :canEnableHDS="false"
        @form-submitted="checkAppValidity"
      />
    </template>

    <SCAlert
      v-else-if="manifestInfo.isError"
      kind="error"
      icon="title"
      :title="$t('error')"
      class="mt-6"
    >
      <template #icon>
        <WarningSignGlyph />
      </template>

      <template v-if="manifestError.error === 'parsing/source'">
        {{ $t("errors.source", { url: manifestError.source }) }}
      </template>
      <template v-else-if="manifestError.error === 'parsing/hostname'">
        {{ $t("errors.hostname", { url: manifestError.source }) }}
      </template>
      <template v-else-if="manifestError.error === 'parsing/owner'">
        {{ $t("errors.owner", { url: manifestError.source }) }}
      </template>
      <template v-else-if="manifestError.error === 'parsing/repo'">
        {{ $t("errors.repo", { url: manifestError.source }) }}
      </template>
      <template v-else-if="manifestError.error === 'fetch-response'">
        <template v-if="manifestError.status === 404">
          {{
            $t("errors.fetch404", {
              url: manifestError.source,
              ref: manifestError.ref,
            })
          }}
        </template>
        <template v-else>
          {{
            $t("errors.fetchGeneric", {
              url: manifestError.source,
              ref: manifestError.ref,
            })
          }}
        </template>
      </template>
      <template v-else-if="manifestError.error === 'fetch-request'">
        {{ $t("errors.fetchGeneric", { url: manifestError.source }) }}
      </template>
      <template v-else-if="manifestError.error === 'fetch-ratelimit'">
        {{
          $t("errors.fetchRatelimit", {
            date: formatDateTime(manifestError.reset),
          })
        }}
      </template>
      <template v-else-if="manifestError.error === 'manifest-parsing'">
        {{ $t("errors.manifestParsing", { file: file }) }}
      </template>
      <template v-else>
        {{ $t("errors.generic") }}
      </template>
    </SCAlert>
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import WarningSignGlyph from "@/components/atoms/glyphs/WarningSignGlyph.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import InfosForm from "@/components/parts/create/app/InfosForm.vue";
import OCDDescription from "@/components/parts/create/app/OCDDescription.vue";
import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "OCDInfosStepCard",
  components: {
    StepCard,
    LoadingCardState,
    OCDDescription,
    InfosForm,
    SCAlert,
    WarningSignGlyph,
  },
  props: {
    user: Object,
    enabled: Boolean,
    formHandler: Object,
    regions: Array,
    manifestInfo: Object,
  },
  emits: ["appDryRunSuccess", "checkAppValidity"],
  computed: {
    title() {
      const name = this.manifestInfo.isSuccess ? this.manifest.name : "app";

      return this.$t("title", { name });
    },
    manifest() {
      return this.manifestInfo.isSuccess ? this.manifestInfo.data : null;
    },
    manifestError() {
      if (!this.manifestInfo.isFinished) return null;
      if (!this.manifestInfo.isError) return null;

      return this.manifestInfo.err;
    },
  },
  methods: {
    formatDateTime,
    checkAppValidity(e) {
      // Add the git source to the app payload that will be used for dry-run.
      this.$emit("checkAppValidity", {
        ...e,
        git_source: this.manifest.source,
      });
    },
  },
});
</script>

<i18n>
en:
  title: "Deploy your own {name}"
  subtitle: "Fill the minimal information required to create an app"
  error: "Error"
  errors:
    source: "The URL supplied ({url}) does not seem valid."
    hostname: "The URL hostname does not seem valid ({url})"
    owner: "There is no repository owner in the url ({url})"
    repo: "There is no repository in the url ({url})"
    fetch404: "There is either no repository at {url} or no branch/ref named {ref}"
    fetchGeneric: "The repository at {url} cannot be read. If you are sure the url is correct and have an adblocker, try disabling it."
    fetchRatelimit: "Requests to the GitHub API are throttled. Retry after {date}"
    manifestParsing: "The file {file} could not be parsed; is it a valid JSON file?"
    generic: "Could not get informations for the one-click deploy, retry later or get in touch with the support"
fr:
  title: "Déployez votre {name}"
  subtitle: "Saisissez les informations requises pour la création d'une app"
  error: "Erreur"
  errors:
    source: "L'URL fournie ({url}) ne semble pas valide."
    hostname: "Le nom d'hôte ne semble pas valide ({url})"
    owner: "Il n'y a pas de propriétaire du repo dans l'URL ({url})"
    repo: "Il n'y a pas de repo dans l'URL ({url})"
    fetch404: "Il n'y a soit pas de repo pour {url}, soit aucune branche/ref nommée {ref}"
    fetchGeneric: "Le repo à l'url {url} ne peut pas être lu. Si vous êtes sûr que l'url est correcte et vous avez un adblocker, essayez de le désactiver."
    fetchRatelimit: "Les requêtes vers l'API GitHub sont limitées. Veuillez retenter après le {date}"
    manifestParsing: "Le fichier {file} ne peut pas être parsé; est-ce un fichier JSON valide?"
    generic: "Impossible de récupérer les informations pour le one-click-deploy. Retentez ultérieurement ou bien contactez le support."
</i18n>
