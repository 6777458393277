<template>
  <div class="flex flex-col">
    <router-link
      :to="route"
      class="flex flex-row items-center"
    >
      <span class="text-scale-5 flex items-center hover:underline">
        <span>{{ title }}</span></span>
    </router-link>

    <span class="font-medium flex flex-row items-center">
      <ThreeStatesIndicator
        :isOk="hasFeatureActivated"
        :isNo="!hasFeatureActivated"
        class="mr-1"
      />
      <span v-if="hasFeatureActivated">{{ $t("enabled") }}</span>
      <span v-else>{{ $t("disabled") }}</span>
    </span>
  </div>
</template>
<script>
import ThreeStatesIndicator from "@/components/atoms/indicators/ThreeStatesIndicator.vue";

export default {
  name: "FeatureKeyPoint",
  components: { ThreeStatesIndicator },
  props: {
    db: Object,
    name: String,
    title: String,
    route: Object,
  },
  computed: {
    hasFeatureActivated() {
      const feature = this.db.features.find(
        (feature) => feature.name === this.name,
      );
      if (!feature) {
        return false;
      }
      return feature.status === "ACTIVATED";
    },
  },
};
</script>

<i18n>
en:
    enabled: "Enabled"
    disabled: "Disabled"
fr:
    enabled: "Activé"
    disabled: "Désactivé"
</i18n>
