<template>
  <span
    class="rounded-full px-1 text-center"
    :class="classBgFn"
    :title="title"
  >
    <span
      class="text-sm font-medium tracking-wider uppercase px-2"
      :class="classTextFn"
    >
      <slot />
    </span>
  </span>
</template>

<script>
import { defineComponent } from "vue";

export const failedType = "danger";
export const successType = "success";
export const neutralType = "neutral";
export const tagType = "tag";
export const tagColor1Type = "color1";
export const tagColor2Type = "color2";
export const tagColor3Type = "color3";

export default defineComponent({
  name: "Pill",
  props: {
    type: String,
    title: String,
  },
  computed: {
    classBgFn: function () {
      if (this.type === successType) {
        return "bg-color-1-10";
      }
      if (this.type === failedType) {
        return "bg-color-2-10";
      }
      if (this.type === neutralType) {
        return "bg-scale-4-10";
      }
      if (this.type === tagColor1Type) {
        return "bg-secondary-4-10";
      }
      if (this.type === tagColor2Type) {
        return "bg-primary-3-10";
      }
      if (this.type === tagColor3Type) {
        return "bg-secondary-1-10";
      }
      return "bg-secondary-1-10";
    },
    classTextFn: function () {
      if (this.type === successType) {
        return "text-color-1";
      }
      if (this.type === failedType) {
        return "text-color-2";
      }
      if (this.type === neutralType) {
        return "text-scale-7";
      }
      if (this.type === tagColor1Type) {
        return "text-secondary-4";
      }
      if (this.type === tagColor2Type) {
        return "text-primary-3";
      }
      if (this.type === tagColor3Type) {
        return "text-secondary-1";
      }
      return "text-secondary-1";
    },
  },
});
</script>
