<template>
  <span
    class="rounded-md px-2 py-1 ml-4 text-center text-xs font-bold text-scale-0"
    :title="title"
    :class="extraClasses"
  >
    <slot>
      {{ title }}
    </slot>
  </span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RecommendationPill",
  props: {
    isDeprecation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.isDeprecation
        ? this.$t("deprecated")
        : this.$t("recommended");
    },
    extraClasses() {
      return this.isDeprecation ? "bg-error" : "bg-primary-2";
    },
  },
});
</script>

<i18n>
en:
  recommended: "recommended"
  deprecated: "deprecated"
fr:
  recommended: "recommandée"
  deprecated: "dépréciée"
</i18n>
