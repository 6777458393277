<template>
  <ViewComponent
    :user="currentUser"
    :regions="regions"
    :hdsPointOfContact="hdsPointOfContact"
    :hdsContract="hdsContract"
    :hdsContractPrerequisite="hdsContractPrerequisite"
    :hdsLoadingOperation="hdsLoadingOperation"
    :containerSizes="containerSizes"
    :addonProviders="addonProviders"
    :manifestInfo="manifestInfo"
    :dryRunnedApp="dryRunnedApp"
    :OCDTunnelStep="OCDTunnelStep"
    :ocdStatus="ocdStatus"
    :formHandler="formHandler"
    :acceptContractHandler="acceptContractHandler"
    :createPointOfContactHandler="createPointOfContactHandler"
    @form-submitted="(e) => formHandler.submit(e)"
    @check-app-validity="(e) => formHandler.submit(e)"
    @variables-submitted="variablesSubmitted"
    @confirm-app-creation="confirmAppCreation"
    @toggle-h-d-s-mode="toggleHDSMode"
    @start-contract-acceptance="startContractAcceptance"
    @cancel-contract-acceptance="cancelContractAcceptance"
    @submit-contract-acceptance="(e) => acceptContractHandler.submit(e)"
    @start-point-of-contact-creation="startPointOfContactCreation"
    @cancel-point-of-contact-creation="cancelPointOfContactCreation"
    @submit-point-of-contact-creation="
      (e) => createPointOfContactHandler.submit(e)
    "
  />
</template>

<script>
import { isString } from "lodash";
import { requiredForHDS } from "scalingo/lib/Contracts";
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/create/App.vue";
import { CreateAppDryRunHandler, CreateAppHandler } from "@/lib/handlers";
import { AcceptContractHandler } from "@/lib/handlers/accept/contract";
import { CreateHDSContactHandler } from "@/lib/handlers/create/hds-contact";
import { performOneClickDeploy } from "@/lib/one-click-deploy";
import { availableRegions } from "@/lib/scalingo/client";
import { ManifestParser } from "@/lib/scalingo/manifest-parser";
import { Routes } from "@/router/names";
import {
  ensureAddonProviders,
  listAddonProviders,
} from "@/store/addon-providers";
import {
  ensureContainerSizes,
  listContainerSizes,
} from "@/store/container-sizes";
import {
  ensureContracts,
  listContracts,
  listPreviousHDSContractsAccepted,
} from "@/store/contracts";
import { userIsAdminOrHasFlag } from "@/store/user";
import { useAppInfosStore } from "@/stores/app-infos";
import { usePointOfContactStore } from "@/stores/hds-point-of-contact";

export default defineComponent({
  name: "CreateAppCtrl",
  components: { ViewComponent },
  setup() {
    const pocStore = usePointOfContactStore();
    const appInfosStore = useAppInfosStore();

    return { pocStore, appInfosStore };
  },
  data() {
    return {
      formHandler: null,
      acceptContractHandler: null,
      createPointOfContactHandler: null,
      regions: null,
      hdsLoadingOperation: null,
      manifestInfo: null,
      OCDTunnelStep: 1,
      dryRunnedApp: null,
      variablesToCreate: null,
      addonsToCreate: null,
      ocdStatus: null,
    };
  },
  computed: {
    hasOCDSource() {
      return (
        this.$route.query.source !== null &&
        this.$route.query.source !== undefined &&
        isString(this.$route.query.source) &&
        this.$route.query.source !== ""
      );
    },
    containerSizes() {
      return this.dryRunnedApp
        ? listContainerSizes(this.$store, this.dryRunnedApp.region)
        : null;
    },
    addonProviders() {
      return this.dryRunnedApp
        ? listAddonProviders(this.$store, this.dryRunnedApp.region)
        : null;
    },
    // It doesn't seem that contracts is used
    contracts() {
      return listContracts(this.$store);
    },
    // Why not using listHDSContracts function here ?
    hdsContract() {
      return listContracts(this.$store).items.find(
        (c) => requiredForHDS(c) && c.latest,
      );
    },
    previousHDSContractsAcceptedPresent() {
      return listPreviousHDSContractsAccepted(this.$store).items.length > 0;
    },
    hdsPointOfContact() {
      return this.pocStore.item;
    },
    hdsContractPrerequisite() {
      if (!this.hdsContract) return null;
      if (!this.hdsContract.depends_on_id) return null;

      return listContracts(this.$store).items.find(
        (c) => c.id === this.hdsContract.depends_on_id,
      );
    },
  },
  watch: {
    OCDTunnelStep(newVal) {
      if (newVal === 3 && this.dryRunnedApp) {
        ensureContainerSizes(this.$store, this.dryRunnedApp.region, {
          staleAfter: "always",
        });

        ensureAddonProviders(this.$store, this.dryRunnedApp.region, {
          staleAfter: "always",
        });
      }
    },
  },
  created() {
    this.setupRegions();
  },
  beforeMount() {
    this.setupFormHandler();
  },
  methods: {
    setupRegions() {
      this.regions = availableRegions
        .filter((r) => r.visible)
        .map((region) => {
          const enabled = region.requiresFlag
            ? userIsAdminOrHasFlag(this.currentUser, region.requiresFlag)
            : true;

          const text = enabled
            ? this.$t(`regions.${region.code}.humanName`)
            : this.$t(`regions.${region.code}.disabled`);

          return {
            ...region,
            value: region.code,
            text,
            enabled,
          };
        });
    },
    setupFormHandler() {
      if (this.hasOCDSource) {
        this.manifestInfo = new ManifestParser(
          this.$route.query.source,
          this.$route.hash,
        ).manifest;

        this.formHandler = new CreateAppDryRunHandler(this);
        this.formHandler.on("success", (app) => {
          this.appDryRunSuccess(app);
        });
      } else {
        this.formHandler = new CreateAppHandler(this);
      }
    },
    appDryRunSuccess(app) {
      this.OCDTunnelStep = 2;
      this.dryRunnedApp = app;
    },
    variablesSubmitted(variables) {
      this.OCDTunnelStep = 3;

      this.variablesToCreate = Object.keys(variables)
        .map((name) => {
          return {
            name: name,
            value: variables[name],
          };
        })
        .filter((v) => !!v);
    },
    async confirmAppCreation({ addons, formation }) {
      this.addonsToCreate = addons;
      const { name, region } = this.dryRunnedApp;

      const result = performOneClickDeploy(
        this.manifestInfo.data,
        { name, region, git_source: this.manifestInfo.data.source },
        formation,
        this.variablesToCreate,
        this.addonsToCreate,
      );

      this.ocdStatus = result.statuses;

      const deployment = await result.promise;

      // Refreshing the app listing
      const appInfosStore = useAppInfosStore();
      appInfosStore.ensure({ staleAfter: "always" });
      await appInfosStore.promise;

      this.$router.push({
        name: Routes.App.Deploy.List.Show,
        params: {
          id: name,
          region: region,
          deploymentId: deployment.id,
        },
      });
    },
    async toggleHDSMode({ hdsAppCreation }) {
      if (hdsAppCreation) {
        ensureContracts(this.$store, { staleAfter: "60" });
        this.pocStore.ensure({ staleAfter: "60" });

        this.hdsLoadingOperation = listContracts(this.$store).latestFetch;
      } else {
        this.hdsLoadingOperation = null;

        this.cancelContractAcceptance();
        this.cancelPointOfContactCreation();
      }
    },
    async startContractAcceptance({ contract }) {
      if (!this.hdsContract) return;
      if (this.hdsContract.accepted) return;

      this.acceptContractHandler = new AcceptContractHandler(
        this,
        contract,
        this.previousHDSContractsAcceptedPresent,
      );
    },
    cancelContractAcceptance() {
      this.acceptContractHandler = null;
    },
    startPointOfContactCreation() {
      this.createPointOfContactHandler = new CreateHDSContactHandler(this);
    },
    cancelPointOfContactCreation() {
      this.createPointOfContactHandler = null;
    },
  },
});
</script>
