<template>
  <ViewComponent
    :user="currentUser"
    :billingProfile="billingProfile.value"
    :paypalCreditHandler="paypalCreditHandler"
    @paypal-modal-opened="openPaypalModal"
    @paypal-modal-closed="closePaypalModal"
    @paypal-form-submitted="(e) => paypalCreditHandler.submit(e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/billing/Balance.vue";
import { InitiatePaypalHandler, ValidatePaypalHandler } from "@/lib/handlers";
import { insertPaypalLib } from "@/lib/paypal";
import { Routes } from "@/router/names";
import { billingProfileAndFetch } from "@/store/billing-profile";

export default defineComponent({
  name: "BalanceContainer",
  components: { ViewComponent },
  data() {
    return {
      paypalCreditHandler: null,
      paypalValidationHandler: null,
    };
  },
  computed: {
    billingProfile() {
      return billingProfileAndFetch(this.$store);
    },
  },
  async beforeMount() {
    await this.billingProfile.latestFetch.settled;

    if (!this.billingProfile.value) {
      this.$router.push({ name: Routes.Billing.Root });
      return;
    }

    insertPaypalLib();

    this.handlePaypalCallback();
  },
  methods: {
    openPaypalModal() {
      this.paypalCreditHandler = new InitiatePaypalHandler(
        this,
        this.billingProfile.value,
      );
    },
    closePaypalModal() {
      this.paypalCreditHandler = null;
    },
    handlePaypalCallback() {
      const query = this.$route.query;

      if (query.action == "success") {
        if (!query.token || !query.PayerID) {
          this.queryError = true;
          return;
        }

        this.paypalValidationHandler = new ValidatePaypalHandler(
          this,
          this.billingProfile.value,
        );

        this.paypalValidationHandler.submit({
          token: query.token,
          payerId: query.PayerID,
        });
      }
    },
  },
});
</script>
