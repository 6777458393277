<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <div class="flex items-center">
        <font-awesome-icon
          size="2x"
          icon="database"
          class="h-5 w-5 text-scale-6"
        />
        <span class="mx-1 text-scale-5"> > </span>
        <font-awesome-icon
          size="2x"
          :icon="['far', 'square-minus']"
          class="h-6 w-6 text-scale-6"
        />
        <div class="text-scale-7 pl-2 font-bold">
          {{ $t("title") }}
        </div>
      </div>
    </template>
    <template #more>
      Feature
      <span class="font-medium ml-1">
        {{ event.type_data.feature }}
      </span>
    </template>
    <template #body>
      <AddonEventBody :event="event" />
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AddonEventBody from "@/components/parts/events/AddonEventBody.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "DatabaseRemoveFeature",
  components: { AddonEventBody, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Database feature removed"
  feature: "Feature"
fr:
  title: "Fonctionnalité supprimée à la BDD"
  feature: "Fonctionnalité"
</i18n>
