<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :scmRepoLink="scmRepoLink"
    :scmIntegrations="scmIntegrations"
    :currentCartridge="currentCartridge"
    :currentSCMIntegration="currentSCMIntegration"
    :scmOrgs="scmOrgs"
    :hasMoreOrgs="hasMoreOrgs"
    :scmRepos="scmRepos"
    :scmBranches="scmBranches"
    :connectIntegrationHandler="connectIntegrationHandler"
    :repoLinkOperation="repoLinkOperation"
    :deleteSCMRepoLinkHandler="deleteSCMRepoLinkHandler"
    :editSCMRepoLinkerHandler="editSCMRepoLinkerHandler"
    :listOrgsInfo="listOrgsInfo"
    :listReposInfo="listReposInfo"
    :listBranchesInfo="listBranchesInfo"
    @deploy-form-submitted="deployFormSubmitted"
    @cartridge-selected="cartridgeSelected"
    @connect-s-c-m-integration="connectSCMIntegration"
    @start-hosted-s-c-m-connection="startHostedSCMConnection"
    @cancel-hosted-s-c-m-connection="cancelHostedSCMConnection"
    @confirm-hosted-s-c-m-connection="
      (e) => connectIntegrationHandler.submit(e)
    "
    @start-s-c-m-link="startSCMLink"
    @stop-s-c-m-link="stopSCMLink"
    @unlink-repository="unlinkRepository"
    @scm-org-selected="scmOrgSelected"
    @scm-repo-searched="scmRepoSearched"
    @scm-repo-selected="scmRepoSelected"
    @scm-branch-selected="scmBranchSelected"
    @finish-s-c-m-repo-link="finishSCMRepoLink"
    @change-linker="changeLinker"
    @disable-auto-deploy="disableAutoDeploy"
    @enable-auto-deploy="enableAutoDeploy"
    @fetch-scm-repo-link-branches="fetchScmRepoLinkBranches"
    @load-more-organizations="loadMoreOrganizations"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/deploy/Configuration.vue";
import {
  CreateSCMIntegrationHandler,
  DeleteSCMRepoLinkHandler,
  EditSCMRepoLinkerHandler,
} from "@/lib/handlers";
import { promiseInfo } from "@/lib/promises/info";
import { scalingoClient } from "@/lib/scalingo/client";
import {
  handlingLinkingFeedback,
  performQuickOAuthLink,
} from "@/lib/scm-integrations";
import {
  listBranchesForAppRepoLink,
  listBranchesForRepo,
  listMatchingRepos,
  listOrganizations,
  orderedBranches,
} from "@/lib/scm-repo-links";
import { Routes } from "@/router/names";
import {
  ensureSCMIntegrations,
  listSCMIntegrations,
} from "@/store/scm-integrations";
import {
  createScmRepoLink,
  disableAutoDeployForSCMRepoLink,
  enableAutoDeployForSCMRepoLink,
} from "@/store/scm-repo-link";

export default defineComponent({
  name: "DeployConfigurationContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    scmRepoLink: Object,
  },
  data() {
    return {
      currentCartridge: "git-push",
      connectIntegrationHandler: null,
      repoLinkOperation: null,
      deleteSCMRepoLinkHandler: null,
      editSCMRepoLinkerHandler: null,
      currentSCMIntegration: null,
      listOrgsInfo: null,
      listReposInfo: null,
      listBranchesInfo: null,
      currentOrgsPage: 1,
    };
  },
  computed: {
    scmIntegrations() {
      return listSCMIntegrations(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
      });
    },
    scmOrgs() {
      return this.listOrgsInfo?.data?.organizations || [];
    },
    hasMoreOrgs() {
      return this.listOrgsInfo?.data?.meta.pagination.next_page !== null;
    },
    scmRepos() {
      return this.listReposInfo?.data || [];
    },
    scmBranches() {
      return orderedBranches(this.listBranchesInfo?.data || []);
    },
  },
  watch: {
    scmIntegrations(newVal) {
      if (newVal && newVal.items[0]) {
        this.currentCartridge = newVal.items[0].scm_type;
      }
    },
    scmRepoLink(newVal) {
      if (newVal) {
        this.currentCartridge = newVal.scm_type;
      }
    },
    "repoLinkOperation.isSuccess": function (newVal) {
      if (newVal) {
        this.stopSCMLink();
      }
    },
  },
  created() {
    this.handleSCMCallback(this.$route.query);
  },
  beforeMount() {
    ensureSCMIntegrations(this.$store);

    handlingLinkingFeedback(this.$router, this.$route, this.$store, this.$i18n);
  },
  methods: {
    resetIntegrationFormObject() {
      this.integrationOperation = null;
      this.integrationFormData = {
        url: "https://",
      };
    },
    async handleSCMCallback(query) {
      if (query.scmType) {
        this.currentCartridge = query.scmType;
      }
    },
    deployFormSubmitted({ app }) {
      this.$router.push({
        name: Routes.App.Overview,
        params: { id: app.name },
      });
    },
    changeLinker() {
      this.editSCMRepoLinkerHandler = new EditSCMRepoLinkerHandler(
        this,
        this.currentUser,
      );
      this.editSCMRepoLinkerHandler.submit();
    },
    async disableAutoDeploy() {
      this.repoLinkOperation = await disableAutoDeployForSCMRepoLink(
        this.$store,
      );
    },
    async enableAutoDeploy(eventArgs) {
      this.repoLinkOperation = await enableAutoDeployForSCMRepoLink(
        this.$store,
        eventArgs.form.branch,
      );
    },
    fetchScmRepoLinkBranches() {
      this.listBranchesInfo = promiseInfo(
        listBranchesForAppRepoLink(
          scalingoClient(this.$store, this.app.region),
          this.app,
        ),
      );
    },
    cartridgeSelected(eventArgs) {
      this.currentCartridge = eventArgs.cartridge;
    },
    connectSCMIntegration({ scmType }) {
      const pathname = this.$router.resolve({
        name: Routes.App.Deploy.Configuration,
        params: { id: this.app.name, region: this.app.region },
      }).href;

      performQuickOAuthLink(this.$store, scmType, pathname);
    },
    startHostedSCMConnection({ scmType }) {
      this.connectIntegrationHandler = new CreateSCMIntegrationHandler(
        this,
        scmType,
      );
    },
    cancelHostedSCMConnection() {
      this.connectIntegrationHandler = null;
    },
    startSCMLink({ scmIntegration }) {
      this.currentSCMIntegration = scmIntegration;
      this.currentOrgsPage = 1;
      this.listOrgsInfo = promiseInfo(
        listOrganizations(
          scalingoClient(this.$store, this.app.region, this.currentOrgsPage),
          scmIntegration.id,
        ),
      );
    },
    stopSCMLink() {
      this.currentSCMIntegration = null;
      this.listOrgsInfo = null;
      this.listReposInfo = null;
      this.listBranchesInfo = null;
    },
    unlinkRepository() {
      this.deleteSCMRepoLinkHandler = new DeleteSCMRepoLinkHandler(
        this,
        this.app,
      );
      this.deleteSCMRepoLinkHandler.submit();
    },
    scmOrgSelected({ scmOrg }) {
      this.listReposInfo = promiseInfo(
        listMatchingRepos(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration,
          scmOrg,
        ),
      );
    },
    async loadMoreOrganizations() {
      this.listOrgsInfo.isLoading = true;
      const nextPage = this.currentOrgsPage + 1;

      const newOrgs = await listOrganizations(
        scalingoClient(this.$store, this.app.region),
        this.currentSCMIntegration.id,
        nextPage,
      );
      this.listOrgsInfo.data.organizations = [
        ...this.scmOrgs,
        ...newOrgs.organizations,
      ];
      this.listOrgsInfo.data.meta.pagination = newOrgs.meta.pagination;
      this.currentOrgsPage = nextPage;
      this.listOrgsInfo.isLoading = false;
    },
    scmRepoSearched({ term, org }) {
      this.listReposInfo = promiseInfo(
        listMatchingRepos(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration,
          org,
          term,
        ),
      );
    },
    scmRepoSelected({ scmRepo }) {
      const repo = this.scmRepos.find((repo) => {
        return repo.url === scmRepo;
      });

      this.listBranchesInfo = promiseInfo(
        listBranchesForRepo(
          scalingoClient(this.$store, this.app.region),
          this.currentSCMIntegration.id,
          repo.fullName,
        ),
      );
    },
    scmBranchSelected() {
      // Nothing to do here
    },
    async finishSCMRepoLink({ form }) {
      const payload = {
        source: form.repository,
        branch: form.branch,
        auth_integration_uuid: this.currentSCMIntegration.id,
        auto_deploy_enabled: form.autoDeploy,
      };

      this.repoLinkOperation = await createScmRepoLink(
        this.$store,
        this.app,
        payload,
      );
    },
  },
});
</script>
