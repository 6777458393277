<template>
  <div
    v-if="show"
    :class="bgColor"
    class="flex rounded"
  >
    <div
      :class="textColor"
      class="px-4 flex py-3 font-bold flex-grow"
    >
      <div class="flex flex-col flex-grow">
        <div class="flex">
          <div class="flex">
            <div class="self-center pr-3">
              <DangerSignGlyph
                v-if="styling === 'error'"
                class="w-6 h-6"
              />
              <WarningSignGlyph
                v-if="styling === 'warning'"
                class="w-6 h-6"
              />
            </div>
            <div>
              <slot name="title" />
            </div>
          </div>
          <div class="flex flex-grow">
            <div class="flex flex-grow" />
            <div
              v-if="dismissible"
              class="flex align-middle h-3 w-3 cursor-pointer"
              @click="dismiss"
            >
              <CrossGlyph />
            </div>
          </div>
        </div>
        <div class="flex-grow flex">
          <div class="self-center font-normal mt-2">
            <slot name="text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import WarningSignGlyph from "@/components/atoms/glyphs/WarningSignGlyph.vue";

export default defineComponent({
  name: "TableAlert",
  components: { WarningSignGlyph, DangerSignGlyph, CrossGlyph },
  props: {
    styling: String,
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["dismissed"],
  data: function () {
    return {
      show: true,
    };
  },
  computed: {
    bgColor: function () {
      if (this.styling === "error") {
        return "bg-color-2-10";
      }
      if (this.styling === "warning") {
        return "bg-secondary-3-10";
      }
      throw "Invalid style: " + this.styling;
    },
    textColor: function () {
      if (this.styling === "error") {
        return "text-color-2";
      }
      if (this.styling === "warning") {
        return "text-secondary-3";
      }
      throw "Invalid style: " + this.styling;
    },
  },
  methods: {
    dismiss() {
      this.show = false;
      this.$emit("dismissed");
    },
  },
});
</script>
