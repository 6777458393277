<template>
  <div class="bg-scale-0 rounded px-8 py-6">
    <div :class="cardHeaderClasses">
      <header class="flex-grow">
        <slot name="header">
          <div>
            <slot name="beforeTitle" />

            <h3 class="text-lg inline-block">
              <span class="font-bold">{{ title }}</span>
              <span
                v-if="showCount"
                class="text-scale-4 pl-1"
              >
                ({{ count }})
              </span>
            </h3>
          </div>

          <div
            v-if="subtitle && subtitle.length > 0"
            class="hidden ph:flex text-scale-7"
          >
            {{ preparedSubtitle }}
          </div>
        </slot>
      </header>

      <div class="flex">
        <slot name="actions" />
      </div>
    </div>

    <slot />

    <footer
      v-if="$slots.footer"
      class="flex justify-center"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SCCard",
  props: {
    title: String,
    subtitle: String,
    count: Number,
    noDelimiter: {
      type: Boolean,
      default: false,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardHeaderClasses() {
      let classes = "flex";

      if (!this.noDelimiter) {
        classes += " border-b-2 border-scale-2 pb-2 mb-3";
      }

      return classes;
    },
    // Temporary, the time we migrate all subtitles to "end with dot" policy
    preparedSubtitle() {
      if (this.subtitle.endsWith(".")) {
        return this.subtitle;
      }
      return this.subtitle + ".";
    },
  },
});
</script>
