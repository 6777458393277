<template>
  <DeleteModal
    :formHandler="formHandler"
    @form-submitted="$emit('formSubmitted')"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      {{ $t("question") }}
    </template>
    <template #button>
      {{ $t("button") }}
      <span
        v-if="formHandler.collaborator.status === 'accepted'"
        class="ml-1"
      >{{ formHandler.collaborator.username }}</span>
      <span
        v-else
        class="ml-1"
      >{{ formHandler.collaborator.email }}</span>
    </template>
  </DeleteModal>
</template>

<script>
import { defineComponent } from "vue";

import DeleteModal from "@/components/molecules/modalsCollection/DeleteModal.vue";

export default defineComponent({
  name: "RemoveCollaboratorModal",
  components: { DeleteModal },
  props: {
    formHandler: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
});
</script>

<i18n>
en:
  title: "Delete a collaborator"
  question: "Do you really want delete this collaborator?"
  button: "Delete"
fr:
  title: "Supprimer un collaborateur"
  question: "Souhaitez-vous vraiment supprimer ce collaborateur ?"
  button: "Supprimer"
</i18n>
