<template>
  <main class="space-y-6">
    <HDSContractsList
      :contracts="hdsContracts"
      @start-contract-acceptance="(e) => $emit('startContractAcceptance', e)"
    />
    <HDSContactProfileCard
      :hdsContact="hdsContact"
      @start-h-d-s-contact-creation="$emit('startHDSContactCreation')"
      @start-h-d-s-contact-edition="(e) => $emit('startHDSContactEdition', e)"
      @touch-h-d-s-contact="$emit('touchHDSContact')"
      @start-delete-h-d-s-contact="$emit('startDeleteHDSContact')"
    />

    <AcceptContractModal
      v-if="acceptContractHandler"
      :formHandler="acceptContractHandler"
      @modal-closed="$emit('cancelContractAcceptance')"
      @form-submitted="(e) => $emit('submitContractAcceptance', e)"
    />
    <HDSContactFormModal
      v-if="upsertHDSContactHandler"
      :formHandler="upsertHDSContactHandler"
      @modal-closed="$emit('cancelHDSContactEdition')"
      @form-submitted="(e) => $emit('submitHDSContactEdition', e)"
    />
    <DeleteHDSContactWarning
      v-if="deleteHDSContactCtx"
      :context="deleteHDSContactCtx"
      :hdsAppsCount="hdsAppsCount"
      @close="$emit('cancelDeleteHDSContact')"
      @confirm="(e) => $emit('submitDeleteHDSContact', e)"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import AcceptContractModal from "@/components/parts/account/contracts/AcceptContractModal.vue";
import HDSContractsList from "@/components/parts/account/contracts/HDSContractsList.vue";
import DeleteHDSContactWarning from "@/components/parts/account/hdsContact/DeleteHDSContactWarning.vue";
import HDSContactFormModal from "@/components/parts/account/hdsContact/HDSContactFormModal.vue";
import HDSContactProfileCard from "@/components/parts/account/hdsContact/HDSContactProfileCard.vue";

export default defineComponent({
  name: "HDS",
  components: {
    DeleteHDSContactWarning,
    HDSContactProfileCard,
    HDSContactFormModal,
    HDSContractsList,
    AcceptContractModal,
  },
  props: {
    hdsContact: Object,
    upsertHDSContactHandler: Object,
    acceptContractHandler: Object,
    deleteHDSContactCtx: Object,
    hdsContracts: Object,
    hdsAppsCount: Number,
  },
  emits: [
    "cancelContractAcceptance",
    "cancelHDSContactEdition",
    "cancelDeleteHDSContact",
    "startContractAcceptance",
    "startHDSContactCreation",
    "startHDSContactEdition",
    "submitContractAcceptance",
    "submitHDSContactEdition",
    "submitDeleteHDSContact",
    "touchHDSContact",
    "startDeleteHDSContact",
  ],
});
</script>
