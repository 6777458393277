<template>
  <div class="flex ph:mt-8 flex-col ph:flex-row">
    <div class="mb-8 ph:w-1/6 ph:mb-0">
      <slot name="side" />
    </div>
    <div class="ph:w-5/6">
      <slot name="router" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SideMenuLayout",
});
</script>
