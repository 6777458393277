<template>
  <WarningModal
    :formHandler="formHandler"
    @modal-closed="$emit('modalClosed')"
    @form-submitted="$emit('warningAccepted')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      {{ $t("message") }}
    </template>
    <template #button>
      {{ $t("button") }}
    </template>
  </WarningModal>
</template>

<script>
import { defineComponent } from "vue";

import WarningModal from "@/components/molecules/modalsCollection/WarningModal.vue";

export default defineComponent({
  name: "ResetCacheWarning",
  components: { WarningModal },
  props: {
    name: String,
    formHandler: Object,
  },
  emits: ["modalClosed", "warningAccepted"],
});
</script>

<i18n>
en:
  title: "Reset cache"
  button: "Reset"
  message: "This action will purge all deployment caches. The build will take longer, but given a clean state, it will remove all sides effects related to previous builds."
fr:
  title: "Vider le cache"
  button: "Vider"
  message: "Cette action va vider tous les caches de déploiments. Le build prendra plus longtemps, mais étant donné un état propre, il supprimera des effets de bord possibles liés aux builds précédents."
</i18n>
