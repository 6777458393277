<template>
  <SCTable
    layout="auto"
    :headers="headers"
    :items="orders.items"
    :paginated="true"
  >
    <template #item.quote="{ item }">
      <router-link
        class="font-mono hover:underline"
        :to="orderRouteFor(item)"
      >
        {{ item.quote }}
      </router-link>
    </template>

    <template #item.status="{ item }">
      <div class="humanize">
        {{ item.status }}
      </div>
    </template>

    <template #item.start_date="{ item }">
      <div>
        {{ humanizedMidDateTime(item.start_date) }}
      </div>
    </template>

    <template #item.quote_amount="{ item }">
      <div>
        {{ formatAmount(item.quote_amount) }}
      </div>
    </template>

    <template #item.consumed="{ item }">
      <div>
        {{ formatAmount(item.consumed) }}
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import SCTable from "@/components/organisms/tables/SCTable.vue";
import { formatAmount } from "@/lib/utils/currency";
import { humanizedMidDateTime } from "@/lib/utils/time";
import ModelsTranslation from "@/mixins/models_translation";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "OrdersListBody",
  components: {
    SCTable,
  },
  mixins: [ModelsTranslation],
  props: {
    orders: Object,
  },
  data() {
    return {
      headers: [
        {
          value: "quote",
          text: this.$ta("order", "quote"),
          class: "humanize w-4/12",
        },
        {
          value: "status",
          text: this.$ta("order", "status"),
          class: "humanize w-2/12",
        },
        {
          value: "start_date",
          text: this.$ta("order", "start_date"),
          class: "humanize w-2/12",
        },
        {
          value: "quote_amount",
          text: this.$ta("order", "quote_amount"),
          class: "humanize w-2/12",
        },
        {
          value: "consumed",
          text: this.$ta("order", "consumed"),
          class: "humanize w-2/12",
        },
      ],
    };
  },
  methods: {
    formatAmount,
    humanizedMidDateTime,
    orderRouteFor(item) {
      return {
        name: Routes.Billing.Orders.Show,
        params: {
          id: item.id,
        },
      };
    },
  },
});
</script>
