<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    >
      <template #buttons>
        <SCButton @click="addPaypalCredit">
          {{ $t("buttonName") }}
          <PayPalLogo class="inline h-4 ml-1" />
        </SCButton>
      </template>
    </CardHeader>
    <template v-if="billingProfile">
      <p>
        {{ $t("currentBalance") }}:
        <span class="font-bold">
          {{ formatAmount(balance) }}
        </span>
      </p>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PayPalLogo from "@/components/atoms/graphics/PayPal.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { formatAmount } from "@/lib/utils/currency";

export default defineComponent({
  name: "BalanceCard",
  components: {
    CardHeader,
    SCButton,
    PayPalLogo,
  },

  props: {
    billingProfile: Object,
  },
  emits: ["addPaypalCredit"],
  computed: {
    balance() {
      return this.billingProfile?.balance || 0;
    },
  },
  methods: {
    formatAmount,
    addPaypalCredit() {
      this.$emit("addPaypalCredit");
    },
  },
});
</script>

<i18n>
en:
  title: "Account balance"
  subtitle: "Credit will be used before other payment methods"
  buttonName: "Add credit via"
  currentBalance: "Current account balance"
fr:
  title: "Solde du compte"
  subtitle: "Le crédit sera utilisé avant les autres moyens de paiement"
  buttonName: "Ajouter du crédit via"
  currentBalance: "Solde actuel du compte"
</i18n>
