<template>
  <main>
    <Card>
      <template #header>
        <CardHeader :title="$t('title')" />
      </template>
      <template #body>
        <p class="text-scale-9">
          {{ $t("hint1") }}
        </p>

        <p class="mt-2 text-scale-9">
          {{ $t("hint2") }}
        </p>

        <div
          v-for="reason in reasons"
          :key="reason.value"
          class="mt-4"
        >
          <CheckboxInputAtom
            :modelValue="isSelected(reason.value)"
            boxType="round"
            @update:model-value="(e) => select(reason.value, e)"
          >
            <template #label>
              {{ reason.text }}
            </template>
          </CheckboxInputAtom>
        </div>

        <TextArea
          v-model="details"
          class="mt-4"
          :rows="3"
          :label="$t('reason')"
        />

        <FormAlert
          v-if="wrongId"
          class="mt-4"
          styling="error"
        >
          <template #text>
            {{ $t("errors.wrongId") }}
          </template>
        </FormAlert>

        <FormAlert
          v-if="wrongToken"
          class="mt-4"
          styling="error"
        >
          <template #text>
            {{ $t("errors.wrongToken") }}
          </template>
        </FormAlert>

        <Button
          class="mt-4"
          kind="danger"
          :loading="deletionHandler.isSubmitting"
          @click="submitDeletion"
        >
          {{ $t("confirm") }}
        </Button>
      </template>
    </Card>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import Button from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import TextArea from "@/components/molecules/inputs/TextArea.vue";

export default defineComponent({
  name: "Deletion",
  components: {
    Button,
    Card,
    CardHeader,
    CheckboxInputAtom,
    TextArea,
    FormAlert,
  },
  props: {
    deletionHandler: Object,
  },
  emits: ["submitDeletion"],
  data() {
    return {
      reasons: [
        { value: "useless", text: this.$t("options.useless") },
        { value: "otherService", text: this.$t("options.otherService") },
        { value: "dislike", text: this.$t("options.dislike") },
        { value: "testing", text: this.$t("options.testing") },
        { value: "other", text: this.$t("options.other") },
      ],
      selectedReason: null,
      details: null,
    };
  },
  computed: {
    wrongId() {
      if (!this.deletionHandler.isError) return false;

      return this.deletionHandler.operation.error.status === 404;
    },
    wrongToken() {
      if (!this.deletionHandler.isError) return false;

      return this.deletionHandler.operation.error.status === 401;
    },
  },
  methods: {
    isSelected(reason) {
      return this.selectedReason === reason;
    },
    select(reason, val) {
      if (val) {
        this.selectedReason = reason;
      } else {
        this.selectedReason = null;
      }
    },
    submitDeletion() {
      const reason = this.reasons.find((r) => r.value === this.selectedReason);
      const payload = {};

      if (reason?.text) {
        payload.reason = reason.text;
      }

      if (this.details) {
        payload.details = this.details;
      }

      this.$emit("submitDeletion", payload);
    },
  },
});
</script>

<i18n>
en:
  title: "Account deletion"
  hint1: "We are continuously working on our platform to make it the best hosting service for developers ever. We are sorry to hear that it didn't fit your needs."
  hint2: "If you have a few minutes to spare to let us know why you are leaving, this will help us improve our platform."
  options:
    useless: "I no longer use the app hosted on Scalingo"
    otherService: "I migrated to another hosting service"
    dislike: "I disliked the experience on Scalingo"
    testing: "It was just a test"
    other: "Other"
  errors:
    wrongId: "The deletion ID is invalid - please restart the process"
    wrongToken: "The deletion token is invalid - please restart the process"
  reason: "What would make you come back?"
  confirm: "Delete my account"
fr:
  title: "Suppression de compte"
  hint1: "Nous travaillons continuellement sur notre plateform pour en faire le meilleur service d'hébergement pour les développeurs. Nous sommes désolé de ne pas répondre a vos besoins."
  hint2: "Si vous avez quelques minutes pour nous communiquer les raisons de votre départ, ceci nous permettra de nous améliorer."
  options:
    useless: "Je n'utilise plus l'application hébergée sur Scalingo"
    otherService: "J'ai migré vers un autre service d'hébergement"
    dislike: "Je n'ai pas apprécié l'expérience sur Scalingo"
    testing: "C'était juste un test"
    other: "Autre"
  errors:
    wrongId: "L'ID de suppression est incorrect - veuillez recommencer le processus"
    wrongToken: "Le token de suppression est incorrect - veuillez recommencer le processus"
  reason: "Qu'est-ce qui pourrait vous faire revenir?"
  confirm: "Supprimer mon compte"
</i18n>
