<template>
  <SupportPlanCard
    :title="$t('title')"
    :description="$t('description')"
    :current="current"
    :features="features"
  >
    <template #pricing>
      <div class="flex flex-col text-scale-7">
        <span>
          {{ $t("pricing") }}
          <span class="font-bold text-lg text-scale-9">{{ $t("price") }}</span>
        </span>
        <span>{{ $t("pricingLine2") }}</span>
      </div>
    </template>
  </SupportPlanCard>
</template>

<script>
import SupportPlanCard from "./SupportPlanCard.vue";

export default {
  name: "StarterSupportPlanCard",
  components: {
    SupportPlanCard,
  },
  props: {
    title: String,
    description: String,
    current: Boolean,
  },
  data() {
    return {
      features: [
        {
          title: this.$t("features.supportFunctional.title"),
          description: this.$t("features.supportFunctional.description"),
        },
        {
          title: this.$t("features.priorityLine.title"),
          description: this.$t("features.priorityLine.description"),
        },
        {
          title: this.$t("features.responseTime.title"),
          description: this.$t("features.responseTime.description"),
        },
        {
          title: this.$t("features.accompaniment.title"),
          description: this.$t("features.accompaniment.description"),
        },
        {
          title: this.$t("features.documentation.title"),
          description: this.$t("features.documentation.description"),
        },
        {
          title: this.$t("features.commitment.title"),
          description: this.$t("features.commitment.description"),
        },
      ],
    };
  },
};
</script>

<i18n>
  fr:
    pricing: "à partir de"
    price: "100 €/mois"
    pricingLine2: "ou 5 % de votre consommation"
    title: "Support Starter"
    description: "Pour les applications de production non critiques."
    features:
      supportFunctional:
        title: "Support fonctionnel"
        description: "Jours ouvrés, 9h-18h"
      priorityLine:
        title: "Ligne prioritaire lors d’incidents"
        description: "Jours ouvrés, 9h-18h"
      responseTime:
        title: "Temps de réponse garanti"
        description: "Heures ouvrées < 60 minutes"
      accompaniment:
        title: "Accompagnement"
        description: "Accès à la documentation en ligne, à nos guides et à nos tutoriels."
      documentation:
        title: "Documents fournis"
        description: "-"
      commitment:
        title: "Engagement"
        description: "3 mois"
  en:
    pricing: "Starting from"
    price: "€100/month"
    pricingLine2: "or 5% of your consumption"
    title: "Starter Support"
    description: "Non-critical production applications."
    features:
      supportFunctional:
        title: "Platform support"
        description: "Business days, 9am-6pm"
      priorityLine:
        title: "Priority line during incidents"
        description: "Working days, 9am-6pm"
      responseTime:
        title: "Guaranteed response time"
        description: "Working hours < 60 minutes"
      accompaniment:
        title: "Assistance & resources"
        description: "Access to online documentation, guides, and tutorials."
      documentation:
        title: "Provided documents"
        description: "-"
      commitment:
        title: "Commitment"
        description: "3 months"
</i18n>
