<template>
  <div class="bg-scale-0 border-t border-scale-2">
    <div class="container px-4 flex py-5 pv:flex-row flex-col">
      <div class="flex flex-col pv:flex-row flex-grow items-center">
        <BreadcrumbNav
          :user="user"
          :appInfo="appInfo"
          :dbInfo="dbInfo"
        />
      </div>

      <div class="flex pv:mt-0 pv:justify-start justify-center mt-4">
        <SCButton
          v-if="appInfo.regional"
          :a="appExternalUrl"
          :actions="dropdownActions"
        >
          {{ $t("actions.open") }}
          <font-awesome-icon
            class="ml-1"
            icon="external-link-alt"
          />
        </SCButton>

        <SCButton
          v-else
          :to="createAppRoute"
        >
          {{ $t("actions.create") }}

          <font-awesome-icon
            class="ml-1"
            icon="plus"
          />
        </SCButton>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BreadcrumbNav from "@/components/molecules/breadcrumb/BreadcrumbNav.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import { Routes } from "@/router/names";
import { userIsAdmin } from "@/store/user";

export default defineComponent({
  name: "AppHeader",
  components: {
    SCButton,
    BreadcrumbNav,
  },
  props: {
    user: Object,
    appInfo: Object,
    dbInfo: Object,
    regions: Array,
  },
  data() {
    return {
      createAppRoute: {
        name: Routes.Create.App,
      },
    };
  },
  computed: {
    displayHDS() {
      return this.appInfo.app?.hds_resource;
    },
    appExternalUrl() {
      return { href: this.appInfo.regional?.url, target: "_blank" };
    },
    dropdownActions() {
      const actions = [];
      const region = this.regions.find(
        (r) => r.code === this.appInfo.app.region,
      );

      if (userIsAdmin(this.user)) {
        actions.push({
          key: "adminLink",
          text: this.$i18n.t("actions.admin"),
          a: {
            href: `${region.adminUrl}/apps/${this.appInfo.app.id}`,
            target: "_blank",
          },
        });
      }
      return actions;
    },
  },
});
</script>

<i18n>
en:
  actions:
    open: "Open application"
    create: "Create an application"
    admin: "Open in admin"
fr:
  actions:
    open: "Ouvrir l'application"
    create: "Créer une application"
    admin: "Ouvrir dans le admin"
</i18n>
