import { DateTime } from "luxon";
import { APIError } from "scalingo/lib/errors";
import { Module, ActionContext } from "vuex";

import { eventBus } from "@/lib/events/bus";
import * as knownActions from "@/lib/store/action-types";
import * as knownGetters from "@/lib/store/getter-types";
import { dashboard } from "@/lib/utils/log";

import { CollectionState } from "./collection-store";
import { START_FETCH, FETCH_SUCCESS, FETCH_ERROR } from "./mutation-types";
import {
  operationError,
  operationLoading,
  operationSuccess,
  RemoteOperation,
} from "./remote-operation";
import { ResourceState } from "./resource-store";
import { Nullable } from "../utils/types";

export type RootState = {};
export interface CommonState<T> {
  latestFetch: RemoteOperation<T>;
  lastFetchAt: DateTime | null;
}

export interface CollectionWithFetch<T> {
  items: T[] | null;
  latestFetch: RemoteOperation<T[]>;
  lastFetchAt?: DateTime | null;
  meta?: GenericResource | null;
}

export interface ResourceWithFetch<T> {
  value: T | null;
  latestFetch: RemoteOperation<T>;
  lastFetchAt?: DateTime | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericResource = Record<string, any>;

export interface StoreMapping {
  actions: Record<string, string>;
  getters: Record<string, string>;
}

/**
 * Takes a store module and a prefix, and returns a map of action/getters constants such as:
 *
 * {
 *   actions: { CLEAR: 'myStore/clear', REFRESH: `myStore/refresh`},
 *   getters: { ALL: 'myStore/all' },
 * }
 */
export function buildMapping<S = GenericResource>(
  module: Module<S, RootState>,
  prefix: string,
): StoreMapping {
  const prefixed = function (action: string) {
    return module.namespaced ? `${prefix}/${action}` : action;
  };

  const mapping: StoreMapping = { actions: {}, getters: {} };

  for (const [actionKey, actionValue] of Object.entries(
    knownActions as Record<string, string>,
  )) {
    if (module.actions?.[actionValue]) {
      mapping.actions[actionKey] = prefixed(actionValue);
    }
  }

  for (const [getterKey, getterValue] of Object.entries(
    knownGetters as Record<string, string>,
  )) {
    if (module.getters?.[getterValue]) {
      mapping.getters[getterKey] = prefixed(getterValue);
    }
  }

  return mapping;
}

/**
 * When an API error is relevant enough to be broadcasted to the rest of the app
 * (like when it looks like the token is expired)
 * this function is called and does just that.
 */
export function broadcastGlobalError(error: unknown): void {
  if (error instanceof APIError) {
    if (error.status === 401) {
      dashboard.debug("scalingoAPIUnauthorized", { error });
      eventBus.emit("scalingoAPIUnauthorized", { error });
    }
  }
}

/**
 * Handles an operation related to a resource or collection store,
 * but the operation is not stored in the store itself.
 * Meant to be used by operations such as form submissions, etc.
 *
 * @param context The context object passed to `dispatch`
 * @param promise The promise representing the operation
 * @param resolveAction The action to perform when the promise resolves
 */
export function handleOperation<T>(
  initial: RemoteOperation<T> | null,
  context: ActionContext<CommonState<T>, RootState>,
  promise: Promise<T>,
  resolveAction?: string | ((resolved: unknown) => void) | null,
): RemoteOperation<T> {
  const operation = initial || new RemoteOperation<T>();
  operation.restart(promise);

  // This happens "in the background"
  promise.then(
    (resolved: T) => {
      operation.resolve(resolved);

      if (resolveAction) {
        if (typeof resolveAction === "string") {
          context.commit(resolveAction, resolved);
        }

        if (typeof resolveAction === "function") {
          resolveAction(resolved);
        }
      }
    },
    (error: unknown) => {
      broadcastGlobalError(error);
      return operation.reject(error);
    },
  );

  return operation;
}

/**
 * Handles the fetching of data for a resource or collection store.
 * Meant to be used by any kind of fetch operation.
 *
 * @param context The context object passed to `dispatch`
 * @param promise The promise representing the fetch operation
 * @param resolveAction The action to perform when the promise resolves
 */
export async function handleFetch<T>(
  context: ActionContext<CommonState<T>, RootState>,
  promise: Promise<T>,
  resolveAction?: string | ((resolved: unknown) => void) | null,
): Promise<void> {
  context.commit(START_FETCH, promise);

  try {
    const resolved = await promise;

    if (resolveAction) {
      if (typeof resolveAction === "string") {
        context.commit(resolveAction, resolved);
      }

      if (typeof resolveAction === "function") {
        resolveAction(resolved);
      }
    }

    context.commit(FETCH_SUCCESS, resolved);
  } catch (error) {
    broadcastGlobalError(error);

    context.commit(FETCH_ERROR, error);
  }
}

export function startFetch<T>(
  state: CommonState<T>,
  promise: Promise<T>,
): void {
  state.latestFetch.restart(promise);
}

export function successfulFetch<T>(state: CommonState<T>, resolved: T): void {
  state.latestFetch.resolve(resolved);
  state.lastFetchAt = DateTime.local();
}

export function failedFetch<T>(state: CommonState<T>, error: unknown): void {
  state.latestFetch.reject(error);
}

export type ListTransformOption<T> = (coll: T[]) => T[];
export type ListItemsOptions<T> = Nullable<{
  sortBy: keyof T;
  sortDirection: "asc" | "desc";
  transform?: ListTransformOption<T>;
  limit: number;
}>;

export const defaultListOptions = {
  sortBy: null,
  sortDirection: null,
  transform: null,
  limit: null,
};

// When supplied with an array, returns an array
export function listItems<T>(
  sourceItems: T[],
  options?: Partial<ListItemsOptions<T>>,
): T[];

// When supplied with null, returns null
export function listItems<T>(
  sourceItems: null,
  options?: Partial<ListItemsOptions<T>>,
): null;

// Actual impl
export function listItems<T>(
  sourceItems: T[] | null,
  options: Partial<ListItemsOptions<T>> = {},
): typeof sourceItems {
  if (!sourceItems) {
    return null;
  }

  let items = sourceItems;

  options = {
    ...defaultListOptions,
    ...options,
  };

  const { sortBy, sortDirection, transform, limit } = options;

  if (sortBy) {
    const whenGreater = sortDirection === "desc" ? -1 : 1;
    const whenLower = whenGreater * -1;

    // sort is in-place, and we have to avoid mutating the original object
    items = items.slice();

    items.sort((a, b) => {
      if (a[sortBy] && !b[sortBy]) { return whenGreater; } // eslint-disable-line prettier/prettier
      if (!a[sortBy] && b[sortBy]) { return whenLower; } // eslint-disable-line prettier/prettier
      if (a[sortBy] > b[sortBy]) { return whenGreater; } // eslint-disable-line prettier/prettier
      if (a[sortBy] < b[sortBy]) { return whenLower; } // eslint-disable-line prettier/prettier
      return 0;
    });
  }

  if (!sortBy && sortDirection === "desc") {
    // Reverse mutates in place
    items = items.slice().reverse();
  }

  if (transform) {
    items = transform(items);
  }

  if (limit) {
    items = items.slice(0, limit);
  }

  // This ensures we return a copy of the original object if it wasn't already copied
  if (Object.is(items, sourceItems)) {
    items = items.slice();
  }

  return items;
}

/** Merges the list item options. Keeps the last value when supplied
 *  and applies all `transform` functions given.
 */
export function mergeListOptions<T>(
  ...opts: Partial<ListItemsOptions<T>>[]
): ListItemsOptions<T> {
  const result: ListItemsOptions<T> = { ...defaultListOptions };
  const transformsToMerge: ListTransformOption<T>[] = [];

  for (const obj of opts) {
    if (obj.limit) result.limit = obj.limit;
    if (obj.sortBy) result.sortBy = obj.sortBy;
    if (obj.sortDirection) result.sortDirection = obj.sortDirection;

    if (obj?.transform) transformsToMerge.push(obj.transform);
  }

  // New transform consist in applying each transform in turn
  if (transformsToMerge.length > 0) {
    result.transform = (coll) => {
      for (const fn of transformsToMerge) {
        coll = fn(coll);
      }

      return coll;
    };
  }

  return result;
}

export interface EnsureOptions {
  /** Trigger a refresh even if data is already present. "alway", or a number of seconds */
  staleAfter?: "always" | number | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export function shouldRefresh<T extends GenericResource>(
  state: CollectionState<T>,
  staleAfter: "always" | number | null | undefined,
): boolean;

export function shouldRefresh<T>(
  state: ResourceState<T>,
  staleAfter: "always" | number | null | undefined,
): boolean;

export function shouldRefresh<T extends GenericResource>(
  state: CollectionState<T> | ResourceState<T>,
  staleAfter: "always" | number | null | undefined,
): boolean {
  let refresh = false;

  if (staleAfter === "always") {
    dashboard.debug("always refreshing");

    refresh = true;
  } else if (
    ("values" in state && !state.values) ||
    ("value" in state && !state.value)
  ) {
    dashboard.debug("no values, refreshing");

    refresh = true;
  } else if (!state.lastFetchAt) {
    dashboard.debug("no last fetch at, refreshing");

    refresh = true;
  } else if (staleAfter) {
    const threshold = state.lastFetchAt.plus({
      seconds: staleAfter,
    });

    dashboard.debug("stale data, refreshing");

    refresh = threshold < DateTime.local();
  } else {
    dashboard.debug("not refreshing");
  }

  return refresh;
}

export function stubStoreCollection<T>(
  data: T[] | unknown,
  status: "success" | "error" | "loading" = "success",
): CollectionWithFetch<T> {
  let latestFetch: RemoteOperation<T[]>;
  let items: T[] | null = null;

  if (status === "success") {
    latestFetch = operationSuccess<T[]>(data as T[]);
    items = data as T[];
  } else if (status === "error") {
    latestFetch = operationError(data);
  } else {
    latestFetch = operationLoading();
  }

  return { items, latestFetch };
}

export function stubStoreResource<T>(
  data: T | unknown,
  status: "success" | "error" | "loading" = "success",
): ResourceWithFetch<T> {
  let latestFetch: RemoteOperation<T>;
  let value: T | null = null;

  if (status === "success") {
    latestFetch = operationSuccess<T>(data as T);
    value = data as T;
  } else if (status === "error") {
    latestFetch = operationError(data);
  } else {
    latestFetch = operationLoading();
  }

  return { value, latestFetch };
}
