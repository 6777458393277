<template>
  <div>
    <label :class="allLabelClasses">
      {{ label }}
    </label>
    <TextInputAtom
      v-model="inputValue"
      :state="inputState"
      :placeholder="placeholder"
      :type="type"
      :min="min"
      :max="max"
      :step="step"
      :name="name"
      :unit="unit"
      class="py-2"
      @blur="$emit('blur')"
    />
    <p class="text-xs text-color-2 mb-4 h-2">
      <span v-if="errors.length > 0">
        {{ errors[0] }}
      </span>
      <span
        v-if="subText.length > 0 && errors.length === 0"
        class="text-scale-7"
      >
        {{ subText }}
      </span>
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import TextInputAtom, {
  STATE_ERROR,
  STATE_NONE,
  TYPE_NUMBER,
} from "@/components/atoms/inputs/TextInput.vue";

export default defineComponent({
  name: "NumberInput",
  components: { TextInputAtom },
  props: {
    label: String,
    labelClass: String,
    modelValue: [String, Number],
    name: String,
    min: {
      type: [String, Number],
      default: 0,
    },
    step: {
      type: [String, Number],
      default: 1,
    },
    max: {
      type: [String, Number],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    placeholder: String,
    unit: {
      type: String,
      default: "",
    },
    subText: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "blur"],
  data() {
    return {
      type: TYPE_NUMBER,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    inputState: function () {
      if (this.errors.length > 0) {
        return STATE_ERROR;
      }
      return STATE_NONE;
    },
    allLabelClasses() {
      let str = "block text-sm font-medium leading-5";

      str += this.errors.length > 0 ? " text-color-2" : " text-scale-10";

      if (this.labelClass) {
        str = `${str} ${this.labelClass}`;
      }

      return str;
    },
  },
});
</script>
