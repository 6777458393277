<template>
  <div class="w-full text-center">
    <EmptyCardState>
      <template #icon>
        <UserFriendsGlyph class="h-8 text-scale-5" />
      </template>
      <span class="text-lg font-bold text-scale-9">
        {{ $t(`${emptyType}.title`) }}
      </span>
    </EmptyCardState>
    <p class="md:w-1/2 mx-auto font-medium text-scale-6 mt-2">
      {{ $t(`${emptyType}.subtitle`) }}
    </p>
    <div class="row text-center mt-4">
      <SCButton
        v-if="!isAppOwner"
        kind="primary"
        size="md"
        class="mx-1"
        :to="createAppRoute"
      >
        {{ $t("noApps.enable") }}
      </SCButton>

      <a
        href="https://doc.scalingo.com/platform/app/collaborators"
        target="_blank"
      >
        <SCButton
          size="md"
          class="mx-1"
        >
          {{ $t("more") }}
          <font-awesome-icon
            class="ml-1"
            icon="external-link-alt"
          />
        </SCButton>
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import UserFriendsGlyph from "@/components/atoms/glyphs/UserFriendsGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "NoCollaborators",
  components: {
    SCButton,
    EmptyCardState,
    UserFriendsGlyph,
  },
  props: {
    isAppOwner: {
      type: Boolean,
      required: true,
    },
    isCollaborator: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      createAppRoute: {
        name: Routes.Create.App,
      },
    };
  },
  computed: {
    emptyType() {
      if (this.isAppOwner) {
        return "noCollaborators";
      }

      if (this.isCollaborator) {
        return "noAppsButCollab";
      }

      return "noApps";
    },
  },
});
</script>

<i18n>
  en:
    noCollaborators:
      title: "You haven’t invited any collaborators"
      subtitle: "Scalingo is designed to facilitate collaboration around your code. Invite your team members to join your applications to work more efficiently."
    noApps:
      title: "Create your first app and invite collaborators"
      subtitle: "You're starting from scratch. Create your first app, then invite collaborators to work as a team."
      enable: "Create an app"
    noAppsButCollab:
      title: "Create your own apps and invite collaborators"
      subtitle: "You already collaborate on applications, but only collaborators from applications you own are displayed here."
      enable: "Create an app"
    more: "Collaborate efficiently on Scalingo"
  fr:
    noCollaborators:
      title: "Vous n’avez invité aucun collaborateur"
      subtitle: "Scalingo est conçu pour faciliter la collaboration autour de votre code. Invitez les membres de votre équipe à rejoindre vos applications pour travailler plus efficacement."
    noApps:
      title: "Créez votre première application et invitez des collaborateurs"
      subtitle: "Vous partez de zéro. Créez votre première application, puis invitez des collaborateurs pour travailler en équipe."
      enable: "Créer une application"
    noAppsButCollab:
      title: "Créez vos propres applications et invitez des collaborateurs"
      subtitle: "Vous collaborez déjà sur des applications, mais seuls les collaborateurs des applications dont vous êtes propriétaire sont affichés ici."
      enable: "Créer une application"
    more: "Collaborer efficacement sur Scalingo"
    </i18n>
