<template>
  <div class="flex items-center text-primary-2 font-medium">
    <ArrowLeftGlyph />
    <TextButton
      :noShadow="true"
      :routeArgs="routeArgs"
      @clicked="$emit('clicked')"
    >
      <slot />
    </TextButton>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import TextButton from "@/components/atoms/buttons/TextButton.vue";
import ArrowLeftGlyph from "@/components/atoms/glyphs/ArrowLeftGlyph.vue";

export default defineComponent({
  name: "BackLinkButton",
  components: { TextButton, ArrowLeftGlyph },
  props: {
    routeArgs: Object,
  },
  emits: ["clicked"],
});
</script>
