export const BillingRoutes = {
  Root: "Billing",
  Profile: "BillingProfile",
  SupportPlans: "BillingSupportPlans",
  Vouchers: "BillingVouchers",
  Credits: "BillingCredits",
  Balance: "BillingBalance",
  PaymentMethods: "BillingPaymentMethods",
  Orders: {
    Root: "BillingOrdersRoot",
    List: "BillingOrdersList",
    Show: "BillingOrdersShow",
  },
  Invoices: {
    Root: "BillingInvoicesRoot",
    List: "BillingInvoicesList",
    Show: "BillingInvoicesShow",
    Detailed: "BillingInvoicesDetailed",
  },
};
