<template>
  <TextButton
    :styling="styling"
    :noShadow="true"
    :routeArgs="routeArgs"
    :isDisabled="isDisabled"
    :isLoading="isLoading"
    @clicked="$emit('clicked')"
  >
    <slot />
  </TextButton>
</template>

<script>
import { defineComponent } from "vue";

import TextButton from "./TextButton.vue";

export default defineComponent({
  name: "LinkButton",
  components: { TextButton },
  props: {
    noUnderLine: Boolean,
    routeArgs: Object,
    alternativeColor: Boolean,
    isDisabled: Boolean,
    isLoading: Boolean,
  },
  emits: ["clicked"],
  computed: {
    styling: function () {
      let value = "text-primary-2 hover:text-primary-2-125 font-medium";
      if (this.alternativeColor) {
        value = "text-tertiary-special font-medium";
      }

      if (this.noUnderLine) {
        return value;
      }
      value += " underline";
      return value;
    },
  },
});
</script>
