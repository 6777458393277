<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <LogDrainAddGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #body>
      <div>
        {{ $t("url") }}
        <span class="font-medium ml-1">{{ event.type_data.url }}</span>
      </div>
      <div>
        {{ $t("addon_name") }}
        <span class="font-medium ml-1">{{ event.type_data.addon_name }}</span>
      </div>
      <div>
        {{ $t("addon_uuid") }}
        <span class="font-medium ml-1">{{ event.type_data.addon_uuid }}</span>
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import LogDrainAddGlyph from "@/components/atoms/glyphs/LogDrainAddGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "NewAddonLogDrainEvent",
  components: { LogDrainAddGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Addon log drain created"
  url: "Url: "
  addon_name: "Addon name: "
  addon_uuid: "Addon uuid: "
fr:
  title: "Log drain d'addon créé"
  url: "Url :"
  addon_name: "Nom de l'addon: "
  addon_uuid: "Uuid de l'addon: "
</i18n>
