<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AlertGlyph
        class="h-6 w-6"
        :class="{
          'text-color-2': event.type_data.activated,
          'text-color-1': !event.type_data.activated,
        }"
      />
      <div
        class="pl-2 font-bold"
        :class="{
          'text-color-2': event.type_data.activated,
          'text-color-1': !event.type_data.activated,
        }"
      >
        {{ $t("title") }}
        <span v-if="event.type_data.activated">{{ $t("triggered") }}</span>
        <span v-else>{{ $t("resolved") }}</span>
      </div>
    </template>
    <template #more>
      <span
        v-if="event.type_data.send_when_below"
        class="ml-1 font-medium"
      >
        {{ $t("low") }}</span><span
          v-else
          class="ml-1 font-medium"
        > {{ $t("high") }}</span>
      <span class="mx-1 font-medium">{{ event.type_data.metric }}</span>
      {{ $t("usage") }}
      <span class="font-medium mx-1">{{ event.type_data.sender_name }}</span>
      : {{ $t("value") }}
      <span class="mx-1 font-medium">{{ event.type_data.value_text }}</span>
      ({{ $t("limit") }}: {{ event.type_data.limit_text }})
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AlertGlyph from "@/components/atoms/glyphs/AlertGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "AlertEvent",
  components: { AlertGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Alert"
  triggered: "triggered"
  resolved: "resolved"
  low: "Low"
  high: "High"
  usage: "usage on container"
  value: "value at"
  limit: "limit"
fr:
  title: "Alerte"
  triggered: "déclenchée"
  resolved: "résolue"
  low: "Bas"
  high: "Haut"
  usage: "usage sur le container"
  value: "valeur à"
  limit: "limite"
</i18n>
