<template>
  <SCTable
    layout="fixed"
    :headers="headers"
    :items="reviewApps.items"
    :searchable="true"
    :searchFn="searchFn"
  >
    <template #item.status="{ item }">
      <ThreeStatesIndicator
        v-if="item.last_deployment"
        :isOk="item.last_deployment.status === 'success'"
        :isNo="item.last_deployment.status !== 'success'"
      />
      <span v-else />
    </template>

    <template #item.appName="{ item }">
      <router-link
        :to="appRouteFor(item)"
        class="hover:underline cursor-pointer"
      >
        {{ item.app_name }}
      </router-link>
    </template>

    <template #item.title="{ item }">
      <a
        :href="item.pull_request.html_url"
        class="hover:underline cursor-pointer"
        target="_blank"
      >
        <font-awesome-icon :icon="scmIcon" />
        {{ item.pull_request.title }}
      </a>
    </template>

    <template #item.lastDeployedAt="{ item }">
      <SimpleDate
        v-if="item.last_deployment && item.last_deployment.created_at"
        :value="item.last_deployment.created_at"
      />
      <span v-else />
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import ThreeStatesIndicator from "@/components/atoms/indicators/ThreeStatesIndicator.vue";
import SimpleDate from "@/components/atoms/presenters/SimpleDate.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ReviewAppsList",
  components: {
    SCTable,
    ThreeStatesIndicator,
    SimpleDate,
  },
  props: {
    parentApp: Object,
    scmRepoLink: Object,
    reviewApps: Object,
  },
  data() {
    return {
      headers: [
        { value: "status", text: "", class: "w-8" },
        { value: "appName", text: this.$t("headers.appName") },
        { value: "title", text: this.$t("headers.title") },
        {
          value: "lastDeployedAt",
          text: this.$t("headers.lastDeployedAt"),
          align: "right",
        },
      ],
      searchFn(row, search) {
        return !![row.app_name, row.pull_request.title].find((col) => {
          return (
            col
              .toString()
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase()) !== -1
          );
        });
      },
    };
  },
  computed: {
    scmIcon() {
      if (["github", "github-enterprise"].includes(this.scmRepoLink.scm_type)) {
        return ["fab", "github"];
      } else {
        return ["fab", "gitlab"];
      }
    },
  },
  methods: {
    appRouteFor(reviewApp) {
      return {
        name: Routes.App.Overview,
        params: { id: reviewApp.app_name, region: this.parentApp.region },
      };
    },
  },
});
</script>

<i18n>
en:
  headers:
    appName: "Application"
    title: "Pull request"
    lastDeployedAt: "Last deployed at"
fr:
  headers:
    appName: "Application"
    title: "Pull request"
    lastDeployedAt: "Dernier déploiement"
</i18n>
