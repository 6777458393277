<template>
  <div
    v-if="isOpen"
    class="mt-2 w-64 rounded shadow-lg border border-scale-1 bg-scale-0 absolute z-20"
    :style="transformXStyle"
  >
    <input
      v-model="filter"
      type="text"
      :placeholder="inputPlaceholder"
      class="w-auto my-2 mx-2 px-4 py-2 h-9 border-b rounded border-scale-4 text-sm focus:outline-none bg-scale-0 focus:border-scale-6 focus:ring-0"
    >
    <ul class="max-h-56 overflow-y-auto pb-2">
      <li
        v-for="(item, index) in filteredItems"
        :key="index"
        class="h-9 pl-6 pr-3 text-sm hover:bg-scale-1 cursor-pointer"
        @click="listClick(item)"
      >
        <button class="flex items-center w-full text-left justify-between">
          <span class="truncate w-3/4 text-base mt-1.5 flex items-center">
            {{ item.name }}
            <Region
              v-if="
                item?.region === 'osc-secnum-fr1' ||
                  item?.region === 'osc-st-fr1'
              "
              class="ml-2 w-5"
              :regionCode="item?.region"
            />
          </span>

          <div class="flex flex-row mt-1.5">
            <UserFriendsGlyph class="h-4 text-scale-5" />
            <span class="text-scale-5">{{ item.count }}</span>
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import UserFriendsGlyph from "@/components/atoms/glyphs/UserFriendsGlyph.vue";
import Region from "@/components/organisms/app/Region.vue";

export default {
  name: "CenteredDropdownMenu",
  components: { UserFriendsGlyph, Region },
  props: {
    items: {
      type: Array,
      required: true,
    },
    callerWidth: {
      type: String,
      required: true,
      default: "w-12",
    },
    inputPlaceholder: {
      type: String,
      default: "Filter ...",
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["dropdownOpen", "toggleAction"],
  data() {
    return {
      filter: "",
    };
  },
  computed: {
    filteredItems() {
      if (!this.filter) {
        return this.items;
      }
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(this.filter.toLowerCase()),
      );
    },
    transformXStyle() {
      return `transform: translateX(${((256 - this.tailwindToPixels(this.callerWidth)) / 2) * -1}px)`;
    },
  },
  methods: {
    listClick(item) {
      this.$emit("toggleAction", item);
    },
    tailwindToPixels(tailwindClass) {
      const match = tailwindClass.match(/w-(\d+)/);
      const unit = parseInt(match[1], 10);
      const remValue = unit * 0.25;
      const pxValue = remValue * 16;

      return pxValue;
    },
  },
};
</script>
