<template>
  <div>
    <label
      :class="labelClass"
      class="block text-sm font-medium leading-5 text-scale-10"
    >
      {{ label }}
    </label>
    <div class="mt-1 relative rounded-md flex">
      <div
        :id="id"
        :class="{ 'border-color-2': isErrorState }"
        class="form-input rounded py-2 px-2 border border-scale-2 block w-full"
      />
      <div
        v-if="isErrorState"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-color-2"
      >
        <DangerSignGlyph />
      </div>
    </div>
    <p
      v-if="errors.length > 0"
      class="text-sm text-scale-10 mb-4"
    >
      {{ errors[0] }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import {
  STATE_ERROR,
  STATE_NONE,
} from "@/components/atoms/inputs/TextInput.vue";

export default defineComponent({
  name: "StripeElement",
  components: { DangerSignGlyph },
  props: {
    label: String,
    labelClass: String,
    id: String,
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    state: function () {
      if (this.errors.length > 0) {
        return STATE_ERROR;
      }
      return STATE_NONE;
    },
    isErrorState: function () {
      return this.state === STATE_ERROR;
    },
  },
});
</script>
