<template>
  <div
    :class="menuClass"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="option-menu"
  >
    <template v-if="$slots.prepend">
      <slot name="prepend" />
    </template>

    <template
      v-for="(item, index) in items"
      :key="`item-${randomId}-${index}`"
    >
      <router-link
        v-if="item.to"
        class="block px-4 py-1 text-sm hover:bg-scale-1 cursor-pointer"
        :class="item.wrapperClass"
        role="menuitem"
        :to="item.to"
        @click="() => itemClicked(item, index)"
      >
        {{ item.text }}
        <font-awesome-icon
          v-if="item.icon"
          class="ml-1"
          :icon="item.icon"
        />
      </router-link>

      <a
        v-else-if="item.a"
        class="block px-4 py-1 text-sm hover:bg-scale-1 cursor-pointer"
        :class="item.wrapperClass"
        role="menuitem"
        v-bind="item.a"
        @click="() => itemClicked(item, index)"
      >
        {{ item.text }}
        <font-awesome-icon
          v-if="item.icon"
          class="ml-1"
          :icon="item.icon"
        />
      </a>

      <div
        v-else
        class="block px-4 py-1 text-sm hover:bg-scale-1 cursor-pointer"
        :class="item.wrapperClass"
        role="menuitem"
        @click="itemClicked(item, index)"
      >
        <span :class="itemClass(item)">
          {{ item.text }}
          <font-awesome-icon
            v-if="item.icon"
            class="ml-1"
            :icon="item.icon"
          />
        </span>
      </div>
    </template>

    <template v-if="$slots.append">
      <slot name="append" />
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ActionsList",
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["click"],
  data() {
    return {
      randomId: Math.random().toString(36).substring(7),
    };
  },
  computed: {
    menuClass() {
      let classes =
        "rounded-md shadow-lg bg-scale-0 z-50 py-1 border border-scale-2";

      if (!this.block) {
        classes += " inline-block";
      }

      return classes;
    },
  },
  methods: {
    itemClass(item) {
      let classes = item.class || "";

      if (item.kind === "warning") {
        classes += " text-secondary-3";
      }

      return classes;
    },
    itemClicked(action, index) {
      this.$emit("click", { action, index });
    },
  },
});
</script>
