<template>
  <div
    :class="classFn"
    class="h-3 w-3 rounded-full"
  />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FiveStatesIndicator",
  props: {
    isOk: {
      type: Boolean,
      default: false,
    },
    isKo: {
      type: Boolean,
      default: false,
    },
    isNo: {
      type: Boolean,
      default: false,
    },
    isOngoing: {
      type: Boolean,
      default: false,
    },
    isNotified: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    classFn() {
      let classString;
      if (this.isOk) {
        classString += " bg-color-1";
      }
      if (this.isKo) {
        classString += " bg-color-2";
      }
      if (this.isNo) {
        classString += " bg-scale-5";
      }
      if (this.isOngoing) {
        classString += " bg-color-3 pulse";
      }
      if (this.isNotified) {
        classString += " bg-color-3";
      }
      classString += " h-" + this.size + " " + "w-" + this.size;
      return classString;
    },
  },
});
</script>

<style scoped>
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.pulse {
  animation: pulse 1s infinite;
}
</style>
