<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <div>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      >
        <template #buttons>
          <SCButton
            v-if="isEnabled"
            :loading="isPending"
            @click="disableRDB"
          >
            {{ $t("disable") }}
          </SCButton>
          <SCButton
            v-else
            :loading="isPending"
            @click="enableRDB"
          >
            {{ $t("enable") }}
          </SCButton>
        </template>
      </CardHeader>

      <div class="flex flex-row items-center mt-4">
        <TwoStatesIndicator
          class="mr-2"
          :isOn="isEnabled"
          :isNo="!isEnabled"
          :alternativeColors="true"
        />
        <span v-if="isEnabled">
          {{ $t("enabled") }}
        </span>
        <span v-if="!isEnabled">
          {{ $t("disabled") }}
        </span>
      </div>

      <div class="mt-4 text-scale-8">
        {{ $t("text") }}
      </div>

      <ul class="mt-4 ml-4 list-disc text-scale-7">
        <li>{{ $t("text2") }}</li>
        <li>{{ $t("text3") }}</li>
        <li>{{ $t("text4") }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import { dbFeatureIs } from "@/lib/scalingo/dbapi/database";

export default defineComponent({
  name: "RDBCard",
  components: {
    CardHeader,
    SCButton,
    TwoStatesIndicator,
  },
  props: {
    db: Object,
    user: Object,
    promiseInfo: Object,
    enableRDB: Function,
    disableRDB: Function,
  },
  setup(props) {
    const isEnabled = computed(() =>
      dbFeatureIs(props.db, "redis-rdb", "ACTIVATED"),
    );

    const isPending = computed(() => {
      return (
        props.promiseInfo.isLoading ||
        dbFeatureIs(props.db, "redis-rdb", "PENDING")
      );
    });

    return {
      isPending,
      isEnabled,
    };
  },
});
</script>

<i18n>
en:
  title: "RDB Persistence"
  subtitle: "RDB persistence configuration"
  text: "This persistency mode, which is configured by default, provides a compromise between performance and persistence. Data are saved to disk periodically, according to the number of write operations, asynchronously. It means that in the scope of an incident, recent data are lost. Here are the saving rules: "
  text2: "Every 900 seconds if at least 1 write operation has occurred"
  text3: "Every 300 seconds if at least 10 write operations have occurred"
  text4: "Every 60 seconds if at least 10000 write operations have occurred"
  enable: "Enable"
  disable: "Disable"
  enabled: "RDB persistence enabled"
  disabled: "RDB persistence disabled"
fr:
  title: "Persistance RDB"
  subtitle: "Configuration de la persistance RDB"
  text: "Ce mode de persistance, configuré par défaut, offre un compromis entre performance et persistance. Les données sont sauvegardées sur le disque périodiquement, en fonction du nombre d'opérations d'écriture, de manière asynchrone. Cela signifie que dans le cadre d'un incident, les données récentes sont perdues. Voici les règles de sauvegarde : "
  text2: "Toutes les 900 secondes si au moins 1 opération d'écriture a eu lieu"
  text3: "Toutes les 300 secondes si au moins 10 opérations d'écriture ont eu lieu"
  text4: "Toutes les 60 secondes si au moins 10000 opérations d'écriture ont eu lieu"
  enable: "Activer"
  disable: "Désactiver"
  enabled: "Persistance RDB activée"
  disabled: "Persistance RDB désactivée"
</i18n>
