<template>
  <div class="flex">
    <Pill
      v-if="orderState === 'archived'"
      class="flex-shrink"
      type="color1"
    >
      {{ $t("attributes.order.statuses.archived") }}
    </Pill>
    <Pill
      v-if="orderState === 'active'"
      class="flex-shrink"
      type="success"
    >
      {{ $t("attributes.order.statuses.active") }}
    </Pill>
    <Pill
      v-if="orderState === 'inactive'"
      class="flex-shrink"
      type="neutral"
    >
      {{ $t("attributes.order.statuses.inactive") }}
    </Pill>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Pill from "@/components/atoms/pills/Pill.vue";

export default defineComponent({
  name: "OrderStatus",
  components: { Pill },
  props: {
    orderState: String,
  },
});
</script>
