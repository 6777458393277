<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4C7.26333 4 6.66667 4.61023 6.66667 5.36364V6.04545H10V5.36364C10 4.61023 9.40333 4 8.66667 4H8ZM15.3333 4C14.5967 4 14 4.61023 14 5.36364V6.04545H17.3333V5.36364C17.3333 4.61023 16.7367 4 16 4H15.3333ZM5.33333 7.40909C4.59667 7.40909 4 8.01932 4 8.77273V17.6364C4 18.3898 4.59667 19 5.33333 19H18.6667C19.4033 19 20 18.3898 20 17.6364V8.77273C20 8.01932 19.4033 7.40909 18.6667 7.40909H5.33333Z"
      fill="currentColor"
    />
    <circle
      cx="18"
      cy="16"
      r="5.5"
      fill="white"
      stroke="white"
    />
    <circle
      cx="18"
      cy="16"
      r="5"
      fill="currentColor"
    />
    <path
      d="M16.6002 13.7999C16.3792 13.7999 16.2002 13.9789 16.2002 14.1999V17.7999C16.2002 18.0209 16.3792 18.1999 16.6002 18.1999H17.0002C17.2212 18.1999 17.4002 18.0209 17.4002 17.7999V14.1999C17.4002 13.9789 17.2212 13.7999 17.0002 13.7999H16.6002ZM19.0002 13.7999C18.7792 13.7999 18.6002 13.9789 18.6002 14.1999V17.7999C18.6002 18.0209 18.7792 18.1999 19.0002 18.1999H19.4002C19.6212 18.1999 19.8002 18.0209 19.8002 17.7999V14.1999C19.8002 13.9789 19.6212 13.7999 19.4002 13.7999H19.0002Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddonPausedGlyph",
});
</script>
