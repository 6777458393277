<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      />
    </template>
    <template #body>
      <SCTable
        layout="fixed"
        :headers="headers"
        :items="db.instances"
        class="humanize mt-4"
        @fetch-more="$emit('fetchMore')"
      >
        <template #item.privateIp="{ item }">
          {{ item.private_ip }}
        </template>

        <template #item.type="{ item }">
          {{ item.type }}
        </template>

        <template #item.status="{ item }">
          {{ $t(`itemStatus.${item.status}`) }}
        </template>
      </SCTable>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";

export default defineComponent({
  name: "ClusterTopology",
  components: { Card, CardHeader, SCTable },
  props: {
    db: Object,
  },
  emits: ["fetchMore"],
  data() {
    return {
      headers: [
        {
          value: "privateIp",
          text: this.$t("headers.privateIp"),
          class: "w-2/6",
        },
        { value: "type", text: this.$t("headers.type"), class: "w-2/6" },
        { value: "status", text: this.$t("headers.status"), class: "1/6" },
      ],
    };
  },
});
</script>

<i18n>
en:
  title: "Cluster Topology"
  subtitle: "Cluster Topology details"
  headers:
    privateIp: "Private IP"
    type: "Type"
    status: "Status"
  itemStatus:
    booting: "booting"
    running: "running"
    restarting: "restarting"
    migrating: "migrating"
    upgrading: "upgrading"
    stopped: "stopped"
    removing: "removing"
    unreachable: "unreachable"
fr:
  title: "Topologie du cluster"
  subtitle: "Détails de la topologie du cluster"
  headers:
    privateIp: "IP privée"
    type: "Type"
    status: "Statut"
  itemStatus:
    booting: "démarrage"
    running: "en cours d'exécution"
    restarting: "redémarrage"
    migrating: "migration"
    upgrading: "mise à niveau"
    stopped: "arrêté"
    removing: "suppression"
    unreachable: "injoignable"
</i18n>
