export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor Authentication (2FA)"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a verification step to protect your account and prevent unauthorized access."])},
        "buttonNameDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
        "buttonNameEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
        "2FaStatus": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is disabled"])},
          "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is enabled"])}
        },
        "explanationsWhenEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each time you log in, you must confirm your identity using a one-time, temporary password generated by your authentication app."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs (2FA)"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une étape de vérification pour protéger votre compte et empêcher les accès non autorisés."])},
        "buttonNameDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "buttonNameEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
        "2FaStatus": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'authentification à deux facteurs"])},
          "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est désactivée"])},
          "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est activée"])}
        },
        "explanationsWhenEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À chaque connexion, vous devez confirmer votre identité à l’aide d’un mot de passe à usage unique et temporaire, généré par votre application d’authentification."])}
      }
    }
  })
}
