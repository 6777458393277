<template>
  <main>
    <div>
      <Card>
        <template #header>
          <CardHeader
            :title="$t('title')"
            :subtitle="$t('subtitle')"
          />
        </template>
        <template #body>
          <div class="flex items-center justify-center mt-8">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-6 w-full">
              <BasicSupportPlanCard :current="supportPlan === 'basic'" />
              <StarterSupportPlanCard :current="supportPlan === 'starter'" />
              <BusinessSupportPlanCard :current="supportPlan === 'business'" />
            </div>
          </div>
        </template>
      </Card>

      <ProTipAlert class="mt-4 flex">
        <div>{{ $t("proTip") }}</div>
      </ProTipAlert>
    </div>
  </main>
</template>

<script>
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import BasicSupportPlanCard from "@/components/parts/billing/supportPlans/BasicSupportPlanCard.vue";
import BusinessSupportPlanCard from "@/components/parts/billing/supportPlans/BusinessSupportPlanCard.vue";
import StarterSupportPlanCard from "@/components/parts/billing/supportPlans/StarterSupportPlanCard.vue";

export default {
  name: "SupportPlans",
  components: {
    Card,
    CardHeader,
    ProTipAlert,
    BasicSupportPlanCard,
    BusinessSupportPlanCard,
    StarterSupportPlanCard,
  },
  props: {
    supportPlan: String,
  },
};
</script>

<i18n>
  en:
    title: "Support plans"
    subtitle: "Choose the coverage and support levels you need to ensure the performance and resilience of your projects."
    proTip: "When we set up your plan, don’t forget to send us the list of team members from your apps with whom you’d like to share the benefits of your subscription. This will allow us to activate their access without delay."
  fr:
    title: "Offres de support"
    subtitle: "Choisissez les garanties et niveaux d’assistance dont vous avez besoin pour assurer la performance et la résilience de vos projets."
    proTip: "Lors de la mise en place de votre plan, pensez à nous envoyer la liste des collaborateurs de vos applications avec qui vous souhaitez partager les bénéfices de votre souscription. Cela nous permettra d’activer leur accès sans délai."
</i18n>
