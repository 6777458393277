export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à partir de"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 €/mois"])},
        "pricingLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou 5 % de votre consommation"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Starter"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les applications de production non critiques."])},
        "features": {
          "supportFunctional": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support fonctionnel"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours ouvrés, 9h-18h"])}
          },
          "priorityLine": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne prioritaire lors d’incidents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours ouvrés, 9h-18h"])}
          },
          "responseTime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de réponse garanti"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures ouvrées < 60 minutes"])}
          },
          "accompaniment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accompagnement"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à la documentation en ligne, à nos guides et à nos tutoriels."])}
          },
          "documentation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents fournis"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          },
          "commitment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 mois"])}
          }
        }
      },
      "en": {
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€100/month"])},
        "pricingLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or 5% of your consumption"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter Support"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-critical production applications."])},
        "features": {
          "supportFunctional": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform support"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business days, 9am-6pm"])}
          },
          "priorityLine": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority line during incidents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working days, 9am-6pm"])}
          },
          "responseTime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guaranteed response time"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working hours < 60 minutes"])}
          },
          "accompaniment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance & resources"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to online documentation, guides, and tutorials."])}
          },
          "documentation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided documents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          },
          "commitment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commitment"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 months"])}
          }
        }
      }
    }
  })
}
