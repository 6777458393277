<template>
  <div
    v-if="events.latestFetch"
    class="bg-scale-0 rounded px-8 py-6 flex flex-col"
  >
    <CardHeader
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    />

    <template v-if="events.latestFetch.isLoading">
      <LoadingCardState />
    </template>

    <template v-if="events.latestFetch.isFinished">
      <div class="flex-grow">
        <div
          v-for="(item, index) in events.items"
          :key="index"
          class="py-3 flex"
          :class="{ 'border-t border-scale-2': index > 0 }"
        >
          <EventSwitch
            :event="item"
            :long="false"
            :displayAuthor="true"
            :displayAppName="false"
          />
        </div>
      </div>
      <CardFooter
        :withViewAllButton="true"
        :routeArgs="routeArgs"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import EventSwitch from "@/components/organisms/events/EventSwitch.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ActivityCard",
  components: {
    EventSwitch,
    CardHeader,
    CardFooter,
    LoadingCardState,
  },
  props: {
    app: Object,
    events: Object,
  },
  data() {
    return {
      routeArgs: {
        name: Routes.App.Activity.Root,
        args: { id: this.app.name },
      },
    };
  },
});
</script>

<i18n>
en:
  title: "Activity"
  subtitle: "Latest events."
  events:
    deploymentInProgress: "deployment in progress"
    buildError: "build error"
    containersRestarted: "containers restarted"
fr:
  title: "Activité"
  subtitle: "Derniers événements."
  events:
    deploymentInProgress: "déploiement en cours"
    buildError: "erreur durant la création"
    containersRestarted: "conteneurs redémarrés"
</i18n>
