<template>
  <div>
    <SCButton
      kind="neutral"
      :actions="actions"
      @action="handleExportJson"
      @click="handleExportCsv"
    >
      <div class="flex flex-row items-center">
        <font-awesome-icon
          class="mr-1 py-0.5 text-scale-7"
          icon="file-export"
        />
        <span>{{ $t("exportCsv") }}</span>
      </div>
    </SCButton>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import { downloadBlob, objectArrayToCsv } from "@/lib/utils/export";

export default defineComponent({
  name: "ExportButton",
  components: { SCButton },
  props: {
    exportItems: {
      type: Array,
      required: true,
    },
    exportFilenamePrefix: {
      type: String,
      default: "export",
    },
  },
  emits: ["export"],
  data() {
    return {
      actions: [
        {
          text: this.$t("exportJson"),
          key: "export-json",
        },
      ],
    };
  },

  methods: {
    handleExportCsv() {
      downloadBlob(
        new Blob([objectArrayToCsv(this.exportItems)], {
          type: "text/csv;charset=utf-8;",
        }),
        this.exportFileName() + ".csv",
      );
    },
    handleExportJson() {
      downloadBlob(
        new Blob([JSON.stringify(this.exportItems, null, 2)], {
          type: "application/json;charset=utf-8;",
        }),
        this.exportFileName() + ".json",
      );
    },

    exportFileName() {
      const date = new Date();
      return `${this.exportFilenamePrefix}_${date.toISOString()}`;
    },
  },
});
</script>

<style scoped>
.mb-4 {
  margin-bottom: 1rem;
}
</style>

<i18n>
  en:
    exportCsv: "Export as CSV"
    exportJson: "Export as JSON"
  fr:
    exportCsv: "Exporter en CSV"
    exportJson: "Exporter en JSON"
  </i18n>
