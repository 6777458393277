<template>
  <div
    class="bg-scale-0 border-t-4 rounded"
    :class="styling"
  >
    <div class="px-6 py-4 flex flex-col">
      <div class="flex mb-2">
        <slot name="header" />
      </div>
      <div class="flex justify-items-auto">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  STATUS_BOOTING,
  STATUS_CRASHED,
  STATUS_NEW,
  STATUS_RESTARTING,
  STATUS_RUNNING,
  STATUS_SCALING,
  STATUS_STOPPED,
} from "scalingo/lib/models/regional/apps";
import { defineComponent } from "vue";

import { Routes } from "@/router/names";

// eslint-disable-next-line no-unused-vars
const INACTIVE = [STATUS_NEW, STATUS_STOPPED];
const ACTIVE = [STATUS_RUNNING];
const WARNING = [STATUS_CRASHED];
const ONGOING = [STATUS_RESTARTING, STATUS_SCALING, STATUS_BOOTING];

export default defineComponent({
  name: "AppCard",
  components: {},
  props: {
    app: Object,
    scmRepoLink: Object,
  },
  computed: {
    styling() {
      if (ACTIVE.includes(this.app.status)) {
        return "border-color-1";
      } else if (WARNING.includes(this.app.status)) {
        return "border-color-2";
      } else if (ONGOING.includes(this.app.status)) {
        return "border-primary-2";
      } else {
        return "border-scale-4";
      }
    },
    routerArgs() {
      return {
        name: Routes.App.Overview,
        params: { region: this.app.region, id: this.app.name },
      };
    },
  },
});
</script>

<i18n>
en:
  region: "Region"
fr:
  région: "Région"
</i18n>
