<template>
  <ViewComponent
    :db="db"
    :queriesListActive="queriesListActive"
    :queriesListIdle="queriesListIdle"
    :isLoading="isLoading"
    :cancelQuery="cancelQuery"
    :terminateQuery="terminateQuery"
    :listQueries="listQueries"
  />
</template>

<script>
import { computed, defineComponent, ref } from "vue";

import ViewComponent from "@/components/views/db/DbRunningQueries.vue";
import { i18nT } from "@/lib/utils/i18n";
import {
  currentDB,
  currentDBClient,
  useCurrentDBStore,
} from "@/stores/current/db";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "RunningQueries",
  components: { ViewComponent },
  setup() {
    const currentDbStore = useCurrentDBStore();
    const queriesListActive = ref([]);
    const queriesListIdle = ref([]);
    const isLoading = ref(true);
    const toastsStore = useToastsStore();
    const t = i18nT();

    async function listQueries() {
      const client = await currentDBClient();
      const db = currentDB();
      const response = await client.Database.pgListQueries(db.id);

      if (response.result) {
        queriesListActive.value = response.result.filter(
          (x) => x.state == "active",
        );
        queriesListIdle.value = response.result.filter(
          (x) => x.state == "idle",
        );
      } else {
        queriesListActive.value = queriesListIdle.value = [];
      }
      isLoading.value = false;
    }

    async function cancelQuery(pid) {
      const client = await currentDBClient();
      const db = currentDB();

      try {
        await client.Database.pgCancelQuery(db.id, pid);

        toastsStore.addOne({
          type: "success",
          title: t("success.title") + " " + pid,
          message: t("success.cancel.message"),
        });
        listQueries();
      } catch (error) {
        toastsStore.addOne({
          type: "error",
          title: t("errorTitle"),
          message: t("errorMessage"),
        });
      }
      isLoading.value = false;
    }

    async function terminateQuery(pid) {
      const client = await currentDBClient();
      const db = currentDB();

      try {
        await client.Database.pgTerminateQuery(db.id, pid);

        toastsStore.addOne({
          type: "success",
          title: t("success.title") + " " + pid,
          message: t("success.terminated.message"),
        });
        listQueries();
      } catch (error) {
        toastsStore.addOne({
          type: "error",
          title: t("errorTitle"),
          message: t("errorMessage"),
        });
      }
      isLoading.value = false;
    }

    listQueries();

    return {
      db: computed(() => currentDbStore.database),
      queriesListActive,
      queriesListIdle,
      listQueries,
      isLoading,
      cancelQuery,
      terminateQuery,
    };
  },
});
</script>

<i18n>
en:
  errorTitle: An error occurred.
  errorMessage: Please reload the page and try again.
  success:
    title: Query
    terminated:
      message: The session has been successfully terminated.
    cancel:
      message: The request has been successfully canceled.
fr:
  errorTitle: Une erreur est survenue.
  errorMessage: Veuillez recharger la page et réessayer.
  success:
    title: Requête
    terminated:
      message: La session a été interrompue avec succès.
    cancel:
      message: La requête a été annulée avec succès.
</i18n>
