<template>
  <SCTable
    class="mt-6"
    layout="fixed"
    :headers="headers"
    :items="keys.items"
    :paginated="true"
    :searchable="true"
    :searchFn="searchFn"
    :truncate="true"
  >
    <template #item.createdAt="{ item }">
      <SimpleDate :value="item.created_at" />
    </template>

    <template #item.actions="{ item, index }">
      <div class="flex justify-end">
        <SCTableAction
          :title="$t('delete')"
          type="delete"
          :rowIndex="index"
          class="ml-2"
          @click="$emit('startKeyDeletion', { key: item })"
        >
          <BinGlyph class="h-4 w-4" />
        </SCTableAction>
      </div>
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import BinGlyph from "@/components/atoms/glyphs/BinGlyph.vue";
import SimpleDate from "@/components/atoms/presenters/SimpleDate.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import SCTableAction from "@/components/organisms/tables/SCTableAction.vue";
import ModelsTranslation from "@/mixins/models_translation";

const searchFn = (row, search) => {
  return (
    row.name
      .toString()
      .toLocaleLowerCase()
      .indexOf(search.toLocaleLowerCase()) !== -1
  );
};

export default defineComponent({
  name: "KeysList",
  components: {
    SimpleDate,
    BinGlyph,
    SCTableAction,
    SCTable,
  },
  mixins: [ModelsTranslation],
  props: {
    keys: Object,
  },
  emits: ["startKeyDeletion"],
  data() {
    return {
      headers: [
        { value: "name", text: this.$ta("key", "name"), class: "w-1/4" },
        {
          value: "createdAt",
          text: this.$ta("key", "createdAt"),
          class: "w-1/4",
        },
        {
          value: "fingerprint",
          text: this.$ta("key", "fingerprint"),
          class: "w-auto",
        },
        { value: "actions", text: "", align: "right", class: "w-16" },
      ],
      searchFn: searchFn,
    };
  },
});
</script>

<i18n>
en:
  delete: "Delete"
fr:
  delete: "Supprimer"
</i18n>
