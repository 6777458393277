<template>
  <div class="flex bg-scale-0 rounded">
    <div class="flex py-3 font-bold text-scale-9">
      <div
        class="pr-3 w-6 flex justify-center items-center bg-scale-1 mr-2 pl-3 rounded border-r border-primary-2"
        title="Info"
      >
        <font-awesome-icon
          class="text-primary-2 text-opacity-75"
          icon="info"
        />
      </div>
      <div class="flex-grow flex">
        <div class="self-center text-scale-7">
          <span class="font-normal">
            <slot />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "InfoCallout",
});
</script>
