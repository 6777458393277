<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :containers="containers"
    :containerSizes="containerSizes"
    :since="since"
    :displayInFullScreen="displayInFullScreen"
    :rpmOperation="rpmOperation"
    :responseTimeOperation="responseTimeOperation"
    :containersOperations="containersOperations"
    :eventsAnnotations="eventsAnnotations"
    @range-selected="resetTo"
    @full-screen-toggled="fullScreenToggled"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/metrics/ApexOverview.vue";
import {
  routerDatasets,
  responseTimeDatasets,
  cpuDatasets,
  memoryDatasets,
  eventsAnnotations,
} from "@/lib/metrics/apex";
import { scalingoClient } from "@/lib/scalingo/client";
import { RemoteOperation } from "@/lib/store/remote-operation";
import FullscreenableController from "@/mixins/fullscreenable_controller";
import { navErrorHandler } from "@/router/utils";
import {
  ensureContainerSizes,
  listContainerSizes,
} from "@/store/container-sizes";
import { listContainers, ensureContainers } from "@/store/containers";

const ALLOWED_RANGES = [3, 12, 24, 48, 72];

export default defineComponent({
  name: "ApexMetricsOverviewCtrl",
  components: { ViewComponent },
  mixins: [FullscreenableController],
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    let since = null;

    if (this.$route.query?.since) {
      since = parseInt(this.$route.query.since, 10);
    }

    if (!since) {
      since = ALLOWED_RANGES[0];
    } else if (!ALLOWED_RANGES.includes(since)) {
      since = ALLOWED_RANGES[0];
    }

    return {
      since,
      refreshInterval: null,
      refreshIntervalID: null,
      rpmOperation: new RemoteOperation(),
      responseTimeOperation: new RemoteOperation(),
      eventsAnnotations: [],
      containersOperations: {},
    };
  },
  computed: {
    containers() {
      return listContainers(this.$store, {
        transform(coll) {
          let web = null;
          let tcp = null;
          const others = [];

          coll.forEach((cont) => {
            if (cont.name === "web") {
              web = cont;
            } else if (cont.name === "tcp") {
              tcp = cont;
            } else {
              others.push(cont);
            }
          });

          if (tcp) others.unshift(tcp);
          if (web) others.unshift(web);

          return others;
        },
      });
    },
    containerSizes() {
      return listContainerSizes(this.$store, this.app.region);
    },
    scalingo() {
      return scalingoClient(this.$store, this.app.region);
    },
  },
  watch: {
    since(newVal) {
      const query = { ...this.$route.query };

      if (newVal) {
        query.since = newVal;
      } else {
        delete query.since;
      }

      this.$router.push({ query }).catch(navErrorHandler);
    },
  },
  mounted() {
    ensureContainerSizes(this.$store, this.app.region);
    ensureContainers(this.$store, { staleAfter: "always" });

    this.initialFetch();
  },
  unmounted() {
    this.teardownPeriodicRefresh();
  },
  methods: {
    async initialFetch() {
      // In parallel, we fetch the global metrics,
      // we wait for the containers to be available,
      // and we fetch the events.
      this.refreshEvents();

      const globalPromises = this.refreshGlobalMetrics();

      await this.containers.latestFetch.promise;

      // Once available, we prepare the object that will receive them
      this.prepareContainersMetrics();

      // Then, we wait for all promises to be finished,
      // except events. We draw them after the main datasets.
      const allPromises = [
        ...globalPromises,
        ...this.refreshContainersMetrics(),
      ];

      try {
        const responses = await Promise.allSettled(allPromises);
        const success = responses.find((r) => r.status === "fulfilled");

        // At least one promise resolves => at least one graph is displayed.
        // We will therefore refresh the data periodically
        if (success) this.setupPeriodicRefresh(success.value.interval);
      } catch (e) {
        // no metrics data at all. This can happen, nothing to do here.
      }
    },
    async refreshEvents() {
      this.eventsAnnotations = await eventsAnnotations(
        this.scalingo,
        this.$i18n,
        {
          app: this.app,
          since: this.since,
        },
      );
    },
    refreshGlobalMetrics() {
      return [this.refreshRoutersRequests(), this.refreshResponseTime()];
    },
    refreshRoutersRequests() {
      const promise = routerDatasets(this.scalingo, this.$i18n, {
        app: this.app,
        since: this.since,
      });

      this.rpmOperation.reset();
      this.rpmOperation.follow(promise);

      return promise;
    },
    refreshResponseTime() {
      const promise = responseTimeDatasets(this.scalingo, this.$i18n, {
        app: this.app,
        since: this.since,
      });

      this.responseTimeOperation.reset();
      this.responseTimeOperation.follow(promise);

      return promise;
    },
    prepareContainersMetrics() {
      this.containersOperations = {};

      // For each containers there will be two graphs.
      this.containers.items.forEach((container) => {
        this.containersOperations[container.name] = {
          cpu: new RemoteOperation(),
          memory: new RemoteOperation(),
        };
      });
    },
    refreshContainersMetrics() {
      const containerTypes = Object.keys(this.containersOperations);

      // We want to keep track of all running promises
      // in order to triggers actions when they're all finished
      const containerPromises = [];

      containerTypes.forEach((containerType) => {
        const cpuPromise = cpuDatasets(this.scalingo, this.$i18n, {
          app: this.app,
          containerType,
          since: this.since,
        });

        this.containersOperations[containerType].cpu.reset();
        this.containersOperations[containerType].cpu.follow(cpuPromise);

        const memoryPromise = memoryDatasets(this.scalingo, this.$i18n, {
          app: this.app,
          containerType,
          since: this.since,
        });

        this.containersOperations[containerType].memory.reset();
        this.containersOperations[containerType].memory.follow(memoryPromise);

        containerPromises.push(cpuPromise, memoryPromise);
      });

      return containerPromises;
    },
    resetTo(range) {
      if (!range) range = 3;

      this.since = range;
      this.teardownPeriodicRefresh();

      this.initialFetch();
    },
    setupPeriodicRefresh(interval) {
      this.teardownPeriodicRefresh();

      if (interval) {
        this.refreshInterval = interval;
        this.refreshIntervalID = setInterval(() => {
          this.refreshGlobalMetrics();
          this.refreshContainersMetrics();
        }, this.refreshInterval);
      }
    },
    teardownPeriodicRefresh() {
      if (this.refreshIntervalID) {
        clearInterval(this.refreshIntervalID);
      }

      this.refreshInterval = null;
      this.refreshIntervalID = null;
    },
  },
});
</script>
