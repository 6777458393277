<template>
  <div class="bg-scale-0 rounded px-8 py-6">
    <div class="flex">
      <div class="flex-grow">
        <CardTitle
          :title="$t('title')"
          :subtitle="$t('subtitle')"
        />
      </div>
    </div>

    <SaasSCMIntegration
      :scmIntegrations="scmIntegrations"
      scmType="github"
      @disconnect-s-c-m-integration="
        (e) => $emit('startIntegrationDeletion', e)
      "
      @connect-s-c-m="(e) => $emit('connectSCM', e)"
    />

    <SaasSCMIntegration
      :scmIntegrations="scmIntegrations"
      scmType="gitlab"
      @disconnect-s-c-m-integration="
        (e) => $emit('startIntegrationDeletion', e)
      "
      @connect-s-c-m="(e) => $emit('connectSCM', e)"
    />

    <transition-group
      tag="div"
      enterActiveClass="ease-out duration-300 transition"
      enterClass="opacity-0"
      enterToClass="opacity-100"
      leaveActiveClass="transition ease-in duration-100"
      leaveClass="opacity-100"
      leaveToClass="opacity-0"
    >
      <HostedSCMIntegration
        v-for="integration in hostedScmIntegrations"
        :key="integration.id"
        :integration="integration"
        @disconnect-s-c-m-integration="
          (e) => $emit('startIntegrationDeletion', e)
        "
      />
    </transition-group>

    <HostedIntegrationsLinks
      class="mt-4"
      @connect-hosted-s-c-m-integration="(e) => connectSCM(e)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CardTitle from "@/components/atoms/titles/CardTitle.vue";
import HostedSCMIntegration from "@/components/parts/account/integrations/HostedSCMIntegration.vue";
import SaasSCMIntegration from "@/components/parts/account/integrations/SaasSCMIntegration.vue";
import HostedIntegrationsLinks from "@/components/parts/configure/app/HostedIntegrationsLinks.vue";

export default defineComponent({
  name: "ListSCMIntegrations",
  components: {
    HostedIntegrationsLinks,
    CardTitle,
    SaasSCMIntegration,
    HostedSCMIntegration,
  },
  props: {
    scmIntegrations: Object,
  },
  emits: ["connectSCM", "startIntegrationDeletion"],
  data() {
    return {};
  },
  computed: {
    githubIntegration() {
      return this.scmIntegrations.items.find((integ) => {
        return integ.scm_type === "gitlab-self-hosted";
      });
    },
    gitlabIntegration() {
      return this.scmIntegrations.items.find((integ) => {
        return integ.scm_type === "gitlab";
      });
    },
    hostedScmIntegrations() {
      if (this.scmIntegrations.none) return [];

      return this.scmIntegrations.items.filter((integ) => {
        return (
          integ.scm_type === "github-enterprise" ||
          integ.scm_type === "gitlab-self-hosted"
        );
      });
    },
  },
  methods: {
    connectSCM(scmType) {
      this.$emit("connectSCM", { scmType });
    },
  },
});
</script>

<style scoped>
.shadow-solid {
  box-shadow: 0 0 0 4px currentColor;
}
</style>

<i18n>
en:
  title: "SCM Integrations"
  subtitle: "Connect your account to enable advanced features such as automatic deployments and review apps"
fr:
  title: "Intégrations SCM"
  subtitle: "Connectez vos comptes pour bénéficier des déploiements et reviews apps automatiques"
</i18n>
