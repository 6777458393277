<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4C7.26333 4 6.66667 4.61023 6.66667 5.36364V6.04545H10V5.36364C10 4.61023 9.40333 4 8.66667 4H8ZM15.3333 4C14.5967 4 14 4.61023 14 5.36364V6.04545H17.3333V5.36364C17.3333 4.61023 16.7367 4 16 4H15.3333ZM5.33333 7.40909C4.59667 7.40909 4 8.01932 4 8.77273V17.6364C4 18.3898 4.59667 19 5.33333 19H18.6667C19.4033 19 20 18.3898 20 17.6364V8.77273C20 8.01932 19.4033 7.40909 18.6667 7.40909H5.33333Z"
      fill="currentColor"
    />
    <circle
      cx="18"
      cy="16"
      r="5.5"
      fill="white"
      stroke="white"
    />
    <circle
      cx="18"
      cy="16"
      r="5"
      fill="currentColor"
    />
    <path
      d="M20.0382 14.9682V14.0317C20.0382 13.5936 18.9816 13.3939 17.9998 13.3939C17.018 13.3939 15.9614 13.5934 15.9614 14.0317V14.9682C15.9614 15.0765 16.0261 15.1702 16.1381 15.2499C16.0261 15.3296 15.9614 15.4234 15.9614 15.5316V16.4682C15.9614 16.5765 16.0261 16.6702 16.1381 16.7499C16.0261 16.8296 15.9614 16.9233 15.9614 17.0316V17.9682C15.9614 18.4062 17.018 18.6059 17.9998 18.6059C18.9813 18.6059 20.0382 18.4062 20.0382 17.9682V17.0316C20.0382 16.9233 19.9735 16.8296 19.8615 16.7499C19.9735 16.6702 20.0382 16.5765 20.0382 16.4682V15.5316C20.0382 15.4234 19.9735 15.3296 19.8615 15.2499C19.9733 15.1702 20.0382 15.0765 20.0382 14.9682ZM17.9998 13.6339C19.1862 13.6339 19.7982 13.9126 19.7982 14.0317C19.7982 14.0556 19.7731 14.0858 19.7244 14.1191C19.6737 14.1533 19.2795 14.3607 18.5103 14.4109C18.354 14.4212 18.1841 14.4294 18.0001 14.4294C17.4425 14.4294 17.0119 14.3678 16.7119 14.2902C16.3737 14.2028 16.2017 14.0947 16.2017 14.0317C16.2015 13.9126 16.8134 13.6339 17.9998 13.6339L17.9998 13.6339ZM19.7982 17.0317C19.7982 17.0556 19.7731 17.0858 19.7244 17.1191C19.6737 17.1533 19.2795 17.3607 18.5103 17.4109C18.354 17.4212 18.1841 17.4294 18.0001 17.4294C17.4425 17.4294 17.0119 17.3678 16.7119 17.2902C16.3737 17.2028 16.2017 17.0947 16.2017 17.0317C16.2017 16.9932 16.2664 16.9379 16.3934 16.8807C16.7919 17.0338 17.4092 17.1062 18 17.1062C18.5907 17.1062 19.2083 17.0338 19.6067 16.8807C19.7335 16.9377 19.7982 16.993 19.7982 17.0317L19.7982 17.0317ZM19.7982 15.5317C19.7982 15.5556 19.7731 15.5858 19.7244 15.6191C19.6737 15.6533 19.2795 15.8607 18.5103 15.9109C18.354 15.9212 18.1841 15.9294 18.0001 15.9294C17.4425 15.9294 17.0119 15.8678 16.7119 15.7902C16.3737 15.7028 16.2017 15.5947 16.2017 15.5317C16.2017 15.4932 16.2664 15.4379 16.3934 15.3807C16.7919 15.5338 17.4092 15.6062 18 15.6062C18.5907 15.6062 19.2083 15.5338 19.6067 15.3807C19.7335 15.4377 19.7982 15.493 19.7982 15.5317L19.7982 15.5317Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddonDatabaseGlyph",
});
</script>
