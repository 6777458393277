<template>
  <ViewComponent
    v-if="currentUser && deletionHandler"
    :deletionHandler="deletionHandler"
    @submit-deletion="(e) => deletionHandler.submit(e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/account/Deletion.vue";
import { ValidateAccountDeletionHandler } from "@/lib/handlers";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DeletionController",
  components: { ViewComponent },
  data() {
    return {
      deletionHandler: null,
    };
  },
  created() {
    const { query } = this.$route;

    if (!query.id || !query.token) {
      this.$router.push({ name: Routes.Account.Auth });
      return;
    }

    this.deletionHandler = new ValidateAccountDeletionHandler(
      this,
      query.id,
      query.token,
    );
  },
});
</script>
