import { defineStore } from "pinia";

import {
  toPublicStore,
  useCollectionStore,
} from "@/lib/pinia/use-collection-store";
import { useSessionStore } from "@/stores/session";

export const useRolesStore = defineStore("roles", () => {
  const { client } = useSessionStore();

  const store = useCollectionStore({
    fetchPromise: () => client().Users.roles(),
  });

  return toPublicStore(store, {});
});
