<template>
  <ViewComponent :app="app" />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/notifiers/Root.vue";

export default defineComponent({
  name: "NotifiersRootContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
});
</script>
