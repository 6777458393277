<template>
  <div
    class="max-w-xs w-full bg-scale-0 shadow-lg rounded-lg pointer-events-auto z-60"
  >
    <div
      class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden border border-scale-0"
    >
      <div class="p-4">
        <div class="flex items-start flex-col">
          <div class="flex items-center w-full pb-2">
            <CheckGlyph
              v-if="notification.type === 'success'"
              :size="5"
              class="text-color-1 mr-2"
            />
            <WarningSignGlyph
              v-if="notification.type === 'warning'"
              :size="5"
              class="text-secondary-4 mr-2"
            />
            <DangerSignGlyph
              v-if="notification.type === 'error'"
              :size="5"
              class="text-color-2 mr-2"
            />
            <div class="text-sm leading-5 font-medium text-scale-10 flex-grow">
              {{ notification.title }}
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button
                class="inline-flex focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                @click="$emit('notificationClosed', notification)"
              >
                <LittleCross class="text-scale-3" />
              </button>
            </div>
          </div>
          <div>
            <p
              v-if="notification.htmlMessage"
              class="mt-1 text-sm leading-5 text-scale-5"
              v-html="notification.htmlMessage"
            />
            <p
              v-else
              class="mt-1 text-sm leading-5 text-scale-5"
            >
              {{ notification.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import LittleCross from "@/components/atoms/glyphs/LittleCross.vue";
import WarningSignGlyph from "@/components/atoms/glyphs/WarningSignGlyph.vue";

export default defineComponent({
  name: "Notification",
  components: { WarningSignGlyph, DangerSignGlyph, LittleCross, CheckGlyph },
  props: {
    notification: Object,
  },
  emits: ["notificationClosed"],
});
</script>
