<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <CreditAddGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      <span>{{ $t("method") }}
        <span class="font-medium ml-1">{{
          event.type_data.payment_method
        }}</span></span>
      <span class="ml-1">- {{ $t("amount") }}
        <span class="font-medium ml-1">{{ event.type_data.amount }}</span></span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import CreditAddGlyph from "@/components/atoms/glyphs/CreditAddGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "Event",
  components: { BaseEvent, CreditAddGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Credit added"
  method: "Method:"
  amount: "Amount:"
fr:
  title: "Crédit ajouté"
  method: "Méthode :"
  montant: "Montant :"
</i18n>
