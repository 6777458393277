<template>
  <div>
    <div
      v-if="domain._detailedSSLStatus === 'error'"
      class="h-3 w-3 rounded-full bg-error"
      :title="$ta('domain', 'sslStatuses.error')"
    />
    <div
      v-if="domain._detailedSSLStatus === 'expired'"
      class="h-3 w-3 rounded-full bg-error"
      :title="$ta('domain', 'sslStatuses.expired')"
    />
    <div
      v-if="domain._detailedSSLStatus === 'pending'"
      class="h-3 w-3 rounded-full bg-pending"
      :title="$ta('domain', 'sslStatuses.pending')"
    />
    <div
      v-if="domain._detailedSSLStatus === 'manualActionRequired'"
      class="h-3 w-3 rounded-full bg-warning"
      :title="$ta('domain', 'sslStatuses.manualActionRequired')"
    />
    <div
      v-if="domain._detailedSSLStatus === 'willSoonExpire'"
      class="h-3 w-3 rounded-full bg-warning"
      :title="$ta('domain', 'sslStatuses.willSoonExpire')"
    />
    <font-awesome-icon
      v-if="domain._detailedSSLStatus === 'success'"
      icon="lock"
      class="text-success"
      :title="$ta('domain', 'sslStatuses.success')"
    />
    <div
      v-if="domain._detailedSSLStatus === 'successLetsEncrypt'"
      :title="$ta('domain', 'sslStatuses.successLetsEncrypt')"
    >
      <LogoLetsEncrypt class="h-5 w-5 -ml-1 -mt-1" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import LogoLetsEncrypt from "@/components/atoms/graphics/LogoLetsEncrypt.vue";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "DomainIndicator",
  components: { LogoLetsEncrypt },
  mixins: [ModelsTranslation],
  props: {
    domain: Object,
  },
});
</script>
