<template>
  <OpenableCartridge
    id="git-push-cartridge"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :isOpen="true"
    :isSelectable="true"
    :isSelected="isSelected"
    @cartridge-selected="cartridgeSelected"
  >
    <template #icon>
      <GitGraphic />
    </template>
    <template #content>
      <div class="px-4 flex-auto">
        <div class="font-light text-scale-7">
          {{ $t("remoteUrlLabel") }}
        </div>
        <div class="text-scale-10 font-medium mt-1">
          {{ app.git_url }}
        </div>
      </div>
    </template>
  </OpenableCartridge>
</template>

<script>
import { defineComponent } from "vue";

import GitGraphic from "@/components/atoms/graphics/GitGraphic.vue";
import OpenableCartridge from "@/components/molecules/cartridge/OpenableCartridge.vue";

export default defineComponent({
  name: "GitPushDeployCartridge",
  components: { OpenableCartridge, GitGraphic },
  props: {
    app: Object,
    state: String,
    isSelected: Boolean,
  },
  emits: ["buttonClicked", "cartridgeSelected"],
  methods: {
    buttonClicked() {
      this.$emit("buttonClicked");
    },
    cartridgeSelected() {
      this.$emit("cartridgeSelected", { cartridge: "git-push" });
    },
  },
});
</script>

<i18n>
en:
  title: "Scalingo Git server"
  subtitle: "Push from your computer to a Git server hosted by Scalingo."
  connectButton: "Connect"
  remoteUrlLabel: "Remote url"
fr:
  title: "Serveur Git de Scalingo"
  subtitle: "Pousser depuis votre ordinateur vers un serveur Git hébergé par Scalingo."
  connectButton: "Connecter"
  remoteUrlLabel: "Url distante"
</i18n>
