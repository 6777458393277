<template>
  <EmptyCardState>
    <template #icon>
      <KeyGlyph class="h-8 w-8 text-scale-5" />
    </template>
    <div class="flex flex-col text-center">
      <div class="text-lg font-bold">
        {{ $t("none.title") }}
      </div>
      <div class="mt-2">
        {{ $t("none.subtitle") }}
      </div>
    </div>
  </EmptyCardState>
</template>

<script>
import { defineComponent } from "vue";

import KeyGlyph from "@/components/atoms/glyphs/KeyGlyph.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";

export default defineComponent({
  name: "EmptySshKeys",
  components: { KeyGlyph, EmptyCardState },
});
</script>

<i18n>
en:
  none:
    title: "There are no keys here yet"
    subtitle: "SSH key will appear here once created."
fr:
  none:
    title: "Il n'y a aucune clé pour le moment"
    subtitle: "Les clés SSH apparaitront ici une fois ajoutées."
</i18n>
