<template>
  <ViewComponent
    :scmIntegrations="scmIntegrations"
    :createCtx="createCtx"
    :deleteCtx="deleteCtx"
    @connect-s-c-m="connectSCM"
    @cancel-hosted-s-c-m-connection="cancelHostedSCMConnection"
    @confirm-hosted-s-c-m-connection="confirmHostedSCMConnection"
    @start-integration-deletion="startIntegrationDeletion"
    @cancel-integration-deletion="cancelIntegrationDeletion"
    @confirm-integration-deletion="confirmIntegrationDeletion"
  />
</template>

<script>
import { APIError } from "scalingo/lib/errors";
import { defineComponent, nextTick, ref } from "vue";

import ViewComponent from "@/components/views/account/Integrations.vue";
import { renameErrors } from "@/lib/handlers/action";
import { closeCurrentModal } from "@/lib/modals";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import {
  handlingLinkingFeedback,
  performQuickOAuthLink,
} from "@/lib/scm-integrations";
import { Routes } from "@/router/names";
import { useScmIntegrationsStore } from "@/stores/scm-integrations";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "IntegrationsController",
  components: { ViewComponent },
  setup() {
    const integrationsStore = useScmIntegrationsStore();
    const toastsStore = useToastsStore();
    const createCtx = ref(null);
    const deleteCtx = ref(null);

    return { integrationsStore, toastsStore, createCtx, deleteCtx };
  },
  computed: {
    scmIntegrations() {
      const { items, promiseInfo, any, none } = this.integrationsStore;

      return { items, promiseInfo, any, none };
    },
  },
  beforeMount() {
    this.integrationsStore.ensure();

    handlingLinkingFeedback(this.$router, this.$route, this.$store, this.$i18n);
  },
  methods: {
    startIntegrationDeletion({ scmIntegration }) {
      this.deleteCtx = {
        info: voidPromiseInfo(),
        scmIntegration,
        errors: null,
      };
    },
    cancelIntegrationDeletion() {
      this.deleteCtx = null;
    },
    async confirmIntegrationDeletion() {
      const ctx = this.deleteCtx;
      const integ = ctx.scmIntegration;

      try {
        const promise = this.integrationsStore.destroy(integ);
        ctx.info = promiseInfo(promise);

        await promise;
        await nextTick();

        this.toastsStore.addOne({
          type: "success",
          title: this.$t(`delete.${integ.scm_type}.title`),
          message: this.$t(`delete.${integ.scm_type}.message`),
        });

        closeCurrentModal();
      } catch (err) {
        ctx.errors = {
          message: err.data.error,
          scmRepoLinks: err.data.scm_repo_links,
        };
      }
    },
    connectSCM({ scmType }) {
      if (scmType === "github" || scmType === "gitlab") {
        this.connectSaasSCM(scmType);
      } else {
        this.startHostedSCMConnection(scmType);
      }
    },
    connectSaasSCM(scmType) {
      const pathname = this.$router.resolve({
        name: Routes.Account.Integrations,
      }).href;

      performQuickOAuthLink(this.$store, scmType, pathname);
    },
    startHostedSCMConnection(scmType) {
      this.createCtx = { info: voidPromiseInfo(), scmType, errors: null };
    },
    cancelHostedSCMConnection() {
      this.createCtx = null;
    },
    async confirmHostedSCMConnection(payload) {
      const ctx = this.createCtx;

      const promise = this.integrationsStore.create(payload);
      ctx.info = promiseInfo(promise);

      try {
        const integ = await promise;
        await nextTick();

        this.toastsStore.addOne({
          type: "success",
          title: this.$t(`create.${integ.scm_type}.title`),
          message: this.$t(`create.${integ.scm_type}.message`),
        });

        closeCurrentModal();
      } catch (err) {
        const errors = err.data.errors;

        renameErrors(errors, { endpoint: "access_token" });

        if (err && err instanceof APIError) {
          if (err.status === 500) {
            this.errors.url = this.errors.url || [];
            this.errors.url.push("cannot be reached");
          }
        }

        ctx.errors = errors;
      }
    },
  },
});
</script>

<i18n>
en:
  create:
    github-enterprise:
      title: "Connected to GitHub"
      message: "Successful connection to your GitHub Enterprise account!"
    gitlab:
      title: "Connected to GitLab"
      message: "Successful connection to your GitLab account!"
    gitlab-self-hosted:
      title: "Connected to GitLab"
      message: "Successful connection to your GitLab self-hosted account!"
  delete:
    github:
      title: "Disconnected from GitHub"
      message: "Successful disconnection from your GitHub account"
    gitlab:
      title: "Disconnected from Gitlab"
      message: "Successful disconnection from your GitLab account"
    github-enterprise:
      title: "Disconnected from GitHub"
      message: "Successful disconnection from your GitHub Enterprise account"
    gitlab-self-hosted:
      title: "Disconnected from Gitlab"
      message: "Successful disconnection from your GitLab self-hosted account"
fr:
  create:
    github-enterprise:
      title: "Connecté à GitHub"
      message: "Connexion à votre compte GitHub Enterprise réussie !"
    gitlab:
      title: "Connecté à GitLab"
      message: "Connexion à votre compte GitLab réussie !"
    gitlab-self-hosted:
      title: "Connecté à GitLab"
      message: "Connexion à votre compte GitLab self-hosted réussie !"
  delete:
    github:
      title: "Déconnexion de GitHub"
      message: "Vous êtes bien déconnecté de votre compte GitHub"
    gitlab:
      title: "Déconnexion de Gitlab"
      message: "Vous êtes bien déconnecté de votre compte GitLab"
    github-enterprise:
      title: "Déconnexion de GitHub"
      message: "Vous êtes bien déconnecté de votre compte GitHub Enterprise"
    gitlab-self-hosted:
      title: "Déconnexion de Gitlab"
      message: "Vous êtes bien déconnecté de votre compte GitLab self-hosted"
</i18n>
