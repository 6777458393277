<template>
  <div
    :class="{
      'bg-primary-2-10': selected,
      'hover:bg-primary-2-25 cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    }"
    class="px-2 border border-scale-2 rounded text-primary-2 text-sm font-medium py-1"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageItem",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
});
</script>
