<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <KeyEditGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("named") }}
      <span class="font-medium ml-1">{{ event.type_data.name }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import KeyEditGlyph from "@/components/atoms/glyphs/KeyEditGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "Event",
  components: { BaseEvent, KeyEditGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Key edited"
  named: "Named:"
fr:
  title: "Clé editée"
  named: "Nommé :"
</i18n>
