<template>
  <main>
    <SectionTitle
      class="flex-grow pt-6"
      :title="$t('title')"
      :withCount="true"
      :count="appInfos.length || 0"
    />

    <template v-if="appInfos.promiseInfo.isLoading">
      <LoadingCardState />
    </template>

    <AppsCards
      v-if="appInfos.promiseInfo.isFinished"
      :appInfos="appInfos"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SectionTitle from "@/components/atoms/titles/SectionTitle.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import AppsCards from "@/components/parts/app/listApps/ListAppsCards.vue";

export default defineComponent({
  name: "AppDetection",
  components: { AppsCards, LoadingCardState, SectionTitle },
  props: {
    appInfos: Object,
  },
});
</script>

<i18n>
  en:
    title: "Matching apps"
  fr:
    title: "Matching apps"
</i18n>
