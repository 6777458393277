<template>
  <div class="flex justify-center">
    <div class="border-scale-3 border-l-2 h-8" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardSeparator",
});
</script>
