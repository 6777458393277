<template>
  <SimpleModal
    size="w-1/4"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template #body>
      <Form
        ref="form"
        v-slot="{ meta, handleSubmit }"
        as=""
      >
        <form
          class="mt-2 pt-6 border-t-2 border-scale-1"
          @submit.prevent="handleSubmit(submit)"
        >
          <AdvancedSelectInput
            v-model="branch"
            :options="branchesOptions"
            :optionsAreLoading="branchesAreLoading"
          >
            <template #label>
              {{ $t("branches.label") }}
            </template>

            <template #optionsAreLoading>
              {{ $t("branches.loading") }}
            </template>

            <template #disabledPrompt>
              {{ $t("branches.disabled") }}
            </template>

            <template #prompt>
              {{ $t("branches.prompt") }}
            </template>

            <template #text="{ option }">
              {{ option.text }}
            </template>
          </AdvancedSelectInput>

          <div class="flex mt-4 flex-col">
            <div />
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-4"
              :disabled="!meta.valid"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";

export default defineComponent({
  name: "EnableAutoDeployModal",
  components: {
    SimpleModal,
    SCButton,
    Form,
    AdvancedSelectInput,
  },
  props: {
    scmBranches: {
      type: Array,
      default: () => [],
    },
    listBranchesInfo: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
  data() {
    return { branch: "" };
  },
  computed: {
    branchesOptions() {
      return this.scmBranches.map((branch) => {
        return { text: branch.name, value: branch.name };
      });
    },
    branchesAreLoading() {
      return this.listBranchesInfo?.isLoading || false;
    },
  },
  methods: {
    submit() {
      this.$emit("formSubmitted", { form: { branch: this.branch } });
      this.$emit("modalClosed");
    },
  },
});
</script>

<i18n>
en:
  title: "Automatic deployments"
  subtitle: "Enable automatic deployments from a branch"
  buttonName: "Enable"
  branches:
    label: "Branch"
    loading: "Loading branches..."
    prompt: "Select a branch"
    disabled: "Select a repository first"
fr:
  title: "Déploiements automatiques"
  subtitle: "Activer les déploiements automatiques à partir d'une branche"
  buttonName: "Activer"
  branches:
    label: "Branche"
    loading: "Chargement des branches..."
    prompt: "Sélectionner une branche"
    disabled: "Sélectionner un dépôt"
</i18n>
