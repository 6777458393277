<template>
  <main>
    <DividedSectionTitle
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    >
      <DateSelector
        :selectedValue="since"
        :displayInFullScreen="displayInFullScreen"
        @range-selected="(e) => $emit('rangeSelected', e)"
        @full-screen-clicked="toggleFullScreen"
      />
    </DividedSectionTitle>

    <section class="bg-scale-0 pt-2 mt-6 theme-light">
      <RequestsGraphsCard
        :rpmOperation="rpmOperation"
        :responseTimeOperation="responseTimeOperation"
        :eventsAnnotations="eventsAnnotations"
      />

      <div
        v-for="(ops, name) in containersOperations"
        :key="name"
      >
        <ContainerGraphsCard
          :container="findContainer(name)"
          :containerSizes="containerSizes"
          :displayInFullScreen="displayInFullScreen"
          :cpuOperation="ops.cpu"
          :memoryOperation="ops.memory"
          :eventsAnnotations="eventsAnnotations"
        />
      </div>
    </section>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import DividedSectionTitle from "@/components/atoms/titles/DividedSectionTitle.vue";
import ContainerGraphsCard from "@/components/parts/app/metrics/ContainerGraphsCard.vue";
import DateSelector from "@/components/parts/app/metrics/DateSelector.vue";
import RequestsGraphsCard from "@/components/parts/app/metrics/RequestsGraphsCard.vue";
import FullscreenableComponent from "@/mixins/fullscreenable_component";

export default defineComponent({
  name: "ApexMetricsOverview",
  components: {
    DateSelector,
    DividedSectionTitle,
    RequestsGraphsCard,
    ContainerGraphsCard,
  },
  mixins: [FullscreenableComponent],
  props: {
    app: Object,
    user: Object,
    containers: Object,
    containerSizes: Object,
    since: [String, Number],
    rpmOperation: Object,
    responseTimeOperation: Object,
    containersOperations: Object,
    eventsAnnotations: Array,
  },
  emits: ["rangeSelected"],
  methods: {
    findContainer(name) {
      return this.containers.items.find((c) => c.name === name);
    },
  },
});
</script>

<i18n>
  en:
    title: "Metrics"
    subtitle: "Analyse key indicators of performance and consumption."
  fr:
    title: "Métriques"
    subtitle: "Analyse des indicateurs clés de performance et de consommation."
</i18n>
