<template>
  <SimpleModal
    :hasBorder="true"
    size="w-2/3 lg:w-1/2 xl:w-1/3"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      <div class="flex flex-grow items-center py-4">
        <GitHubGraphic v-if="scmType === 'github-enterprise'" />
        <GitLabGraphic v-if="scmType === 'gitlab-self-hosted'" />

        <div class="px-4 text-lg font-bold">
          {{ title }}
        </div>
      </div>
    </template>

    <template #body>
      <template v-if="scmType === 'github-enterprise'">
        <p class="mt-4">
          {{ $t("github.instructions.url") }}
          <code class="text-sm">https://github.example.dev</code>
        </p>

        <p class="mt-2">
          {{ $t("github.instructions.token") }}

          <DocLink
            href="https://help.github.com/articles/creating-a-personal-access-token-for-the-command-line"
          >
            {{ $t("github.instructions.doc") }}
          </DocLink>
        </p>

        <p class="mt-2">
          {{ $t("github.instructions.rights") }}

          <code class="text-sm">repo</code>.
        </p>
      </template>

      <template v-else-if="scmType === 'gitlab-self-hosted'">
        <p class="mt-4">
          {{ $t("gitlab.instructions.url") }}

          <code class="text-sm">https://gitlab.example.dev</code>
        </p>

        <p class="mt-2">
          {{ $t("gitlab.instructions.token") }}
          <DocLink
            href="https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html"
          >
            {{ $t("gitlab.instructions.doc") }}
          </DocLink>
        </p>

        <p class="mt-2">
          {{ $t("gitlab.instructions.rights") }}
          <code class="text-sm">api,read_user</code>
          {{ $t("gitlab.instructions.roles") }}

          <DocLink
            href="https://docs.gitlab.com/ee/user/permissions.html#project-members-permissions"
          >
            {{ $t("gitlab.instructions.docLink") }}
          </DocLink>
        </p>
      </template>

      <Form
        ref="form"
        v-slot="{ meta, handleSubmit }"
        as=""
      >
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex flex-col">
            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.url"
                name="url"
                rules="required|url"
                mode="eager"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('scmIntegration', 'url')"
                  labelClass="uppercase"
                  :errors="errors"
                  @update:model-value="handleChange"
                />
              </Field>
            </div>
            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.access_token"
                name="access_token"
                rules="required"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$ta('scmIntegration', 'accessToken')"
                  labelClass="capitalize"
                  :errors="errors"
                  @update:model-value="handleChange"
                />
              </Field>
            </div>

            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-8"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("action") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import GitHubGraphic from "@/components/atoms/graphics/GitHubGraphic.vue";
import GitLabGraphic from "@/components/atoms/graphics/GitLabGraphic.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "HostedSCMModal",
  components: {
    SimpleModal,
    GitHubGraphic,
    GitLabGraphic,
    TextInput,
    SCButton,
    DocLink,
    Field,
    Form,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  props: {
    scmType: String,
  },
  emits: ["formSubmitted", "modalClosed"],
  computed: {
    title() {
      if (this.scmType === "github-enterprise") {
        return this.$t("github.title");
      } else if (this.scmType === "gitlab-self-hosted") {
        return this.$t("gitlab.title");
      }

      return null;
    },
    subtitle() {
      if (this.scmType === "github-enterprise") {
        return this.$t("github.subtitle");
      } else if (this.scmType === "gitlab-self-hosted") {
        return this.$t("gitlab.subtitle");
      }

      return null;
    },
  },
});
</script>

<i18n>
en:
  action: "Connect"
  github:
    title: "Connect to a GitHub Enterprise"
    instructions:
      url: "Fill with the domain used to access your Github Enteprise, eg. "
      token: "The token you need is a personal access token: "
      doc: "here is the GitHub documentation on how to create one"
      rights: "The only scope you need on Scalingo is "
  gitlab:
    title: "Connect to a self-hosted Gitlab"
    instructions:
      url: "Fill with the domain used to access your GitLab instance, eg. "
      token: "The token you need is a personal access token: "
      doc: "here is the GitLab documentation on how to create one"
      rights: "The scopes you need on Scalingo are "
      roles: ". Your user account must also have "
      docLink: "a sufficient role, maintainer or owner"
fr:
  action: "Connecter"
  github:
    title: "Connexion a un GitHub Enterprise"
    instructions:
      url: "Remplissez avec le nom de domaine utilisé par votre GitHub Enterprise, par exemple "
      token: |
        Le token nécessaire est un "personal access token" :
      doc: "voici la documentation GitHub sur comment en créer un"
      rights: "Le seul scope nécessaire pour Scalingo est "
  gitlab:
    title: "Connexion a une instance GitLab"
    instructions:
      url: "Remplissez avec le nom de domaine utilisé par votre instance GitLab, par exemple "
      token: |
        Le token nécessaire est un "personal access token" :
      doc: "voici la documentation GitLab sur comment en créer un"
      rights: "Les scopes nécessaires pour Scalingo sont "
      roles: ". Votre compte doit également avoir "
      docLink: "un rôle tel quel maintainer ou owner"
</i18n>
