<template>
  <div class="bg-scale-0 rounded px-8 py-6 flex flex-col">
    <Form
      ref="form"
      v-slot="{ meta, handleSubmit }"
      as=""
    >
      <form @submit.prevent="handleSubmit(submitForm)">
        <CardHeader
          :title="$t('title')"
          :subtitle="$t('subtitle')"
        >
          <template #buttons>
            <SCButton
              type="submit"
              :disabled="!meta.valid"
              :loading="isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </template>
        </CardHeader>
        <div class="mt-6">
          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.current_password"
            name="current_password"
            rules="required"
          >
            <PasswordInput
              :name="field.name"
              :modelValue="field.value"
              :label="$t('currentPasswordLabel')"
              :errors="errors"
              @update:model-value="handleChange"
            />
          </Field>
          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.password"
            name="password"
            rules="required"
          >
            <PasswordInput
              :name="field.name"
              :modelValue="field.value"
              :label="$t('newPasswordLabel')"
              :errors="errors"
              @update:model-value="handleChange"
            />
          </Field>
          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.password_confirmation"
            name="password_confirmation"
            rules="required|passwordConfirmation:@password"
          >
            <PasswordInput
              :name="field.name"
              :modelValue="field.value"
              :label="$t('confirmationPasswordLabel')"
              :errors="errors"
              @update:model-value="handleChange"
            />
          </Field>
        </div>
      </form>
    </Form>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import PasswordInput from "@/components/molecules/inputs/PasswordInput.vue";

export default defineComponent({
  name: "ChangePasswordForm",
  components: {
    SCButton,
    PasswordInput,
    CardHeader,
    Field,
    Form,
  },
  props: {
    formHandler: Object,
  },
  emits: ["formSubmitted"],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    isSubmitting() {
      return this.formHandler?.isSubmitting || false;
    },
  },
  watch: {
    formHandler: {
      immediate: true,
      handler(newVal) {
        newVal?.initComponent(this);
        this.$refs.form?.reset();
      },
    },
  },
  methods: {
    submitForm() {
      this.$emit("formSubmitted", this.form);
    },
  },
});
</script>

<i18n>
en:
  title: "Change password"
  subtitle: "Update your password to another one"
  buttonName: "Update password"
  currentPasswordLabel: "Current password"
  newPasswordLabel: "New password"
  confirmationPasswordLabel: "Confirm password"
fr:
  title: "Changer le mot de passe"
  subtitle: "Mettre à jour votre mot de passe pour un autre"
  buttonName: "Mettre à jour le mot de passe"
  currentPasswordLabel: "Mot de passe actuel"
  newPasswordLabel: "Nouveau mot de passe"
  confirmationPasswordLabel: "Confirmer le mot de passe"
</i18n>
