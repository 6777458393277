<template>
  <VerticalPageMenu :items="menuItems" />
</template>

<script>
import { defineComponent } from "vue";

import VerticalPageMenu from "@/components/molecules/menus/VerticalPageMenu.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "AppSettingsMenu",
  components: { VerticalPageMenu },
  props: {
    user: Object,
    selection: String,
    app: Object,
  },
  computed: {
    selectedRoutes() {
      if (this.selection) {
        return [this.selection];
      }

      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems: function () {
      const routerParams = { id: this.app.name };

      const menuItems = [
        {
          name: this.$t("collaborators"),
          key: Routes.App.Collaborators,
          routerArgs: { name: Routes.App.Collaborators, params: routerParams },
        },
        {
          name: this.$t("domains"),
          key: Routes.App.Domains.Root,
          routerArgs: { name: Routes.App.Domains.Root, params: routerParams },
        },
        {
          name: this.$t("notifiers"),
          key: Routes.App.Notifiers.Root,
          routerArgs: { name: Routes.App.Notifiers.Root, params: routerParams },
        },
        {
          name: this.$t("alerts"),
          key: Routes.App.Alerts.Root,
          routerArgs: { name: Routes.App.Alerts.Root, params: routerParams },
        },
        {
          name: this.$t("routing"),
          key: Routes.App.Routing,
          routerArgs: { name: Routes.App.Routing, params: routerParams },
        },
        {
          name: this.$t("management"),
          key: Routes.App.Management,
          routerArgs: { name: Routes.App.Management, params: routerParams },
        },
      ];

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.key)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
  en:
    collaborators: "Collaborators"
    domains: "Domains / SSL"
    notifiers: "Notifiers"
    alerts: "Alerts"
    routing: "Routing"
    management: "App management"
  fr:
    collaborators: "Collaborateurs"
    domains: "Domaines / SSL"
    notifiers: "Notifieurs"
    alerts: "Alertes"
    routing: "Routing"
    management: "Gestion de l'app"
</i18n>
