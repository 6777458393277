<template>
  <SimpleCartridge
    :title="title"
    :subtitle="subtitle"
    @click="$emit('platformSelected', { platform })"
  >
    <template #icon>
      <SVGPlatformEmail
        v-if="platform.name === 'email'"
        class="m-2"
      />
      <SVGPlatformSlack
        v-if="platform.name === 'slack'"
        class="m-2"
      />
      <SVGPlatformWebhook
        v-if="platform.name === 'webhook'"
        class="m-2"
      />
      <SVGPlatformRocketChat
        v-if="platform.name === 'rocket_chat'"
        class="m-2"
      />
    </template>

    <template #action>
      <LinkButton>
        {{ $t("actions.add") }}
      </LinkButton>
    </template>
  </SimpleCartridge>
</template>

<script>
import { defineComponent } from "vue";

import LinkButton from "@/components/atoms/buttons/LinkButton.vue";
import SVGPlatformEmail from "@/components/graphics/platforms/Email.vue";
import SVGPlatformRocketChat from "@/components/graphics/platforms/RocketChat.vue";
import SVGPlatformSlack from "@/components/graphics/platforms/Slack.vue";
import SVGPlatformWebhook from "@/components/graphics/platforms/Webhook.vue";
import SimpleCartridge from "@/components/molecules/cartridge/SimpleCartridge.vue";

export default defineComponent({
  name: "NotifierPlatformCartridge",
  components: {
    SimpleCartridge,
    LinkButton,
    SVGPlatformEmail,
    SVGPlatformSlack,
    SVGPlatformWebhook,
    SVGPlatformRocketChat,
  },
  props: {
    platform: Object,
  },
  emits: ["platformSelected"],
  computed: {
    title() {
      return this.$t(`${this.platform.name}.title`);
    },
    subtitle() {
      return this.$t(`${this.platform.name}.subtitle`);
    },
  },
});
</script>

<i18n>
en:
  email:
    title: "Email"
    subtitle: "Send notification emails"
  rocket_chat:
    title: "Rocket.Chat"
    subtitle: "Send notifications through Rocket.Chat"
  slack:
    title: "Slack"
    subtitle: "Send notifications to slack channels"
  webhook:
    title: "Webhook"
    subtitle: "Send a POST request to a url"
  actions:
    add: "Add"
fr:
  email:
    title: "Email"
    subtitle: "Envoyer des emails de notification"
  rocket_chat:
    title: "Rocket.Chat"
    subtitle: "Envoyer les notifications via Rocket.Chat"
  slack:
    title: "Slack"
    subtitle: "Envoyer les notifications à des canaux slacks"
  webhook:
    title: "Webhook"
    subtitle: "Envoyer des requêtes POST vers une url"
  actions:
    add: "Ajouter"
</i18n>
