<template>
  <div class="flex">
    <div class="pl-3 self-center text-2xl font-bold flex flex-row items-center">
      <slot />
    </div>

    <div
      v-if="withSeparator"
      class="pl-3 mt-1 text-scale-2"
    >
      <svg
        width="16"
        height="30"
        viewBox="0 0 16 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5277 1.64143C11.8136 0.950546 12.4876 0.5 13.2353 0.5C14.5521 0.5 15.4464 1.83791 14.9429 3.05463L4.47232 28.3586C4.18643 29.0495 3.5124 29.5 2.7647 29.5C1.44793 29.5 0.553618 28.1621 1.05709 26.9454L11.5277 1.64143Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BreadcrumbItem",
  props: {
    withSeparator: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
