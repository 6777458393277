<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <div
        class="border border-scale-6 rounded-full h-6 w-6 border-dashed"
      />
      <div class="text-scale-7 pl-2 font-bold">
        {{ type }}
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "GenericEvent",
  components: { BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      let s = this.event.type;
      s = s.replace("_", " ");
      return s[0].toUpperCase() + s.slice(1);
    },
    title() {
      if (
        !this.event.type_data.command ||
        this.event.type_data.command === ""
      ) {
        return this.event.type_data;
      }

      return this.event.type_data.command;
    },
  },
  created() {
    const dbFeaturesEvents = [
      "database/add_feature",
      "database/remove_feature",
    ];

    let msg = `Unsupported event type: ${this.event.type}`;

    if (dbFeaturesEvents.includes(this.event.type)) {
      msg = `${msg} - ${this.event.type_data.feature}`;
    }

    this.$rollbar.error(msg, { event: this.event });
  },
});
</script>
