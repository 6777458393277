<template>
  <DeleteModal
    :formHandler="formHandler"
    @form-submitted="$emit('formSubmitted')"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>

    <template #question>
      {{ $t("question") }}
    </template>

    <template #button>
      <div>
        {{ $t("button") }}
        <span>{{ formHandler.domain.name }}</span>
      </div>
    </template>
  </DeleteModal>
</template>

<script>
import { defineComponent } from "vue";

import DeleteModal from "@/components/molecules/modalsCollection/DeleteModal.vue";

export default defineComponent({
  name: "RemoveDomainModal",
  components: { DeleteModal },
  props: {
    formHandler: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
});
</script>

<i18n>
en:
  title: "Delete a domain"
  question: "Do you really want delete this domain?"
  button: "Delete"
fr:
  title: "Supprimer un domaine"
  question: "Souhaitez-vous vraiment supprimer ce domaine ?"
  button: "Supprimer"
</i18n>
