<template>
  <SimpleModal
    :hasBorder="true"
    size="w-1/4"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      <div class="flex flex-grow py-4">
        <div class="pl-4">
          <GitHubGraphic v-if="githubSCM" />
          <GitLabGraphic v-if="gitlabSCM" />
        </div>
        <div class="flex items-center px-4">
          <div class="flex flex-col">
            <div class="text-scale-9 text-lg font-bold">
              {{ title }}
            </div>
            <div class="text-scale-7">
              {{ subtitle }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="mt-4">
        <div>
          <AdvancedSelectInput
            ref="organizationDropdown"
            :options="organizationOptions"
            :optionsAreLoading="orgsAreLoading"
            :modelValue="form.organization"
            @update:model-value="organizationSelected"
          >
            <template #label>
              {{ $t("orgs.label") }}
            </template>

            <template #optionsAreLoading>
              {{ $t("orgs.loading") }}
            </template>

            <template #prompt>
              {{ $t("orgs.prompt") }}
            </template>

            <template #picture="{ option }">
              <img
                :src="option.picture"
                alt=""
                class="rounded-full"
              >
            </template>

            <template #text="{ option }">
              {{ option.text }}
            </template>
          </AdvancedSelectInput>
        </div>

        <div class="mt-4">
          <AdvancedSelectInput
            :options="repositoryOptions"
            :optionsAreLoading="reposAreLoading"
            :modelValue="form.repository"
            :disabled="!form.organization"
            :searchable="true"
            @update:model-value="repositorySelected"
            @search="
              (term) =>
                $emit('scmRepoSearched', { term, org: form.organization })
            "
          >
            <template #label>
              {{ $t("repos.label") }}
            </template>

            <template #optionsAreLoading>
              {{ $t("repos.loading") }}
            </template>

            <template #disabledPrompt>
              {{ $t("repos.disabled") }}
            </template>

            <template #prompt>
              {{ $t("repos.prompt") }}
            </template>

            <template #text="{ option }">
              {{ option.text }}
            </template>
          </AdvancedSelectInput>
        </div>
        <div>
          <Toggle
            v-model="form.autoDeploy"
            class="mt-4"
          >
            {{
              $t("autoDeployLabel")
            }}
          </Toggle>
        </div>
        <div
          v-if="form.autoDeploy"
          class="mt-4"
        >
          <AdvancedSelectInput
            :options="branchesOptions"
            :optionsAreLoading="branchesAreLoading"
            :modelValue="form.branch"
            :disabled="!form.repository"
            :searchable="true"
            @update:model-value="branchSelected"
          >
            <template #label>
              {{ $t("branches.label") }}
            </template>

            <template #optionsAreLoading>
              {{ $t("branches.loading") }}
            </template>

            <template #disabledPrompt>
              {{ $t("branches.disabled") }}
            </template>

            <template #prompt>
              {{ $t("branches.prompt") }}
            </template>

            <template #text="{ option }">
              {{ option.text }}
            </template>
          </AdvancedSelectInput>
        </div>
        <div class="flex flex-col mt-4">
          <FormAlert v-if="isRepoLinkFailing">
            <template #text>
              {{ scmErrors }}
            </template>
          </FormAlert>

          <SCButton
            block
            kind="primary"
            size="lg"
            class="flex-grow mt-2"
            :disabled="!canSubmit"
            :loading="isSubmitting"
            @click="$emit('submit', { form })"
          >
            Continue
          </SCButton>

          <SCButton
            block
            kind="neutral"
            size="lg"
            class="flex-grow mt-2"
            @click="$emit('modalClosed')"
          >
            Cancel
          </SCButton>
        </div>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
import { sortBy } from "lodash";
import { defineComponent } from "vue";

import GitHubGraphic from "@/components/atoms/graphics/GitHubGraphic.vue";
import GitLabGraphic from "@/components/atoms/graphics/GitLabGraphic.vue";
import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import Toggle from "@/components/molecules/toggles/SmallToggle.vue";

export default defineComponent({
  name: "SCMLinkModal",
  components: {
    Toggle,
    SCButton,
    AdvancedSelectInput,
    FormAlert,
    GitHubGraphic,
    GitLabGraphic,
    SimpleModal,
  },
  props: {
    currentSCMIntegration: Object,
    scmOrgs: {
      type: Array,
      default: () => [],
    },
    hasMoreOrgs: {
      type: Boolean,
      default: false,
    },
    scmRepos: {
      type: Array,
      default: () => [],
    },
    scmBranches: {
      type: Array,
      default: () => [],
    },
    repoLinkOperation: Object,
    listOrgsInfo: Object,
    listReposInfo: Object,
    listBranchesInfo: Object,
  },
  emits: [
    "modalClosed",
    "scmBranchSelected",
    "scmOrgSelected",
    "scmRepoSearched",
    "scmRepoSelected",
    "submit",
    "loadMoreOrganizations",
  ],
  data() {
    return {
      form: {
        organization: "",
        repository: "",
        branch: "",
        autoDeploy: true,
      },
    };
  },
  computed: {
    scmType() {
      return this.currentSCMIntegration.scm_type;
    },
    githubSCM() {
      return this.scmType === "github" || this.scmType === "github-enterprise";
    },
    gitlabSCM() {
      return this.scmType === "gitlab" || this.scmType === "gitlab-self-hosted";
    },
    title() {
      if (this.githubSCM) {
        return this.$t("github.title");
      } else if (this.gitlabSCM) {
        return this.$t("gitlab.title");
      }

      return null;
    },
    subtitle() {
      if (this.githubSCM) {
        return this.$t("github.subtitle");
      } else if (this.gitlabSCM) {
        return this.$t("gitlab.subtitle");
      }

      return null;
    },
    canSubmit() {
      const orgFilled = this.form.organization && this.form.organization !== "";
      const repoFilled = this.form.repository && this.form.repository !== "";
      const branchFilled = this.form.autoDeploy
        ? this.form.branch && this.form.branch !== ""
        : true;

      return orgFilled && repoFilled && branchFilled;
    },
    isSubmitting() {
      return this.repoLinkOperation?.isLoading || false;
    },
    isRepoLinkFailing() {
      return this.repoLinkOperation?.isError || false;
    },
    orgsAreLoading() {
      return this.listOrgsInfo?.isLoading || false;
    },
    reposAreLoading() {
      return this.listReposInfo?.isLoading || false;
    },
    branchesAreLoading() {
      return this.listBranchesInfo?.isLoading || false;
    },
    organizationOptions() {
      const options = [];

      if (this.currentSCMIntegration) {
        options.push({
          text: this.currentSCMIntegration.username,
          value: this.currentSCMIntegration.username,
          picture: this.currentSCMIntegration.avatar_url,
        });
      }

      this.scmOrgs.forEach((org) => {
        options.push({
          text: org.login,
          value: org.login,
          picture: org.avatarUrl,
        });
      });

      if (this.hasMoreOrgs && !this.orgsAreLoading) {
        options.push({
          text: this.$t("orgs.loadMore"),
          value: "__load_more__",
          loadMore: true,
        });
      }

      return options;
    },
    repositoryOptions() {
      return sortBy(this.scmRepos, [(o) => o.name.toLowerCase()]).map(
        (repo) => {
          return { text: repo.name, value: repo.url };
        },
      );
    },
    branchesOptions() {
      return sortBy(this.scmBranches, [(o) => o.name.toLowerCase()]).map(
        (branch) => {
          return { text: branch.name, value: branch.name };
        },
      );
    },
    scmErrors() {
      const errData = this.repoLinkOperation?.err?.data;
      return (
        errData.errors?.map((error) => error.message)?.join(", ") ||
        errData.error
      );
    },
  },
  watch: {
    "form.organization": function (scmOrg) {
      if (scmOrg) {
        this.$emit("scmOrgSelected", { scmOrg });
      }
    },
    "form.repository": function (scmRepo) {
      if (scmRepo) {
        this.$emit("scmRepoSelected", { scmRepo });
      }
    },
    "form.branch": function (scmBranch) {
      if (scmBranch) {
        this.$emit("scmBranchSelected", { scmBranch });
      }
    },
  },
  methods: {
    organizationSelected(e) {
      if (e == "__load_more__") {
        this.$emit("loadMoreOrganizations");
        this.reopenDropdown();
      } else {
        // Regular organization selection
        this.form.organization = e;
        this.form.repository = null;
        this.form.branch = null;
      }
    },
    repositorySelected(e) {
      this.form.repository = e;
      this.form.branch = null;
    },
    branchSelected(e) {
      this.form.branch = e;
    },
    reopenDropdown() {
      const dropdown = this.$refs.organizationDropdown;
      if (dropdown && typeof dropdown.toggleOptionsDropdown === "function") {
        dropdown.toggleOptionsDropdown();
      }
    },
  },
});
</script>

<i18n>
en:
  github:
    title: "GitHub deployment"
    subtitle: "Select your GitHub settings"
  gitlab:
    title: "Gitlab deployment"
    subtitle: "Select your Gitlab settings"
  orgs:
    label: "Organization"
    loading: "Loading organizations..."
    prompt: "Select an organization"
    loadMore: "Load More ..."
  repos:
    label: "Repository"
    loading: "Loading repositories..."
    prompt: "Select a repository"
    disabled: "Select an organization first"
  branches:
    label: "Branch"
    loading: "Loading branches..."
    prompt: "Select a branch"
    disabled: "Select a repository first"
  autoDeployLabel: "Enable automatic deploys"
fr:
  github:
    title: "Déploiements GitHub"
    subtitle: "Sélectionner vos paramètres GitHub"
  gitlab:
    title: "Déploiements Gitlab"
    subtitle: "Sélectionner vos paramètres Gitlab"
  orgs:
    label: "Organization"
    loading: "Loading organizations..."
    prompt: "Select an organization"
    loadMore: "Charger Plus ..."
  repos:
    label: "Repository"
    loading: "Loading repositories..."
    prompt: "Select a repository"
    disabled: "Select an organization first"
  branches:
    label: "Branch"
    loading: "Loading branches..."
    prompt: "Select a branch"
    disabled: "Select a repository first"
  autoDeployLabel: "Activer le déploiement automatique"
</i18n>
