<template>
  <SideMenuLayout>
    <template #side>
      <SideMenu
        :app="app"
        :user="user"
        :db="db"
      />
    </template>
    <template #router>
      <router-view :app="app" />
    </template>
  </SideMenuLayout>
</template>

<script>
import { defineComponent } from "vue";

import SideMenuLayout from "@/components/layouts/SideMenuLayout.vue";
import SideMenu from "@/components/organisms/menus/DbSettingsMenu.vue";

export default defineComponent({
  name: "SettingsRoot",
  components: {
    SideMenuLayout,
    SideMenu,
  },
  props: {
    app: Object,
    user: Object,
    db: Object,
  },
  data: function () {
    return {};
  },
});
</script>
