<template>
  <main class="container px-4">
    <section class="mt-8">
      <ButtonedSectionTitle
        v-if="!displayInFullScreen"
        :title="$t('title')"
        :subtitle="$t('subtitle')"
        :buttonName="$t('viewArchives')"
        :buttonRouteArgs="viewArchiveLink"
      />
      <LogsWindow
        :logs="logs"
        :fullscreen="displayInFullScreen"
        :streamingEnabled="streamLogs"
        :fontSize="logsFontSize"
        class="mt-6"
        @streaming-toggled="streamingToggled"
        @full-screen-clicked="toggleFullScreen"
        @font-size-changed="(e) => $emit('fontSizeChanged', e)"
        @clear-clicked="$emit('clearClicked')"
      />
      <ProTipLogsViaCLI
        v-if="!displayInFullScreen"
        class="mt-4"
        :app="app"
        :db="db"
      />
    </section>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import ButtonedSectionTitle from "@/components/molecules/titles/ButtonedSectionTitle.vue";
import ProTipLogsViaCLI from "@/components/organisms/alerts/ProTipLogsViaCLI.vue";
import LogsWindow from "@/components/parts/app/showLogs/ShowLogsLogs.vue";
import FullscreenableComponent from "@/mixins/fullscreenable_component";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DbLogsLive",
  components: {
    ProTipLogsViaCLI,
    LogsWindow,
    ButtonedSectionTitle,
  },
  mixins: [FullscreenableComponent],
  props: {
    app: Object,
    user: Object,
    db: Object,
    logs: Array,
    streamLogs: Boolean,
    logsFontSize: String,
  },
  emits: ["clearClicked", "fontSizeChanged", "streamingToggled"],
  computed: {
    viewArchiveLink() {
      return {
        name: Routes.Db.Logs.Archives,
        params: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      };
    },
  },
  methods: {
    streamingToggled(eventArgs) {
      this.$emit("streamingToggled", eventArgs);
    },
  },
});
</script>

<i18n>
  en:
    title: "Logs"
    subtitle: "Real time logs."
    viewArchives: "View archives"
  fr:
    title: "Logs"
    subtitle: "Logs temps réel."
    viewArchives: "Voir archives"
</i18n>
