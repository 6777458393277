<template>
  <main>
    <BalanceCard
      :billingProfile="billingProfile"
      @add-paypal-credit="$emit('paypalModalOpened')"
    />

    <PaypalFormModal
      v-if="paypalCreditHandler"
      :formHandler="paypalCreditHandler"
      @form-submitted="(e) => $emit('paypalFormSubmitted', e)"
      @modal-closed="$emit('paypalModalClosed')"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BalanceCard from "@/components/parts/billing/showBilling/BalanceCard.vue";
import PaypalFormModal from "@/components/parts/billing/showBilling/PaypalFormModal.vue";

export default defineComponent({
  name: "Balance",
  components: {
    BalanceCard,
    PaypalFormModal,
  },
  props: {
    user: Object,
    billingProfile: Object,
    paypalCreditHandler: Object,
  },
  emits: ["paypalFormSubmitted", "paypalModalClosed", "paypalModalOpened"],
});
</script>
