<template>
  <div class="flex flex-col items-center pt-6">
    <div
      class="bg-scale-1 h-20 w-20 rounded-full flex items-center justify-center"
    >
      <slot name="icon" />
    </div>
    <span class="text-scale-6 pt-3">
      <slot />
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmptyCardState",
});
</script>
