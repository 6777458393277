<template>
  <ViewComponent
    :user="currentUser"
    :supportPlan="supportPlan"
  />
</template>

<script>
import ViewComponent from "@/components/views/billing/SupportPlans.vue";
import { userHasFlag } from "@/store/user";

export default {
  name: "SupportPlans",
  components: {
    ViewComponent,
  },
  computed: {
    supportPlan() {
      if (userHasFlag(this.currentUser, "emergency-support")) {
        return "business";
      }
      if (userHasFlag(this.currentUser, "priority-support")) {
        return "starter";
      }

      return "basic";
    },
  },
};
</script>
