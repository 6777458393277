<template>
  <SCTable
    class="mt-6"
    layout="fixed"
    :headers="headers"
    :items="databases"
  >
    <template #item.name="{ item }">
      <div class="flex flex-grow place-items-center">
        <span class="flex flex-row items-center">
          <div
            v-if="item.protected"
            class="mr-2"
            :title="$t('lock')"
          >
            <MySQLProtectedDatabaseGlyph class="h-5 text-scale-5" />
          </div>
          {{ item.name }}
        </span>
      </div>
    </template>

    <template #item.actions="{ item }">
      <HorizontalDottedDropdown
        :actions="actionsFor(item)"
        class="flex justify-end"
        @action="actionClicked"
      />
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import MySQLProtectedDatabaseGlyph from "@/components/atoms/glyphs/MySQLProtectedDatabaseGlyph.vue";
import HorizontalDottedDropdown from "@/components/molecules/dropdowns/HorizontalDottedDropdown.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";

export default defineComponent({
  name: "DatabasesList",
  components: {
    SCTable,
    MySQLProtectedDatabaseGlyph,
    HorizontalDottedDropdown,
  },
  props: {
    databases: Array,
  },
  emits: ["startDatabaseDeletion", "startDatabaseEmptying"],
  data() {
    return {
      headers: [
        { value: "name", text: this.$t("headers.name"), class: "w-64" },
        { value: "actions", text: "" },
      ],
    };
  },
  methods: {
    actionsFor(database) {
      const actions = [];
      const wrapperClass = "leading-5";

      actions.push({
        wrapperClass,
        text: this.$t("actions.empty"),
        key: "empty",
        target: database,
      });

      if (!database.protected) {
        actions.push({
          wrapperClass,
          text: this.$t("actions.drop"),
          key: "drop",
          kind: "warning",
          target: database,
        });
      }

      return actions;
    },
    actionClicked({ action }) {
      if (action.key === "drop") {
        this.$emit("startDatabaseDeletion", {
          mysql_database: action.target,
        });
        return;
      } else if (action.key === "empty") {
        this.$emit("startDatabaseEmptying", {
          mysql_database: action.target,
        });
      }
    },
  },
});
</script>

<i18n>
en:
  headers:
    name: "Name"
  lock: "The default database cannot be deleted. It can only be emptied."
  actions:
    empty: "Empty"
    drop: "Drop"
fr:
  headers:
    name: "Nom"
  lock: "La base de données par défaut ne peut pas être supprimée. Elle peut seulement être vidée."
  actions:
    empty: "Vider"
    drop: "Supprimer"
</i18n>
