<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      >
        <template #buttons>
          <SCButton
            v-if="isAParentApp"
            :loading="branchesAreLoading"
            @click="$emit('fetchScmRepoLinkBranches')"
          >
            {{ $t("refreshBranches") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="pb-4">
        <SCMIntegrationLinks
          :app="app"
          :scmRepoLink="scmRepoLink"
          :moreSpacing="true"
        />
      </div>

      <div
        v-if="branchesAreLoading"
        class="max-h-56 rounded-md p-8 ring-1 ring-black ring-opacity-5 flex items-center justify-center"
      >
        <Spinner class="h-10 w-10 text-scale-5" />
      </div>
      <div v-else>
        <SCTable
          v-if="isAParentApp"
          :headers="headers"
          :items="scmBranches"
          :paginated="true"
          :searchable="true"
        >
          <template #item.action="{ item }">
            <CheckboxInputAtom
              :modelValue="isSelected(item)"
              boxType="round"
              @update:model-value="(e) => select(item, e)"
            />
          </template>
          <template #item.name="{ item }">
            <span
              class="text-sm text-scale-9"
              :class="isSelected(item) ? 'font-bold' : ''"
            >
              {{ item.name }}
            </span>
          </template>
        </SCTable>

        <div
          v-else
          class="text-scale-9"
        >
          <p
            v-html="
              $t('reviewAppDisclaimer', {
                repo: `${scmRepoLink.owner}/${scmRepoLink.repo}`,
                branch: repoLinkBranch,
              })
            "
          />
        </div>
      </div>

      <SCButton
        class="mt-4"
        kind="primary"
        :disabled="!selectedBranch"
        :loading="deployHandler.isSubmitting"
        @click="$emit('manualDeploy', selectedBranch)"
      >
        {{ $t("action") }}
      </SCButton>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import Spinner from "@/components/atoms/spinners/Spinner.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import SCMIntegrationLinks from "@/components/organisms/appCards/SCMIntegrationLinks.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";

export default defineComponent({
  name: "ManualDeployCard",
  components: {
    SCButton,
    CardHeader,
    Card,
    Spinner,
    SCTable,
    CheckboxInputAtom,
    SCMIntegrationLinks,
  },
  props: {
    app: Object,
    scmRepoLink: Object,
    scmBranches: Array,
    deployHandler: Object,
    listBranchesInfo: Object,
  },
  emits: ["fetchScmRepoLinkBranches", "manualDeploy"],
  data() {
    return {
      selectedBranch: null,
      headers: [
        { value: "action", text: "", class: "w-8" },
        { value: "name", text: this.$t("headers.name") },
      ],
    };
  },
  computed: {
    isAParentApp() {
      return !this.app.parent_app_name;
    },
    repoLinkBranch() {
      const parts = this.scmRepoLink.branch.split(":");
      if (parts[0] === this.scmRepoLink.owner) {
        return parts[1];
      }

      return this.scmRepoLink.branch;
    },
    branchesAreLoading() {
      return this.listBranchesInfo?.isLoading || false;
    },
  },
  beforeMount() {
    this.selectedBranch = this.scmRepoLink.branch;
  },
  methods: {
    isSelected(item) {
      return this.selectedBranch === item.name;
    },
    select(item, val) {
      if (val) {
        this.selectedBranch = item.name;
      } else {
        this.selectedBranch = null;
      }
    },
  },
});
</script>

<i18n>
en:
  title: "Manual deployment of a branch"
  subtitle: "Trigger a manual deployment from a branch of the app repository."
  action: "Trigger deployment"
  refreshBranches: "Refresh branches"
  reviewAppDisclaimer: "This review app is linked to the repo <span class='font-bold'>{repo}</span> on the branch <span class='font-bold'>{branch}</span>. Only this branch can be deployed."
  headers:
    name: "Branch"
fr:
  title: "Déploiement manuel d'une branche"
  subtitle: "Déclenchez un déploiement manuel à partir d'une branche du dépôt de l'app."
  action: "Lancer le déploiement"
  refreshBranches: "Rafraichir les branches"
  reviewAppDisclaimer: "Cette review app est liée au dépot <span class='font-bold'>{repo}</span> sur la branche <span class='font-bold'>{branch}</span>. Seule cette branche peut être déployée."
  headers:
    name: "Branche"
</i18n>
