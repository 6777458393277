<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :db="db"
    :archives="archives"
    @fetch-more-archives="fetchMoreArchives"
  />
</template>

<script>
import { computed, defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/db/logs/DbLogsArchives.vue";
import { currentApp } from "@/stores/current/app";
import { currentDB } from "@/stores/current/db";
import { useDbLogArchivesStore } from "@/stores/db/log-archives";

export default defineComponent({
  name: "DbLogsArchivesContainer",
  components: { ViewComponent },
  setup() {
    const app = currentApp();
    const db = currentDB();
    const archivesStore = useDbLogArchivesStore();

    onBeforeMount(() => archivesStore.ensure());

    return {
      app: computed(() => app),
      db: computed(() => db),
      archives: computed(() => {
        return {
          items: archivesStore.items,
          promiseInfo: archivesStore.promiseInfo,
          none: archivesStore.none,
          hasMore: archivesStore.hasMore,
        };
      }),
      // TODO: unchecked yet, no real-life examples for redis
      fetchMoreArchives() {
        archivesStore.fetchNextPage();
      },
    };
  },
});
</script>
