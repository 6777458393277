<template>
  <div class="flex items-center">
    <input
      :id="'input-' + inputId"
      v-model="inputValue"
      type="checkbox"
      :disabled="disabled"
      class="bg-scale-0 border-2 border-scale-3 form-checkbox box-border focus:shadow-none h-4 w-4 transition duration-150 focus:border-scale-4 ease-in-out"
      :class="{
        'rounded-none': boxType === 'square',
        'rounded-full': boxType === 'round',
        'bg-scale-2 cursor-not-allowed': disabled,
      }"
    >
    <label
      :for="'input-' + inputId"
      :class="labelClass"
      class="ml-3 block text-scale-8"
    >
      <slot name="label">{{ label }}</slot>
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export const BOX_TYPE_SQUARE = "square";
export const BOX_TYPE_ROUND = "round";

export default defineComponent({
  name: "CheckboxInput",
  props: {
    modelValue: Boolean,
    label: String,
    labelClass: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    boxType: {
      type: String,
      default: BOX_TYPE_SQUARE,
    },
    inputId: {
      type: String,
      default() {
        return Math.random().toString(36).substring(7);
      },
    },
  },
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>
