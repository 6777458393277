<template>
  <SCTable
    :headers="headers"
    :items="balanceOperations.items"
    :hasMore="hasMore"
    :fetchingMore="balanceOperations.latestFetch.isLoading"
    @fetch-more="$emit('fetchMore')"
  >
    <template #item.date="{ item }">
      <div class="humanize">
        {{ formatDateTime(item.created_at, "DD, t") }}
      </div>
    </template>

    <template #item.description="{ item }">
      <PayPalLogo
        v-if="item.source === 'paypal'"
        class="h-4"
      />
      <router-link
        v-else-if="item.source === 'invoicing'"
        class="hover:underline"
        :to="invoiceRouteFor(item)"
      >
        {{ item.description }}
      </router-link>
      <span v-else>
        <font-awesome-icon
          v-if="item.source === 'sepa'"
          icon="money-check-alt"
        />
        {{ item.description }}
      </span>
    </template>

    <template #item.amount="{ item }">
      {{ formatAmount(item.amount) }}
    </template>

    <template #item.status="{ item }">
      <PaymentStatus
        v-if="item.source === 'invoicing'"
        :invoiceState="item.invoice_state"
      />
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import PayPalLogo from "@/components/atoms/graphics/PayPal.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import PaymentStatus from "@/components/parts/billing/showBilling/PaymentStatus.vue";
import { formatAmount } from "@/lib/utils/currency";
import { formatDateTime } from "@/lib/utils/time";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "OperationsListBody",
  components: {
    PaymentStatus,
    SCTable,
    PayPalLogo,
  },

  props: {
    balanceOperations: Object,
  },
  emits: ["fetchMore"],
  data() {
    return {
      headers: [
        { value: "date", text: this.$i18n.t(`headers.date`), class: "w-52" },
        {
          value: "amount",
          text: this.$i18n.t(`headers.amount`),
          class: "w-40",
        },
        {
          value: "description",
          text: this.$i18n.t(`headers.description`),
        },
        { value: "status", text: "", align: "right", class: "w-40" },
      ],
    };
  },
  computed: {
    hasMore() {
      return this.balanceOperations.latestFetch.isSuccess
        ? !!this.balanceOperations.meta?.pagination?.next_page
        : false;
    },
  },

  methods: {
    formatAmount,
    formatDateTime,
    invoiceRouteFor(item) {
      return {
        name: Routes.Billing.Invoices.Show,
        params: {
          id: item.invoice_id,
        },
      };
    },
  },
});
</script>

<i18n>
en:
  headers:
    date: "Date"
    description: "Description"
    amount: "Amount"
fr:
  headers:
    date: "Date"
    description: "Description"
    amount: "Montant"
</i18n>
