export class TokenManager {
  private static instance: TokenManager;
  private tokenWorker: Worker;

  private constructor() {
    this.tokenWorker = new Worker("/workers/token-worker.js", {
      type: "module",
    });
  }

  public static getInstance(): TokenManager {
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager();
    }
    return TokenManager.instance;
  }

  setToken(token: string): void {
    this.tokenWorker.postMessage({ type: "SET_TOKEN", token });
  }

  getToken(): Promise<string | null> {
    return new Promise((resolve) => {
      this.tokenWorker.onmessage = (event) => {
        if (event.data.type === "TOKEN") {
          resolve(event.data.token);
        }
      };
      this.tokenWorker.postMessage({ type: "GET_TOKEN" });
    });
  }

  removeToken(): void {
    this.tokenWorker.postMessage({ type: "REMOVE_TOKEN" });
  }
}
