<template>
  <span
    class="inline-flex items-center rounded-full py-0.5 font-medium bg-scale-2 text-scale-9"
    :class="{
      'pl-2 pr-0.5 text-xs': this.size === 'xs',
      'pl-2.5 pr-1 text-sm': this.size === 'sm',
    }"
  >
    <slot />

    <button
      v-if="dismissible"
      type="button"
      class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-scale-9 hover:bg-scale-4 focus:outline-none focus:bg-scale-8 focus:text-scale-0"
      @click="$emit('dismiss')"
    >
      <span class="sr-only">{{ $t("label") }}</span>
      <svg
        class="h-2 w-2"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 8 8"
      >
        <path
          stroke-linecap="round"
          stroke-width="1.5"
          d="M1 1l6 6m0-6L1 7"
        />
      </svg>
    </button>
  </span>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BadgeAtom",
  props: {
    dismissible: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "sm",
      validator(value) {
        return ["xs", "sm"].includes(value);
      },
    },
  },
  emits: ["dismiss"],
});
</script>

<i18n>
en:
  label: "Remove option"
fr:
  label: "Supprimer l'option"
</i18n>
