<template>
  <main>
    <BackLinkButton
      class="my-2"
      :routeArgs="backLink"
    >
      {{ $t("actions.back") }}
    </BackLinkButton>

    <Card v-if="notifier">
      <template #header>
        <CardHeader :title="notifier.name">
          <template #buttons>
            <SCButton :to="editNotifierRoute">
              {{ $t("edit") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <section>
          <div class="bg-scale-1 p-4">
            <h5 class="text-scale-6 text-sm">
              {{ $t("titles.events") }}
            </h5>

            <p v-if="notifier.send_all_events">
              {{ $t("generic.all") }}
            </p>

            <div class="grid grid-col-4">
              <div
                v-for="eventId in notifier.selected_event_ids"
                :key="eventId"
              >
                {{ findEventType(eventId).name }}
              </div>
            </div>
          </div>
        </section>
        <section class="mt-4">
          <SmallToggle
            :modelValue="notifier.active"
            :title="
              notifier.active ? $t('actions.disable') : $t('actions.enable')
            "
            @update:model-value="
              $emit('updateNotifier', { notifier, active: !notifier.active })
            "
          >
            {{ $t("labels.active") }}
          </SmallToggle>
        </section>

        <template v-if="notifier.type === 'email'">
          <section class="mt-4">
            <h4>
              <span class="font-medium">
                {{ $t("titles.collaborators") }}
              </span>
              <span class="text-scale-4 pl-1">({{ users.length }})</span>
            </h4>

            <CollaboratorsList
              v-if="users.length > 0"
              class="mt-2"
              :app="app"
              :collaborators="users"
            />
          </section>

          <section class="mt-4">
            <h4>
              <span class="font-medium">
                {{ $t("titles.emails") }}
              </span>
              <span class="text-scale-4 pl-1">({{ emails.length }})</span>
            </h4>

            <p v-if="emailsString">
              {{ emailsString }}
            </p>
          </section>
        </template>
        <template v-else>
          <section class="mt-4">
            <h4>
              <span class="font-medium">
                {{ $ta("notifier", "webhookUrl") }}
              </span>
            </h4>
            <div class="mt-2">
              <code class="text-sm">
                {{ notifier.type_data.webhook_url }}
              </code>
            </div>
          </section>
        </template>
      </template>
    </Card>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import SmallToggle from "@/components/molecules/toggles/SmallToggle.vue";
import CollaboratorsList from "@/components/parts/app/showApp/CollaboratorsList.vue";
import ModelsTranslation from "@/mixins/models_translation";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "Show",
  components: {
    Card,
    CardHeader,
    BackLinkButton,
    CollaboratorsList,
    SCButton,
    SmallToggle,
  },
  mixins: [ModelsTranslation],
  props: {
    app: Object,
    user: Object,
    collaborators: Object,
    notifier: Object,
    eventTypes: Object,
  },
  emits: ["updateNotifier"],
  data: function () {
    return {};
  },
  computed: {
    editNotifierRoute() {
      return {
        name: Routes.App.Notifiers.Edit.Infos,
        params: {
          id: this.app.name,
          region: this.app.region,
          notifierId: this.notifier.id,
        },
      };
    },
    users() {
      if (!this.notifier.type === "email") {
        return [];
      }

      const userIds = this.notifier.type_data?.user_ids;

      if (!userIds || userIds.length === 0) {
        return [];
      }

      const owner = this.app.owner;

      const collaboratorsIncluded = this.collaborators.items.filter(
        (collaborator) => {
          return userIds.includes(collaborator.user_id);
        },
      );

      if (userIds.includes(owner.id)) {
        collaboratorsIncluded.unshift({
          email: owner.email,
          user_id: owner.id,
          username: owner.username,
          status: "accepted",
        });
      }

      return collaboratorsIncluded;
    },
    emails() {
      return this.notifier.type_data?.emails;
    },
    emailsString() {
      return this.emails.join(", ");
    },
    backLink() {
      return {
        name: Routes.App.Notifiers.Root,
        params: { id: this.app.name },
      };
    },
  },
  methods: {
    findEventType(id) {
      return this.eventTypes.items.find((item) => item.id === id) || {};
    },
  },
});
</script>

<i18n>
en:
  titles:
    events: "Triggered on events"
    collaborators: "Collaborators to notify"
    emails: "Extra mails to notify"
  labels:
    active: "Send notifications"
  actions:
    disable: "Disable"
    enable: "Enable"
    back: "Back to notifiers"
  edit: "Edit"
fr:
  titles:
    events: "Déclenché lors des évènements"
    collaborators: "Collaborateurs à notifier"
  labels:
    active: "Envoyer les notifications"
    emails: "Addresses emails à notifier"
  actions:
    disable: "Désactiver"
    enable: "Activer"
    back: "Retour à la liste des notifieurs"
  edit: "Modifier"
</i18n>
