<template>
  <OpenableCartridge
    :id="id"
    :title="title"
    :subtitle="subtitle"
    :titleData="titleData"
    :isOpen="repoLinkedToIntegration"
    :isSelectable="!repoLinkedToOtherIntegration"
    :isSelected="isSelected"
    @button-clicked="buttonClicked"
    @cartridge-selected="cartridgeSelected"
  >
    <template #icon>
      <GitHubGraphic v-if="githubSCM" />
      <GitLabGraphic v-if="gitlabSCM" />
    </template>
    <template
      v-if="!repoLinkedToOtherIntegration"
      #action
    >
      <LinkButton
        :noUnderLine="true"
        @clicked="buttonClicked"
      >
        {{ buttonText }}
      </LinkButton>
    </template>
    <template #content>
      <div class="px-4 flex-auto">
        <div class="font-light text-scale-7">
          {{ $t("organizationLabel") }}
        </div>
        <div class="text-scale-10 font-medium mt-1">
          {{ scmRepoLink.owner }}
        </div>
      </div>
      <div class="px-4 flex-auto">
        <div class="font-light text-scale-7">
          {{ $t("repositoryLabel") }}
        </div>
        <div class="text-scale-10 font-medium mt-1">
          {{ scmRepoLink.repo }}
        </div>
      </div>
      <div class="px-4 flex-auto">
        <div class="font-light text-scale-7">
          {{ $t("branchLabel") }}
        </div>
        <div class="text-scale-10 font-medium mt-1">
          <template v-if="scmRepoLink.branch">
            {{ scmRepoLink.branch }}
          </template>
          <template v-else>
            N/A
          </template>
        </div>
      </div>
      <div class="px-4 flex-auto">
        <div class="font-light text-scale-7">
          {{ $t("automaticDeployLabel") }}
        </div>
        <div class="text-scale-10 font-medium mt-1 flex items-center">
          <TwoStatesIndicator
            class="mr-2"
            :isOn="scmRepoLink.auto_deploy_enabled"
          />
          <div v-if="scmRepoLink.auto_deploy_enabled">
            on
          </div>
          <div v-else>
            off
          </div>
        </div>
      </div>
    </template>
  </OpenableCartridge>
</template>

<script>
import { defineComponent } from "vue";

import LinkButton from "@/components/atoms/buttons/LinkButton.vue";
import GitHubGraphic from "@/components/atoms/graphics/GitHubGraphic.vue";
import GitLabGraphic from "@/components/atoms/graphics/GitLabGraphic.vue";
import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import OpenableCartridge from "@/components/molecules/cartridge/OpenableCartridge.vue";

export default defineComponent({
  name: "SaasSCMDeployCartridge",
  components: {
    TwoStatesIndicator,
    OpenableCartridge,
    GitHubGraphic,
    GitLabGraphic,
    LinkButton,
  },
  props: {
    scmType: String,
    integration: Object,
    scmRepoLink: Object,
    state: String,
    isOpen: Boolean,
    isSelected: Boolean,
  },
  emits: ["cartridgeSelected", "connect", "startSCMLink", "unlinkRepo"],
  computed: {
    id() {
      return this.scmType + "-cartridge";
    },
    githubSCM() {
      return this.scmType === "github" || this.scmType === "github-enterprise";
    },
    gitlabSCM() {
      return this.scmType === "gitlab" || this.scmType === "gitlab-self-hosted";
    },
    title() {
      if (this.githubSCM) {
        return this.$t("github.title");
      } else if (this.gitlabSCM) {
        return this.$t("gitlab.title");
      }

      return null;
    },
    subtitle() {
      if (this.githubSCM) {
        return this.$t("github.subtitle");
      } else if (this.gitlabSCM) {
        return this.$t("gitlab.subtitle");
      }

      return null;
    },
    accountConnected() {
      return this.integration !== null && this.integration !== undefined;
    },
    repoLinked() {
      return !!this.scmRepoLink;
    },
    repoLinkedToIntegration() {
      return (
        this.repoLinked &&
        this.accountConnected &&
        this.scmRepoLink.auth_integration_uuid == this.integration?.id
      );
    },
    repoLinkedToOtherIntegration() {
      return (
        this.repoLinked &&
        this.scmRepoLink.auth_integration_uuid != this.integration?.id
      );
    },
    currentAction() {
      if (this.repoLinkedToIntegration) {
        return "unlink";
      } else if (this.accountConnected) {
        return "link";
      } else {
        return "connect";
      }
    },
    buttonText() {
      if (this.repoLinkedToIntegration) {
        return this.$t("unlinkButton");
      } else if (this.accountConnected) {
        return this.$t("linkButton");
      } else {
        return this.$t("connectButton");
      }
    },
    titleData() {
      if (this.accountConnected) {
        return `${this.integration.username} (${this.integration.email})`;
      }
      return "";
    },
  },
  methods: {
    buttonClicked() {
      if (this.currentAction === "connect") {
        this.$emit("connect");
        return;
      }

      if (this.currentAction === "link") {
        this.$emit("startSCMLink");
        return;
      }

      if (this.currentAction === "unlink") {
        this.$emit("unlinkRepo");
        return;
      }
    },
    cartridgeSelected() {
      this.$emit("cartridgeSelected", { cartridge: this.scmType });
    },
  },
});
</script>

<i18n>
en:
  github:
    title: "GitHub"
    subtitle: "Deployments triggered from a GitHub repo."
  gitlab:
    title: "GitLab"
    subtitle: "Deployments triggered from a GitLab repo."
  connectButton: "Connect account"
  linkButton: "Link repo"
  unlinkButton: "Unlink repo"
  organizationLabel: "Organization"
  repositoryLabel: "Repository"
  branchLabel: "Default branch"
  automaticDeployLabel: "Automatic deploy"
fr:
  github:
    title: "GitHub"
    subtitle: "Déploiements déclenchés depuis un dépôt GitHub."
  gitlab:
    title: "GitLab"
    subtitle: "Déploiements déclenchés depuis un dépôt GitLab."
  connectButton: "Connecter"
  linkButton: "Lier"
  unlinkButton: "Délier"
  organizationLabel: "Organisation"
  repositoryLabel: "Dépôt"
  branchLabel: "Branche par défaut"
  automaticDeployLabel: "Déploiement automatique"
</i18n>
