<template>
  <div class="flex justify-end flex-grow">
    <SCButton
      kind="empty"
      class="hidden xl:block mr-2"
      @click="$emit('fullScreenClicked')"
    >
      <div class="py-2 font-medium">
        {{ displayInFullScreen ? $t("exitFullscreen") : $t("enterFullscreen") }}
      </div>
    </SCButton>

    <RangeSelectors
      :items="items"
      :selectedValue="selectedValue"
      @selected="(e) => $emit('rangeSelected', e)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import RangeSelectors from "@/components/atoms/selectors/RangeSelectors.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";

export default defineComponent({
  name: "DateSelector",
  components: { RangeSelectors, SCButton },
  props: {
    displayInFullScreen: Boolean,
    selectedValue: [String, Number],
  },
  emits: ["fullScreenClicked", "rangeSelected"],
  data() {
    return {
      items: [
        { value: "3", text: "3h" },
        { value: "12", text: "12h" },
        { value: "24", text: "24h" },
        { value: "48", text: "48h" },
        { value: "72", text: "72h" },
      ],
    };
  },
});
</script>

<i18n>
  en:
    enterFullscreen: "Enter fullscreen"
    exitFullscreen: "Exit fullscreen"
  fr:
    enterFullscreen: "Entrer en plein écran"
    exitFullscreen: "Sortir du plein écran"
</i18n>
