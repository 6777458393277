<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.29464 15.9999C5.47891 16.1931 5.47891 16.4968 5.29464 16.6899L4.58407 17.3999C4.38489 17.5983 4.06268 17.5983 3.86349 17.3999C0.0455021 13.2041 0.0455021 6.79574 3.86349 2.59992C4.06268 2.40159 4.38489 2.40159 4.58407 2.59992L5.29464 3.30992C5.47891 3.50307 5.47891 3.80677 5.29464 3.99992C2.23646 7.41633 2.23646 12.5835 5.29464 15.9999ZM20.1365 2.59992C19.9373 2.40159 19.6151 2.40159 19.4159 2.59992L18.7054 3.30992C18.5211 3.50307 18.5211 3.80677 18.7054 3.99992C21.7635 7.41633 21.7635 12.5835 18.7054 15.9999C18.5211 16.1931 18.5211 16.4968 18.7054 16.6899L19.4159 17.3999C19.6151 17.5983 19.9373 17.5983 20.1365 17.3999C23.9545 13.2041 23.9545 6.79574 20.1365 2.59992ZM16.994 5.26992C17.1191 5.28902 17.233 5.35301 17.3142 5.44992C19.5682 8.05963 19.5852 11.9205 17.3543 14.5499C17.2632 14.6558 17.1334 14.7207 16.994 14.7299C16.8525 14.7316 16.7159 14.6778 16.6137 14.5799L15.9031 13.8699C15.7312 13.6796 15.7312 13.3902 15.9031 13.1999C17.4485 11.346 17.4485 8.65383 15.9031 6.79992C15.7312 6.60961 15.7312 6.32023 15.9031 6.12992L16.6137 5.41992C16.7159 5.32209 16.8525 5.26823 16.994 5.26992ZM8.11689 6.12992L7.40633 5.41992C7.29439 5.31175 7.14119 5.25708 6.98599 5.26992C6.84658 5.27917 6.71675 5.34403 6.6257 5.44992C4.3833 8.06949 4.3833 11.9304 6.6257 14.5499C6.71675 14.6558 6.84658 14.7207 6.98599 14.7299C7.12753 14.7316 7.26408 14.6778 7.36629 14.5799L8.07686 13.8699C8.24876 13.6796 8.24876 13.3902 8.07686 13.1999C6.53146 11.346 6.53146 8.65383 8.07686 6.79992C8.26053 6.62005 8.27784 6.33035 8.11689 6.12992ZM9.07006 10.6577C8.84284 9.65631 9.14398 8.60834 9.8683 7.87992C10.7376 6.99846 12.0594 6.7408 13.1965 7.23114C14.3336 7.72147 15.0528 8.85917 15.0072 10.0958C14.9617 11.3325 14.1609 12.4144 12.9908 12.8199V21.4999C12.9908 21.7761 12.7668 21.9999 12.4904 21.9999H11.4896C11.2132 21.9999 10.9892 21.7761 10.9892 21.4999V12.8199C10.0213 12.4749 9.29728 11.6591 9.07006 10.6577Z"
      fill="currentColor"
    />
    <circle
      cx="18"
      cy="18"
      r="5.5"
      fill="white"
      stroke="white"
    />
    <path
      d="M18 13C15.2386 13 13 15.2386 13 18C13 20.7614 15.2386 23 18 23C20.7614 23 23 20.7614 23 18C23 15.2386 20.7614 13 18 13ZM15.8571 17.2857H20.1429C20.5371 17.2857 20.8571 17.605 20.8571 18C20.8571 18.395 20.5371 18.7143 20.1429 18.7143H15.8571C15.4629 18.7143 15.1429 18.395 15.1429 18C15.1429 17.605 15.4629 17.2857 15.8571 17.2857Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotifierDelGlyph",
});
</script>
