<template>
  <main class="container px-4">
    <section class="mt-8">
      <SectionTitle
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      />
      <BackLinkButton
        class="my-2"
        :routeArgs="backLink"
      >
        {{ $t("backToLogs") }}
      </BackLinkButton>
    </section>

    <section>
      <Card class="mt-6">
        <template #body>
          <EmptyCardState v-if="archives.none">
            <template #icon>
              <font-awesome-icon
                icon="circle"
                class="h-4 text-scale-5"
              />
            </template>
            {{ $t("empty") }}
          </EmptyCardState>

          <DbLogsArchivesList
            v-else
            class="mt-2"
            :archives="archives"
            @fetch-more-archives="$emit('fetchMoreArchives')"
          />
        </template>
      </Card>
    </section>

    <ProTipLogsViaCLI
      class="mt-4"
      :app="app"
      :db="db"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import SectionTitle from "@/components/atoms/titles/SectionTitle.vue";
import Card from "@/components/molecules/card/Card.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import ProTipLogsViaCLI from "@/components/organisms/alerts/ProTipLogsViaCLI.vue";
import DbLogsArchivesList from "@/components/parts/db/dbListLogsArchives/DbLogsArchivesList.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DbLogsArchives",
  components: {
    ProTipLogsViaCLI,
    BackLinkButton,
    SectionTitle,
    DbLogsArchivesList,
    EmptyCardState,
    Card,
  },
  props: {
    app: Object,
    user: Object,
    archives: Object,
    db: Object,
  },
  emits: ["fetchMoreArchives"],
  computed: {
    backLink() {
      return {
        name: Routes.Db.Logs.Live,
        params: {
          region: this.app.region,
          id: this.app.name,
          dbId: this.db.type_name,
        },
      };
    },
  },
});
</script>

<i18n>
  en:
    title: "Logs / Archives"
    subtitle: "Logs db archives, downloadable in gzip format"
    viewArchives: "View archives"
    backToLogs: "Back to real time logs"
    empty: "No logs archive yet"
  fr:
    title: "Logs / Archives"
    subtitle: "Archives des logs de la base de données, téléchargeables au format gzip"
    viewArchives: "Voir archives"
    backToLogs: "Revenir aux logs temps réel"
    empty: "Pas encore d'archives de logs"
</i18n>
