<template>
  <div class="bg-scale-0 rounded px-8 py-6">
    <CardHeader
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    >
      <template #buttons>
        <SCButton @click="$emit('startDataAccessConsentCreation')">
          {{ $t("action") }}
        </SCButton>
      </template>
    </CardHeader>
    <div class="mt-2 flex flex-col space-y-2">
      <DataAccessConsentItem
        :name="$t('containers')"
        :hasAccess="dataAccessConsent && !!dataAccessConsent.containers_until"
        :until="dataAccessConsent ? dataAccessConsent.containers_until : ''"
      />
      <DataAccessConsentItem
        :name="$t('databases')"
        :hasAccess="dataAccessConsent && !!dataAccessConsent.databases_until"
        :until="dataAccessConsent ? dataAccessConsent.databases_until : ''"
      />
    </div>
    <Alert
      :text="$t('more.label')"
      styling="proTip"
      :withIcon="true"
    >
      <template #icon>
        <BulbGlyph />
      </template>
      <template #textComplement>
        <span
          v-if="!learnMore"
          @click="learnMore = true"
        >
          <span class="-ml-1 mr-1">:</span>
          <a class="underline cursor-pointer hover:text-primary-2-125">{{
            $t("more.link")
          }}</a>
        </span>
        <div v-if="learnMore">
          <p>{{ $t("more.line1") }}</p>
          <p>{{ $t("more.line2") }}</p>
        </div>
      </template>
    </Alert>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BulbGlyph from "@/components/atoms/glyphs/BulbGlyph.vue";
import Alert from "@/components/molecules/alerts/Alert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import DataAccessConsentItem from "@/components/parts/app/showApp/DataAccessConsentItem.vue";

export default defineComponent({
  name: "DataAccessConsentCard",
  components: {
    DataAccessConsentItem,
    SCButton,
    CardHeader,
    BulbGlyph,
    Alert,
  },
  props: {
    app: Object,
    createDataAccessConsentHandler: Object,
    dataAccessConsent: Object,
  },
  emits: ["startDataAccessConsentCreation"],
  data() {
    return {
      learnMore: false,
    };
  },
});
</script>

<i18n>
en:
  title: "Operator data access"
  subtitle: "Specify which data operators are allowed to inspect."
  databases: "Databases metadata and monitoring data"
  containers: "Application runtime environment"
  until: "until"
  action: "Configure"
  more:
    label: "Support operators & data access"
    link: "learn more"
    line1: "In the context of customer support, our operators may have to inspect your containeurs or databases to help their diagnostics."
    line2: "Except in emergencies, these access are only performed after explicit consent and in the full respect of your data's integrity and privacy."
fr:
  title: "Accès aux données par l'opérateur"
  subtitle: "Indiquez quelles données nos opérateurs sont autorisés à inspecter."
  databases: "Métadonnées et données de surveillance des bases de données"
  containers: "Environnement d'exécution de l'application"
  until: "jusqu'au"
  action: "Configurer"
  more:
    label: "Opérateurs de support & accès aux données"
    link: "en savoir plus"
    line1: "Dans le cadre du support, nos opérateurs peuvent être amenés à inspecter vos containers ou bases de données à des fins de diagnostic."
    line2: "Sauf urgence, ces accès se font uniquement après consentement explicite et dans le respect de l'intégrité et la confidentialité de vos données."
</i18n>
