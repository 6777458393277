<template>
  <div>
    <Form
      ref="form"
      v-slot="{ meta }"
      @submit="submitForm"
    >
      <Field
        v-slot="{ field, errors, handleChange }"
        v-model="form.name"
        name="name"
        :rules="rules"
      >
        <TextInput
          :name="field.name"
          :modelValue="field.value"
          required
          :label="$t('appNameLabel')"
          :errors="errors"
          :placeholder="
            $t('appNamePlaceholder', { min: minName, max: maxName })
          "
          @update:model-value="handleChange"
        />
      </Field>

      <label class="block text-sm font-medium leading-5 text-scale-10">
        {{ $t("regionLabel") }}
      </label>

      <OpenableCartridge
        v-for="region in regions"
        :id="`${region.code}-cartridge`"
        :key="region.code"
        class="mt-2"
        :title="region.code"
        :subtitle="region.text"
        :isOpen="false"
        :isSelectable="region.enabled"
        :isSelected="region.code === form.region"
        @cartridge-selected="form.region = region.code"
      >
        <template #icon>
          <Region
            :regionCode="region.code"
            :monochrome="!region.enabled"
          />
        </template>
      </OpenableCartridge>

      <section v-if="canCreateHDSApp">
        <CheckboxInput
          v-model="form.hds_resource"
          :label="$t('hds.label')"
          class="mt-6"
        />

        <div
          class="ml-9 mt-2 grid gap-2 text-sm text-scale-9"
          style="grid-template-columns: 1rem 1fr"
        >
          <div class="pt-1">
            <QuestionGlyph
              class="object-contain text-primary-2"
              width=""
              height=""
            />
          </div>

          <div style="padding-top: 0.15rem">
            {{ $t("hds.disclaimer") }}
          </div>

          <template v-if="form.hds_resource">
            <template v-if="hdsLoading">
              <div class="pt-1">
                <Spinner />
              </div>
              <div style="padding-top: 0.15rem">
                {{ $t("hds.appendix.loading") }}
              </div>
            </template>

            <template v-else-if="hdsContract && hdsContract.accepted">
              <div class="pt-1">
                <CheckGlyph
                  size=""
                  class="object-contain text-success"
                  width=""
                  height=""
                />
              </div>
              <div style="padding-top: 0.15rem">
                {{ $t("hds.appendix.accepted") }}
              </div>
            </template>

            <template
              v-else-if="
                hdsContractPrerequisite && !hdsContractPrerequisite.accepted
              "
            >
              <div class="pt-1">
                <DangerSignGlyph
                  size=""
                  class="object-contain text-warning"
                  width=""
                  height=""
                />
              </div>

              <p style="padding-top: 0.15rem">
                {{ $t("hds.prerequisite.unsigned") }}

                <a
                  class="underline cursor-pointer"
                  @click="
                    $emit('startContractAcceptance', {
                      contract: hdsContractPrerequisite,
                    })
                  "
                >
                  {{
                    $t("hds.prerequisite.link", {
                      name: localizedAttr(hdsContractPrerequisite, "name"),
                    })
                  }}
                </a>
                {{ $t("hds.prerequisite.inOrderTo") }}
              </p>
            </template>
            <template v-else>
              <div class="pt-1">
                <DangerSignGlyph
                  size=""
                  class="object-contain text-warning"
                  width=""
                  height=""
                />
              </div>

              <p style="padding-top: 0.15rem">
                {{ $t("hds.appendix.unsigned") }}

                <a
                  class="underline cursor-pointer"
                  @click="
                    $emit('startContractAcceptance', {
                      contract: hdsContract,
                    })
                  "
                >
                  {{ $t("hds.appendix.link") }}
                </a>
                {{ $t("hds.appendix.inOrderTo") }}
              </p>
            </template>

            <template v-if="validHdsPointOfContact">
              <div class="pt-1">
                <CheckGlyph
                  size=""
                  class="object-contain text-success"
                  width=""
                  height=""
                />
              </div>
              <span style="padding-top: 0.15rem">
                {{ $t("hds.contact.filled") }}

                <router-link
                  :to="hdsRoute"
                  class="underline cursor-pointer"
                >
                  {{ $t("hds.contact.accountHds") }}
                </router-link>
              </span>
            </template>
            <template v-else>
              <div class="pt-1">
                <DangerSignGlyph
                  class="object-contain text-warning"
                  size=""
                  width=""
                  height=""
                />
              </div>

              <span
                v-if="hdsPointOfContact && hdsPointOfContact.outdated"
                style="padding-top: 0.15rem"
              >
                {{ $t("hds.contact.youNeedTo") }}

                <router-link
                  :to="hdsRoute"
                  class="underline cursor-pointer"
                >
                  {{ $t("hds.contact.update") }}
                </router-link>

                {{ $t("hds.contact.outdated") }}
              </span>
              <span
                v-else
                style="padding-top: 0.1rem"
              >
                {{ $t("hds.contact.youNeedTo") }}
                <a
                  class="underline cursor-pointer"
                  @click="$emit('startPointOfContactCreation')"
                >
                  {{ $t("hds.contact.link") }}
                </a>
                {{ $t("hds.contact.unfilled") }}
              </span>
            </template>
          </template>
        </div>
      </section>

      <FormAlert
        v-if="freeTrialExpired || freeTrialExceeded"
        class="mt-4"
        styling="error"
      >
        <template #text>
          <template v-if="freeTrialExpired">
            {{ $t("errors.freeTrialExpired") }}
          </template>
          <template v-if="freeTrialExceeded">
            {{ $t("errors.freeTrialExceeded") }}
          </template>
          <br>
          <br>
          <p>
            {{ $t("errors.fillBilling.text1") }}
            <router-link
              :to="billingProfileRoute"
              class="underline"
            >
              {{ $t("billingProfile") }}
            </router-link>{{ $t("errors.fillBilling.text2") }}

            <router-link
              :to="paymentMethodsRoute"
              class="underline"
            >
              {{ $t("paymentMethod") }}
            </router-link>
            {{ $t("errors.fillBilling.text3") }}
            <router-link
              :to="vouchersRoute"
              class="underline"
            >
              {{ $t("voucher") }}
            </router-link>
            .
          </p>
        </template>
      </FormAlert>

      <FormAlert
        v-if="maxAppsExceeded"
        class="mt-4"
        styling="error"
      >
        <template #text>
          {{ $t("errors.maxAppsExceeded") }}
        </template>
      </FormAlert>

      <FormAlert
        v-if="appRejected"
        class="mt-4"
        styling="error"
      >
        <template #text>
          {{ $t("errors.appRejected.line1") }}
          <br>
          {{ $t("errors.appRejected.line2") }}
        </template>
      </FormAlert>

      <div class="flex">
        <SCButton
          block
          kind="primary"
          type="submit"
          size="lg"
          class="flex-grow mt-8"
          :disabled="!meta.valid || !hdsValid"
          :loading="formHandler?.isSubmitting"
        >
          {{ $t("action") }}
        </SCButton>
      </div>
    </Form>

    <AcceptContractModal
      v-if="acceptContractHandler"
      :formHandler="acceptContractHandler"
      @modal-closed="$emit('cancelContractAcceptance')"
      @form-submitted="(e) => $emit('submitContractAcceptance', e)"
    />

    <HDSContactFormModal
      v-if="createPointOfContactHandler"
      :formHandler="createPointOfContactHandler"
      @modal-closed="$emit('cancelPointOfContactCreation')"
      @form-submitted="(e) => $emit('submitPointOfContactCreation', e)"
    />
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import QuestionGlyph from "@/components/atoms/glyphs/QuestionGlyph.vue";
import CheckboxInput from "@/components/atoms/inputs/CheckboxInput";
import Spinner from "@/components/atoms/spinners/Spinner.vue";
import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import OpenableCartridge from "@/components/molecules/cartridge/OpenableCartridge.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import Region from "@/components/organisms/app/Region.vue";
import AcceptContractModal from "@/components/parts/account/contracts/AcceptContractModal.vue";
import HDSContactFormModal from "@/components/parts/account/hdsContact/HDSContactFormModal.vue";
import FormHandlerMixin from "@/mixins/form_handler_mixin";
import { Routes } from "@/router/names";

const MIN_APP_NAME = 6;
const MAX_APP_NAME = 48;

export default defineComponent({
  name: "AppInfosForm",
  components: {
    SCButton,
    CheckboxInput,
    TextInput,
    Field,
    Form,
    OpenableCartridge,
    Region,
    FormAlert,
    AcceptContractModal,
    HDSContactFormModal,
    QuestionGlyph,
    DangerSignGlyph,
    CheckGlyph,
    Spinner,
  },
  mixins: [FormHandlerMixin],
  props: {
    acceptContractHandler: Object,
    createPointOfContactHandler: Object,
    regions: Array,
    canEnableHDS: Boolean,
    hdsPointOfContact: Object,
    hdsContract: Object,
    hdsContractPrerequisite: Object,
    hdsLoadingOperation: Object,
    user: Object,
  },
  emits: [
    "cancelContractAcceptance",
    "cancelPointOfContactCreation",
    "startPointOfContactCreation",
    "submitContractAcceptance",
    "submitPointOfContactCreation",
    "toggleHDSMode",
    "startContractAcceptance",
  ],
  data() {
    return {};
  },
  computed: {
    canCreateHDSApp() {
      return this.canEnableHDS && !!this.hdsContract;
    },
    minName() {
      return MIN_APP_NAME;
    },
    maxName() {
      return MAX_APP_NAME;
    },
    rules() {
      return {
        required: true,
        appName: true,
        noStartHyphen: true,
        noEndHyphen: true,
        noConsecutiveHyphens: true,
        max: this.maxName,
      };
    },
    hdsLoading() {
      if (!this.form.hds_resource) {
        return false;
      }

      return !this.hdsLoadingOperation || this.hdsLoadingOperation.isLoading;
    },
    hdsValid() {
      if (!this.form.hds_resource) {
        return true;
      }

      const hasFilledHDSContent = this.hdsPointOfContact;
      const HDSContactUpToDate = !this.hdsPointOfContact?.outdated;
      const hasHDSAgreement = this.hdsContract?.accepted;

      return hasHDSAgreement && hasFilledHDSContent && HDSContactUpToDate;
    },
    validHdsPointOfContact() {
      return this.hdsPointOfContact && !this.hdsPointOfContact.outdated;
    },
    billingProfileRoute() {
      return { name: Routes.Billing.Profile };
    },
    paymentMethodsRoute() {
      return { name: Routes.Billing.PaymentMethods };
    },
    vouchersRoute() {
      return { name: Routes.Billing.Vouchers };
    },
    hdsRoute() {
      return { name: Routes.Account.HDS };
    },
    freeTrialExpired() {
      return this.formHandler.errors.$codes.includes("free-trial-expired");
    },
    freeTrialExceeded() {
      return this.formHandler.errors.$codes.includes("free-trial-exceeded");
    },
    maxAppsExceeded() {
      return this.formHandler.errors.$codes.includes("max-apps-exceeded");
    },
    appRejected() {
      return this.formHandler.errors.$codes.includes("app-rejected");
    },
  },
  watch: {
    "form.hds_resource": function (newVal) {
      this.$emit("toggleHDSMode", { hdsAppCreation: newVal });
    },
    hdsPointOfContact: function (newVal) {
      if (newVal) {
        // TODO: this field doesn't exist yet on the API
        this.form.hds_account_contact_id = newVal.id;
      }
    },
  },
  methods: {
    localizedAttr(object, attr) {
      return object[attr]?.[this.$i18n.locale];
    },
  },
});
</script>

<i18n>
en:
  appNameLabel: "App name"
  regionLabel: "Region"
  appNamePlaceholder: "{min} to {max} characters"
  billingProfile: "billing profile"
  paymentMethod: "a payment method"
  voucher: "a voucher"
  action: "Create app"
  hds:
    label: "Health data hosting (conform to French norm HDH)"
    disclaimer: "If your application consists of hosting personal health data collected during prevention, diagnosis, care or social and medico-social monitoring activities, you must tick this box."
    prerequisite:
      unsigned: "You need to "
      link: "accept the {name}"
      inOrderTo: " in order to create a HDH application."
    appendix:
      loading: "Checking the HDH contract appendix..."
      accepted: "You have already accepted the terms of the HDH contract appendix."
      unsigned: "You need to "
      link: "accept the terms of the HDH contract appendix "
      inOrderTo: "in order to create a HDH application."
    contact:
      filled: "You have filled an HDH contact. {accountHds}"
      accountHds: "Check or update this contact in your account settings."
      youNeedTo: "You need to"
      unfilled: "in order to create a HDH application."
      outdated: "before you can create a new HDH app"
      link: "fill a HDH contact"
      update: "update the HDH contact"
  errors:
    freeTrialExpired: "Your free trial period has expired."
    freeTrialExceeded: "You cannot create more apps while in free trial."
    maxAppsExceeded: "You have reached the limits of apps you can create."
    fillBilling:
      text1: "Please fill your "
      text2: " and add "
      text3: " or "
    appRejected:
      line1: "Deployment of this application is not allowed."
      line2: "If you think this is an error, get in touch with the support."
fr:
  appNameLabel: "Nom de l'app"
  regionLabel: "Région"
  appNamePlaceholder: "{min} à {max} caractères minimum"
  billingProfile: "profil de facturation"
  paymentMethod: "un moyen de paiement"
  voucher: "un voucher"
  action: "Créer l'app"
  hds:
    label: "Hébergement de données de santé (conforme HDS)"
    disclaimer: "Si votre application consiste à héberger les données de santé à caractère personnel recueillies à l'occasion d'activités de prévention, de diagnostic, de soins ou de suivi social et médico-social, vous devez cocher cette case."
    prerequisite:
      unsigned: "Vous devez "
      link: "accepter les {name}"
      inOrderTo: "avant de pouvoir créer une application HDS."
    appendix:
      loading: "Nous vérifions l'état de l'annexe HDS..."
      accepted: "Vous avez déjà accepté l'annexe HDS."
      unsigned: "Vous devez "
      link: "accepter l'annexe HDS "
      inOrderTo: "avant de pouvoir créer une application HDS."
    contact:
      filled: "Vous avez renseigné un point de contact professionnel de santé. {accountHds}"
      accountHds: "Vérifiez ou modifiez ce contact dans les paramètres de votre compte."
      youNeedTo: "Vous devez"
      unfilled: "avant de pouvoir créer une application HDS"
      outdated: "avant de pouvoir créer une nouvelle application HDS."
      link: "renseigner un point de contact professionnel de santé"
      update: "mettre à jour le contact HDS"
  errors:
    freeTrialExpired: "Votre période d'essai est terminée."
    freeTrialExceeded: "Vous ne pouvez pas créer d'autres applications pendant la période d'essai."
    maxAppsExceeded: "Vous avez atteint le nombre maximum d'applications que vous pouvez créer."
    fillBilling:
      text1: "Veuillez remplir votre "
      text2: " et ajoutez "
      text3:  " ou "
    appRejected:
      line1: "Le déploiement de cette application n'est pas autorisé."
      line2: "Si vous pensez que c'est une erreur, veuillez contacter le support."
</i18n>
