<template>
  <StepCard
    :step="2"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-5xl"
  >
    <div class="py-4">
      <Form
        ref="form"
        v-slot="{ meta, handleSubmit }"
        as=""
      >
        <form @submit.prevent="handleSubmit(submit)">
          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.name"
            name="name"
            rules="required"
          >
            <TextInput
              :name="field.name"
              :modelValue="field.value"
              :label="$ta('notifier', 'name')"
              :errors="errors"
              @update:model-value="handleChange"
            />
          </Field>
        </form>

        <LoadingCardState v-if="!notifierType" />

        <template v-else-if="notifierType === 'email'">
          <AdvancedSelectInput
            class="mt-4"
            :options="availableCollaborators"
            @update:model-value="addCollaborator"
          >
            <template #prompt>
              {{ $t("collaborators.prompt") }}
            </template>

            <template #noResults>
              {{ $t("collaborators.noAvailable") }}
            </template>

            <template #label>
              <div class="mb-3">
                {{ $t("collaborators.label") }}
              </div>
            </template>

            <template #text="{ option }">
              {{ option.email }}
            </template>
          </AdvancedSelectInput>

          <div
            v-if="selectedCollaborators.length > 0"
            class="mt-4"
          >
            <template
              v-for="collaborator in selectedCollaborators"
              :key="`notifier-collaborator-${collaborator.id}`"
            >
              <BadgeAtom
                class="mr-2"
                @dismiss="removeCollaborator(collaborator)"
              >
                {{ collaborator.email }}
              </BadgeAtom>
            </template>
          </div>

          <div class="mt-4">
            <Form
              ref="extraEmailForm"
              v-slot="extraEmailForm"
            >
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="extraEmail"
                name="extraEmail"
                mode="eager"
                :rules="extraEmailValidations"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$t('extraEmail.label')"
                  :errors="errors"
                  @update:model-value="handleChange"
                  @keypress.enter="extraEmailForm.handleSubmit(addExtraEmail)"
                />

                <SCButton
                  :disabled="!extraEmail || !extraEmailForm.meta.valid"
                  @click="extraEmailForm.handleSubmit(addExtraEmail)"
                >
                  {{ $t("extraEmail.action") }}
                </SCButton>
              </Field>

              <div
                v-if="form.extraEmails.length > 0"
                class="mt-4"
              >
                <template
                  v-for="(email, idx) in form.extraEmails"
                  :key="`notifier-email-${idx}`"
                >
                  <BadgeAtom
                    class="mr-2"
                    @dismiss="removeEmail(email)"
                  >
                    {{ email }}
                  </BadgeAtom>
                </template>
              </div>
            </Form>
          </div>
        </template>

        <template v-else>
          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.webhookUrl"
            name="webhook_url"
            rules="required|url"
          >
            <TextInput
              :name="field.name"
              :modelValue="field.value"
              :label="$ta('notifier', 'webhookUrl')"
              :errors="errors"
              @update:model-value="handleChange"
              @keypress.enter="handleSubmit(submit)"
            />
          </Field>
        </template>

        <div class="flex mt-4">
          <SCButton
            size="lg"
            kind="primary"
            class="flex-grow"
            :block="true"
            :disabled="!meta.valid"
            :loading="isSubmitting"
            @click="handleSubmit(submit)"
          >
            {{ $t("action") }}
          </SCButton>
        </div>
      </Form>
    </div>
  </StepCard>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent, nextTick } from "vue";

import BadgeAtom from "@/components/atoms/badges/BadgeAtom.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "NotifierInfoCard",
  components: {
    BadgeAtom,
    StepCard,
    SCButton,
    Field,
    Form,
    TextInput,
    AdvancedSelectInput,
    LoadingCardState,
  },
  mixins: [ModelsTranslation],
  props: {
    enabled: Boolean,
    notifierType: String,
    collaborators: Object,
    formHandler: Object,
    owner: Object,
  },
  emits: ["submit"],
  data() {
    return {
      form: {},
      extraEmail: null,
    };
  },
  computed: {
    isSubmitting() {
      return this.formHandler?.isSubmitting || false;
    },
    extraEmailValidations() {
      return {
        email: true,
        excluded: this.form.extraEmails,
      };
    },
    acceptedCollaborators() {
      const collaborators = this.collaborators.items.filter(
        (collaborator) => collaborator.status === "accepted",
      );
      const collaborator = {
        email: this.owner.email,
        user_id: this.owner.id,
        username: this.owner.username,
      };
      return [collaborator, ...collaborators]; // owner is also accepted collaborator on the app
    },
    selectedCollaborators() {
      return this.form.selectedUserIds
        .map((userId) => {
          return this.acceptedCollaborators.find((c) => c.user_id === userId);
        })
        .filter((c) => !!c);
    },
    availableCollaborators() {
      return this.acceptedCollaborators
        .filter((collaborator) => {
          return !this.form.selectedUserIds.includes(collaborator.user_id);
        })
        .map((collaborator) => {
          return { ...collaborator, value: collaborator.user_id };
        });
    },
  },
  watch: {
    formHandler: {
      immediate: true,
      handler(newVal) {
        newVal?.initComponent(this);
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submit", this.form);
    },
    addCollaborator(user_id) {
      this.form.selectedUserIds.push(user_id);
    },
    removeCollaborator(collaborator) {
      const idx = this.form.selectedUserIds.indexOf(collaborator.user_id);

      this.form.selectedUserIds.splice(idx, 1);
    },
    async addExtraEmail() {
      if (!this.extraEmail || this.extraEmail === "") return;

      this.form.extraEmails.push(this.extraEmail);
      this.extraEmail = "";
      await nextTick();
      this.$refs.extraEmailForm?.resetForm();
    },
    removeEmail(email) {
      const idx = this.form.extraEmails.indexOf(email);

      this.form.extraEmails.splice(idx, 1);
    },
  },
});
</script>

<i18n>
  en:
    title: "Basic informations"
    subtitle: "Set the name and the target(s) of your notifier"
    action: "Next step"
    collaborators:
      label: "Choose collaborators to notify"
      prompt: "Select a collaborator..."
      noAvailable: "All collaborators have been added."
    extraEmail:
      label: "Other emails to notify"
      action: "Add email"
  fr:
    title: "Informations basiques"
    subtitle: "Configurez le nom et les cibles de votre notifieur"
    action: "Étape suivante"
    collaborators:
      label: "Choisissez les collaborateurs à notifier"
      prompt: "Ajouter un collaborateur..."
      noAvailable: "Tous les collaborators ont été ajoutés."
    extraEmail:
      label: "Autres adresses emails à notifier"
      action: "Ajouter l'adresse"
</i18n>
