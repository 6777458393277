<template>
  <div
    :class="cardDelimiterClass"
    class="flex"
  >
    <div class="flex-grow">
      <CardTitle
        :title="title"
        :subtitle="subtitle"
        :withCount="withCount"
        :count="count"
      />
    </div>
    <div class="flex">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CardTitle from "../../atoms/titles/CardTitle.vue";

export default defineComponent({
  name: "CardHeader",
  components: { CardTitle },
  props: {
    title: String,
    subtitle: String,
    withDelimiter: {
      type: Boolean,
      default: true,
    },
    withCount: Boolean,
    count: Number,
  },
  computed: {
    cardDelimiterClass() {
      return this.withDelimiter ? "border-b-2 border-scale-2 pb-2 mb-3" : "";
    },
  },
});
</script>
