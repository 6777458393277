import { TokenManager } from "@/lib/auth/token-manager";
import { Response } from "@/lib/auth/utils";
import { debug } from "@/lib/utils/log";

export function saveTokenInCache(response: Response): Response {
  debug("-> Auth/saveTokenInCache", response.token);

  const tokenManager = TokenManager.getInstance();
  tokenManager.setToken(response.token);

  return response;
}
