<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AppTransferGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("from") }}
      <span class="font-medium mx-1">{{
        event.type_data.old_owner.email
      }}</span>
      {{ $t("to") }}
      <span class="font-medium ml-1">{{
        event.type_data.new_owner.email
      }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AppTransferGlyph from "@/components/atoms/glyphs/AppRenameGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "TransferAppEvent",
  components: { BaseEvent, AppTransferGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "App transferred"
  from: "From"
  to: "to"
fr:
  title: "App transférée"
  from: "De"
  to: "à"
</i18n>
