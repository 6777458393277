import mitt, { Emitter } from "mitt";
import { APIError } from "scalingo/lib/errors";

import type { UpdateDataFn } from "@/lib/pinia/use-data-store";
import { RemoteOperation } from "@/lib/store/remote-operation";
import router from "@/router";
import { ToastType, useToastsStore } from "@/stores/toasts";

import type { ComponentPublicInstance } from "vue";
import type { VueI18n } from "vue-i18n";

/** General categorization of the error that happened, if any */
export type ErrorInfo = {
  generic?: true;
  network?: true;
  scalingo?: true;
  paymentRequired?: true;
  unauthorized?: true;
  forbidden?: true;
  notFound?: true;
};

/** Errors are represented by {"fieldName": [array, of, error, messages]} */
export type ErrorBag = Record<string, string[]>;

/*
 * But we could use a bit more structure. So by convention:
 * - `$codes` stores the global errors codes (example: "free-trial-expired").
 * - `$base` stores the global error messages (example: "impossible to scale to this formation")
 * Those fields should always be present and empty.
 */

export type ActionErrorBag = Record<string, string[]> & {
  $base: string[];
  $codes: string[];
};

/** Generates an empty error bag */
function makeErrorBag(): ActionErrorBag {
  return {
    $codes: [],
    $base: [],
  };
}

/** A type representing the base set of events */
export type EventTypes<T> = {
  /** Emitted when the operation is submitted (= when it starts) */
  submit: void;
  /** Emitted when the operation is a success. Event arg is the operation/promise resolve value */
  success: T;
  /** Emitted when the operation fails. Event arg is the error reason */
  failure: unknown;
  /** Emitted when the operation finished (success or failure). No argument. */
  finally: void;
  /** Emitted when the handler asks for its related state to be reset */
  reset: void;
};

/** Renames an error fields in place. Merges if the destination is already existing. */
export function renameErrors(
  errors: Record<string, string[]>,
  mapping: Record<string, string>,
): void {
  if (Object.keys(errors).length === 0) return;
  if (Object.keys(mapping).length === 0) return;

  for (const [oldField, newField] of Object.entries(mapping)) {
    if (errors[oldField]) {
      errors[newField] = errors[newField] || [];
      errors[newField].push(...errors[oldField]);

      delete errors[oldField];
    }
  }
}

/**
 * The generic skeleton of an action handler, to be subclassed.
 * Subclasses should implement `submit`. */
export abstract class ActionHandler<T> {
  /** The operation representing the form submission */
  operation: RemoteOperation<T> | null = null;

  /** The i18n service */
  $i18n: VueI18n;

  /** The store service */
  $store: ComponentPublicInstance["$store"];

  /** The router service */
  $router = router;

  /**
   * The errors resulting from the failure of the action, formatted.
   * Internal field.
   */
  protected _errors: ActionErrorBag = makeErrorBag();

  /** Information about what type of error happened */
  errorsInfo: ErrorInfo = {};

  /**
   * API responses are not always consistent on the naming of the fields, or sometimes they're not well chosen.
   * This object is a list of {apifield: formfield} to ease up the renaming.
   */
  errorFieldsMapping: Record<string, string> = {};

  /** Use to give event emitters/listeners abilities to handlers */
  private _emitter: Emitter<EventTypes<T>> = mitt<EventTypes<T>>();

  /** The i18n key path */
  abstract keyPath: string;

  /** We need any component to get access to some of the app's mechanisms. */
  constructor(component: ComponentPublicInstance) {
    this.$i18n = component.$i18n as VueI18n;
    this.$store = component.$store;

    this.dispatchEvents();
  }

  /**
   * What happens when submitting the action.
   * Expected to invoke `follow` with the adequate RemoteOperation.
   */
  abstract submit(event: unknown): Promise<unknown>;

  /**
   * A function that is invoked once per object, and meant to set up events listeners.
   * Subclasses should override.
   */
  dispatchEvents(): void {
    // no-op
  }

  /** Whether the action is being submitted */
  get isSubmitting(): boolean {
    return this.operation?.isLoading || false;
  }

  /** Whether the action has successfully proceeded */
  get isSuccess(): boolean {
    return this.operation?.isSuccess || false;
  }

  /** Whether the action has not successfully proceeded */
  get isError(): boolean {
    return this.operation?.isError || false;
  }

  /** Whether the action has started */
  get hasStarted(): boolean {
    return this.operation?.hasStarted || false;
  }

  /** Whether the action is finished */
  get isFinished(): boolean {
    return this.operation?.isFinished || false;
  }

  /** Link the form object to the supplied operation */
  follow(operation: RemoteOperation<T>): void {
    this.emit("submit");

    this.operation = operation;
    this.errorsInfo = {};
    this._errors = makeErrorBag();

    this.operation.promise
      ?.then(
        (a) => {
          this.emit("success", a);
        },
        (err) => {
          this.processErrors();
          this.renameErrors();
          this.formatErrors();
          this.emit("failure", err);
        },
      )
      .finally(() => {
        this.emit("finally");
      });
  }

  followPromise(promise: Promise<T>): void {
    this.follow(new RemoteOperation<T>().follow(promise));
  }

  followPinia(info: ReturnType<UpdateDataFn<T>>): void {
    this.followPromise(info.promise);
  }

  /** Potentially called by the backing component */
  initComponent(..._: unknown[]): void {
    // no-op
  }

  /** The notify function */
  notify(type: ToastType, extra: Record<string, string | number> = {}): void {
    const store = useToastsStore();

    store.addOne({
      type: type,
      title: this.getLocaleString(`${type}.title`, extra),
      message: this.getLocaleString(`${type}.message`, extra),
    });
  }

  /** A shortcut to get a translated notifications string */
  getLocaleString(
    key: string,
    values: Record<string, string | number> = {},
  ): string {
    return this.$i18n
      .t(`notifications.handlers.${this.keyPath}.${key}`, values)
      .toString();
  }

  /** Wether there were field-specific errors returned from the api */
  get hasFieldErrors(): boolean {
    if (!this.isError) return false;

    let hasErrors = false;

    for (const [field, errors] of Object.entries(this._errors)) {
      hasErrors = hasErrors || (!field.startsWith("$") && errors.length > 0);
    }

    return hasErrors;
  }

  /** The errors resulting from the failure of the action, formatted. */
  get errors(): ActionErrorBag {
    return this._errors;
  }

  /** The generic part of the error processing */
  processErrors(): void {
    // No operation or in success? Don't do anything.
    // This case should not happen.
    if (
      !this.operation ||
      !this.operation.isFinished ||
      this.operation.isSuccess
    ) {
      return;
    }

    // From here on, this.operation.isError == true

    const error = this.operation?.error as Error | null; // We'll re-use this one a lot.

    // No data from the API? Generic error. This case should not happen.
    if (!error) {
      this.errorsInfo.generic = true;
      return;
    }

    // Scalingo API errors handling
    if (error instanceof APIError) {
      this.errorsInfo.scalingo = true;

      // Server error
      if (error.status >= 500) {
        this.errorsInfo.generic = true;
        return;
      }

      // Could be global auth error, could be more like "forbidden".
      if (error.status === 401) this.errorsInfo.unauthorized = true;

      // Payment required
      if (error.status === 402) this.errorsInfo.paymentRequired = true;

      // Forbidden access.
      if (error.status === 403) this.errorsInfo.forbidden = true;

      // Not found. Could be routing error, no-longer existing resources, etc...
      if (error.status === 404) this.errorsInfo.notFound = true;

      // If we have an error that looks like an error bag, copy them
      if (error.data?.errors !== null && error.data?.errors !== undefined) {
        for (const [field, errs] of Object.entries(
          error.data.errors as ErrorBag,
        )) {
          if (!this._errors[field]) this._errors[field] = [];

          this._errors[field].push(...errs);
        }
      }

      // Otherwise, the structure of the response is not reliable.
      // Usually {error: "message blabbla", code: "blabla-code"}, but not always.
      // For the time being, they will have to be handled per handler.
    } else if (error.name == "NetworkError") {
      this.errorsInfo.network = true;
    } else {
      // For now, considering all other errors generic
      this.errorsInfo.generic = true;
    }
  }

  /** Renames an error field. Merges if the destination is already existing. */
  renameErrors(): void {
    if (!this.hasFieldErrors) return;

    for (const [oldField, newField] of Object.entries(
      this.errorFieldsMapping,
    )) {
      if (this._errors[oldField]) {
        if (!this._errors[newField]) this._errors[newField] = [];

        this._errors[newField] = this._errors[newField] || [];
        this._errors[newField].push(...this._errors[oldField]);

        delete this._errors[oldField];
      }
    }
  }

  /**
   * The custom part of the error processing.
   * To be overridden by subclasses.
   */
  formatErrors(): void {
    return;
  }

  on = this._emitter.on;
  off = this._emitter.off;
  emit = this._emitter.emit;
}
