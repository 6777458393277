<template>
  <VerticalPageMenu :items="menuItems" />
</template>

<script>
import { defineComponent } from "vue";

import VerticalPageMenu from "@/components/molecules/menus/VerticalPageMenu.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DeploymentMenu",
  components: { VerticalPageMenu },
  props: {
    selection: String,
    app: Object,
    scmRepoLink: Object,
  },
  computed: {
    selectedRoutes() {
      if (this.selection) {
        return [this.selection];
      }

      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems() {
      const routerParams = { id: this.app.name };

      const menuItems = [
        {
          name: this.$t("deployments"),
          key: Routes.App.Deploy.List.Root,
          routerArgs: {
            name: Routes.App.Deploy.List.Root,
            params: routerParams,
          },
        },
        {
          name: this.$t("configuration"),
          key: Routes.App.Deploy.Configuration,
          routerArgs: {
            name: Routes.App.Deploy.Configuration,
            params: routerParams,
          },
        },
      ];

      if (this.scmRepoLink) {
        menuItems.push({
          name: this.$t("manual"),
          key: Routes.App.Deploy.Manual,
          routerArgs: { name: Routes.App.Deploy.Manual, params: routerParams },
        });
      }

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.key)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
  en:
    deployments: "History"
    configuration: "Configuration"
    manual: "Manual deployment"
  fr:
    deployments: "Historique"
    configuration: "Configuration"
    manual: "Déploiement manuel"
</i18n>
