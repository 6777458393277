<template>
  <div class="flex justify-center">
    <div
      :class="classFn"
      class="self-center bg-scale-0 rounded-lg py-6 flex flex-col flex-grow border border-scale-2"
    >
      <StepCardHeader
        :step="step"
        :title="title"
        :subtitle="subtitle"
        :enabled="enabled"
      />
      <div class="px-8">
        <slot v-if="enabled" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import StepCardHeader from "@/components/molecules/card/StepCardHeader.vue";

export default defineComponent({
  name: "StepCard",
  components: { StepCardHeader },
  props: {
    step: Number,
    title: String,
    subtitle: String,
    enabled: {
      type: Boolean,
      default: true,
    },
    maxSize: String,
  },
  computed: {
    classFn: function () {
      let classString = this.maxSize;

      if (this.enabled) {
        classString += " shadow-lg";
      }

      return classString;
    },
  },
});
</script>
