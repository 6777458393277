<template>
  <ViewComponent
    :user="currentUser"
    :billingProfile="billingProfile"
    :billingProfileHandler="billingProfileHandler"
    :freeTrialHandler="freeTrialHandler"
    @billing-profile-form-submitted="(e) => billingProfileHandler.submit(e)"
    @billing-modal-opened="openBillingModal"
    @billing-modal-closed="closeBillingModal"
    @stop-free-trial="freeTrialHandler.submit()"
    @free-trial-modal-opened="openFreeTrialModal"
    @free-trial-modal-closed="closeFreeTrialModal"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/billing/Profile.vue";
import {
  CreateBillingProfileHandler,
  EditBillingProfileHandler,
  DeleteFreeTrialHandler,
} from "@/lib/handlers";
import { billingProfile } from "@/store/billing-profile";

export default defineComponent({
  name: "ProfileContainer",
  components: { ViewComponent },
  data() {
    return {
      billingProfileHandler: null,
      freeTrialHandler: null,
    };
  },
  computed: {
    billingProfile() {
      return billingProfile(this.$store);
    },
  },
  methods: {
    openBillingModal() {
      if (this.billingProfile) {
        this.billingProfileHandler = new EditBillingProfileHandler(
          this,
          this.billingProfile,
        );
      } else {
        this.billingProfileHandler = new CreateBillingProfileHandler(this);
      }
    },
    closeBillingModal() {
      this.billingProfileHandler = null;
    },
    openFreeTrialModal() {
      this.freeTrialHandler = new DeleteFreeTrialHandler(this);
    },
    closeFreeTrialModal() {
      this.freeTrialHandler = null;
    },
  },
});
</script>
