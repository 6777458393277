<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" />
    </template>
    <template #body>
      <template v-if="userIsOwner">
        <p>{{ $t("allowed") }}</p>

        <SCButton
          class="mt-4"
          @click="$emit('click')"
        >
          {{ $t("action") }}
        </SCButton>
      </template>

      <FormAlert
        v-else
        class="mt-4"
        styling="warning"
      >
        <template #text>
          {{ $t("notAllowed", { name: app.owner.username }) }}
        </template>
      </FormAlert>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "RenameAppCard",
  components: {
    CardHeader,
    Card,
    SCButton,
    FormAlert,
  },
  props: {
    app: Object,
    user: Object,
  },
  emits: ["click"],
  data() {
    return {};
  },
  computed: {
    userIsOwner() {
      return this.user.id === this.app.owner.id;
    },
  },
});
</script>

<i18n>
en:
  title: "Rename app"
  action: "Rename app"
  allowed: "As the owner of this application, you are the only one who has the right to rename it."
  notAllowed: "Only {name}, owner of the app is allowed to rename it."
fr:
  title: "Renommer l’application"
  action: "Renommer l’application"
  allowed: "En tant que propriétaire de cette application, vous seul·e êtes autorisé·e à la renommer."
  notAllowed: "Seul·e {name}, propriétaire de l'application, peut la renommer."
</i18n>
