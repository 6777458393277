<template>
  <ViewComponent
    :event="event"
    :eventComplement="eventComplement"
    @body-opened="bodyOpened"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/activity/Show.vue";
import { ensureAuditLog, findAuditLogs } from "@/store/audit-logs";
import { ensureEvent, findEvent } from "@/store/events";

export default defineComponent({
  name: "ActivityShowController",
  components: { ViewComponent },
  data() {
    return { eventComplement: null };
  },
  computed: {
    event() {
      if (this.$route.params.eventId) {
        return findEvent(this.$store, this.$route.params.eventId);
      }

      return null;
    },
  },
  beforeMount() {
    ensureEvent(this.$store, this.$route.params.eventId);
  },
  methods: {
    bodyOpened(eventArgs) {
      if (eventArgs.event.type === "run") {
        this.complementRunEvent(eventArgs);
      }
    },
    async complementRunEvent(eventArgs) {
      if (!("audit_log_id" in eventArgs.event.type_data)) {
        return;
      }
      const auditLogId = eventArgs.event.type_data.audit_log_id;

      const eventComplement = { eventId: eventArgs.event.id, data: null };

      const operation = await ensureAuditLog(
        this.$store,
        eventArgs.event.type_data.audit_log_id,
      );
      await operation.settled;
      await this.$nextTick();

      eventComplement.data = findAuditLogs(this.$store, auditLogId);
      this.eventComplement = eventComplement;
    },
  },
});
</script>
