<template>
  <VerticalPageMenu :items="menuItems" />
</template>

<script>
import { defineComponent } from "vue";

import VerticalPageMenu from "@/components/molecules/menus/VerticalPageMenu.vue";
import { isC3Customer } from "@/lib/utils/billing-profile";
import { formatAmount } from "@/lib/utils/currency";
import { Routes } from "@/router/names";
import { userIsAdminOrHasFlag } from "@/store/user";
import { currentUser } from "@/stores/user";

export default defineComponent({
  name: "BillingMenu",
  components: { VerticalPageMenu },
  props: {
    selection: String,
    billingProfile: Object,
    hasCredits: Boolean,
    hasOrders: Boolean,
  },
  computed: {
    selectedRoutes() {
      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems: function () {
      const routerParams = {};

      const menuItems = [
        {
          name: this.$t("profile"),
          routerArgs: { name: Routes.Billing.Profile, params: routerParams },
        },
      ];

      if (this.billingProfile && !isC3Customer(this.billingProfile)) {
        const balance = formatAmount(this.billingProfile.balance);
        if (userIsAdminOrHasFlag(currentUser(), "employee")) {
          menuItems.push({
            name: this.$t("supportPlans"),
            routerArgs: {
              name: Routes.Billing.SupportPlans,
              params: routerParams,
            },
          });
        }
        menuItems.push(
          {
            name: this.$t("vouchers"),
            routerArgs: { name: Routes.Billing.Vouchers, params: routerParams },
          },
          {
            name: this.$t("balance", { balance }),
            routerArgs: { name: Routes.Billing.Balance, params: routerParams },
          },
          {
            name: this.$t("paymentMethods"),
            routerArgs: {
              name: Routes.Billing.PaymentMethods,
              params: routerParams,
            },
          },
          {
            name: this.$t("invoices"),
            routerArgs: {
              name: Routes.Billing.Invoices.Root,
              params: routerParams,
            },
          },
        );
      }

      if (this.hasCredits) {
        menuItems.push({
          name: this.$t("credits"),
          routerArgs: { name: Routes.Billing.Credits, params: routerParams },
        });
      }

      if (this.hasOrders) {
        menuItems.push({
          name: this.$t("orders"),
          routerArgs: {
            name: Routes.Billing.Orders.List,
            params: routerParams,
          },
        });
      }

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.routerArgs.name)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
  en:
    profile: "Billing profile"
    supportPlans: "Support plans"
    vouchers: "Vouchers"
    credits: "Credits"
    balance: "Balance ({balance})"
    paymentMethods: "Payment methods"
    orders: "Orders"
    invoices: "Invoices"
    operations: "Operations"
  fr:
    profile: "Profil de facturation"
    supportPlans: "Offres de support"
    vouchers: "Vouchers"
    credits: "Crédits"
    balance: "Balance ({balance})"
    paymentMethods: "Moyens de paiement"
    orders: "Commandes"
    invoices: "Factures"
    operations: "Opérations"
</i18n>
