/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "vue";

type Opts = {
  handlerPropKey: string;
  formDataKey: string;
  modal: boolean;
  withoutHandler: boolean;
};

/**
 * Returns a mixin with behavior related to components with forms and form handlers.
 * The keys used for the form handler and the form data
 * can be overridden if needed.
 */
export function formHandlerMixin(opts: Partial<Opts> = {}) {
  const options: Opts = {
    handlerPropKey: opts.handlerPropKey || "formHandler",
    formDataKey: opts.formDataKey || "form",
    modal: opts.modal || false,
    withoutHandler: opts.withoutHandler || false,
  };

  return defineComponent({
    props: {
      [options.handlerPropKey]: {
        type: Object,
        required: !options.withoutHandler,
      },
    },
    emits: ["formSubmitted"],
    data() {
      return {
        [options.formDataKey]: {},
      };
    },
    watch: {
      [options.handlerPropKey]: {
        immediate: true,
        handler(newVal) {
          if (!newVal) return;

          newVal.initComponent(this);

          if (options.modal) {
            newVal.on("success", () => {
              (this as any).$emit("modalClosed");
            });
          }
        },
      },
    },
    methods: {
      submitForm() {
        const form = (this as any)[options.formDataKey];

        this.$emit("formSubmitted", form);
      },
    },
  });
}

const FormHandlerMixin = formHandlerMixin();
const FormModalHandlerMixin = formHandlerMixin({ modal: true });

/** Default version of the mixin */
export default FormHandlerMixin;

export { FormHandlerMixin, FormModalHandlerMixin };
