<template>
  <div class="mt-6">
    <Form
      ref="form"
      v-slot="{ meta, handleSubmit }"
      as=""
    >
      <form @submit.prevent="handleSubmit(submit)">
        <div class="flex space-x-3">
          <div class="w-1/2">
            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.containerType"
              name="container_type"
              rules="required"
            >
              <AdvancedSelectInput
                :name="field.name"
                :modelValue="field.value"
                :options="containerOptions"
                :errors="errors"
                @update:model-value="handleChange"
              >
                <template #label>
                  {{ $t("labels.containerType") }}
                </template>

                <template #text="{ option }">
                  <span>{{ option.text }}</span>
                </template>
              </AdvancedSelectInput>
            </Field>
          </div>

          <div class="w-1/2">
            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.metric"
              name="metric"
              rules="required"
            >
              <AdvancedSelectInput
                :name="field.name"
                :modelValue="field.value"
                :options="metricOptions"
                :errors="errors"
                @update:model-value="handleChange"
              >
                <template #label>
                  {{ $t("labels.metric") }}
                </template>

                <template #text="{ option }">
                  {{ option.text }}
                </template>
              </AdvancedSelectInput>
            </Field>
          </div>
        </div>

        <div class="flex space-x-3 mt-4">
          <div class="w-1/3">
            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.is"
              name="is"
              rules="required"
            >
              <AdvancedSelectInput
                :name="field.name"
                :modelValue="field.value"
                :options="isOptions"
                :errors="errors"
                @update:model-value="handleChange"
              >
                <template #label>
                  {{ $t("labels.is") }}
                </template>

                <template #text="{ option }">
                  {{ option.text }}
                </template>
              </AdvancedSelectInput>
            </Field>
          </div>
          <div class="w-1/3">
            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.limit"
              name="limit"
              rules="required"
            >
              <NumberInput
                :name="field.name"
                :modelValue="field.value"
                :unit="$t('units.limit.' + form.metric)"
                :label="$t('labels.limit')"
                min="0"
                labelClass="capitalize"
                :errors="errors"
                @update:model-value="handleChange"
              />
            </Field>
          </div>
          <div class="w-1/3">
            <Field
              v-slot="{ field, errors, handleChange }"
              v-model="form.duration"
              name="duration"
              rules="required"
            >
              <NumberInput
                :name="field.name"
                :modelValue="field.value"
                :unit="$t('units.duration')"
                :label="$t('labels.duration')"
                min="0"
                labelClass="capitalize"
                :errors="errors"
                @update:model-value="handleChange"
              />
            </Field>
          </div>
        </div>

        <div>{{ formHandler.errors["base"] }}</div>
        <div class="flex">
          <SCButton
            size="lg"
            kind="primary"
            type="submit"
            class="flex-grow"
            :block="true"
            :disabled="!meta.valid"
            :loading="isSubmitting"
          >
            {{ $t("action") }}
          </SCButton>
        </div>
      </form>
    </Form>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import NumberInput from "@/components/molecules/inputs/NumberInput.vue";

export default defineComponent({
  name: "MetricsChoiceForm",
  components: {
    AdvancedSelectInput,
    NumberInput,
    SCButton,
    Field,
    Form,
  },
  props: {
    formHandler: Object,
    containers: Object,
  },
  emits: ["formSubmitted"],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    containerOptions() {
      const options = [];

      if (this.containers.items.length === 0) {
        return options;
      }

      for (const container of this.containers.items) {
        options.push({ text: container.id, value: container.id });
      }

      return options;
    },
    isOptions() {
      return [
        { text: this.$t("selects.is.greater"), value: "greater" },
        { text: this.$t("selects.is.lower"), value: "lower" },
      ];
    },
    metricOptions() {
      return [
        { text: this.$t("selects.metric.cpu"), value: "cpu" },
        { text: this.$t("selects.metric.memory"), value: "memory" },
        { text: this.$t("selects.metric.swap"), value: "swap" },
        {
          text: this.$t("selects.metric.p95_response_time"),
          value: "p95_response_time",
        },
        {
          text: this.$t("selects.metric.5XX"),
          value: "5XX",
        },
        {
          text: this.$t("selects.metric.rpm"),
          value: "rpm",
        },
        {
          text: this.$t("selects.metric.rpm_per_container"),
          value: "rpm_per_container",
        },
      ];
    },
    isSubmitting() {
      return this.formHandler?.isSubmitting || false;
    },
  },
  watch: {
    formHandler: {
      immediate: true,
      handler(newVal) {
        newVal?.initComponent(this);
      },
    },
  },
  methods: {
    submit() {
      this.$emit("formSubmitted", this.form);
    },
  },
});
</script>

<i18n>
en:
  labels:
    containerType: "Define an alert on"
    metric: "If"
    is: "Is"
    limit: "Limit"
    duration: "During"
  selects:
    is:
      greater: "Greater than or equal to"
      lower: "Lower than or equal to"
    metric:
      cpu: "CPU"
      memory: "RAM"
      swap: "Swap"
      p95_response_time: "Response time"
      5XX: "5xx errors"
      rpm: "Requests per minute"
      rpm_per_container: "RPM per container"
  units:
    duration: "min"
    limit:
      cpu: "%"
      memory: "%"
      swap: "%"
      p95_response_time: "ms"
      5XX: "per min"
      rpm: "rpm"
      rpm_per_container: "rpm"
  action: "Next step"
fr:
  labels:
    containerType: "Definir une alerte sur"
    metric: "Si"
    is: "Est"
    limit: "Limite"
    duration: "Durant"
  selects:
    is:
      greater: "Plus grand que ou égal à"
      lower: "Plus petit que ou égal à"
    metric:
      cpu: "CPU"
      memory: "RAM"
      swap: "Swap"
      p95_response_time: "Temps de réponse"
      5XX: "Erreurs 5xx"
      rpm: "Requête par minute"
      rpm_per_container: "RPM par conteneur"
  units:
    duration: "min"
    limit:
      cpu: "%"
      memory: "%"
      swap: "%"
      p95_response_time: "ms"
      5XX: "par min"
      rpm: "rpm"
      rpm_per_container: "rpm"
  action: "Étape suivante"
</i18n>
