<template>
  <ViewComponent
    :appInfos="appInfos"
    :allAppInfosCount="allAppInfosCount"
    :user="currentUser"
    :displayFilters="displayFilters"
    :currentSort="currentSort.key"
    :filterDefs="filterDefs"
    :filterQuery="filterQuery"
    :currentTypeFilter="currentTypeFilter.value"
    :currentStatusFilter="currentStatusFilter.value"
    :currentOwnerFilter="currentOwnerFilter && currentOwnerFilter.value"
    :currentRegionFilter="currentRegionFilter && currentRegionFilter.value"
    @sort-by-choice-selected="sortByChoiceSelected"
    @toggle-filters-display="toggleFiltersDisplay"
    @filter-by="filterBy"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/Projects.vue";
import { filterItems } from "@/lib/pinia/utils/filter-items";
import {
  getAppTypeFilters,
  getAppStatusFilters,
  getAppOwnerFilters,
  getAppRegionsFilters,
} from "@/lib/utils/app-filters";
import { POSSIBLE_SORTS, DEFAULT_POSSIBLE_SORT } from "@/lib/utils/app-sorts";
import { applyFilters } from "@/lib/utils/filters";
import { Routes } from "@/router/names";
import { updatePreferences } from "@/store/user";
import { useAppInfosStore } from "@/stores/app-infos";

export default defineComponent({
  name: "Projects",
  components: { ViewComponent },
  setup() {
    const appInfosStore = useAppInfosStore();

    return { appInfosStore };
  },
  data: function () {
    return {
      currentSort: DEFAULT_POSSIBLE_SORT,
      displayFilters: false,
    };
  },
  computed: {
    filterDefs() {
      return {
        type: getAppTypeFilters(),
        status: getAppStatusFilters(),
        owner: getAppOwnerFilters(this.appInfosStore.items),
        region: getAppRegionsFilters(this.appInfosStore.items),
      };
    },
    filterQuery() {
      const query = this.$route.query;
      const filterQuery = {};

      if (query.type) filterQuery.type = query.type;
      if (query.status) filterQuery.status = query.status;
      if (query.owner) filterQuery.owner = query.owner;
      if (query.region) filterQuery.region = query.region;

      return filterQuery;
    },
    currentTypeFilter() {
      let opt;

      if (this.$route.query?.type) {
        opt = this.filterDefs.type.filter.options.find(
          (option) => option.value === this.$route.query?.type,
        );
      }

      return opt || this.filterDefs.type.default;
    },
    currentStatusFilter() {
      let opt;

      if (this.$route.query?.status) {
        opt = this.filterDefs.status.filter.options.find(
          (option) => option.value === this.$route.query?.status,
        );
      }

      return opt || this.filterDefs.status.default;
    },
    currentOwnerFilter() {
      let opt;

      if (this.$route.query?.owner) {
        opt = this.filterDefs.owner.filter.options.find(
          (option) => option.value === this.$route.query?.owner,
        );
      }

      return opt || this.filterDefs.owner.default;
    },
    currentRegionFilter() {
      let opt;

      if (this.$route.query?.region) {
        opt = this.filterDefs.region.filter.options.find(
          (option) => option.value === this.$route.query?.region,
        );
      }

      return opt || this.filterDefs.region.default;
    },
    currentNameFilter() {
      if (this.$route.query?.appName) {
        return this.$route.query?.appName;
      }

      return null;
    },
    allAppInfosCount() {
      return this.appInfosStore.items?.length || 0;
    },
    appInfos() {
      const listOptions = { ...this.currentSort.value };
      const filters = [];

      if (this.currentTypeFilter?.check) {
        filters.push(this.currentTypeFilter.check);
      }

      if (this.currentStatusFilter?.check) {
        filters.push(this.currentStatusFilter.check);
      }

      if (this.currentOwnerFilter?.check) {
        filters.push(this.currentOwnerFilter.check);
      }

      if (this.currentRegionFilter?.check) {
        filters.push(this.currentRegionFilter.check);
      }

      if (this.currentNameFilter) {
        filters.push((appInfo) => {
          return appInfo.name.includes(this.currentNameFilter);
        });
      }

      listOptions.transform = applyFilters(filters);

      const { items, promiseInfo, initialPromiseInfo, lastFetchedAt } =
        this.appInfosStore;
      const filteredItems = filterItems(items, listOptions);

      return {
        items: filteredItems,
        promiseInfo,
        initialPromiseInfo,
        lastFetchedAt,
      };
    },
  },
  watch: {
    allAppInfosCount: function () {
      if (this.allAppInfosCount === 0) {
        this.$router.push({ name: Routes.Welcome });
      }
    },
  },
  created() {
    this.appInfosStore.ensure();
  },
  beforeMount() {
    const preferences = this.currentUser.preferences;

    if (preferences?.app_sort_key) {
      this.currentSort = POSSIBLE_SORTS.find(
        (item) => item.key === preferences?.app_sort_key,
      );
    }
  },
  mounted() {
    if (Object.keys(this.filterQuery).length > 0) {
      this.displayFilters = true;
    }
  },
  methods: {
    sortByChoiceSelected({ action }) {
      updatePreferences(this.$store, { app_sort_key: action.key });
      this.currentSort = action;
    },
    toggleFiltersDisplay() {
      if (this.displayFilters) {
        this.clearFilters();
      }

      this.displayFilters = !this.displayFilters;
    },
    clearFilters() {
      const query = { ...this.$route.query };

      delete query.type;
      delete query.status;
      delete query.owner;
      delete query.region;

      this.$router.push({ query }).catch((e) => {
        if (e.name !== "NavigationDuplicated") {
          throw e;
        }
      });
    },
    filterBy({ filter, option }) {
      this.$router.push({
        query: {
          ...this.$route.query,
          [filter.key]: option.value,
        },
      });
    },
  },
});
</script>
