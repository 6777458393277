<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 9.08V8.92C21.0004 8.75501 20.9177 8.60091 20.78 8.51L13.11 3.26C12.8625 3.0908 12.5698 3.00019 12.27 3H11.73C11.4302 3.00019 11.1375 3.0908 10.89 3.26L3.22 8.51C3.08232 8.60091 2.99963 8.75501 3 8.92V9.08C2.99963 9.24499 3.08232 9.39909 3.22 9.49L10.89 14.74C11.1375 14.9092 11.4302 14.9998 11.73 15H12.27C12.5698 14.9998 12.8625 14.9092 13.11 14.74L20.78 9.49C20.9177 9.39909 21.0004 9.24499 21 9.08ZM16.9 14.27L18.67 13.06L20.78 14.51C20.9177 14.6009 21.0004 14.755 21 14.92V15.08C21.0004 15.245 20.9177 15.3991 20.78 15.49L13.11 20.74C12.8625 20.9092 12.5698 20.9998 12.27 21H11.73C11.4302 20.9998 11.1375 20.9092 10.89 20.74L3.22 15.49C3.08232 15.3991 2.99963 15.245 3 15.08V14.92C2.99963 14.755 3.08232 14.6009 3.22 14.51L5.33 13.06L7.06 14.27L6 15L11.93 19H12.15L18 15L16.9 14.27Z"
      fill="currentColor"
    />
    <circle
      cx="18"
      cy="17"
      r="5.5"
      fill="currentColor"
      stroke="white"
    />
    <path
      d="M19.4002 15.2001H16.6002C16.3792 15.2001 16.2002 15.3791 16.2002 15.6001V18.4001C16.2002 18.6211 16.3792 18.8001 16.6002 18.8001H19.4002C19.6212 18.8001 19.8002 18.6211 19.8002 18.4001V15.6001C19.8002 15.3791 19.6212 15.2001 19.4002 15.2001Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppStopGlyph",
});
</script>
