export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "labels": {
          "containerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define an alert on"])},
          "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If"])},
          "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is"])},
          "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During"])}
        },
        "selects": {
          "is": {
            "greater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greater than or equal to"])},
            "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower than or equal to"])}
          },
          "metric": {
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAM"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response time"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5xx errors"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests per minute"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPM per container"])}
          }
        },
        "units": {
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
          "limit": {
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ms"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per min"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])}
          }
        },
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next step"])}
      },
      "fr": {
        "labels": {
          "containerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir une alerte sur"])},
          "metric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
          "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est"])},
          "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durant"])}
        },
        "selects": {
          "is": {
            "greater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus grand que ou égal à"])},
            "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus petit que ou égal à"])}
          },
          "metric": {
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAM"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de réponse"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs 5xx"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requête par minute"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPM par conteneur"])}
          }
        },
        "units": {
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
          "limit": {
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ms"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par min"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])},
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])}
          }
        },
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape suivante"])}
      }
    }
  })
}
