<template>
  <section>
    <template v-if="scmRepoLink.latestFetch.isLoading">
      <LoadingCardState />
    </template>
    <template v-if="scmRepoLink.latestFetch.isSuccess">
      <Alert
        styling="warning"
        :text="$t('review_app_risk.text')"
        :withIcon="true"
        class="mb-8"
      >
        <template #icon>
          <DangerSignGlyph />
        </template>
        <template #textComplement>
          <DocLink href="https://doc.scalingo.com/platform/app/review-apps">
            {{
              $t("review_app_risk.more")
            }}
          </DocLink>
        </template>
      </Alert>
      <DeploymentsViaCard
        :user="user"
        :scmRepoLink="scmRepoLink.value"
        :deleteSCMRepoLinkHandler="deleteSCMRepoLinkHandler"
        :editSCMRepoLinkerHandler="editSCMRepoLinkerHandler"
        @unlink-repository="$emit('unlinkRepository')"
        @change-linker="$emit('changeLinker')"
      />
      <AutomaticReviewAppsCard
        class="mt-8"
        :scmRepoLink="scmRepoLink.value"
        @start-review-app-configuration="$emit('startReviewAppConfiguration')"
      />
    </template>
    <template v-if="scmRepoLink.latestFetch.isError">
      <Alert
        styling="warning"
        :text="$t('alert.title')"
        :withButton="true"
        :textButton="$t('alert.action')"
        :withIcon="true"
        :buttonRouteArgs="routeArgs"
      >
        <template #textComplement>
          {{ $t("alert.message") }}
        </template>
      </Alert>
    </template>

    <EditAutomaticReviewAppsModal
      v-if="reviewAppsConfigHandler"
      :owner="app.owner"
      :scmRepoLink="scmRepoLink"
      :formHandler="reviewAppsConfigHandler"
      @modal-closed="$emit('cancelReviewAppConfiguration')"
      @form-submitted="(e) => $emit('submitReviewAppConfiguration', e)"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import Alert from "@/components/molecules/alerts/Alert.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import DeploymentsViaCard from "@/components/parts/app/showDeployConfiguration/DeploymentsViaCard.vue";
import AutomaticReviewAppsCard from "@/components/parts/app/showReviewApps/AutomaticReviewAppsCard.vue";
import EditAutomaticReviewAppsModal from "@/components/parts/app/showReviewApps/EditAutomaticReviewAppsModal.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ReviewApps",
  components: {
    DangerSignGlyph,
    DocLink,
    AutomaticReviewAppsCard,
    DeploymentsViaCard,
    LoadingCardState,
    EditAutomaticReviewAppsModal,
    Alert,
  },
  props: {
    app: Object,
    user: Object,
    scmRepoLink: Object,
    reviewAppsConfigHandler: Object,
    deleteSCMRepoLinkHandler: Object,
    editSCMRepoLinkerHandler: Object,
  },
  emits: [
    "cancelReviewAppConfiguration",
    "changeLinker",
    "startReviewAppConfiguration",
    "submitReviewAppConfiguration",
    "unlinkRepository",
  ],
  computed: {
    routeArgs() {
      return {
        name: Routes.App.Deploy.Configuration,
        params: { id: this.app.name, region: this.app.region },
      };
    },
  },
});
</script>

<i18n>
  en:
    alert:
      title: "No configuration linked"
      message: "Configure a repository deployment method to be able to use review apps"
      action: "Configure"
    review_app_risk:
      text: "Scalingo does not recommend using review apps for production applications. Our recommendation is to create a second application linked to the same source code repository, but without production data."
      more: "Learn more"
  fr:
    alert:
      title: "Aucune configuration liée"
      message: "Configurez une méthode de déploiement à partir d'un repo pour pouvoir utiliser les review apps"
      action: "Configurer"
    review_app_risk:
      text: "Scalingo déconseille l'utilisation de review apps pour les applications de production. Notre recommandation est de créer une deuxième application liée au même dépôt de code source, mais sans donnée de production."
      more: "En savoir plus"
</i18n>
