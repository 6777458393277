<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
    :button="true"
  >
    <template #name>
      <GithubDelGlyph
        v-if="isGithub"
        class="h-6 w-6 text-scale-6"
      />
      <GitlabDelGlyph
        v-else
        class="h-6 w-6 text-scale-6"
      />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("repository") }}:
      <ExtLink
        :href="event.type_data.source"
        class="font-medium ml-1"
      >
        {{ event.type_data.repo_name }}
      </ExtLink>
    </template>
    <template #body>
      <div>
        {{ $t("unlinker") }}:
        <span class="font-medium ml-1">{{
          event.type_data.unlinker_username
        }}</span>
      </div>
      <div>
        {{ $t("repository") }}:
        <span class="font-medium ml-1">{{ event.type_data.repo_name }}</span>
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import GithubDelGlyph from "@/components/atoms/glyphs/GithubDelGlyph.vue";
import GitlabDelGlyph from "@/components/atoms/glyphs/GitlabDelGlyph.vue";
import ExtLink from "@/components/molecules/links/ExtLink.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "UnlinkScmEvent",
  components: { ExtLink, GitlabDelGlyph, GithubDelGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isGithub() {
      const source = this.event.type_data.source;
      return !!source.startsWith("https://github.com/");
    },
  },
});
</script>

<i18n>
en:
  title: "SCM integration unlinked"
  repository: "Repository"
  unlinker: "Unlinker"
fr:
  title: " Intégration SCM dissociée"
  repository: "Dépôt"
  unlinker: "Unlinker"
</i18n>
