<template>
  <div class="bg-scale-0">
    <div class="container px-4">
      <div class="flex py-4 border-t border-scale-2">
        <PageMenu :items="menuItems" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PageMenu from "@/components/molecules/menus/PageMenu.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "AppMenu",
  components: { PageMenu },
  props: {
    app: Object,
    selection: String,
  },
  computed: {
    selectedRoutes() {
      if (this.selection) {
        return [this.selection];
      }

      return this.$route.matched.map((e) => e.name).filter((e) => !!e);
    },
    menuItems: function () {
      if (!this.app) {
        return [];
      }

      const routerParams = { id: this.app.name };

      const menuItems = [
        {
          name: this.$t("overview"),
          key: Routes.App.Overview,
          routerArgs: { name: Routes.App.Overview, params: routerParams },
        },
        {
          name: this.$t("resources"),
          key: Routes.App.Resources,
          routerArgs: { name: Routes.App.Resources, params: routerParams },
        },
        {
          name: this.$t("deploy"),
          key: Routes.App.Deploy.Root,
          routerArgs: { name: Routes.App.Deploy.Root, params: routerParams },
        },
      ];

      if (!this.app._isReviewApp) {
        menuItems.push({
          name: this.$t("reviewApps"),
          key: Routes.App.ReviewApps.Root,
          routerArgs: {
            name: Routes.App.ReviewApps.Root,
            params: routerParams,
          },
        });
      }

      menuItems.push(
        {
          name: this.$t("activity"),
          key: Routes.App.Activity.Root,
          routerArgs: { name: Routes.App.Activity.Root, params: routerParams },
        },
        {
          name: this.$t("logs"),
          key: Routes.App.Logs.Root,
          routerArgs: { name: Routes.App.Logs.Root, params: routerParams },
        },
        {
          name: this.$t("metrics"),
          key: Routes.App.Metrics.Root,
          routerArgs: { name: Routes.App.Metrics.Root, params: routerParams },
        },
        {
          name: this.$t("environment"),
          key: Routes.App.Environment,
          routerArgs: { name: Routes.App.Environment, params: routerParams },
        },
        {
          name: this.$t("settings"),
          key: Routes.App.Settings,
          routerArgs: { name: Routes.App.Settings, params: routerParams },
        },
      );

      menuItems.forEach((menuItem) => {
        if (this.selectedRoutes.includes(menuItem.key)) {
          menuItem.selected = true;
        }
        menuItem.text = menuItem.name;
        menuItem.value = menuItem.routerArgs.name;
      });

      return menuItems;
    },
  },
});
</script>

<i18n>
  en:
    overview: "Overview"
    resources: "Resources"
    deploy: "Deploy"
    reviewApps: "Review apps"
    activity: "Activity"
    logs: "Logs"
    metrics: "Metrics"
    environment: "Environment"
    settings: "Settings"
  fr:
    overview: "Vue générale"
    resources: "Ressources"
    deploy: "Déploiement"
    reviewApps: "Review apps"
    activity: "Activité"
    logs: "Logs"
    metrics: "Métriques"
    environment: "Environnement"
    settings: "Paramètres"
</i18n>
