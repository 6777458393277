<template>
  <SimpleModal
    :hasTitleIcon="true"
    :overflowVisible="true"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title", { type: form.container }) }}
    </template>
    <template #subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template #titleIcon>
      <ScaleGlyph />
    </template>
    <template #body>
      <div class="popinWidth">
        <Form
          ref="form"
          v-slot="{ meta, handleSubmit }"
          as=""
        >
          <form @submit.prevent="handleSubmit(submitForm)">
            <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
              <div class="mt-6 flex">
                <p>
                  {{ $t("documentation.intro") }}
                  <DocLink
                    class="font-normal"
                    href="https://doc.scalingo.com/platform/app/autoscaler"
                  >
                    {{ $t("documentation.link") }}
                  </DocLink>
                </p>
              </div>
              <div class="mt-6 flex">
                <div class="w-1/2">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.min_containers"
                    name="min_containers"
                    :rules="minContainersRules"
                  >
                    <NumberInput
                      :name="field.name"
                      :modelValue="field.value"
                      :label="$t('form.containersRangeMinimum')"
                      labelClass="humanize"
                      min="2"
                      :max="maxContainers"
                      :errors="errors"
                      @update:model-value="handleChange"
                    />
                  </Field>
                </div>
                <div class="w-1/2 ml-2">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.max_containers"
                    name="max_containers"
                    :rules="maxContainersRules"
                  >
                    <NumberInput
                      :name="field.name"
                      :modelValue="field.value"
                      :label="$t('form.containersRangeMaximum')"
                      labelClass="humanize"
                      :min="form.min_containers"
                      :max="maxContainers"
                      :errors="errors"
                      @update:model-value="handleChange"
                    />
                  </Field>
                </div>
              </div>
              <div class="flex">
                <div class="w-1/2">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.metric"
                    name="metric"
                    rules="required"
                  >
                    <AdvancedSelectInput
                      :name="field.name"
                      :modelValue="field.value"
                      :options="whenOptions"
                      :errors="errors"
                      @update:model-value="handleChange"
                    >
                      <template #label>
                        {{ $t("form.scaleWhen") }}
                      </template>

                      <template #text="{ option }">
                        {{ option.text }}
                        <RecommendationPill v-if="option.recommended" />
                        <RecommendationPill
                          v-if="option.deprecated"
                          isDeprecation="true"
                        />
                      </template>
                    </AdvancedSelectInput>
                  </Field>
                </div>
                <div class="w-1/2 ml-2">
                  <Field
                    v-slot="{ field, errors, handleChange }"
                    v-model="form.target"
                    name="target"
                    :rules="targetRules"
                  >
                    <NumberInput
                      :name="field.name"
                      :modelValue="field.value"
                      :subText="recommendationLabel"
                      :unit="$t('form.variesFromUnit.' + form.metric)"
                      :label="$t('form.variesFrom')"
                      min="0"
                      :max="maxTarget"
                      labelClass="humanize"
                      :errors="errors"
                      @update:model-value="handleChange"
                    />
                  </Field>
                </div>
              </div>

              <CheckboxInputAtom
                v-if="initiallyDisabled"
                :modelValue="!form.disabled"
                :label="$t('form.reenable')"
                class="mt-6"
                @update:model-value="(e) => (form.disabled = !e)"
              />

              <FormAlert
                v-if="formHandler.errors.base"
                class="mt-4"
                styling="error"
              >
                <template #text>
                  {{ $t("errors.generic") }}
                </template>
              </FormAlert>

              <SCButton
                block
                kind="primary"
                type="submit"
                size="lg"
                class="flex-grow mt-12"
                :disabled="!meta.valid"
                :loading="formHandler?.isSubmitting"
              >
                {{ $t("buttonName") }}
              </SCButton>

              <SCButton
                block
                kind="neutral"
                size="lg"
                class="mt-4"
                @click="$emit('modalClosed')"
              >
                {{ $t("cancelButton") }}
              </SCButton>
            </div>
          </form>
        </Form>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import ScaleGlyph from "@/components/atoms/glyphs/ScaleGlyph.vue";
import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import RecommendationPill from "@/components/atoms/pills/RecommendationPill.vue";
import FormAlert from "@/components/molecules/alerts/FormAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";
import NumberInput from "@/components/molecules/inputs/NumberInput.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import { METRICS_IN_PERCENTS } from "@/store/autoscalers";

export default defineComponent({
  name: "AutoScaleModal",
  components: {
    AdvancedSelectInput,
    DocLink,
    NumberInput,
    RecommendationPill,
    ScaleGlyph,
    SCButton,
    SimpleModal,
    Field,
    Form,
    FormAlert,
    CheckboxInputAtom,
  },
  mixins: [FormModalHandlerMixin],
  props: {
    maxContainers: [Number, String],
    autoscalerRecommendedValue: Number,
  },
  emits: ["autoscalerMetricChanged", "modalClosed"],
  data() {
    return {
      form: {
        initiallyDisabled: false,
        initialMetric: null,
        initialTarget: null,
      },
    };
  },
  computed: {
    minContainersRules() {
      return this.form.max_containers
        ? `required|integer|max_value:${this.form.max_containers}`
        : "required|integer";
    },
    maxContainersRules() {
      return this.form.min_containers
        ? `required|integer|min_value:${this.form.min_containers}`
        : "required|integer";
    },
    targetRules() {
      return METRICS_IN_PERCENTS.includes(this.form.metric)
        ? "required|max_value:200"
        : "required";
    },
    maxTarget() {
      return METRICS_IN_PERCENTS.includes(this.form.metric) ? 200 : null;
    },
    recommendationLabel() {
      if (this.autoscalerRecommendedValue) {
        return this.$i18n.t(`form.variesFromSubText.${this.form.metric}`, {
          value: this.autoscalerRecommendedValue,
        });
      } else {
        return this.$i18n.t("form.noRecommendation");
      }
    },
    whenOptions() {
      const standard_options = [
        { text: this.$t("form.variesFromValues.cpu"), value: "cpu" },
        { text: this.$t("form.variesFromValues.memory"), value: "memory" },
        { text: this.$t("form.variesFromValues.swap"), value: "swap" },
      ];

      if (this.form.container == "web") {
        return [
          {
            text: this.$t("form.variesFromValues.rpm_per_container"),
            value: "rpm_per_container",
            recommended: true,
          },
          ...standard_options,
          {
            text: this.$t("form.variesFromValues.p95_response_time"),
            value: "p95_response_time",
          },
          { text: this.$t("form.variesFromValues.5XX"), value: "5XX" },
          {
            text: this.$t("form.variesFromValues.rpm"),
            value: "rpm",
            deprecated: true,
          },
        ];
      }

      return standard_options;
    },
  },
  watch: {
    formHandler: {
      immediate: true,
      handler(newVal) {
        newVal?.initComponent(this);

        // Without this temp field,
        // the checkbox would disappear as soon as we tick it
        this.initiallyDisabled = this.form.disabled;

        this.initialMetric = this.form.metric;
        this.initialTarget = this.form.target;
      },
    },
    "form.metric": {
      immediate: true,
      handler(metric) {
        if (metric === this.initialMetric) {
          this.form.target = this.initialTarget;
        } else {
          this.form.target = null;
        }

        this.$emit("autoscalerMetricChanged", {
          container: this.form.container,
          metric,
        });
      },
    },
  },
});
</script>

<style scoped>
.popinWidth {
  width: 750px;
}
</style>

<i18n>
en:
  title: "Autoscaler for {type} containers"
  subtitle: "Configure how this application adjusts its scaling based on the load"
  documentation:
    intro: "Finding the best scaling target for your app is not an easy task."
    link: "Please read our recommendations"
  errors:
    generic: "Unable to configure the autoscaler: please check that the selected values are correct."
  form:
    containersRange: "Containers range"
    containersRangeMinimum: "Minimum number of containers"
    containersRangeMaximum: "Maximum number of containers"
    scaleWhen: "Metric"
    variesFrom: "Target"
    noRecommendation: "Set a value and adjust it according to the impact on your app"
    reenable: "Re-enable autoscaler"
    variesFromValues:
      rpm_per_container: "RPM per container"
      cpu: "CPU"
      memory: "RAM"
      swap: "Swap"
      p95_response_time: "Response time"
      5XX: "5xx errors"
      rpm: "Requests per minute"
    variesFromSubText:
      rpm_per_container: "Recommended value: {value} rpm"
      cpu: "Recommended value: {value} %"
      memory: "Recommended value: {value} %"
      swap: "Recommended value: {value} %"
      p95_response_time: "Recommended value: {value} ms"
      5XX: "Recommended value: {value} per min"
      rpm: "{value} rpm recommended"
    variesFromUnit:
      rpm_per_container: "rpm"
      cpu: "%"
      memory: "%"
      swap: "%"
      p95_response_time: "ms"
      5XX: "per min"
      rpm: "rpm"
  buttonName: "Confirm"
  cancelButton: "Cancel"
fr:
  title: "Autoscaler pour les conteneurs {type}"
  subtitle: "Configurer la façon dont cette application ajuste son échelle en fonction de la charge"
  documentation:
    intro: "Trouver la meilleure cible de mise à l'échelle n'est pas une chose facile."
    link: "Veuillez consulter nos recommandations"
  errors:
    generic: "Impossible de configurer l'autoscaler : vérifiez que les valeurs sélectionnées sont correctes."
  form:
    containersRange: "Intervalle des conteneurs"
    containersRangeMinimum: "Nombre minimum de conteneurs"
    containersRangeMaximum: "Nombre maximum de conteneurs"
    scaleWhen: "Métrique"
    variesFrom: "Cible"
    noRecommendation: "Définissez une valeur et ajustez-la selon l'impact sur votre app"
    reenable: "Réactiver l'autoscaler"
    variesFromValues:
      rpm_per_container: "RPM par conteneur"
      cpu: "CPU"
      memory: "RAM"
      swap: "Swap"
      p95_response_time: "Temps de réponse"
      5XX: "Erreurs 5xx"
      rpm: "Requêtes par minute"
    variesFromSubText:
      rpm_per_container: "Valeur recommandée : {value} rpm"
      cpu: "Valeur recommandée : {value} %"
      memory: "Valeur recommandée : {value} %"
      swap: "Valeur recommandée : {value} %"
      p95_response_time: "Valeur recommandée : {value} ms"
      5XX: "Valeur recommandée : {value} par min"
      rpm: "{value} rpm recommandé"
    variesFromUnit:
      rpm_per_container: "rpm"
      cpu: "%"
      memory: "%"
      swap: "%"
      p95_response_time: "ms"
      5XX: "par min"
      rpm: "rpm"
  buttonName: "Confirmer"
  cancelButton: "Annuler"
</i18n>
