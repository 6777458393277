<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <GithubAddGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("user") }}
      <span class="font-medium ml-1">{{
        event.type_data.github_user.login
      }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import GithubAddGlyph from "@/components/atoms/glyphs/GithubAddGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "Event",
  components: { BaseEvent, GithubAddGlyph },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Github authorized"
  user: "User:"
fr:
  title: "Github autorisé"
  user: "Utilisateur :"
</i18n>
