<template>
  <SCBanner kind="warning">
    <template #icon>
      <DangerSignGlyph />
    </template>

    <template v-if="app.status === 'migrating'">
      {{ $t("migrating") }}
    </template>

    <i18n-t
      v-if="app.status === 'migrated'"
      keypath="migrated"
    >
      <template #region>
        {{ app.new_region }}
      </template>
      <template #link>
        <router-link
          :to="migratedRoute"
          class="underline"
        >
          {{ app.name }}
        </router-link>
      </template>
    </i18n-t>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "MigratedBanner",
  components: { DangerSignGlyph, SCBanner },
  props: {
    app: Object,
  },
  computed: {
    migratedRoute() {
      if (!this.app.status === "migrated") return {};

      return {
        name: Routes.App.Root,
        params: {
          id: this.app.name,
          region: this.app.new_region,
        },
      };
    },
  },
});
</script>

<i18n>
  en:
    migrating: "This app is currently migrating. You can still use the dashboard, but your actions will have no effect."
    migrated: "This app has been migrated to the region {region}: {link}"
  fr:
    migrating: "Cette application est en cours de migration. Vous pouvez toujours utiliser le tableau de bord, mais vos actions n'auront aucun effet."
    migrated: "Cette application a été migrée vers la région {region}: {link}"
</i18n>
