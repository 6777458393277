<template>
  <SupportPlanCard
    :title="$t('title')"
    :description="$t('description')"
    :current="current"
    :features="features"
  >
    <template #pricing>
      <div class="flex flex-col text-scale-7">
        <span>
          {{ $t("pricing") }}
          <span class="font-bold text-lg text-scale-9">{{ $t("price") }}</span>
        </span>
        <span>{{ $t("pricingLine2") }}</span>
      </div>
    </template>
  </SupportPlanCard>
</template>

<script>
import SupportPlanCard from "./SupportPlanCard.vue";

export default {
  name: "BusinessSupportPlanCard",
  components: {
    SupportPlanCard,
  },
  props: {
    title: String,
    description: String,
    current: Boolean,
  },
  data() {
    return {
      features: [
        {
          title: this.$t("features.supportFunctional.title"),
          description: this.$t("features.supportFunctional.description"),
        },
        {
          title: this.$t("features.priorityLine.title"),
          description: this.$t("features.priorityLine.description"),
        },
        {
          title: this.$t("features.responseTime.title"),
          description: this.$t("features.responseTime.description"),
        },
        {
          title: this.$t("features.accompaniment.title"),
          description: this.$t("features.accompaniment.description"),
        },
        {
          title: this.$t("features.documentation.title"),
          description: this.$t("features.documentation.description"),
        },
        {
          title: this.$t("features.commitment.title"),
          description: this.$t("features.commitment.description"),
        },
      ],
    };
  },
};
</script>

<i18n>
  fr:
    pricing: "à partir de"
    price: "500 €/mois"
    pricingLine2: "ou 10 % de votre consommation"
    title: "Support Business"
    description: "Pour les applications commerciales nécessitant une haute disponibilité."
    features:
      supportFunctional:
        title: "Support fonctionnel"
        description: "Jours ouvrés, 9h-18h"
      priorityLine:
        title: "Ligne prioritaire lors d’incidents"
        description: "24/7/365"
      responseTime:
        title: "Temps de réponse garanti"
        description: "Heures ouvrées < 30 minutes<br>Heures non ouvrées < 60 minutes"
      accompaniment:
        title: "Accompagnement"
        description: "Personnalisé, ainsi qu’un comité de suivi annuel et, sur devis, accès à nos experts techniques."
      documentation:
        title: "Documentation"
        description: "Documents standards fournis sur demande : attestation d’hébergement, PAS générique, schéma d’architecture."
      commitment:
        title: "Engagement"
        description: "6 mois"
  en:
    pricing: "Starting from"
    price: "€500/month"
    pricingLine2: "or 10% of your consumption"
    title: "Business Support"
    description: "Business applications requiring high availability."
    features:
      supportFunctional:
        title: "Platform support"
        description: "Business days, 9am-6pm"
      priorityLine:
        title: "Priority line during incidents"
        description: "24/7/365"
      responseTime:
        title: "Guaranteed response time"
        description: "Business hours < 30 minutes<br>Non-business hours < 60 minutes"
      accompaniment:
        title: "Assistance & resources"
        description: "Customized, with an annual review committee and, upon request, access to our technical experts."
      documentation:
        title: "Provided documents"
        description: "Standard documents provided upon request: hosting certificate, generic PAS, architecture diagram."
      commitment:
        title: "Commitment"
        description: "6 months"
</i18n>
