<template>
  <div class="mt-1 relative rounded-md flex">
    <span
      v-if="withButton"
      class="inline-flex items-center px-3 rounded-l-md border border-r-0 bg-scale-1 cursor-pointer border-scale-2"
      @click="$emit('buttonClicked')"
    >
      <span class="px-2">
        <slot name="buttonIcon" />
      </span>
    </span>
    <input
      v-model="inputValue"
      :class="{
        'text-scale-10 focus:border-color-3': noState,
        'pr-10 text-color-2 border-color-2 focus:border-color-2': isErrorState,
        'border-color-1': isSuccessState,
        'rounded-none rounded-r-md': withButton,
        'password-letter-spacing font-extrabold': isPasswordField,
      }"
      class="bg-scale-0 form-input border-scale-2 rounded block w-full focus:ring-transparent placeholder-scale-5 focus:shadow-none"
      :placeholder="placeholder"
      :type="typeFn"
      :disabled="disabled"
      :min="min"
      :max="max"
      :step="step"
      :name="name"
      @blur="$emit('blur')"
      @keypress="(e) => $emit('keypress', e)"
    >
    <div
      v-if="withIcon"
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-color-2"
    >
      <DangerSignGlyph v-if="isErrorState" />
    </div>
    <div
      v-if="unit.length > 0"
      :class="{ 'pr-10': state !== 'error', 'pr-16': state === 'error' }"
      class="absolute inset-y-0 right-0 flex items-center pointer-events-none"
    >
      <span class="text-scale-5 sm:text-sm"> {{ unit }} </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";

export const STATE_ERROR = "error";
export const STATE_SUCCESS = "success";
export const STATE_NONE = "";

export const TYPE_STRING = "string";
export const TYPE_PASSWORD = "password";
export const TYPE_NUMBER = "number";

export default defineComponent({
  name: "TextInput",
  components: { DangerSignGlyph },
  props: {
    modelValue: [String, Number],
    name: String,
    state: String,
    placeholder: String,
    type: String,
    min: [String, Number],
    step: [String, Number],
    max: [String, Number],
    withButton: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "blur", "buttonClicked", "keypress"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    withIcon: function () {
      return this.state !== STATE_NONE;
    },
    noState: function () {
      return !this.state || this.state === STATE_NONE;
    },
    isSuccessState: function () {
      return this.state === STATE_SUCCESS;
    },
    isErrorState: function () {
      return this.state === STATE_ERROR;
    },
    isPasswordField() {
      return this.type === TYPE_PASSWORD;
    },
    typeFn: function () {
      if (this.type === TYPE_PASSWORD) {
        return "password";
      }

      if (this.type === TYPE_NUMBER) {
        return "number";
      }

      return "text";
    },
  },
});
</script>

<style scoped>
.password-letter-spacing {
  letter-spacing: 0.4em;
}

input[disabled] {
  @apply cursor-not-allowed;
  @apply text-scale-3;
}
</style>
