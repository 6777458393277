<template>
  <ViewComponent
    :invoice="invoice"
    :user="currentUser"
    @download-invoice-pdf="downloadInvoicePdf"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/billing/invoices/Show.vue";
import { scalingoPrivateClient } from "@/lib/scalingo/client";
import { downloadBlob } from "@/lib/utils/export";
import { findInvoice, ensureInvoice } from "@/store/invoices";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "InvoiceContainer",
  components: { ViewComponent },
  props: {
    billingProfile: Object,
  },
  setup() {
    const toastsStore = useToastsStore();

    return { toastsStore };
  },
  computed: {
    invoice() {
      if (this.$route.params.id) {
        return findInvoice(this.$store, this.$route.params.id);
      }

      return null;
    },
  },
  beforeMount() {
    if (this.currentUser) {
      ensureInvoice(this.$store, this.$route.params.id);
    }
  },
  methods: {
    async downloadInvoicePdf({ invoice }) {
      try {
        const client = scalingoPrivateClient(this.$store);
        const promise = client.Invoices.download(invoice.id);
        const response = await promise;

        downloadBlob(response, `${invoice.invoice_number}.pdf`);
      } catch (error) {
        this.toastsStore.addOne({
          type: "error",
          title: this.$t("cannotDownload.title"),
          message: this.$t("cannotDownload.message"),
        });
      }
    },
  },
});
</script>

<i18n>
en:
  cannotDownload:
    title: "Cannot download invoice"
    message: "An error occurred while downloading the invoice. Please try again later."
fr:
  cannotDownload:
    title: "Impossible de télécharger la facture"
    message: "Une erreur est survenue lors du téléchargement de la facture. Veuillez réessayer plus tard."
</i18n>
