/**
 * @see https://stackoverflow.com/questions/53795644/downloading-pdf-from-api-via-javascript
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Blob
 */
export function downloadBlob(blob: Blob, fileName: string) {
  const tempEl = document.createElement("a");
  const url = window.URL.createObjectURL(blob);

  document.body.appendChild(tempEl);
  tempEl.style.display = "none";
  tempEl.href = url;
  tempEl.download = fileName;
  tempEl.click();
  window.URL.revokeObjectURL(url);
}

export function objectArrayToCsv(
  data: Array<Record<string, string | number | boolean | null | undefined>>,
): string {
  const headers = [...new Set(data.flatMap((row) => Object.keys(row)))];
  const rows = data.map((row) =>
    headers
      .map((header) => {
        const value = row[header];

        return value === null || value === undefined
          ? '""'
          : `"${String(value).replace(/"/g, '""')}"`;
      })
      .join(","),
  );

  return [headers.join(","), ...rows].join("\n");
}

export function arrayToCsv(
  data: (string | number | null | undefined)[][],
): string {
  return data
    .map((row) =>
      row.map((value) => `"${String(value).replaceAll('"', '""')}"`).join(","),
    )
    .join("\r\n");
}
