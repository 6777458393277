<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :billingProfile="billingProfile"
    :collaborators="collaborators"
    :stacks="stacks"
    :deployments="deployments"
    :scmRepoLink="scmRepoLink"
    :renameAppHandler="renameAppHandler"
    :transferAppHandler="transferAppHandler"
    :deleteAppHandler="deleteAppHandler"
    :editAppSettingsHandler="editAppSettingsHandler"
    @start-app-renaming="startAppRenaming"
    @cancel-app-renaming="cancelAppRenaming"
    @confirm-app-renaming="(e) => renameAppHandler.submit(e)"
    @start-app-transfer="startAppTransfer"
    @cancel-app-transfer="cancelAppTransfer"
    @confirm-app-transfer="(e) => transferAppHandler.submit(e)"
    @start-app-deletion="startAppDeletion"
    @cancel-app-deletion="cancelAppDeletion"
    @confirm-app-deletion="deleteAppHandler.submit()"
    @start-stack-change="startStackChange"
    @cancel-stack-change="cancelStackChange"
    @confirm-stack-change="(e) => editAppSettingsHandler.submit(e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/Management.vue";
import {
  DeleteAppHandler,
  EditAppStackSettingsHandler,
  RenameAppHandler,
  TransferAppHandler,
} from "@/lib/handlers";
import { billingProfile } from "@/store/billing-profile";
import { ensureCollaborators, listCollaborators } from "@/store/collaborators";
import { ensureDeployments, listDeployments } from "@/store/deployments";
import { ensureScmRepoLink, scmRepoLink } from "@/store/scm-repo-link";
import { ensureStacks, listStacks } from "@/store/stacks";

export default defineComponent({
  name: "ManagementContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      renameAppHandler: null,
      transferAppHandler: null,
      deleteAppHandler: null,
      editAppSettingsHandler: null,
    };
  },
  computed: {
    billingProfile() {
      return billingProfile(this.$store);
    },
    collaborators() {
      return listCollaborators(this.$store, {
        transform(collection) {
          return collection.filter((collaborator) => {
            return collaborator.status === "accepted";
          });
        },
      });
    },
    stacks() {
      return listStacks(this.$store, this.app.region);
    },
    deployments() {
      return listDeployments(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
      });
    },
    scmRepoLink() {
      return scmRepoLink(this.$store);
    },
  },
  beforeMount() {
    ensureCollaborators(this.$store, { staleAfter: "always" });
    ensureStacks(this.$store, this.app.region);
    ensureDeployments(this.$store);
    ensureScmRepoLink(this.$store, { staleAfter: "always" });
  },
  methods: {
    startAppRenaming() {
      this.renameAppHandler = new RenameAppHandler(this, this.app);
    },
    cancelAppRenaming() {
      this.renameAppHandler = null;
    },
    startAppTransfer() {
      this.transferAppHandler = new TransferAppHandler(this, this.app);
    },
    cancelAppTransfer() {
      this.transferAppHandler = null;
    },
    startAppDeletion() {
      this.deleteAppHandler = new DeleteAppHandler(this, this.app);
    },
    cancelAppDeletion() {
      this.deleteAppHandler = null;
    },
    cancelStackChange() {
      this.editAppSettingsHandler = null;
    },
    startStackChange() {
      this.editAppSettingsHandler = new EditAppStackSettingsHandler(
        this,
        this.app,
      );
    },
  },
});
</script>
