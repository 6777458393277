<template>
  <div>
    <label :class="allLabelClasses">
      {{ label }}
    </label>
    <TextAreaAtom
      v-model="inputValue"
      :state="inputState"
      :disabled="disabled"
      :placeholder="placeholder"
      :rows="rows"
      :inputClass="inputClass"
      :readonly="readonly"
      class="py-2"
    />
    <p class="text-xs text-color-2 mb-4 h-2">
      <span v-if="errors.length > 0">
        {{ errors[0] }}
      </span>
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import TextAreaAtom from "@/components/atoms/inputs/TextArea.vue";
import {
  STATE_ERROR,
  STATE_NONE,
} from "@/components/atoms/inputs/TextInput.vue";

export default defineComponent({
  name: "TextArea",
  components: { TextAreaAtom },
  props: {
    label: String,
    labelClass: String,
    inputClass: String,
    modelValue: String,
    errors: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    rows: [String, Number],
  },
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    inputState: function () {
      if (this.errors.length > 0) {
        return STATE_ERROR;
      }
      return STATE_NONE;
    },
    allLabelClasses() {
      let str = "block text-sm font-medium leading-5";

      str += this.errors.length > 0 ? " text-color-2" : " text-scale-10";

      if (this.labelClass) {
        str = `${str} ${this.labelClass}`;
      }

      return str;
    },
  },
});
</script>
