<template>
  <div class="mt-1 relative rounded-md">
    <div
      v-if="withIcon"
      class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
    >
      <slot name="icon" />
    </div>
    <!--suppress HtmlFormInputWithoutLabel -->
    <select
      v-model="inputValue"
      :label="label"
      class="form-select border-scale-2 rounded block w-full focus:ring-transparent bg-scale-0"
      :class="{
        'pl-10': withIcon,
        'text-scale-10 focus:border-color-3': noState,
        'pr-10 text-color-2 border-color-2 focus:border-color-2': isErrorState,
        'border-color-1': isSuccessState,
      }"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export const STATE_ERROR = "error";
export const STATE_SUCCESS = "success";
export const STATE_NONE = "";

export default defineComponent({
  name: "SelectInput",
  props: {
    modelValue: [String, Number],
    options: Array,
    state: String,
    label: String,
    withIcon: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    noState: function () {
      return !this.state || this.state === STATE_NONE;
    },
    isSuccessState: function () {
      return this.state === STATE_SUCCESS;
    },
    isErrorState: function () {
      return this.state === STATE_ERROR;
    },
  },
});
</script>
