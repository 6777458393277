export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recommended"])},
        "deprecated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deprecated"])}
      },
      "fr": {
        "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recommandée"])},
        "deprecated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dépréciée"])}
      }
    }
  })
}
