export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Autoscaler for ", _interpolate(_named("type")), " containers"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure how this application adjusts its scaling based on the load"])},
        "documentation": {
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding the best scaling target for your app is not an easy task."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read our recommendations"])}
        },
        "errors": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to configure the autoscaler: please check that the selected values are correct."])}
        },
        "form": {
          "containersRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Containers range"])},
          "containersRangeMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum number of containers"])},
          "containersRangeMaximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of containers"])},
          "scaleWhen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metric"])},
          "variesFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
          "noRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a value and adjust it according to the impact on your app"])},
          "reenable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enable autoscaler"])},
          "variesFromValues": {
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPM per container"])},
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAM"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response time"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5xx errors"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests per minute"])}
          },
          "variesFromSubText": {
            "rpm_per_container": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended value: ", _interpolate(_named("value")), " rpm"])},
            "cpu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended value: ", _interpolate(_named("value")), " %"])},
            "memory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended value: ", _interpolate(_named("value")), " %"])},
            "swap": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended value: ", _interpolate(_named("value")), " %"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended value: ", _interpolate(_named("value")), " ms"])},
            "5XX": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended value: ", _interpolate(_named("value")), " per min"])},
            "rpm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " rpm recommended"])}
          },
          "variesFromUnit": {
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])},
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ms"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per min"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])}
          }
        },
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Autoscaler pour les conteneurs ", _interpolate(_named("type"))])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer la façon dont cette application ajuste son échelle en fonction de la charge"])},
        "documentation": {
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver la meilleure cible de mise à l'échelle n'est pas une chose facile."])},
          "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez consulter nos recommandations"])}
        },
        "errors": {
          "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de configurer l'autoscaler : vérifiez que les valeurs sélectionnées sont correctes."])}
        },
        "form": {
          "containersRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalle des conteneurs"])},
          "containersRangeMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre minimum de conteneurs"])},
          "containersRangeMaximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre maximum de conteneurs"])},
          "scaleWhen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métrique"])},
          "variesFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cible"])},
          "noRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez une valeur et ajustez-la selon l'impact sur votre app"])},
          "reenable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactiver l'autoscaler"])},
          "variesFromValues": {
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPM par conteneur"])},
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAM"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de réponse"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs 5xx"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requêtes par minute"])}
          },
          "variesFromSubText": {
            "rpm_per_container": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valeur recommandée : ", _interpolate(_named("value")), " rpm"])},
            "cpu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valeur recommandée : ", _interpolate(_named("value")), " %"])},
            "memory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valeur recommandée : ", _interpolate(_named("value")), " %"])},
            "swap": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valeur recommandée : ", _interpolate(_named("value")), " %"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valeur recommandée : ", _interpolate(_named("value")), " ms"])},
            "5XX": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valeur recommandée : ", _interpolate(_named("value")), " par min"])},
            "rpm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " rpm recommandé"])}
          },
          "variesFromUnit": {
            "rpm_per_container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])},
            "cpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["%"])},
            "p95_response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ms"])},
            "5XX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par min"])},
            "rpm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rpm"])}
          }
        },
        "buttonName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      }
    }
  })
}
