<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      >
        <template #buttons>
          <SCButton :to="domainsRoute">
            {{ $t("manage") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div class="flex items-center">
        <TwoStatesIndicator
          :alternativeColors="true"
          :size="4"
          :isOn="!!selected"
          class="mr-2"
        />

        <span class="text-scale-8">
          <template v-if="domains.latestFetch.isLoading">
            {{ $t("loading") }}
          </template>
          <template v-else-if="selected">{{ selected.name }}</template>
          <template v-else>{{ $t("no") }}</template>
        </span>
      </div>
      <ProTipAlert class="mt-3">
        {{ $t("proTip") }}
      </ProTipAlert>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "CanonicalDomainCard",
  components: {
    ProTipAlert,
    Card,
    SCButton,
    TwoStatesIndicator,
    CardHeader,
  },
  props: {
    domains: Object,
  },
  computed: {
    domainsRoute() {
      return { name: Routes.App.Domains.Root };
    },
    selected() {
      return this.domains.items.find((domain) => {
        return domain.canonical;
      });
    },
  },
});
</script>

<i18n>
en:
  title: "Canonical domain"
  subtitle: "When enabled, all traffic coming to other domains will be redirected to the selected domain."
  manage: "Manage domains"
  loading: "Loading domains..."
  no: "No canonical domain configured"
  proTip: "To configure a canonical domain, go to the Domains/SSL tab"
fr:
  title: "Domaine canonique"
  subtitle: "Quand configuré, tout le trafic des autres domaines sera redirigé vers le domaine choisi."
  manage: "Gérer les domaines"
  loading: "Chargement des domaines..."
  no: "Aucun domaine canonique configuré"
  proTip: "Pour configuer le nom de domaine canonique, aller dans l'onglet Domaines/SSL"
</i18n>
