import { loadStripe } from "@stripe/stripe-js";

import { SetupIntent as ScalingoSetupIntent } from "@/lib/scalingo/setup-intents";

import type {
  Stripe,
  StripeCardElement,
  StripeIbanElement,
  SetupIntent as StripeSetupIntent,
  StripeConstructorOptions,
  SetupIntentResult,
} from "@stripe/stripe-js";

const STRIPE_PUBLISHABLE_KEY = process.env
  .VUE_APP_STRIPE_PUBLISHABLE_KEY as string;

let _stripeClient: Stripe | null = null;

export async function stripeClient() {
  if (!_stripeClient) {
    const opts: StripeConstructorOptions = {};

    if (process.env.VUE_APP_PLATFORM_ENV !== "production") {
      opts.apiVersion = "2020-03-02";
    }

    _stripeClient = await loadStripe(STRIPE_PUBLISHABLE_KEY, opts);
  }

  return _stripeClient;
}

export type StripeSEPACreateParams = {
  holder: string;
  email: string;
};

export function setupStripeSepa(
  client: Stripe,
  setupIntent: ScalingoSetupIntent,
  el: StripeIbanElement,
  payload: StripeSEPACreateParams,
): Promise<StripeSetupIntent> {
  const promise = client.confirmSepaDebitSetup(setupIntent.client_secret, {
    payment_method: {
      sepa_debit: el,
      billing_details: { name: payload.holder, email: payload.email },
    },
  });

  return processStripeSetupIntentResult(promise);
}

export function setupStripeCard(
  client: Stripe,
  setupIntent: ScalingoSetupIntent,
  el: StripeCardElement,
  holder: string,
): Promise<StripeSetupIntent> {
  const promise = client.confirmCardSetup(setupIntent.client_secret, {
    payment_method: {
      card: el,
      billing_details: { name: holder },
    },
  });

  return processStripeSetupIntentResult(promise);
}

export async function processStripeSetupIntentResult(
  promise: Promise<SetupIntentResult>,
): Promise<StripeSetupIntent> {
  try {
    const response = await promise;
    const { setupIntent, error } = response;

    // stripeResponse will have either error or setupIntent, never both according to doc.
    if (setupIntent) return setupIntent;
    if (error) throw { stripe: error };

    throw { stripe: { code: "generic_error" } };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    throw { stripe: { code: "generic_error" } };
  }
}
