<template>
  <div class="bg-scale-0 rounded px-8 py-6">
    <div class="flex mb-4 items-center">
      <div class="flex flex-col pv:flex-row">
        <SmallToggle
          :modelValue="streamingEnabled"
          @update:model-value="streamingToggled"
        >
          {{ $t("logsStreaming") }}
        </SmallToggle>
        <div>
          <SmallToggle
            v-model="autoScroll"
            class="pv:ml-12"
          >
            {{ $t("autoScroll") }}
          </SmallToggle>
        </div>
        <SmallToggle
          v-model="smartColoration"
          class="pv:ml-12"
        >
          {{ $t("smartColoration") }}
        </SmallToggle>
      </div>
      <div class="flex flex-grow">
        <div
          class="flex justify-end items-center flex-grow flex-col ph:flex-row"
        >
          <div class="mr-2 mb-2 ph:mb-0">
            <FontSizeButton
              class="h-8"
              @down="fontDown"
              @up="fontUp"
            />
          </div>
          <SCButton
            class="hidden xl:block mr-2"
            @click="$emit('fullScreenClicked')"
          >
            {{ fullscreen ? $t("exitFullscreen") : $t("enterFullscreen") }}
          </SCButton>
          <SCButton @click="$emit('clearClicked')">
            {{ $t("clear") }}
          </SCButton>
        </div>
      </div>
    </div>
    <Console
      :fontSize="fontSize"
      :lines="logs"
      :autoScroll="autoScroll"
      :fullscreen="fullscreen"
      :smartColoration="smartColoration"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SmallToggle from "@/components/molecules/toggles/SmallToggle.vue";
import FontSizeButton from "@/components/parts/app/showLogs/FrontSizeButton.vue";
import Console from "@/components/parts/app/showLogs/ShowLogsConsole.vue";

export default defineComponent({
  name: "Logslisting",
  components: { FontSizeButton, SCButton, SmallToggle, Console },
  props: {
    logs: Array,
    streamingEnabled: Boolean,
    fullscreen: Boolean,
    fontSize: String,
  },
  emits: [
    "clearClicked",
    "fontSizeChanged",
    "fullScreenClicked",
    "streamingToggled",
  ],
  data() {
    return {
      fontSizes: [
        "text-xs",
        "text-sm",
        "text-base",
        "text-lg",
        "text-xl",
        "text-2xl",
        "text-3xl",
      ],
      autoScroll: true,
      smartColoration: true,
    };
  },
  methods: {
    fontUp() {
      let index = this.fontSizes.findIndex(
        (element) => element === this.fontSize,
      );
      index++;
      if (index > this.fontSizes.length - 1) {
        return;
      }
      this.$emit("fontSizeChanged", { fontSize: this.fontSizes[index] });
    },
    fontDown() {
      let index = this.fontSizes.findIndex(
        (element) => element === this.fontSize,
      );
      index--;
      if (index < 0) {
        return;
      }
      this.$emit("fontSizeChanged", { fontSize: this.fontSizes[index] });
    },
    streamingToggled(eventArgs) {
      this.$emit("streamingToggled", eventArgs);
    },
  },
});
</script>

<i18n>
  en:
    clear: "Clear"
    enterFullscreen: "Enter fullscreen"
    exitFullscreen: "Exit fullscreen"
    routerLogs: "Router logs"
    logsStreaming: "Logs streaming"
    autoScroll: "Auto-scroll"
    smartColoration: "Coloration"
  fr:
    clear: "Nettoyer"
    enterFullscreen: "Entrer en plein écran"
    exitFullscreen: "Sortir du plein écran"
    routerLogs: "Logs router"
    logsStreaming: "Logs streaming"
    autoScroll: "Auto-scroll"
    smartColoration: "Coloration"
</i18n>
