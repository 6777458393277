import { defineComponent } from "vue";

export default defineComponent({
  props: {
    displayInFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["fullScreenToggled"],
  watch: {
    displayInFullScreen(newVal) {
      newVal ? this.enterFullScreen() : this.exitFullScreen();
    },
  },
  beforeUnmount() {
    if (this.displayInFullScreen) {
      this.exitFullScreen();
    }
  },
  mounted() {
    this.displayInFullScreen ? this.enterFullScreen() : this.exitFullScreen();
  },
  methods: {
    toggleFullScreen() {
      this.$emit("fullScreenToggled");
    },
    async enterFullScreen() {
      const mainContainer = document.getElementById("main-container");

      if (!mainContainer) return;

      mainContainer.classList.remove("container");
      mainContainer.classList.remove("px-4");

      // gotta do this twice for the scroll to work as expected
      await this.$nextTick();
      await this.$nextTick();

      this.$el.scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    },
    exitFullScreen() {
      const mainContainer = document.getElementById("main-container");

      if (!mainContainer) return;

      mainContainer.classList.add("container");
      mainContainer.classList.add("px-4");
    },
  },
});
