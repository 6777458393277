<template>
  <div>
    <DeployCartridges
      :app="app"
      :scmRepoLink="scmRepoLink"
      :scmIntegrations="scmIntegrations"
      :selectedCartridge="currentCartridge"
      :currentSCMIntegration="currentSCMIntegration"
      :scmOrgs="scmOrgs"
      :hasMoreOrgs="hasMoreOrgs"
      :scmRepos="scmRepos"
      :scmBranches="scmBranches"
      :connectIntegrationHandler="connectIntegrationHandler"
      :repoLinkOperation="repoLinkOperation"
      :listOrgsInfo="listOrgsInfo"
      :listReposInfo="listReposInfo"
      :listBranchesInfo="listBranchesInfo"
      @cartridge-selected="cartridgeSelected"
      @connect-s-c-m-integration="connectSCMIntegration"
      @start-hosted-s-c-m-connection="
        (e) => $emit('startHostedSCMConnection', e)
      "
      @cancel-hosted-s-c-m-connection="$emit('cancelHostedSCMConnection')"
      @confirm-hosted-s-c-m-connection="
        (e) => $emit('confirmHostedSCMConnection', e)
      "
      @start-s-c-m-link="startSCMLink"
      @stop-s-c-m-link="$emit('stopSCMLink')"
      @unlink-repo="$emit('unlinkRepo')"
      @scm-org-selected="(eventArgs) => $emit('scmOrgSelected', eventArgs)"
      @scm-repo-searched="(e) => $emit('scmRepoSearched', e)"
      @scm-repo-selected="(eventArgs) => $emit('scmRepoSelected', eventArgs)"
      @scm-branch-selected="
        (eventArgs) => $emit('scmBranchSelected', eventArgs)
      "
      @finish-s-c-m-repo-link="
        (eventArgs) => $emit('finishSCMRepoLink', eventArgs)
      "
      @load-more-organizations="
        (eventArgs) => $emit('loadMoreOrganizations', eventArgs)
      "
    />
    <div class="flex">
      <SCButton
        block
        kind="primary"
        size="lg"
        class="flex-grow mt-4"
        @click="submit"
      >
        {{ $t("finishButton") }}
      </SCButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import DeployCartridges from "@/components/parts/configure/app/DeployCartridges.vue";

export default defineComponent({
  name: "DeployForm",
  components: { DeployCartridges, SCButton },
  props: {
    app: Object,
    scmRepoLink: Object,
    scmIntegrations: Object,
    currentCartridge: String,
    currentSCMIntegration: Object,
    scmOrgs: Array,
    hasMoreOrgs: Boolean,
    scmRepos: Array,
    scmBranches: Array,
    connectIntegrationHandler: Object,
    repoLinkOperation: Object,
    listOrgsInfo: Object,
    listReposInfo: Object,
    listBranchesInfo: Object,
  },
  emits: [
    "cancelHostedSCMConnection",
    "cartridgeSelected",
    "confirmHostedSCMConnection",
    "connectSCMIntegration",
    "finishSCMRepoLink",
    "formSubmitted",
    "scmBranchSelected",
    "scmOrgSelected",
    "scmRepoSearched",
    "scmRepoSelected",
    "startHostedSCMConnection",
    "startSCMLink",
    "stopSCMLink",
    "unlinkRepo",
    "loadMoreOrganizations",
  ],
  data() {
    return {
      form: {},
    };
  },
  methods: {
    submit() {
      this.$emit("formSubmitted", { form: this.form });
    },
    cartridgeSelected(eventArgs) {
      this.$emit("cartridgeSelected", eventArgs);
    },
    connectSCMIntegration(eventArgs) {
      this.$emit("connectSCMIntegration", eventArgs);
    },
    startSCMLink(eventArgs) {
      this.$emit("startSCMLink", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  finishButton: "Finish"
fr:
  finishButton: "Finish"
</i18n>
