<template>
  <img
    v-if="srcFn"
    class="ring-2 ring-scale-2"
    :src="srcFn"
    :class="classFn"
    alt=""
  >
  <div
    v-else
    :class="classFn"
    class="bg-gray-400"
  />
</template>

<script>
import { defineComponent } from "vue";

import { userAvatar } from "@/lib/utils/avatars";

export default defineComponent({
  name: "Portrait",
  props: {
    user: Object,
    source: String,
    size: {
      type: [Number, String],
      default: 6,
    },
  },
  computed: {
    classFn() {
      return `h-${this.size} w-${this.size} rounded-full`;
    },
    srcFn() {
      if (this.source && this.source !== "") {
        return this.source;
      }
      return this.user ? userAvatar(this.user) : null;
    },
  },
});
</script>
