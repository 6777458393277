<template>
  <ViewComponent
    :user="currentUser"
    :appInfo="appInfo"
    :parentAppInfo="parentAppInfo"
    :app="app"
    :scmRepoLink="scmRepoLink"
    :addons="addons"
    :collaborators="collaborators"
    :containers="containers"
    :events="events"
    :lastDeployment="lastDeployment"
    :reviewApps="reviewApps"
    :createDataAccessConsentHandler="createDataAccessConsentHandler"
    :dataAccessConsent="dataAccessConsent"
    @copy-git-remote="copyGitRemote"
    @restart-action="action"
    @start-data-access-consent-creation="startDataAccessConsentCreation()"
    @cancel-data-access-consent-creation="createDataAccessConsentHandler = null"
    @submit-data-access-consent-creation="
      (e) => createDataAccessConsentHandler.submit(e)
    "
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/Overview.vue";
import { CopyGitRemoteHandler } from "@/lib/handlers";
import { CreateDataAccessConsentHandler } from "@/lib/handlers/create/data-access-consent";
import { listCollaborators, ensureCollaborators } from "@/store/collaborators";
import {
  listContainers,
  ensureContainers,
  restartAllContainers,
  restartContainersOfType,
} from "@/store/containers";
import { ensureDeployments, listDeployments } from "@/store/deployments";
import { listEvents, ensureEvents } from "@/store/events";
import { ensureReviewApps, listReviewApps } from "@/store/review-apps";
import { ensureScmRepoLink, scmRepoLink } from "@/store/scm-repo-link";
import { useAppAddonsStore } from "@/stores/app/addons";

export default defineComponent({
  name: "OvervierCtrl",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    appInfo: Object,
    parentAppInfo: Object,
  },
  data() {
    return {
      createDataAccessConsentHandler: null,
    };
  },
  computed: {
    addonsStore() {
      return useAppAddonsStore();
    },
    scmRepoLink() {
      return scmRepoLink(this.$store);
    },
    accessAsAdmin() {
      return this.user.flags?.admin && !this.appInfo;
    },
    addons() {
      return {
        items: this.addonsStore.items,
        promiseInfo: this.addonsStore.promiseInfo,
      };
    },
    lastDeployment() {
      // We have the "last_deployment_id" on the `app` object, but it's a mongo object id
      // deployments objects from the api return their `uuid`, not their internal mongo object id
      // Therefore, we can't do a precise match.
      // All we can go is sort by created_at (finished_at is not always filled) and take the first item.
      return listDeployments(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
        limit: 1,
      });
    },
    collaborators() {
      return listCollaborators(this.$store, {
        transform: (coll) => {
          return [
            ...coll.filter((item) => item.status !== "pending"),
            ...coll.filter((item) => item.status === "pending"),
          ];
        },
      });
    },
    containers() {
      return listContainers(this.$store);
    },
    events() {
      return listEvents(this.$store, { limit: 6 });
    },
    reviewApps() {
      return listReviewApps(this.$store, {
        sortBy: "created_at",
        sortDirection: "desc",
        limit: 5,
      });
    },
    dataAccessConsent() {
      const consent = this.app ? { ...this.app.data_access_consent } : null;

      if (!consent) return null;

      if (consent.user_id === this.app.owner.id) {
        consent.user = this.app.owner;
        return consent;
      }

      const matchingUser = this.collaborators.items.find(
        (item) => item.user_id === consent.user_id,
      );

      if (matchingUser) {
        consent.user = matchingUser;
      }

      return consent;
    },
  },
  mounted() {
    this.addonsStore.ensure();
    ensureCollaborators(this.$store);
    ensureContainers(this.$store);
    ensureEvents(this.$store, { staleAfter: "always" });
    ensureScmRepoLink(this.$store);

    if (this.app.last_deployment_id) {
      ensureDeployments(this.$store);
    }

    if (!this.app.parent_app_name) {
      ensureReviewApps(this.$store);
    }
  },
  methods: {
    action({ action }) {
      if (action.key === "containers.restartAll") {
        restartAllContainers(this.$store);
      }

      if (action.key === "containers.restartOne") {
        restartContainersOfType(this.$store, action.container);
      }
    },
    startDataAccessConsentCreation() {
      this.createDataAccessConsentHandler = new CreateDataAccessConsentHandler(
        this,
        this.app,
        this.currentUser,
      );
    },
    copyGitRemote() {
      new CopyGitRemoteHandler(this, this.app).submit();
    },
  },
});
</script>
