export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "groupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group by"])},
        "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators"])},
        "applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
        "search_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by"])}
      },
      "fr": {
        "groupBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouper par"])},
        "collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborateurs"])},
        "applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
        "search_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par"])}
      }
    }
  })
}
