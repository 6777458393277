<template>
  <section>
    <Card>
      <template #header>
        <CardHeader
          :title="$t('title')"
          :subtitle="$t('subtitle')"
          :withCount="!!deploymentsCount"
          :count="deploymentsCount"
        >
          <template #buttons>
            <SCButton @click="$emit('startResetDeploymentCache')">
              {{ $t("resetCacheButton") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <Deployments
          :app="app"
          :deployments="deployments"
          :addons="addons"
          @view-more-button-clicked="$emit('viewMoreButtonClicked')"
        />
      </template>
    </Card>
    <ResetCacheWarning
      v-if="resetDeploymentCacheHandler"
      :formHandler="resetDeploymentCacheHandler"
      @warning-accepted="$emit('confirmResetDeploymentCache')"
      @modal-closed="$emit('cancelResetDeploymentCache')"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ResetCacheWarning from "@/components/parts/app/showDeploy/ResetCacheWarning.vue";
import Deployments from "@/components/parts/app/showDeploy/ShowDeployDeployments.vue";

export default defineComponent({
  name: "DeployListAll",
  components: {
    ResetCacheWarning,
    SCButton,
    Card,
    CardHeader,
    Deployments,
  },
  props: {
    app: Object,
    user: Object,
    deployments: Object,
    addons: Object,
    resetDeploymentCacheHandler: Object,
  },
  emits: [
    "cancelResetDeploymentCache",
    "confirmResetDeploymentCache",
    "startResetDeploymentCache",
    "viewMoreButtonClicked",
  ],
  data() {
    return {};
  },
  computed: {
    deploymentsCount() {
      return (
        this.deployments.meta?.pagination?.total_count ||
        this.deployments.items.length
      );
    },
  },
});
</script>

<i18n>
  en:
    title: "Deployments"
    subtitle: "Last deployments."
    resetCacheButton: "Reset cache"
  fr:
    title: "Déploiements"
    subtitle: "Liste des derniers déploiements."
    resetCacheButton: "Vider le cache"
</i18n>
