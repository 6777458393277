<template>
  <DeleteModal
    @form-submitted="$emit('confirmBulkModeExit')"
    @modal-closed="$emit('cancelBulkModeExit')"
  >
    <template #title>
      {{ $t("title") }}
    </template>

    <template #question>
      {{ $t("question") }}
    </template>

    <template #button>
      {{ $t("button") }}
    </template>
  </DeleteModal>
</template>

<script>
import { defineComponent } from "vue";

import DeleteModal from "@/components/molecules/modalsCollection/DeleteModal.vue";

export default defineComponent({
  name: "DiscardBulkChangesModal",
  components: { DeleteModal },
  props: {
    handler: Object,
  },
  emits: ["cancelBulkModeExit", "confirmBulkModeExit"],
});
</script>

<i18n>
en:
  title: "Discard changes"
  question: "You have unsaved changes. Switching to table mode will discard them. Do you want to proceed?"
  button: "Yes, discard the changes"
fr:
  title: "Annuler les changements"
  question: "Vous avez des modifications non sauvegardées. Retourner au tableau annulera ces changements. Souhaitez-vous continuer?"
  button: "Oui, continuer"
</i18n>
