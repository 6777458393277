export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database Version"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keeping your database up-to-date helps to improve the security and availability of your data."])},
        "upgrade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, linked: _linked, type: _type } = ctx;return _normalize([_linked(_interpolate(_named("type")), "capitalize", _type), " Upgrade to ", _interpolate(_named("version"))])},
        "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["major"])},
        "minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minor"])},
        "patch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["patch"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version de la base de données"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenir votre base de données à jour contribue à améliorer la sécurité et la disponibilité de vos données."])},
        "upgrade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mise à jour ", _interpolate(_named("type")), " vers ", _interpolate(_named("version"))])},
        "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["majeure"])},
        "minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mineure"])},
        "patch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["patch"])}
      }
    }
  })
}
