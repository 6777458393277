<template>
  <div class="flex">
    <div class="flex-grow">
      <SectionTitle
        :title="title"
        :subtitle="subtitle"
        :withCount="withCount"
        :count="count"
      />
    </div>
    <div class="flex">
      <SCButton
        class="self-center"
        :to="buttonRouteArgs"
        @click="$emit('buttonClicked')"
      >
        {{ buttonName }}
      </SCButton>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";

import SectionTitle from "../../atoms/titles/SectionTitle";

export default defineComponent({
  name: "ButtonedSectionTitle",
  components: { SCButton, SectionTitle },
  props: {
    title: String,
    subtitle: String,
    withCount: Boolean,
    count: Number,
    buttonName: String,
    buttonRouteArgs: Object,
  },
  emits: ["buttonClicked"],
});
</script>
