<template>
  <div>
    <label class="block text-sm font-medium leading-5 text-scale-10 humanize">
      {{ label }}
    </label>

    <div class="mt-4">
      <AdvancedSelectInput
        :options="choices"
        :modelValue="selectedOption"
        :optionsAreLoading="optionsAreLoading"
        :disabled="filterObject.disabled"
        @update:model-value="onInput"
      >
        <template #text="{ option }">
          <slot
            name="optionText"
            v-bind="{ option }"
          >
            {{ option.text }}
          </slot>
        </template>

        <template #disabledPrompt>
          {{ selectedChoice.text }}
        </template>
      </AdvancedSelectInput>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import AdvancedSelectInput from "@/components/molecules/inputs/AdvancedSelectInput.vue";

export default defineComponent({
  name: "FilterByEnum",
  components: { AdvancedSelectInput },
  props: {
    label: String,
    filterObject: Object,
    selectedOption: String,
    optionsAreLoading: Boolean,
  },
  emits: ["filterSelected"],
  computed: {
    selectedChoice() {
      return this.filterObject.options.find(
        (opt) => opt.value === this.selectedOption,
      );
    },
    choices() {
      return this.filterObject.options;
    },
  },
  methods: {
    onInput(value) {
      const option = this.choices.find((item) => item.value === value);

      this.$emit("filterSelected", {
        filter: this.filterObject,
        option: option,
      });
    },
  },
});
</script>
