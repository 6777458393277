<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="60"
    viewBox="0 0 20 60"
    fill="none"
  >
    <circle
      cx="6"
      cy="6"
      r="5"
      fill="bg-scale-0"
      stroke="#7B8794"
      stroke-width="2"
    />
    <path
      d="M6,11.375 V42.375 C6,46.793301 9.58172,50.375 14,50.375 H16 "
      stroke-width="2"
      stroke="#7B8794"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ParentAppLink",
});
</script>
