export default {
  "attributes": {
    "defaults": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crée le"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
    },
    "account": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
      "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
      "emailNewsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement à la newsletter"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre profil"])},
      "roles": {
        "freelancer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
        "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étudiant(e)"])},
        "hobbyist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passionné(e)"])},
        "entrepreneur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneur(euse)"])},
        "developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développeur(euse)"])},
        "devops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps"])},
        "it_consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant(e) IT"])},
        "web_agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agence Web"])},
        "cto_engineering_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTO / Engineering Manager"])},
        "founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "no_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne souhaite pas répondre"])}
      }
    },
    "maintenanceObjectives": {
      "no-op": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le but de la maintenance est de tester l'entièreté du processus de maintenance sans faire d'opération sur la base de données."])},
      "failing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le but de cette maintenance est d'échouer. Cet évènement ne doit pas se trouver sur votre interface, notre équipe a été informée."])},
      "db-node-migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le but de cette maintenance est de migrer une partie de la base de données d'un serveur à un autre. Cette maintenance nous permet de faire des mises à jours de sécurité dans notre infrastructure. Nous l'avons conçue afin d'engendrer la plus courte interruption possible."])},
      "pg-md5-to-sha256-auth-migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cadre de notre engagement à maintenir les normes de sécurité les plus élevées, cette maintenance mettra à jour l'algorithme de hachage du mot de passe et la méthode d'authentification utilisée par l'utilisateur interne géré par Scalingo vers SCRAM-SHA-256. Pour plus d'informations, veuillez consulter le bulletin de sécurité associé: https://doc.scalingo.com/security/bulletins/ssb-2024-002."])},
      "db-upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'objectif de cette maintenance est de mettre à jour la base de données avec une version plus récente disponible."])}
    },
    "app": {
      "types": {
        "onlyParentsApps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seulement les apps parentes"])},
        "onlyReviewApps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seulement les review apps"])}
      },
      "statuses": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle"])},
        "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrêtée"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en cours"])},
        "crashed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crashée"])},
        "restarting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en redémarrage"])},
        "scaling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en scaling"])},
        "booting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en démarrage"])}
      }
    },
    "billingProfile": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom pour la facturation"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email pour la facturation"])},
      "addressLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse (ligne 1)"])},
      "addressLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresse (ligne 2)"])},
      "addressZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code postal"])},
      "addressCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ville"])},
      "addressState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["état"])},
      "addressCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pays"])},
      "vatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de TVA"])},
      "invoiceAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["champ libre sur la facture"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entreprise"])}
    },
    "hdsContactProfile": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
      "addressLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse (ligne 1)"])},
      "addressLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse (ligne 2)"])},
      "addressZip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
      "addressCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
      "addressCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
      "orgType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'organisation"])},
      "orgTypes": {
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise / Libéral"])},
        "non-profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisme à but non lucratif"])},
        "public-entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entité publique"])}
      }
    },
    "deployment": {
      "statuses": {
        "queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en attente"])},
        "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en construction"])},
        "pushing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pushing"])},
        "starting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en démarrage"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déploiement réussi"])},
        "crashed-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crash au démarrage"])},
        "timeout-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timeout au démarrage"])},
        "push-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur avant le démarrage"])},
        "build-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur durant la construction"])},
        "hook-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur avant le démarrage"])},
        "aborted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annulé"])}
      }
    },
    "backup": {
      "statuses": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réussi"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en cours"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["planifié"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annulé"])}
      },
      "full_statuses": {
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backup réussi"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur de backup"])},
        "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backup en cours"])},
        "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backup planifié"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backup annulé"])}
      },
      "method": {
        "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manuel"])},
        "periodic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quotidien"])}
      },
      "retention_policy_labels": {
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
        "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaines"])},
        "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
        "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel"])}
      },
      "retention_policy": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les backups, quelle que soit leur politique de conservation"])},
        "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conserver ", _interpolate(_named("daily_scheduled_backups")), " backups: un pour chaque jour de la semaine précédente"])},
        "weeks": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conserver ", _interpolate(_named("weekly_scheduled_backups")), " backups: un pour chaque semaine des ", _interpolate(_named("weekly_scheduled_backups")), " précédents"])},
        "months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conserver ", _interpolate(_named("monthly_scheduled_backups")), " backups: un pour chaque mois des ", _interpolate(_named("monthly_scheduled_backups")), " précédents"])},
        "user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conserver les derniers ", _interpolate(_named("manual_backups_count")), " backups effectués manuellement par l'utilisateur"])}
      }
    },
    "notifier": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "webhookUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL du webhook"])},
      "types": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "rocket_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rocket.Chat"])},
        "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack."])},
        "webhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook"])}
      }
    },
    "invoice": {
      "statuses": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouvelle"])},
        "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payée"])},
        "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annulée"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paiement échoué"])},
        "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remboursée"])},
        "partially_refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partiellement remboursée"])}
      }
    },
    "token": {
      "issuedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crée le"])},
      "lastUsedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dernière utilisation"])}
    },
    "contract": {
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
    },
    "key": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contenu"])},
      "fingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empreinte"])}
    },
    "paymentMethod": {
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iban"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro de carte"])},
      "exp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date d'expiration"])},
      "cvc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cvc"])},
      "holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détenteur"])},
      "infos": {
        "sepa": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Compte ", _interpolate(_named("start")), "...", _interpolate(_named("end"))])},
        "card": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Carte finissant par ", _interpolate(_named("last4")), ". Exp: ", _interpolate(_named("exp")), ". Détenteur: ", _interpolate(_named("holder"))])}
      }
    },
    "scmIntegration": {
      "accessToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["access token"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url"])}
    },
    "voucher": {
      "redeemed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisé"])},
      "redeemedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisé le"])},
      "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expiration"])},
      "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["validité"])},
      "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expire"])},
      "expireAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expire le"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
      "voucherCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code du voucher"])},
      "creditCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code du crédit"])},
      "initialAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant initial"])},
      "maxMonthlyAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant maximum par mois"])},
      "remainingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant restant"])}
    },
    "order": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statut"])},
      "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bon de commande"])},
      "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ouverture du service"])},
      "quote_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant de la commande"])},
      "consumed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant consommé"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email du contact"])},
      "statuses": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])},
        "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archivée"])}
      }
    },
    "collaborator": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])}
    },
    "domain": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "sslStatuses": {
        "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine n'est pas encore protégé par SSL"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine ne peut pas être protégé par SSL, la génération du certificat rencontre des erreurs"])},
        "manualActionRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine doit être configuré avant d'être protégé par SSL"])},
        "willSoonExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine est protégé par SSL, mais le certificat va bientôt expirer"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le certificat SSL est expiré"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine est protégé par SSL"])},
        "successLetsEncrypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le domaine est protégé par SSL via Let's Encrypt"])}
      }
    },
    "variable": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valeur"])}
    },
    "icons": {
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horodatage"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
      "commit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commit"])},
      "imageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille de l'image"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])}
    }
  }
}