export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à partir de"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500 €/mois"])},
        "pricingLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou 10 % de votre consommation"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Business"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les applications commerciales nécessitant une haute disponibilité."])},
        "features": {
          "supportFunctional": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support fonctionnel"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours ouvrés, 9h-18h"])}
          },
          "priorityLine": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne prioritaire lors d’incidents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7/365"])}
          },
          "responseTime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de réponse garanti"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures ouvrées < 30 minutes<br>Heures non ouvrées < 60 minutes"])}
          },
          "accompaniment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accompagnement"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisé, ainsi qu’un comité de suivi annuel et, sur devis, accès à nos experts techniques."])}
          },
          "documentation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents standards fournis sur demande : attestation d’hébergement, PAS générique, schéma d’architecture."])}
          },
          "commitment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 mois"])}
          }
        }
      },
      "en": {
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€500/month"])},
        "pricingLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or 10% of your consumption"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Support"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business applications requiring high availability."])},
        "features": {
          "supportFunctional": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform support"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business days, 9am-6pm"])}
          },
          "priorityLine": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority line during incidents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7/365"])}
          },
          "responseTime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guaranteed response time"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business hours < 30 minutes<br>Non-business hours < 60 minutes"])}
          },
          "accompaniment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance & resources"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized, with an annual review committee and, upon request, access to our technical experts."])}
          },
          "documentation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided documents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard documents provided upon request: hosting certificate, generic PAS, architecture diagram."])}
          },
          "commitment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commitment"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 months"])}
          }
        }
      }
    }
  })
}
