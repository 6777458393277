<template>
  <SimpleModal
    size="w-1/2 xl:w-1/4"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template #body>
      <Form
        ref="form"
        v-slot="{ meta, handleSubmit }"
        as=""
      >
        <form
          class="mt-4"
          @submit.prevent="handleSubmit(submitForm)"
        >
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div>
              <Field
                v-slot="{}"
                v-model="form.stack_id"
                name="stack_id"
              >
                <div class="space-y-2 flex flex-col py-4">
                  <div
                    v-for="option in options"
                    :key="option.value"
                  >
                    <CheckboxInputAtom
                      :modelValue="isSelected(option)"
                      boxType="round"
                      @update:model-value="form.stack_id = option.value"
                    >
                      <template #label>
                        <span class="font-medium">{{
                          option.object.name
                        }}</span>
                        <span class="ml-1 text-scale-5">({{ option.object.description }})</span>
                      </template>
                    </CheckboxInputAtom>
                  </div>
                </div>
              </Field>
            </div>
            <SCAlert
              kind="warning"
              icon="title"
              :title="$t('warning.title')"
              class="mb-4"
            >
              <template #icon>
                <WarningSignGlyph />
              </template>

              {{ $t("warning.content") }}
            </SCAlert>
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-4"
              :disabled="!meta.valid || isDisabled"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("button") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import WarningSignGlyph from "@/components/atoms/glyphs/WarningSignGlyph.vue";
import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";

export default defineComponent({
  name: "StackChangeModal",
  components: {
    SCAlert,
    WarningSignGlyph,
    SimpleModal,
    SCButton,
    CheckboxInputAtom,
    Field,
    Form,
  },
  mixins: [FormModalHandlerMixin],
  props: {
    stacks: Object,
    app: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
  computed: {
    currentStackId() {
      return this.stacks.items?.find((stack) => {
        return stack.id === this.app.stack_id;
      })?.id;
    },
    options() {
      const validStacks = this.stacks.items.filter(
        (stack) => stack.deprecated_at === null,
      );
      return validStacks.map((stack) => ({
        value: stack.id,
        object: stack,
      }));
    },
    isDisabled() {
      return this.form.stack_id === this.currentStackId || !this.form.stack_id;
    },
  },
  methods: {
    isSelected(option) {
      if (this.form.stack_id === option.value) {
        return true;
      }
      if (this.form.stack_id) {
        return false;
      }
      return this.currentStackId === option.value;
    },
  },
});
</script>

<i18n>
en:
  title: "Change Scalingo Stack"
  subtitle: "Change the Linux environment used by this app."
  button: "Change"
  warning:
    title: "App deployment required"
    content: "A Scalingo Stack change is only effective once the app has been redeployed."
fr:
  title: "Changer la Stack Scalingo"
  subtitle: "Changer l'environnement Linux utilisé sur cette app."
  button: "Changer"
  warning:
    title: "Déploiment de l'app requis"
    content: "Le changement de Stack Scalingo ne sera effectif uniquement apres le rédéploiment de l'app."
</i18n>
