<template>
  <section>
    <Card>
      <template #header>
        <CardHeader
          :title="$t('title')"
          :subtitle="$t('subtitle')"
        >
          <template #buttons>
            <SCButton @click="$emit('startDomainCreation')">
              {{ $t("add") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <template v-if="domains.latestFetch.isLoading">
          <LoadingCardState />
        </template>

        <EmptyCardState v-else-if="noItems">
          <template #icon>
            <SVGModelDomain class="h-8 w-8 text-scale-5" />
          </template>
          <div class="flex flex-col text-center">
            <div class="text-lg font-bold text-scale-10">
              {{ $t("none.title") }}
            </div>
            <div class="mt-2">
              {{ $t("none.subtitle") }}
            </div>
            <div class="mt-2">
              <SCButton @click="$emit('startDomainCreation')">
                {{ $t("none.action") }}
              </SCButton>
            </div>
          </div>
        </EmptyCardState>

        <DomainsList
          v-else
          class="mt-2"
          :domains="domains"
          :sortBy="sortBy"
          :sortDirection="sortDirection"
          @start-make-canonical="(e) => $emit('startMakeCanonical', e)"
          @start-unmake-canonical="(e) => $emit('startUnmakeCanonical', e)"
          @sort-changed="(e) => $emit('sortChanged', e)"
          @delete-domain-clicked="(e) => $emit('startDomainDeletion', e)"
        />

        <DomainInfo
          :app="app"
          class="mt-6"
        />
        <DomainNote :url="url" />
      </template>
    </Card>

    <AddDomainFormModal
      v-if="createDomainHandler"
      :formHandler="createDomainHandler"
      @modal-closed="$emit('cancelDomainCreation')"
      @form-submitted="(e) => $emit('submitDomainCreation', e)"
    />

    <RemoveDomainModal
      v-if="deleteDomainHandler"
      :formHandler="deleteDomainHandler"
      @modal-closed="$emit('cancelDomainDeletion')"
      @form-submitted="(e) => $emit('submitDomainDeletion', e)"
    />

    <MakeCanonicalWarning
      v-if="makeCanonicalHandler"
      :formHandler="makeCanonicalHandler"
      @modal-closed="$emit('cancelMakeCanonical')"
      @warning-accepted="$emit('submitMakeCanonical')"
    />

    <UnmakeCanonicalWarning
      v-if="unmakeCanonicalHandler"
      :formHandler="unmakeCanonicalHandler"
      @modal-closed="$emit('cancelUnmakeCanonical')"
      @warning-accepted="$emit('submitUnmakeCanonical')"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import SVGModelDomain from "@/components/graphics/models/Domain.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import AddDomainFormModal from "@/components/parts/appSettings/domainsSettings/AddDomainFormModal.vue";
import DomainInfo from "@/components/parts/appSettings/domainsSettings/DomainInfo.vue";
import DomainNote from "@/components/parts/appSettings/domainsSettings/DomainNote.vue";
import DomainsList from "@/components/parts/appSettings/domainsSettings/DomainsList.vue";
import MakeCanonicalWarning from "@/components/parts/appSettings/domainsSettings/MakeCanonicalWarning.vue";
import RemoveDomainModal from "@/components/parts/appSettings/domainsSettings/RemoveDomainModal.vue";
import UnmakeCanonicalWarning from "@/components/parts/appSettings/domainsSettings/UnmakeCanonicalWarning.vue";

export default defineComponent({
  name: "DomainsIndex",
  components: {
    UnmakeCanonicalWarning,
    MakeCanonicalWarning,
    RemoveDomainModal,
    SCButton,
    SVGModelDomain,
    DomainNote,
    DomainInfo,
    DomainsList,
    CardHeader,
    AddDomainFormModal,
    EmptyCardState,
    LoadingCardState,
    Card,
  },
  props: {
    app: Object,
    user: Object,
    domains: Object,
    createDomainHandler: Object,
    deleteDomainHandler: Object,
    makeCanonicalHandler: Object,
    unmakeCanonicalHandler: Object,
    url: String,
    sortBy: String,
    sortDirection: String,
  },
  emits: [
    "cancelDomainCreation",
    "cancelDomainDeletion",
    "cancelMakeCanonical",
    "cancelUnmakeCanonical",
    "sortChanged",
    "startDomainCreation",
    "startDomainDeletion",
    "startMakeCanonical",
    "startUnmakeCanonical",
    "submitDomainCreation",
    "submitDomainDeletion",
    "submitMakeCanonical",
    "submitUnmakeCanonical",
  ],
  computed: {
    noItems() {
      return (
        this.domains.latestFetch.isFinished && this.domains.items.length === 0
      );
    },
  },
});
</script>

<i18n>
en:
  title: "Domains"
  subtitle: "Domains attached to your app."
  add: "Add"
  none:
    title: "There are no items here"
    subtitle: "Domains will appear here once created."
    action: "Add a domain"
fr:
  title: "Domaines"
  subtitle: "Domaines rattaché à votre app"
  add: "Ajouter"
  none:
    title: "Il n'y a aucun élément"
    subtitle: "Les noms de domaines apparaitront ici une fois ajoutés."
    action: "Ajouter un nom de domaine"
</i18n>
