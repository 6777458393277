<template>
  <section>
    <h3 class="mb-2 leading-8">
      <font-awesome-icon
        v-if="iconForContract(contract)"
        v-bind="iconForContract(contract)"
      />

      <a
        class="font-medium mr-1 underline cursor-pointer"
        @click="
          $emit('startContractAcceptance', {
            contract: contract,
          })
        "
      >
        {{
          $t("contractName", {
            name: localizedAttr(contract, "name"),
            version: contract.version,
          })
        }}
      </a>

      <span class="text-sm text-scale-7">
        {{
          $t("publishedAt", {
            date: formatDateTime(contract.published_at, "DDD"),
          })
        }}
      </span>
    </h3>
  </section>
</template>

<script>
import { requiredForHDS, requiredForPlatform } from "scalingo/lib/Contracts";
import { defineComponent } from "vue";

import { formatDateTime } from "@/lib/utils/time";

export default defineComponent({
  name: "OptionalContractInfos",
  components: {},
  props: {
    contract: Object,
  },
  emits: ["startContractAcceptance"],
  methods: {
    formatDateTime,
    localizedAttr(object, attr) {
      return object[attr]?.[this.$i18n.locale];
    },
    iconForContract(contract) {
      if (requiredForPlatform(contract)) {
        return {
          icon: "file-contract",
          class: "ml-1 mr-1",
        };
      }

      if (requiredForHDS(contract)) {
        return {
          icon: "staff-snake",
          class: "text-success ml-1 mr-1",
        };
      }

      return null;
    },
  },
});
</script>

<i18n>
en:
  contractName: "{name} – version {version}"
  publishedAt: "published on {date}"
fr:
  contractName: "{name} – version {version}"
  publishedAt: "publié(e)(s) le {date}"
</i18n>
