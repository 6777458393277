<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :variables="variables"
    :createVariableHandler="createVariableHandler"
    :editVariableHandler="editVariableHandler"
    :deleteVariableHandler="deleteVariableHandler"
    :bulkEditVariableHandler="bulkEditVariableHandler"
    @copy-variable="copyVariable"
    @start-variable-creation="startVariableCreation"
    @cancel-variable-creation="cancelVariableCreation"
    @confirm-variable-creation="(e) => createVariableHandler.submit(e)"
    @start-variable-edition="startVariableEdition"
    @cancel-variable-edition="cancelVariableEdition"
    @confirm-variable-edition="(e) => editVariableHandler.submit(e)"
    @start-variable-deletion="startVariableDeletion"
    @cancel-variable-deletion="cancelVariableDeletion"
    @confirm-variable-deletion="(e) => deleteVariableHandler.submit(e)"
    @start-bulk-edition="startBulkEdition"
    @reset-bulk-edition="resetBulkEdition"
    @cancel-bulk-edition="cancelBulkEdition"
    @confirm-bulk-edition="(e) => bulkEditVariableHandler.submit(e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/Environment.vue";
import {
  CopyVariableHandler,
  CreateVariableHandler,
  DeleteVariableHandler,
  EditVariableHandler,
  EditBulkVariablesHandler,
} from "@/lib/handlers";
import { ensureVariables, listVariables } from "@/store/variables";

export default defineComponent({
  name: "EnvironmentContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      createVariableHandler: null,
      editVariableHandler: null,
      deleteVariableHandler: null,
      bulkEditVariableHandler: null,
    };
  },
  computed: {
    variables() {
      return listVariables(this.$store, {
        sortBy: "name",
        sortDirection: "asc",
      });
    },
  },
  mounted() {
    ensureVariables(this.$store, { staleAfter: "always" });
  },
  methods: {
    copyVariable({ variable }) {
      new CopyVariableHandler(this, variable).submit(variable);
    },
    startVariableCreation() {
      this.createVariableHandler = new CreateVariableHandler(this);
    },
    cancelVariableCreation() {
      this.createVariableHandler = null;
    },
    startVariableEdition({ variable }) {
      this.editVariableHandler = new EditVariableHandler(this, variable);
    },
    cancelVariableEdition() {
      this.editVariableHandler = null;
    },
    startVariableDeletion({ variable }) {
      this.deleteVariableHandler = new DeleteVariableHandler(this, variable);
    },
    cancelVariableDeletion() {
      this.deleteVariableHandler = null;
    },
    startBulkEdition() {
      this.bulkEditVariableHandler = new EditBulkVariablesHandler(
        this,
        this.variables.items,
      );

      // On success re-creates the bulk editor from scratch.
      // This will reset the bulk editor to a pristine state
      this.bulkEditVariableHandler.on("success", () => {
        this.startBulkEdition();
      });
    },
    resetBulkEdition() {
      this.startBulkEdition();
    },
    cancelBulkEdition() {
      this.bulkEditVariableHandler = null;
    },
  },
});
</script>

<i18n>
en:
  createSuccess:
    title: "Variables successfully created!"
    message: "The following variables have been created: <strong>{names}</strong>"
  updateSuccess:
    title: "Variables successfully updated!"
    message: "The following variables have been updated: <strong>{names}</strong>"
  deleteSuccess:
    title: "Variables successfully removed!"
    message: "The following variables have been removed: <strong>{names}</strong>"
fr:
  createSuccess:
    title: "Variables successfully created!"
    message: "The following variables have been created: <strong>{names}</strong>"
  updateSuccess:
    title: "Variables successfully updated!"
    message: "The following variables have been updated: <strong>{names}</strong>"
  deleteSuccess:
    title: "Variables successfully removed!"
    message: "The following variables have been removed: <strong>{names}</strong>"
</i18n>
