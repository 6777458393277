export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption reports"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This report is updated daily at midnight UTC."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured."])},
        "noBillingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a billing profile to follow your consumption."])},
        "orderWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amounts displayed may differ from those shown on your orders. These are calculated using the price grid associated with your account and not one of the specific grids for one of your orders."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amounts shown are frozen at the end of each billing period. An application transferred during the month is billed to its new owner, even if you can see its consumption for the period prior to its transfer."])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports de consommation"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce rapport est mis à jour quotidiennement à minuit UTC."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue."])},
        "noBillingProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un profil de facturation pour suivre vos consommation."])},
        "orderWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les montants affichés peuvent différer de ceux reportés sur vos commandes. Ceux-ci sont calculés en utilisant la grille de prix associée à votre compte et non une des grilles spécifiques à l'une de vos commandes."])},
        "proTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les montants indiqués sont gelés à la fin de chaque période de facturation. Une application transférée en cours de mois est facturée à son nouveau propriétaire, même si vous pouvez voir sa consommation pour la période précédant son transfert."])}
      }
    }
  })
}
