<template>
  <EmptyCardState>
    <template #icon>
      <AddonDatabaseGlyph class="h-8 text-scale-5" />
    </template>
    {{ $t("noDb") }}
  </EmptyCardState>
</template>

<script>
import { defineComponent } from "vue";

import AddonDatabaseGlyph from "@/components/atoms/glyphs/AddonDatabaseGlyph.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";

export default defineComponent({
  name: "NoDatabase",
  components: { AddonDatabaseGlyph, EmptyCardState },
});
</script>

<i18n>
en:
  noDb: "No database yet"
fr:
  noDb: "Pas encore de base de données"
</i18n>
