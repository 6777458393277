<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AppAddGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("name") }}
      <span class="font-medium ml-1 mr-1">{{ event.app_name }}</span>
      <span v-if="event.type_data.git_source">
        - {{ $t("source") }}:
        <span class="font-medium ml-1">{{
          event.type_data.git_source
        }}</span></span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AppAddGlyph from "@/components/atoms/glyphs/AppAddGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "NewAppEvent",
  components: { AppAddGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "App created"
  name: "Named"
  source: "Source"
fr:
  title: "App créée"
  name: "Nommée"
  source: "Source"
</i18n>
