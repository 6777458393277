<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      />
    </template>
    <template #body>
      <LoadingCardState
        v-if="isLoading"
        class="h-64 justify-center"
      />
      <EmptyCardState
        v-else-if="isError"
        class="h-64 justify-center"
      >
        <template #icon>
          <CrossGlyph />
        </template>
        <span class="capitalize">{{ promiseInfo.err.data.error }}</span>
      </EmptyCardState>
      <ContainerRAM
        v-else-if="isLoaded"
        :datasets="datasets"
        :containerMemoryLimit="maxMemory"
      />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CrossGlyph from "@/components/atoms/glyphs/CrossGlyph.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import ContainerRAM from "@/components/organisms/graphs/ContainerRAM.vue";
import { makeDataSet } from "@/lib/metrics";

export default defineComponent({
  name: "MemoryCard",
  components: {
    Card,
    CardHeader,
    LoadingCardState,
    EmptyCardState,
    CrossGlyph,
    ContainerRAM,
  },
  props: {
    promiseInfo: Object,
    dbPlan: Object,
    memoryData: Array,
    swapData: Array,
  },
  computed: {
    isLoaded() {
      return this.promiseInfo.isSuccess;
    },
    isLoading() {
      return this.promiseInfo.isLoading;
    },
    isError() {
      return this.promiseInfo.isError;
    },
    maxMemory() {
      return this.dbPlan?.memory || 0;
    },
    memoryDataset() {
      return makeDataSet(this.$i18n, "containers", "memory", this.memoryData);
    },
    swapDataset() {
      return makeDataSet(this.$i18n, "containers", "swap", this.swapData);
    },
    datasets() {
      return [this.memoryDataset, this.swapDataset];
    },
  },
});
</script>

<i18n>
en:
  title: "Memory"
  subtitle: "Memory consumption for this instance."
fr:
  title: "Mémoire"
  subtitle: "Consommation mémoire pour cette instance."
</i18n>
