<template>
  <SCButton
    v-if="deployment.status === 'build-error'"
    kind="neutral"
    :a="docBuildErrorsLink"
  >
    {{ $t("commonBuildErrors") }}
    <font-awesome-icon
      class="ml-1"
      icon="external-link-alt"
    />
  </SCButton>
  <SCButton
    v-else-if="deployment.status === 'timeout-error'"
    kind="neutral"
    :a="docStartErrorsLink"
  >
    {{ $t("commonStartErrors") }}
    <font-awesome-icon
      class="ml-1"
      icon="external-link-alt"
    />
  </SCButton>
  <SCButton
    v-else-if="deployment.status.endsWith('error')"
    kind="neutral"
    :a="docErrorsLink"
  >
    {{ $t("commonErrors") }}
    <font-awesome-icon
      class="ml-1"
      icon="external-link-alt"
    />
  </SCButton>
</template>
<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";

export default defineComponent({
  name: "DeploymentHelpLinks",
  components: { SCButton },
  props: {
    deployment: { type: Object },
  },
  data() {
    return {
      docBuildErrorsLink: {
        href: "https://doc.scalingo.com/platform/getting-started/common-deployment-errors#build-errors",
        target: "_blank",
      },
      docStartErrorsLink: {
        href: "https://doc.scalingo.com/platform/getting-started/common-deployment-errors#start-errors",
        target: "_blank",
      },
      docErrorsLink: {
        href: "https://doc.scalingo.com/platform/getting-started/common-deployment-errors",
        target: "_blank",
      },
    };
  },
});
</script>

<i18n>
en:
  commonBuildErrors: "Common build errors"
  commonStartErrors: "Common start errors"
  commonErrors: "Common deployment errors"
fr:
  commonBuildErrors: "Erreurs fréquentes au build"
  commonStartErrors: "Erreurs fréquentes au démarrage"
  commonErrors: "Erreurs fréquentes de déploiment"
</i18n>
