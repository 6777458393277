<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="23"
    viewBox="0 0 36 23"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <rect
        x="1"
        width="38"
        height="26"
        fill="white"
      />
      <path
        d="M36 0H24V23H36V0Z"
        :fill="red"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 23H12V0H0V23Z"
        :fill="blue"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="36"
          height="23"
          rx="4"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SVGRegionFrance",
  props: {
    monochrome: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    red() {
      return this.monochrome ? "#616E7C" : "#F44653";
    },
    blue() {
      return this.monochrome ? "#323F4B" : "#1035BB";
    },
  },
});
</script>
