<template>
  <ViewComponent
    :user="currentUser"
    :billingProfile="billingProfile.value"
    :hasCredits="hasCredits"
    :vouchers="vouchers"
    :voucherFormHandler="voucherFormHandler"
    @voucher-form-submitted="(e) => voucherFormHandler.submit(e)"
    @voucher-modal-opened="openVoucherModal"
    @voucher-modal-closed="closeVoucherModal"
  />
</template>

<script>
import { defineComponent, onBeforeMount } from "vue";

import ViewComponent from "@/components/views/billing/Vouchers.vue";
import { CreateVoucherHandler } from "@/lib/handlers";
import { Routes } from "@/router/names";
import { billingProfileAndFetch } from "@/store/billing-profile";
import { listVouchers, ensureVouchers } from "@/store/vouchers";
import { useCreditsStore } from "@/stores/credits";

export default defineComponent({
  name: "VouchersContainer",
  components: { ViewComponent },
  setup() {
    const creditsStore = useCreditsStore();

    onBeforeMount(() => creditsStore.ensure());

    return { creditsStore };
  },
  data() {
    return {
      voucherFormHandler: null,
    };
  },
  computed: {
    billingProfile() {
      return billingProfileAndFetch(this.$store);
    },
    vouchers() {
      return listVouchers(this.$store);
    },
    hasCredits() {
      const { any } = this.creditsStore;

      return any;
    },
  },
  async beforeMount() {
    await this.billingProfile.latestFetch.settled;

    if (!this.billingProfile.value) {
      this.$router.push({ name: Routes.Billing.Root });
      return;
    }

    if (this.currentUser) {
      ensureVouchers(this.$store, this.currentUser.id);
    }
  },
  methods: {
    openVoucherModal() {
      this.voucherFormHandler = new CreateVoucherHandler(this);
    },
    closeVoucherModal() {
      this.voucherFormHandler = null;
    },
  },
});
</script>
