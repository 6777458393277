<template>
  <div
    :class="classFn"
    class="font-medium"
  >
    <router-link :to="item.routerArgs">
      {{ item.name }}
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuItem",
  props: {
    item: Object,
  },
  computed: {
    classFn: function () {
      return {
        "text-primary-2": this.item.selected,
        "text-scale-5 hover:text-scale-7": !this.item.selected,
      };
    },
  },
});
</script>
