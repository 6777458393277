<template>
  <ViewComponent />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/misc/NotFoundError.vue";

export default defineComponent({
  name: "NotFoundErrorContainer",
  components: { ViewComponent },
});
</script>
