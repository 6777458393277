<template>
  <SimpleModal
    :hasBorder="true"
    size="w-1/4"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      <div class="mb-4">
        {{ $t("title") }}
      </div>
    </template>
    <template #body>
      <Form
        ref="form"
        v-slot="{ meta, handleSubmit }"
        as=""
      >
        <form
          class="mt-4"
          @submit.prevent="handleSubmit(submitForm)"
        >
          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.name"
            name="name"
            rules="required|appName|noStartHyphen|noEndHyphen|noConsecutiveHyphens"
          >
            <TextInput
              :name="field.name"
              :modelValue="field.value"
              :label="$t('labels.newAppName')"
              :errors="errors"
              @update:model-value="handleChange"
            />
          </Field>

          <Field
            v-slot="{ field, errors, handleChange }"
            v-model="form.currentName"
            name="currentname"
            :rules="currentNameRules"
          >
            <TextInput
              :name="field.name"
              :modelValue="field.value"
              :label="$t('labels.currentAppName')"
              :errors="errors"
              @update:model-value="handleChange"
            />
          </Field>

          <div v-if="errors">
            {{ errors.base }}
          </div>

          <div class="flex">
            <SCButton
              block
              kind="primary"
              type="submit"
              size="lg"
              class="flex-grow mt-8"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("button") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";

export default defineComponent({
  name: "RenameAppModal",
  components: {
    TextInput,
    SCButton,
    SimpleModal,
    Field,
    Form,
  },
  mixins: [FormModalHandlerMixin],
  props: {
    app: Object,
  },
  emits: ["formSubmitted", "modalClosed"],
  computed: {
    currentNameRules() {
      return {
        required: true,
        is: this.app.name,
      };
    },
  },
});
</script>

<i18n>
en:
  title: "Rename an app"
  question: "Do you really want delete this app?"
  info: "To confirm, type the name of the app below:"
  button: "Rename"
  labels:
    newAppName: "New name of the app"
    currentAppName: "Current name of the app"
fr:
  title: "Renommer une app"
  info: "Pour confirmer, entrez le nom de l'app ci dessous:"
  question: "Souhaitez-vous vraiment supprimer cette app ?"
  button: "Renommer"
  labels:
    newAppName: "New name of the app"
    currentAppName: "Current name of the app"
</i18n>
