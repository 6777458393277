<template>
  <div class="flex">
    <Pill
      v-if="invoiceState === STATES.NEW"
      class="flex-shrink"
      type="tag"
    >
      {{ $t("attributes.invoice.statuses.new") }}
    </Pill>
    <Pill
      v-if="invoiceState === STATES.PAID"
      class="flex-shrink"
      type="success"
    >
      {{ $t("attributes.invoice.statuses.paid") }}
    </Pill>
    <Pill
      v-if="invoiceState === STATES.FAILED"
      class="flex-shrink"
      type="danger"
    >
      {{ $t("attributes.invoice.statuses.failed") }}
    </Pill>
    <Pill
      v-if="invoiceState === STATES.CANCELED"
      class="flex-shrink"
      type="tag"
    >
      {{ $t("attributes.invoice.statuses.cancelled") }}
    </Pill>
    <Pill
      v-if="invoiceState === STATES.REFUNDED"
      class="flex-shrink"
      type="success"
    >
      {{ $t("attributes.invoice.statuses.refunded") }}
    </Pill>
    <Pill
      v-if="invoiceState === STATES.PARTIALLY_REFUNDED"
      class="flex-shrink"
      type="success"
    >
      {{ $t("attributes.invoice.statuses.partially_refunded") }}
    </Pill>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Pill from "@/components/atoms/pills/Pill.vue";
import { STATES } from "@/lib/scalingo/balance-operations";

export default defineComponent({
  name: "PaymentStatus",
  components: { Pill },
  props: {
    invoiceState: String,
  },
  created() {
    this.STATES = STATES;
  },
  methods: {
    statusFor(item) {
      return this.$t(`attributes.invoice.statuses.${item.state}`);
    },
  },
});
</script>
