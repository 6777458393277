export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Support"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects in development."])},
        "features": {
          "supportFunctional": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform support"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business days, 9am-6pm"])}
          },
          "priorityLine": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority line during incidents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          },
          "responseTime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guaranteed response time"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          },
          "accompaniment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance & resources"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to online documentation, guides, and tutorials."])}
          },
          "documentation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided documents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          },
          "commitment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commitment"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          }
        }
      },
      "fr": {
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclus"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Basic"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les projets en développement."])},
        "features": {
          "supportFunctional": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support fonctionnel"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours ouvrés, 9h-18h"])}
          },
          "priorityLine": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne prioritaire lors d’incidents"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          },
          "responseTime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de réponse garanti"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          },
          "accompaniment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accompagnement"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à la documentation en ligne, à nos guides et à nos tutoriels."])}
          },
          "documentation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents fournis"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          },
          "commitment": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
          }
        }
      }
    }
  })
}
