<template>
  <ViewComponent
    :collaborators="collaborators"
    :appsCollaborators="appsCollaborators"
    :fetchPromiseInfo="collaboratorsStore.promiseInfo"
    :username="currentUsername"
    :isAppOwner="isAppOwner"
    :isCollaborator="isCollaborator"
    :groupBy="groupBy"
    :searchTerm="searchTerm"
    @group-by-selected="changeGroupBy"
    @open-app-collaborators="openAppCollaborators"
  />
</template>

<script>
import { defineComponent, onBeforeMount, computed, ref } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/Collaborators.vue";
import { sortCollaborators } from "@/lib/utils/collaborators.ts";
import { AppRoutes } from "@/router/app/names";
import { useAppInfosStore } from "@/stores/app-infos";
import { useCollaboratorsStore } from "@/stores/collaborators";
import { currentUser } from "@/stores/user";

export default defineComponent({
  name: "Collaborators",
  components: { ViewComponent },
  setup() {
    const router = useRouter();
    const collaboratorsStore = useCollaboratorsStore();
    const appInfosStore = useAppInfosStore();

    onBeforeMount(() => {
      collaboratorsStore.ensure({ staleAfter: "always" });
      appInfosStore.ensure();
    });

    const ownedApps = (apps) => {
      return apps.filter((app) => app.owner.id === currentUser().id);
    };

    const isAppOwner = computed(() => {
      const { items, promiseInfo } = appInfosStore;

      if (promiseInfo.isFinished) {
        return ownedApps(items).length > 0;
      }

      return true;
    });

    const isCollaborator = computed(() => {
      const { items, promiseInfo } = appInfosStore;

      if (promiseInfo.isFinished) {
        return items.length > ownedApps(items).length;
      }

      return true;
    });

    const collaborators = computed(() => {
      const result = Object.values(groupedByEmailCollaborators()).map((collaborators) => {
      const referenceCollaborator = haveCollaboratorsAccepted(collaborators)
        ? findAcceptedCollaborators(collaborators)
        : collaborators[0];

      referenceCollaborator.apps = referenceCollaborator.apps || [];

      collaborators.forEach(({ app_id, app_name, regionCode, status }) => {
        const existingApp = referenceCollaborator.apps.find((app) => app.name === app_name);

        if (existingApp) {
          Object.assign(existingApp, { id: app_id, status });
        } else {
          referenceCollaborator.apps.push({ id: app_id, name: app_name, region: regionCode, status });
        }
      });

        return referenceCollaborator
      })

      return result;
    });

    const sortedCollaborators = () => {
      if (!collaboratorsStore.items) {
        return [];
      }
      return sortCollaborators(collaboratorsStore.items);
    };

    const groupedByEmailCollaborators = () => {
      return Object.groupBy(sortedCollaborators(), ({ email }) => email);
    };

    const findAcceptedCollaborators = (collaborators) => {
      return collaborators.find(({ status }) => status === "accepted");
    };

    const haveCollaboratorsAccepted = (collaborators) => {
      return findAcceptedCollaborators(collaborators) !== undefined;
    };

    const appsCollaborators = computed(() => {
      const allApps = {};

      appsCollaboratorsSorted.value.forEach((collaborator) => {
        collaborator.apps.forEach((app) => {
          if (!allApps[app.name]) {
            allApps[app.name] = { ...app, collaborators: [] };
          }

          const found = allApps[app.name]["collaborators"].find(
            (c) => c.email === collaborator.email,
          );

          if (!found) {
            allApps[app.name]["collaborators"].push({ ...collaborator });
          }
        });
      });

      return allApps;
    });

    const appsCollaboratorsSorted = computed(() => {
      if (!collaboratorsStore.items) {
        return [];
      }

      const collaborators = collaboratorsStore.items.map((c) => {
        return new Proxy(JSON.parse(JSON.stringify(c)), {});
      });

      collaborators.forEach((collaborator) => {
        collaborator.apps = [
          {
            name: collaborator.app_name,
            region: collaborator.regionCode,
          },
        ];
      });
      return sortCollaborators(collaborators);
    });

    const currentUsername = computed(() => currentUser().username);

    const groupBy = ref("collaborators");
    const changeGroupBy = (value) => {
      groupBy.value = value;
    };

    const searchTerm = ref("");

    const openAppCollaborators = (item) => {
      const region = item.region;
      const appName = item.name;

      router.push({
        name: AppRoutes.Collaborators,
        params: {
          region,
          id: appName,
        },
      });
    };

    return {
      collaboratorsStore,
      isAppOwner,
      isCollaborator,
      currentUsername,
      groupBy,
      changeGroupBy,
      searchTerm,
      openAppCollaborators,
      collaborators,
      appsCollaborators,
    };
  },
});
</script>
