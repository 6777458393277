<template>
  <section>
    <ReviewAppsCard
      :parentApp="app"
      :reviewApps="reviewApps"
      :scmRepoLink="scmRepoLink"
    />
  </section>
</template>

<script>
import { defineComponent } from "vue";

import ReviewAppsCard from "@/components/parts/app/showReviewApps/ReviewAppsCard.vue";

export default defineComponent({
  name: "ReviewApps",
  components: {
    ReviewAppsCard,
  },
  props: {
    app: Object,
    user: Object,
    reviewApps: Object,
    scmRepoLink: Object,
  },
});
</script>
