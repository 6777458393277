<template>
  <main v-if="order">
    <BackLinkButton
      class="my-2"
      :routeArgs="backLink"
    >
      {{ $t("backToOrders") }}
    </BackLinkButton>

    <OrderSummary :order="order" />
    <OrderConsumption
      v-if="invoices"
      class="mt-4"
      :invoices="invoices"
    />
    <ProTipAlert
      icon="title"
      class="mt-4"
    >
      {{ $t("protip") }}
      <router-link
        class="underline"
        :to="consumptionRoute"
      >
        {{ $t("link") }}
      </router-link>
    </ProTipAlert>
  </main>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import OrderConsumption from "@/components/parts/billing/showOrder/OrderConsumption.vue";
import OrderSummary from "@/components/parts/billing/showOrder/OrderSummary.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "Show",
  components: {
    BackLinkButton,
    OrderSummary,
    OrderConsumption,
    ProTipAlert,
  },
  props: {
    order: Object,
    invoices: Object,
  },
  computed: {
    backLink() {
      return {
        name: Routes.Billing.Orders.List,
      };
    },
    consumptionRoute() {
      return {
        name: Routes.Consumption,
      };
    },
  },
});
</script>

<i18n>
en:
  backToOrders: "Back to orders"
  protip: "Monthly statements are available at the beginning of the following month. However, you can "
  link: "follow and estimate your consumption now."
fr:
  backToOrders: "Retour aux commandes"
  protip: "Les décomptes mensuels sont disponibles au début du mois suivant. Cependant, vous pouvez "
  link: "suivre et estimer votre consommation dès maintenant."
</i18n>
