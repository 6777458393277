<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    />
    <OrdersListBody :orders="orders" />
    <ProTipAlert
      icon="title"
      class="mt-4"
    >
      {{ $t("proTip") }}
    </ProTipAlert>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import OrdersListBody from "@/components/parts/billing/showBilling/OrdersListBody.vue";

export default defineComponent({
  name: "List",
  components: { CardHeader, OrdersListBody, ProTipAlert },
  props: { orders: Object },
});
</script>

<i18n>
en:
  title: "Orders"
  subtitle: "All orders associated with your account."
  proTip: "These orders are invoiced through the C3 market and not by Scalingo."
fr:
  title: "Commandes"
  subtitle: "Toutes les commandes associées à votre compte."
  proTip: "Ces commandes sont facturées à travers la place de marché C3 et non par Scalingo."
</i18n>
