<template>
  <div class="bg-scale-0 rounded px-8 py-6">
    <CardHeader
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    />
    <template
      v-if="lastDeployment.latestFetch.isLoading && app.last_deployment_id"
    >
      <LoadingCardState />
    </template>
    <template v-else-if="deployment">
      <div class="my-2">
        <Deployment
          :app="app"
          :dockerAddon="null"
          :withLink="true"
          :deployment="deployment"
          :withActionsButtons="false"
          :withHelpLinks="true"
          :openableConsole="false"
          :isList="false"
        />
      </div>
      <CardFooter
        :withViewAllButton="true"
        :routeArgs="routeArgs"
      />
    </template>
    <EmptyCardState v-if="!app.last_deployment_id">
      <template #icon>
        <TerminalGlyph class="h-4 text-scale-5" />
      </template>
      {{ $t("none") }}
    </EmptyCardState>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import TerminalGlyph from "@/components/atoms/glyphs/TerminalGlyph.vue";
import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import Deployment from "@/components/parts/app/showDeploy/ShowDeployDeployment.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "LastDeploymentCard",
  components: {
    CardFooter,
    LoadingCardState,
    CardHeader,
    Deployment,
    TerminalGlyph,
    EmptyCardState,
  },
  props: {
    app: Object,
    lastDeployment: Object,
  },
  data() {
    return {
      routeArgs: {
        name: Routes.App.Deploy.Root,
        args: { id: this.app.name },
      },
    };
  },
  computed: {
    deployment() {
      if (!this.lastDeployment.latestFetch.isSuccess) return;
      if (!this.app.last_deployment_id) return;

      return this.lastDeployment.items?.[0];
    },
  },
});
</script>

<i18n>
en:
  title: "Last deployment"
  subtitle: "A summary about the last app deployment."
  none: "No deployment yet"
fr:
  title: "Dernier déploiement"
  subtitle: "Un résumé à propos du dernier déploiement de l'app."
  none: "Aucun déploiement"
</i18n>
