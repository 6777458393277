import { sortBy } from "lodash";
import Scalingo from "scalingo";
import {
  Organization,
  Repository,
  Branch,
  SCMIntegration,
  OrganizationsMeta,
} from "scalingo/lib/models/auth";
import { App, PullRequest } from "scalingo/lib/models/regional";

import { AppInfo } from "@/lib/scalingo/app-infos";

const MAIN_BRANCHES = [
  "master",
  "main",
  "develop",
  "production",
  "prod",
  "staging",
];

export function listOrganizations(
  client: Scalingo,
  integrationID: string,
  page: number = 1,
  perPage: number = 20,
): Promise<{ organizations: Organization[]; meta: OrganizationsMeta }> {
  return client.SCMIntegrations.organizations(integrationID, page, perPage);
}

export function listMatchingRepos(
  client: Scalingo,
  integration: SCMIntegration,
  owner: string,
  term = "",
): Promise<Repository[]> {
  let query = `user:${owner} ${term}`;

  if (
    integration.scm_type === "github" ||
    integration.scm_type === "github-enterprise"
  ) {
    query = `fork:true ${query}`;
  }

  return client.SCMIntegrations.searchRepositories(integration.id, query);
}

export function listBranchesForRepo(
  client: Scalingo,
  integrationID: string,
  repo_name: string,
): Promise<Branch[]> {
  return client.SCMIntegrations.branchesForRepo(integrationID, repo_name);
}

export function listBranchesForAppRepoLink(
  client: Scalingo,
  app: App | AppInfo,
): Promise<Branch[]> {
  return client.SCMRepoLinks.branches(app.id);
}

export function listPullsForAppRepoLink(
  client: Scalingo,
  app: App | AppInfo,
): Promise<PullRequest[]> {
  return client.SCMRepoLinks.pulls(app.id);
}

export function orderedBranches(branches: Branch[]): Branch[] {
  const onTop: Record<string, Branch> = {};
  const others: Branch[] = [];
  const mainBranches: Branch[] = [];

  // Scan all branches, flag main ones
  branches.forEach((branch) => {
    if (MAIN_BRANCHES.includes(branch.name)) {
      onTop[branch.name] = branch;
    } else {
      others.push(branch);
    }
  });

  // Put main ones on top of the list
  MAIN_BRANCHES.forEach((name) => {
    if (onTop[name]) {
      mainBranches.push(onTop[name]);
    }
  });

  return [...mainBranches, ...sortBy(others, [(o) => o.name.toLowerCase()])];
}
