<template>
  <OnClickOutside
    as="span"
    class="relative"
    @trigger="hideActions"
  >
    <SCButton
      kind="empty"
      size="md"
      @click="toggleActions"
    >
      <span class="flex min-h-full place-items-center">
        <slot />
        <Chevron
          :direction="displayActions ? 'up' : 'down'"
          class="h-4 w-4"
        />
      </span>
    </SCButton>

    <DropdownMenu
      class="w-64"
      align="right"
      :show="displayActions"
      :items="actions"
      @click="actionClicked"
    />
  </OnClickOutside>
</template>

<script>
import { OnClickOutside } from "@vueuse/components";
import { defineComponent } from "vue";

import Chevron from "@/components/atoms/glyphs/Chevron.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import DropdownMenu from "@/components/molecules/dropdowns/DropdownMenu.vue";

export default defineComponent({
  name: "SCDropdownButton",
  components: { OnClickOutside, Chevron, SCButton, DropdownMenu },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["action"],
  data() {
    return {
      displayActions: false,
    };
  },
  computed: {
    hasActions() {
      return this.actions.length > 0;
    },
  },
  methods: {
    toggleActions() {
      this.displayActions = !this.displayActions;
    },
    actionClicked(args) {
      this.hideActions();
      this.$emit("action", args);
    },
    hideActions() {
      this.displayActions = false;
    },
  },
});
</script>
