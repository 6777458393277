<template>
  <SCTable
    :headers="headers"
    :items="queryStatisticsList"
    :showFooter="queryStatisticsList.length > 0"
  >
    <template #item.query="{ item }">
      <QueryContainer
        class="max-w-lg"
        :query="item.query"
        :isOpened="expandedContainer === item"
        @expand="expandContainer(item)"
      />
    </template>
    <template #item.calls="{ item }">
      <div class="truncate w-full">
        {{ item.calls }}
      </div>
    </template>
    <template #item.rows="{ item }">
      <div class="truncate w-full">
        {{ item.rows }}
      </div>
    </template>
    <template #item.totalTime="{ item }">
      <div class="truncate w-full">
        {{ formatDurationFromMilliseconds(item.total_time) }}
      </div>
    </template>
    <template #item.minTime="{ item }">
      <div class="truncate w-full">
        {{ formatDurationFromMilliseconds(item.min_time) }}
      </div>
    </template>
    <template #item.maxTime="{ item }">
      <div class="truncate w-full">
        {{ formatDurationFromMilliseconds(item.max_time) }}
      </div>
    </template>
    <template #item.meanTime="{ item }">
      <div class="truncate w-full">
        {{ formatDurationFromMilliseconds(item.mean_time) }}
      </div>
    </template>
    <template #item.timeStddev="{ item }">
      <div class="truncate w-full">
        {{ formatDurationFromMilliseconds(item.stddev_time) }}
      </div>
    </template>
  </SCTable>
  <div
    v-if="queryStatisticsList.length == 0"
    class="w-full text-center"
  >
    <h3 class="text-scale-6 font-medium pt-4">
      {{ $t("firstDataMessagePartOne") }}
      <br>
      {{ $t("firstDataMessagePartTwo") }}
    </h3>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import QueryContainer from "@/components/atoms/tables/QueryContainer.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import { formatDurationFromMilliseconds } from "@/lib/utils/time";

export default defineComponent({
  name: "QueryStatisticsList",
  components: {
    SCTable,
    QueryContainer,
  },
  props: {
    queryStatisticsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          value: "query",
          text: this.$i18n.t("tableHeaders.sqlQuery"),
          class: "w-5/12",
        },
        {
          value: "calls",
          text: this.$i18n.t("tableHeaders.calls"),
          class: "w-1/12",
        },
        {
          value: "rows",
          text: this.$i18n.t("tableHeaders.rows"),
          class: "w-1/12",
        },
        {
          value: "totalTime",
          text: this.$i18n.t("tableHeaders.totalTime"),
          class: "w-1/12",
        },
        {
          value: "meanTime",
          text: this.$i18n.t("tableHeaders.meanTime"),
          class: "w-1/12",
        },
        {
          value: "minTime",
          text: this.$i18n.t("tableHeaders.minTime"),
          class: "w-1/12",
        },
        {
          value: "maxTime",
          text: this.$i18n.t("tableHeaders.maxTime"),
          class: "w-1/12",
        },
        {
          value: "timeStddev",
          text: this.$i18n.t("tableHeaders.timeStddev"),
          class: "w-1/12",
        },
      ],
      tableContainerWidth: 0,
      expandedContainer: null,
    };
  },
  methods: {
    formatDurationFromMilliseconds,
    expandContainer(item) {
      const isAlreadyExpanded = this.expandedContainer === item;

      this.expandedContainer = isAlreadyExpanded ? null : item;
    },
  },
});
</script>

<i18n>
  en:
    tableHeaders:
      sqlQuery: "SQL Query"
      calls: "Calls"
      rows: "Rows"
      totalTime: "Total time"
      meanTime: "Mean time"
      minTime: "Min time"
      maxTime: "Max time"
      timeStddev: "Time stddev"
    firstDataMessagePartOne: "The first data will be displayed shortly."
    firstDataMessagePartTwo: "Wait a few moments and refresh the list to explore the performance of your first queries."
    copyQuery: "Copy the query"
  fr:
    tableHeaders:
      sqlQuery: "Requête SQL"
      calls: "Appels"
      rows: "Lignes"
      totalTime: "Temps total"
      meanTime: "Temps moyen"
      minTime: "Temps min"
      maxTime: "Temps max"
      timeStddev: "Écart type de la durée"
    firstDataMessagePartOne: "Les premières données seront affichées sous peu."
    firstDataMessagePartTwo: "Attendez quelques instants et rafraîchissez la liste pour explorer les performances de vos premières requêtes."
    copyQuery: "Copier la requête"
</i18n>
