<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      />
    </template>
    <template #body>
      <template v-if="entries.latestFetch.isLoading">
        <LoadingCardState />
      </template>
      <template v-if="entries.latestFetch.isFinished">
        <div class="py-4">
          <div
            v-if="entries.items.length > 0"
            class="divide-y divide-scale-2"
          >
            <div
              v-for="(entry, key) in entries.items"
              :key="key"
              class="py-2"
            >
              <a
                class="inline-block humanize font-bold hover:underline"
                :href="entry.link"
                target="_blank"
              >
                {{ entry.mainCategory }}
              </a>
              <span class="text-scale-4"> - </span>
              <a
                :href="entry.link"
                target="_blank"
                class="hover:underline"
              >
                {{ entry.title }}
              </a>
              <span class="text-scale-4"> - </span>

              <SimpleDate
                class="text-scale-5"
                :value="entry.publishedAt"
              />
            </div>
          </div>
          <div v-else>
            No changelog entry (probably blocked by CORS)
          </div>
        </div>
      </template>
    </template>
    <template #footer>
      <CardFooter
        :withViewAllButton="entries.latestFetch.isSuccess"
        :routeArgs="changelogUrl"
      />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SimpleDate from "@/components/atoms/presenters/SimpleDate.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";

export default defineComponent({
  name: "ChangelogCard",
  components: { SimpleDate, Card, CardFooter, CardHeader, LoadingCardState },
  props: {
    entries: Object,
  },
  data() {
    return {
      changelogUrl: {
        noRouter: true,
        tagAttrs: {
          href: `https://doc.scalingo.com/changelog`,
          target: "_blank",
        },
      },
    };
  },
});
</script>

<i18n>
en:
  title: "Changelog"
  subtitle: "The latest updates to the platform."
fr:
  title: "Journal des modifications"
  subtitle: "Les dernières mises à jour de Scalingo."
</i18n>
