<template>
  <StepCard
    :step="2"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-5xl"
  >
    <LoadingCardState v-if="isLoading" />
    <NotifierChoiceForm
      v-else
      :formHandler="formHandler"
      :notifiers="notifiers"
      @form-submitted="(e) => $emit('formSubmitted', e)"
    />
  </StepCard>
</template>

<script>
import { defineComponent } from "vue";

import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import NotifierChoiceForm from "@/components/parts/appSettings/alertsCreation/NotifierChoiceForm.vue";

export default defineComponent({
  name: "NotifierStep",
  components: { NotifierChoiceForm, StepCard, LoadingCardState },
  props: {
    enabled: Boolean,
    formHandler: Object,
    notifiers: Object,
  },
  emits: ["formSubmitted"],
  computed: {
    isLoading() {
      return !this.formHandler || this.notifiers.latestFetch.isLoading;
    },
  },
});
</script>

<i18n>
  en:
    title: "Notifier"
    subtitle: "Select the notifier(s) to use when the alert is triggered."
  fr:
    title: "Notifieur"
    subtitle: "Selectionnez le ou les notifiers à utiliser lorsque l'alerte est déclenchée"
</i18n>
