<template>
  <SCBanner
    kind="warning"
    align="center"
  >
    {{ text }}
    <a
      class="cursor-pointer underline ml-1"
      @click="$emit('startContractAcceptance', { contract })"
    >{{ $t("link") }}</a>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";

export default defineComponent({
  name: "ContractUpdateBanner",
  components: { SCBanner },
  props: {
    contract: Object,
  },
  emits: ["startContractAcceptance"],
  computed: {
    text() {
      return this.$t("text", {
        contract: this.contract.name[this.$i18n.locale],
      });
    },
  },
});
</script>

<i18n>
  en:
    text: "A new version of the {contract} is available."
    link: "Review and accept"
  fr:
    text: "Une nouvelle version des {contract} est disponible."
    link: "Passer en revue et accepter."
</i18n>
