<template>
  <ViewComponent
    :app="app"
    :db="db"
  />
</template>

<script>
import { computed, defineComponent } from "vue";

import ViewComponent from "@/components/views/db/logs/DbLogsRoot.vue";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";

export default defineComponent({
  name: "DbLogsRootContainer",
  components: { ViewComponent },
  setup() {
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();

    return { currentAppStore, db: computed(() => currentDbStore.database) };
  },
  computed: {
    app() {
      return this.currentAppStore.appInfoOrFull;
    },
  },
});
</script>
