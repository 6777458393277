<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      >
        <template #buttons>
          <SCButton @click="$emit('openConfigurationModal')">
            {{ $t("edit") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div
        v-if="configurationList.length > 0"
        class="my-4"
      >
        <p v-html="$t('activeConfiguration')" />
        <div class="my-4 flex flex-wrap gap-2">
          <div
            v-for="configuration in configurationList"
            :key="configuration"
            class="col-auto rounded border border-padding"
          >
            <div class="flex items-center p-2">
              <TwoStatesIndicator
                class="mr-2"
                :isOn="true"
              />
              <div>{{ configuration }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="my-4 flex flex-wrap gap-2"
      >
        <p v-html="$t('noConfiguration')" />
      </div>
      <InformationAlert class="mt-3">
        <p v-html="$t('info')" />
      </InformationAlert>
      <EditConfigurationModal
        v-if="updateModesCtx"
        :context="updateModesCtx"
        :activatedConfiguration="configurationList"
        :availableModes="availableModes"
        @update-configuration-list="$emit('updateConfigurationList')"
        @modal-closed="$emit('closedConfigurationModal')"
      />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import InformationAlert from "@/components/organisms/sc_alerts/InformationAlert.vue";
import EditConfigurationModal from "@/components/parts/dbSettings/mysql/EditConfigurationModal.vue";

export default defineComponent({
  name: "MysqlConfiguration",
  components: {
    Card,
    CardHeader,
    TwoStatesIndicator,
    InformationAlert,
    SCButton,
    EditConfigurationModal,
  },
  props: {
    configurationList: Array,
    availableModes: Array,
    updateModesCtx: Object,
  },
  emits: [
    "clearErrorMessage",
    "updateConfigurationList",
    "closedConfigurationModal",
    "openConfigurationModal",
  ],
});
</script>

<i18n>
  en:
    title: "SQL Modes Configuration"
    subtitle: "Modes determine the SQL syntax accepted by MySQL and the validation rules it applies."
    info: "For more information, plese \
      <a class='underline' href='https://doc.scalingo.com/databases/mysql/managing#working-with-sql-modes' target='_blank'>refer to our documentation</a>."
    activeConfiguration: "This database has the following active SQL modes:"
    noConfiguration: "This database has no active SQL modes. Popular ones include \
      <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_ansi' target='_blank'>ANSI</a>, \
      <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_strict_trans_tables' target='_blank'>STRICT_TRANS_TABLES</a> and  \
      <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_traditional' target='_blank'>TRADITIONAL</a>."
    edit: "Manage SQL modes"
  fr:
    title: "Modes SQL Configuration"
    subtitle: "Les modes déterminent la syntaxe SQL acceptée par MySQL et les règles de validation qu’il applique."
    info: "Pour plus d'informations, veuillez \
      <a class='underline' href='https://doc.scalingo.com/databases/mysql/managing#working-with-sql-modes' target='_blank'>consulter notre documentation</a>."
    activeConfiguration: "Cette base de données dispose des modes SQL actifs suivants :"
    noConfiguration: "Cette base de données ne dispose d'aucun mode SQL actif. Les modes populaires incluent \
      <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_ansi' target='_blank'>ANSI</a>, \
      <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_strict_trans_tables' target='_blank'>STRICT_TRANS_TABLES</a> et  \
      <a class='underline' href='https://dev.mysql.com/doc/refman/8.0/en/sql-mode.html#sqlmode_traditional' target='_blank'>TRADITIONAL</a>."
    edit: "Gérer les modes SQL"
</i18n>
