<template>
  <SimpleModal
    size="w-2/4"
    @modal-closed="$emit('modalClosed')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #subtitle>
      <div class="text-scale-7">
        {{ $t("subtitle") }}
      </div>
    </template>
    <template #body>
      <Form
        ref="form"
        v-slot="{ meta, handleSubmit }"
        as=""
      >
        <form @submit.prevent="handleSubmit(submitForm)">
          <div class="flex border-t-2 border-scale-1 mt-4 flex-col">
            <div class="mt-6">
              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.name"
                name="name"
                rules="required"
              >
                <TextInput
                  :name="field.name"
                  :modelValue="field.value"
                  :label="$t('nameLabel')"
                  :placeholder="$t('namePlaceholder')"
                  :errors="errors"
                  @update:model-value="handleChange"
                />
              </Field>

              <Field
                v-slot="{ field, errors, handleChange }"
                v-model="form.content"
                name="content"
                rules="required"
              >
                <TextArea
                  :name="field.name"
                  :modelValue="field.value"
                  :rows="10"
                  inputClass="font-mono text-xs"
                  :label="$t('contentLabel')"
                  :placeholder="$t('contentPlaceholder')"
                  :errors="errors"
                  @update:model-value="handleChange"
                />
              </Field>
            </div>
            <SCButton
              size="lg"
              class="flex-grow mt-4"
              :block="true"
              type="submit"
              kind="primary"
              :disabled="!meta.valid"
              :loading="formHandler?.isSubmitting"
            >
              {{ $t("buttonName") }}
            </SCButton>
          </div>
        </form>
      </Form>
    </template>
  </SimpleModal>
</template>

<script>
import { Field, Form } from "vee-validate";
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import TextArea from "@/components/molecules/inputs/TextArea.vue";
import TextInput from "@/components/molecules/inputs/TextInput.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";
import { FormModalHandlerMixin } from "@/mixins/form_handler_mixin";
import ModelsTranslation from "@/mixins/models_translation";

export default defineComponent({
  name: "AddCollaboratorFormModal",
  components: {
    SCButton,
    TextArea,
    SimpleModal,
    TextInput,
    Field,
    Form,
  },
  mixins: [ModelsTranslation, FormModalHandlerMixin],
  emits: ["modalClosed"],
});
</script>

<i18n>
en:
  title: "Add SSH key"
  subtitle: "SSH keys are used to identify you"
  buttonName: "Add"
  nameLabel: "SSH Key Name"
  namePlaceholder: "Office computer SSH key"
  contentLabel: "SSH Public Key"
  contentPlaceholder: "ssh-ed25519 .... AAC3Nza"
fr:
  title: "Ajouter une clé SSH"
  subtitle: "Les clés SSH sont utilisées pour vous identifier"
  buttonName: "Ajouter"
  nameLabel: "Nom de la clé SSH"
  namePlaceholder: "Clé SSH machine du bureau"
  contentLabel: "Clé SSH publique"
  contentPlaceholder: "ssh-ed25519 .... AAC3Nza"
</i18n>
