<template>
  <ViewComponent
    :app="app"
    :user="currentUser"
    :db="db"
  />
</template>

<script>
import { computed, defineComponent } from "vue";

import ViewComponent from "@/components/views/db/settings/DbSettingsRoot.vue";
import { useCurrentAppStore } from "@/stores/current/app";
import { useCurrentDBStore } from "@/stores/current/db";

export default defineComponent({
  name: "DbSettingsRootContainer",
  components: { ViewComponent },
  setup() {
    const currentAppStore = useCurrentAppStore();
    const currentDbStore = useCurrentDBStore();

    return {
      app: computed(() => currentAppStore.appInfoOrFull),
      db: computed(() => currentDbStore.database),
    };
  },
});
</script>
