<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      />
    </template>
    <template #body>
      <div>
        <div
          v-if="appInfos.items.length > 0"
          class="divide-y divide-scale-2"
        >
          <AppItem
            v-for="(appInfo, key) in appInfos.items"
            :key="key"
            :appInfo="appInfo"
          />
        </div>
        <div v-else>
          No app
        </div>
      </div>
    </template>
    <template #footer>
      <CardFooter
        :withViewAllButton="true"
        :routeArgs="routerArgsViewAll"
      />
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import CardFooter from "@/components/molecules/card/CardFooter.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import AppItem from "@/components/parts/home/overview/AppItem.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "AppsCard",
  components: { AppItem, CardFooter, Card, CardHeader },
  props: {
    appInfos: Object,
  },
  data() {
    return {
      routerArgsCreate: { name: Routes.Create.App },
      routerArgsViewAll: { name: Routes.Projects },
    };
  },
});
</script>

<i18n>
en:
  title: "Apps"
  subtitle: "Last apps deployed."
fr:
  title: "Apps"
  subtitle: "Dernières apps déployées."
</i18n>
