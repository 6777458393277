<template>
  <StepCard
    :step="3"
    :title="title"
    :subtitle="$t('subtitle')"
    :enabled="enabled"
    maxSize="max-w-3xl"
  >
    <LoadingCardState v-if="metaInfo.isLoading" />

    <div
      v-else-if="metaInfo.isSuccess"
      class="mt-6"
    >
      <SCAlert
        v-if="error"
        class="flex-grow"
        kind="error"
        :title="$t('errors.title')"
      >
        <template v-if="error.error === 'unknown-provider'">
          {{ $t("errors.provider", { name: error.name }) }}
        </template>
        <template v-else-if="error.error === 'unknown-plan'">
          {{ $t("errors.plan", { name: error.name }) }}
        </template>
      </SCAlert>

      <template v-else>
        <div
          v-for="(container, index) in formation"
          :key="container.name"
          class="py-3 flex content-end"
          :class="{ 'border-t border-scale-2': index > 0 }"
        >
          <div>
            {{ $tc("containers", container.amount) }}
            {{ container.type }}
            {{ container.size }}
            ({{ humanReadableSize(container.memory) }})
          </div>
          <div class="flex-grow" />
          <div class="text-right">
            {{ formatAmount(container.price) }}
          </div>
        </div>
        <div
          v-for="addon in addons"
          :key="addon.addon_provider.id"
          class="py-3 flex content-end border-t border-scale-2"
        >
          <div class="pr-2 self-center">
            <AddonIcon
              :addon="addon"
              :width="22"
            />
          </div>
          <div class="font-medium">
            {{ addon.addon_provider.name }}
          </div>

          <div class="text-scale-5 pl-1">
            ({{ addon.plan.display_name }})
          </div>
          <div class="flex-grow" />
          <div class="text-right">
            {{ formatAmount(addon.plan.price) }}
          </div>
        </div>
        <div class="py-3 flex content-end border-t border-scale-2">
          <div class="pr-2 self-center">
            {{ $t("total") }}
          </div>

          <div class="flex-grow" />
          <div class="text-right font-bold">
            {{ formatAmount(totalPrice) }}
          </div>
        </div>

        <transition-group
          tag="div"
          class="flex flex-col mt-8"
          enterActiveClass="ease-out duration-1000 transition"
          enterClass="opacity-0"
          enterToClass="opacity-100"
          leaveActiveClass="transition ease-in duration-100"
          leaveClass="opacity-100"
          leaveToClass="opacity-0"
        >
          <SCButton
            v-if="!ocdStatus"
            key="ocd-submit"
            block
            kind="primary"
            size="lg"
            class="flex-grow"
            @click="$emit('confirmAppCreation', { addons, formation })"
          >
            {{ $t("action") }}
          </SCButton>

          <SCAlert
            v-if="ocdStatus"
            key="ocd-status"
            class="flex-grow"
            :kind="ocdAlertKind"
            :title="ocdAlertTitle"
          >
            <ul class="list-none list-inside">
              <li
                v-if="!ocdStatus.app.isError"
                class="flex mb-1"
              >
                <Spinner v-if="ocdStatus.app.isLoading" />
                <CheckGlyph
                  v-else-if="ocdStatus.app.isSuccess"
                  size="4"
                />

                <span class="leading-4 ml-2">
                  {{ $t("status.app", { name: appName }) }}
                </span>
              </li>
              <li
                v-if="!ocdStatus.variables.isError"
                class="flex mb-1"
              >
                <Spinner v-if="ocdStatus.variables.isLoading" />
                <CheckGlyph
                  v-else-if="ocdStatus.variables.isSuccess"
                  size="4"
                />
                <span class="leading-4 ml-2">
                  {{ $t("status.variables") }}
                </span>
              </li>
              <li
                v-if="!ocdStatus.addons.isError"
                class="flex mb-1"
              >
                <Spinner v-if="ocdStatus.addons.isLoading" />
                <CheckGlyph
                  v-else-if="ocdStatus.addons.isSuccess"
                  size="4"
                />
                <span class="leading-4 ml-2">
                  {{ $t("status.addons1") }}
                </span>
              </li>
              <li
                v-if="!ocdStatus.provisionning.isError"
                class="flex mb-1"
              >
                <Spinner v-if="ocdStatus.provisionning.isLoading" />
                <CheckGlyph
                  v-else-if="ocdStatus.provisionning.isSuccess"
                  size="4"
                />
                <span class="leading-4 ml-2">
                  {{ $t("status.addons2") }}
                </span>
              </li>
              <li
                v-if="!ocdStatus.deployment.isError"
                class="flex mb-1"
              >
                <Spinner v-if="ocdStatus.deployment.isLoading" />
                <CheckGlyph
                  v-else-if="ocdStatus.deployment.isSuccess"
                  size="4"
                />
                <span class="leading-4 ml-2">
                  {{ $t("status.deployment") }}
                </span>
              </li>
              <li
                v-else
                class="flex mb-1"
              >
                <SVGIconWarningTriangle class="h-4 w-4" />

                <span class="leading-4 ml-2">
                  {{ ocdStatus.deployment.err }} –
                  {{ errorAsString(ocdStatus.deployment.err) }}
                </span>
              </li>
            </ul>
          </SCAlert>
        </transition-group>
      </template>
    </div>
  </StepCard>
</template>

<script>
import { isObject } from "lodash";
import { defineComponent } from "vue";

import CheckGlyph from "@/components/atoms/glyphs/CheckGlyph.vue";
import Spinner from "@/components/atoms/spinners/Spinner.vue";
import SVGIconWarningTriangle from "@/components/graphics/icons/WarningTriangle.vue";
import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import StepCard from "@/components/molecules/card/StepCard.vue";
import AddonIcon from "@/components/organisms/addons/Icon.vue";
import { promiseInfo } from "@/lib/promises/info";
import { errorAsString } from "@/lib/scalingo/errors";
import { formatAmount } from "@/lib/utils/currency";
import { humanReadableSize } from "@/lib/utils/size";

export default defineComponent({
  name: "OCDValidationStepCard",
  components: {
    StepCard,
    LoadingCardState,
    AddonIcon,
    SCButton,
    SCAlert,
    Spinner,
    CheckGlyph,
    SVGIconWarningTriangle,
  },
  props: {
    user: Object,
    enabled: Boolean,
    manifestInfo: Object,
    dryRunnedApp: Object,
    containerSizes: Object,
    addonProviders: Object,
    ocdStatus: Object,
  },
  emits: ["confirmAppCreation"],
  setup(props) {
    const promises = [props.manifestInfo.promise];

    if (props.containerSizes)
      promises.push(props.containerSizes.latestFetch.promise);

    if (props.addonProviders)
      promises.push(props.addonProviders.latestFetch.promise);

    const metaInfo = promiseInfo(Promise.all(promises));

    return { metaInfo };
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    appName() {
      return this.dryRunnedApp?.name;
    },
    manifestName() {
      return this.manifestInfo.isSuccess ? this.manifest.name : "app";
    },
    ocdAlertTitle() {
      if (this.ocdStatus?.deployment.isError) {
        return this.$t("status.errorTitle", { name: this.manifestName });
      }

      return this.$t("status.title", { name: this.manifestName });
    },
    ocdAlertKind() {
      if (this.ocdStatus?.deployment.isError) return "error";

      return "info";
    },
    title() {
      return this.$t("title", { name: this.manifestName });
    },
    manifest() {
      return this.manifestInfo.isSuccess ? this.manifestInfo.data : null;
    },
    defaultContainerSize() {
      return this.containerSizes.items.find((s) => s.name === "M");
    },
    formation() {
      if (!this.containerSizes?.latestFetch.isSuccess) return [];

      const { formation } = this.manifest;

      if (!formation) {
        return [
          makeContainer(this.defaultContainerSize, {
            type: "web",
            amount: 1,
          }),
        ];
      }

      return Object.keys(formation).map((type) => {
        const containerSize =
          this.containerSizes.items.find(
            (s) => s.name === formation[type].size,
          ) || this.defaultContainerSize;

        const amount = formation[type].amount || formation[type].quantity || 1;

        return makeContainer(containerSize, { type, amount });
      });
    },
    addons() {
      if (!this.addonProviders?.latestFetch.isSuccess) return [];

      const { addons } = this.manifest;

      if (!addons) return [];

      return addons.map((addon) => {
        if (this.error) return;

        let addonOpts = {};

        if (isObject(addon)) {
          addon = addon.plan;
          addonOpts = addon.options;
        }

        const parts = addon.split(":", 2);
        const providerName = parts[0];
        const planName = parts[1];

        // Look for the requested addons. If not found, try scrubbing "heroku-" and "scalingo-" prefixes
        const provider =
          this.addonProviders.items.find(
            (provider) => provider.id === providerName,
          ) ||
          this.addonProviders.items.find((provider) => {
            const name = providerName
              .replace("heroku-", "")
              .replace("scalingo-", "");

            return provider.id === name;
          });

        if (!provider) {
          this.error = { error: "unknown-provider", name: providerName };
          return;
        }

        // Look for the requested plan. If not found, fallback on a free plan
        const storePlan =
          provider.plans.find((plan) => plan.name === planName) ||
          provider.plans.find((plan) => plan.price === 0);

        if (!storePlan) {
          this.error = { error: "unknown-plan", name: planName };
          return;
        }

        // plan's `price` is in euro for 30 days. We want it in cents for 30 days.
        // we're copying the object because Vue throws an error when we mutate an object from the store directly
        const plan = { ...storePlan, price: (storePlan.price || 0) * 100 };

        return {
          addon_provider: provider,
          plan,
          addonOpts,
        };
      });
    },
    totalPrice() {
      let price = 0;
      this.formation.forEach((c) => (price += c.price));
      this.addons.forEach((a) => (price += a.plan.price));
      return price;
    },
  },
  methods: {
    formatAmount,
    humanReadableSize,
    errorAsString,
  },
});

function makeContainer(containerSize, containerInfo) {
  return {
    ...containerInfo,
    size: containerSize.name,
    memory: containerSize.memory,
    price: containerSize.thirtydays_price,
  };
}
</script>

<i18n>
en:
  title: "Confirm your {name} configuration"
  subtitle: "Review the configuration and estimated pricing of your app"
  containers: "no container | 1 container | {count} containers"
  total: "Total (for 30 days)"
  action: "Create and deploy"
  errors:
    title: "Wrong one-click-deploy configuration"
    provider: "The addon provider \"{name}\" is invalid."
    plan: "The addon plan \"{name}\" is invalid."
  status:
    title: "Your {name} is in progress"
    errorTitle: "Your {name} could not be created"
    app: "creating app \"{name}\""
    variables: "adding environment variables"
    addons1: "creating addons"
    addons2: "waiting for addons to be provisionned"
    deployment: "starting app deployment"
fr:
  title: "Confirmez la configuration de votre {name}"
  subtitle: "Vérifiez la configuration et le coût estimé de votre app"
  containers: "aucun container | 1 container | {count} containers"
  total: "Total (pour 30 jours)"
  action: "Créer et déployer"
  errors:
    title: "Mauvaise configuration one-click-deploy"
    provider: "Le type d'addon \"{name}\" n'est pas valide."
    plan: "Le plan d'addon \"{name}\" n'est pas valide."
  status:
    title: "Votre {name} est en cours de création"
    errorTitle: "Votre {name} n'a pas pu être créé"
    app: "création de l'app \"{name}\""
    variables: "ajout des variables d'environnement"
    addons1: "création des addons"
    addons2: "provisionnement des addons en cours"
    deployment: "lancement du déploiement de l'app"
</i18n>
