export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "collaboratorsTable": {
          "headers": {
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
            "2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA"])}
          },
          "columns": {
            "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 app"]), _normalize(["1 app"]), _normalize([_interpolate(_named("count")), " apps"])])}
          },
          "2faValues": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])}
          }
        },
        "dropdown": {
          "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter apps..."])}
        },
        "itemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborator"])}
      },
      "fr": {
        "collaboratorsTable": {
          "headers": {
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’utilisateur"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigné à"])},
            "2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA"])}
          },
          "columns": {
            "app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 app"]), _normalize(["1 app"]), _normalize([_interpolate(_named("count")), " apps"])])}
          },
          "2faValues": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activée"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivée"])}
          }
        },
        "dropdown": {
          "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les apps..."])}
        },
        "itemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collaborateur"])}
      }
    }
  })
}
