<template>
  <main>
    <AddSshKeyModal
      v-if="createKeyHandler"
      :formHandler="createKeyHandler"
      @modal-closed="$emit('cancelKeyCreation')"
      @form-submitted="(e) => $emit('submitKeyCreation', e)"
    />

    <Card>
      <template #header>
        <CardHeader
          :title="$t('title')"
          :subtitle="$t('subtitle')"
        >
          <template #buttons>
            <SCButton @click="$emit('startKeyCreation')">
              {{ $t("add") }}
            </SCButton>
          </template>
        </CardHeader>
      </template>
      <template #body>
        <EmptySshKeys v-if="noItems" />
        <KeysList
          v-else
          class="mt-6"
          :keys="keys"
          @start-key-deletion="(e) => $emit('startKeyDeletion', e)"
        />
      </template>
    </Card>

    <ProTipAlert class="mt-4 flex">
      <div class="flex">
        <div>{{ $t("tip") }}</div>
        <div class="ml-1 font-mono bg-scale-1 px-1 pv:whitespace-nowrap">
          {{ $t("tipCommand") }}
        </div>
        <DocLink
          href="https://doc.scalingo.com/platform/getting-started/troubleshooting-ssh#verify-the-public-key-is-attached-to-your-scalingo-account"
          class="inline ml-2"
        >
          {{ $t("tipDoc") }}
        </DocLink>
      </div>
    </ProTipAlert>

    <RemoveSshKeyModal
      v-if="deleteKeyHandler"
      :formHandler="deleteKeyHandler"
      @modal-closed="$emit('cancelKeyDeletion')"
      @form-submitted="$emit('confirmKeyDeletion')"
    />
  </main>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";
import ProTipAlert from "@/components/organisms/alerts/ProTipAlert.vue";
import AddSshKeyModal from "@/components/parts/account/showAccountKeys/AddSshKeyModal.vue";
import KeysList from "@/components/parts/account/showAccountKeys/KeysList.vue";
import RemoveSshKeyModal from "@/components/parts/account/showAccountKeys/RemoveSshKeyModal.vue";
import EmptySshKeys from "@/components/views/account/Empty.vue";

export default defineComponent({
  name: "Keys",
  components: {
    DocLink,
    ProTipAlert,
    EmptySshKeys,
    SCButton,
    RemoveSshKeyModal,
    Card,
    CardHeader,
    KeysList,
    AddSshKeyModal,
  },
  props: {
    keys: Object,
    deleteKeyHandler: Object,
    createKeyHandler: Object,
  },
  emits: [
    "cancelKeyCreation",
    "cancelKeyDeletion",
    "confirmKeyDeletion",
    "formSubmitted",
    "keyDeleted",
    "startKeyCreation",
    "startKeyDeletion",
    "submitKeyCreation",
  ],
  data() {
    return {};
  },
  computed: {
    noItems() {
      return this.keys.items.length === 0;
    },
  },
  methods: {
    keyDeleted(eventArgs) {
      this.$emit("keyDeleted", eventArgs);
    },
    formSubmitted(eventArgs) {
      this.$emit("formSubmitted", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "My SSH Keys"
  subtitle: "SSH keys allow to recognize actions as legitimate"
  add: "Add"
  tip: "Get the fingerprint of your SSH key via "
  tipCommand: "ssh-add -l -E md5"
  tipDoc: "Documentation"
fr:
  title: "Mes clés SSH"
  subtitle: "Les clés SSH autorisent à reconnaitre des actions comme légitimes"
  add: "Ajouter"
  tip: "Obtenez l'empreinte digitale de votre clé SSH"
  tipCommand: "ssh-add -l -E md5"
  tipDoc: "Documentation"
</i18n>
