<template>
  <EmptyCardState>
    <template #icon>
      <UserFriendsGlyph class="h-8 text-scale-5" />
    </template>
    {{ $t("noMember") }}
  </EmptyCardState>
</template>

<script>
import { defineComponent } from "vue";

import UserFriendsGlyph from "@/components/atoms/glyphs/UserFriendsGlyph.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";

export default defineComponent({
  name: "NoCollaborators",
  components: { UserFriendsGlyph, EmptyCardState },
});
</script>

<i18n>
en:
  noMember: "No collaborators yet"
fr:
  noMember: "Pas encore de collaborateur"
</i18n>
