<template>
  <router-link
    v-if="to"
    :to="to"
    :class="classes"
    :title="title"
  >
    <slot />
  </router-link>

  <div
    v-else
    :class="classes"
    :title="title"
    @click="onClick"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export const TYPE_DELETE = "delete";

export default defineComponent({
  name: "SCTableAction",
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: null,
    },
    highlight: {
      type: String,
      default: null,
      validator(value) {
        return [null, "info", "warning"].includes(value);
      },
    },
    type: {
      type: String,
      default: null,
    },
    to: {
      type: Object,
      default: null,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  computed: {
    classes() {
      let classes = "rounded flex justify-items-center ";

      if (!this.noPadding) {
        classes += "p-2 ";
      }

      if (this.highlight === "info") {
        classes += "text-secondary-2 ";
      } else if (this.highlight === "warning") {
        classes += "text-warning hover:text-error ";
      } else {
        classes += "text-scale-7 ";
      }

      classes +=
        this.rowIndex % 2 === 0 ? "hover:bg-scale-1" : "hover:bg-scale-0";

      if (this.disabled) {
        classes += " opacity-50 cursor-not-allowed";
        return classes;
      } else {
        classes += " cursor-pointer";
      }

      if (!this.highlight) {
        classes += " ";
        classes +=
          this.type === "delete" ? "hover:text-error" : "hover:text-scale-9";
      }

      return classes;
    },
  },
  methods: {
    onClick(e) {
      if (!this.disabled) {
        this.$emit("click", e);
      }
    },
  },
});
</script>
