<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <font-awesome-icon
        icon="layer-group"
        class="h-6 w-6 text-scale-6"
      />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      <div class="flex space-x-1">
        <span>{{ $t("from") }}</span>
        <span class="font-medium">{{
          event.type_data.previous_stack_name
        }}</span>
        <span>{{ $t("to") }}</span>
        <span class="font-medium">{{
          event.type_data.current_stack_name
        }}</span>
      </div>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "StackChangedEvent",
  components: { BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Stack changed"
  from: "From"
  to: "to"
fr:
  title: "Stack changée"
  from: "De"
  to: "à"
</i18n>
