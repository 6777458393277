<template>
  <ViewComponent
    :user="currentUser"
    :app="app"
    :scmRepoLink="scmRepoLink"
    :deleteSCMRepoLinkHandler="deleteSCMRepoLinkHandler"
    :reviewAppsConfigHandler="reviewAppsConfigHandler"
    :editSCMRepoLinkerHandler="editSCMRepoLinkerHandler"
    @change-linker="changeLinker"
    @unlink-repository="unlinkRepository"
    @start-review-app-configuration="startReviewAppConfiguration"
    @cancel-review-app-configuration="cancelReviewAppConfiguration"
    @submit-review-app-configuration="(e) => reviewAppsConfigHandler.submit(e)"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/reviewApps/Configuration.vue";
import {
  EditReviewAppsConfigHandler,
  DeleteSCMRepoLinkHandler,
  EditSCMRepoLinkerHandler,
} from "@/lib/handlers";

export default defineComponent({
  name: "ReviewAppsConfigurationContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
    scmRepoLink: Object,
  },
  data() {
    return {
      repoLinkOperation: null,
      deleteSCMRepoLinkHandler: null,
      editSCMRepoLinkerHandler: null,
      reviewAppsConfigHandler: null,
    };
  },
  methods: {
    startReviewAppConfiguration() {
      this.reviewAppsConfigHandler = new EditReviewAppsConfigHandler(
        this,
        this.scmRepoLink.value,
      );
    },
    cancelReviewAppConfiguration() {
      this.reviewAppsConfigHandler = null;
    },
    unlinkRepository() {
      this.deleteSCMRepoLinkHandler = new DeleteSCMRepoLinkHandler(
        this,
        this.app,
      );
      this.deleteSCMRepoLinkHandler.submit();
    },
    changeLinker() {
      this.editSCMRepoLinkerHandler = new EditSCMRepoLinkerHandler(
        this,
        this.currentUser,
      );
      this.editSCMRepoLinkerHandler.submit();
    },
  },
});
</script>
