<template>
  <section>
    <BackLinkButton
      class="my-2"
      :routeArgs="backLink"
    >
      {{ $t("backToDeployments") }}
    </BackLinkButton>

    <Card>
      <template #body>
        <Deployment
          v-if="deployment"
          :app="app"
          :deployment="deployment"
          :openConsole="true"
        />
      </template>
    </Card>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import Deployment from "@/components/parts/app/showDeploy/ShowDeployDeployment.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "DeployShow",
  components: {
    BackLinkButton,
    Card,
    Deployment,
  },
  props: {
    app: Object,
    user: Object,
    deployment: Object,
  },
  computed: {
    backLink() {
      return {
        name: Routes.App.Deploy.List.Root,
      };
    },
  },
});
</script>

<i18n>
en:
  title: "Deployment"
  backToDeployments: "Back to deployments"
fr:
  title: "Déploiement"
  backToDeployments: "Back to deployments"
</i18n>
