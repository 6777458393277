<template>
  <Card>
    <template #header>
      <CardHeader :title="$t('title')" />
    </template>
    <template #body>
      <p>{{ $t("text") }}</p>
      <SCButton
        class="mt-4"
        kind="warning"
        @click="$emit('startAddonDeletion')"
      >
        {{ $t("delete") }}
      </SCButton>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";

export default defineComponent({
  name: "DbDeleteCard",
  components: { Card, CardHeader, SCButton },

  emits: ["startAddonDeletion"],
});
</script>

<i18n>
en:
  title: "Delete database"
  text: "This operation is irreversible. Your database and all its data will be permanently destroyed and unrecoverable."
  delete: "Delete this database"
fr:
  title: "Supprimer la base de données"
  text: "Cette opération est irréversible. Votre base de données et toutes ses données seront définitivement détruites et irrécupérables."
  delete: "Supprimer cette base de données"
</i18n>
