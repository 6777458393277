<template>
  <ViewComponent
    :db="db"
    :users="users"
    :createCtx="createCtx"
    :deleteCtx="deleteCtx"
    :resetCtx="resetCtx"
    :createdUser="createdUser"
    :updatedUser="updatedUser"
    @start-user-creation="startUserCreation"
    @cancel-user-creation="cancelUserCreation"
    @confirm-user-creation="confirmUserCreation"
    @close-password-copy-modal="closePasswordCopyModal"
    @start-user-deletion="startUserDeletion"
    @cancel-user-deletion="cancelUserDeletion"
    @confirm-user-deletion="confirmUserDeletion"
    @start-password-reset="startPasswordReset"
    @cancel-password-reset="cancelPasswordReset"
    @confirm-password-reset="confirmPasswordReset"
  />
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref, nextTick } from "vue";
import { useRouter } from "vue-router";

import ViewComponent from "@/components/views/db/settings/UserAccess.vue";
import { closeCurrentModal } from "@/lib/modals";
import { promiseInfo, voidPromiseInfo } from "@/lib/promises/info";
import { i18nT } from "@/lib/utils/i18n";
import { Routes } from "@/router/names";
import {
  currentDB,
  useCurrentDBStore,
  currentDBClient,
} from "@/stores/current/db";
import { useDbUsersStore } from "@/stores/db/users";
import { useToastsStore } from "@/stores/toasts";

export default defineComponent({
  name: "UserAccess",
  components: { ViewComponent },
  setup() {
    const t = i18nT();
    const toastsStore = useToastsStore();

    const currentDbStore = useCurrentDBStore();
    const dbUsersStore = useDbUsersStore();
    const router = useRouter();
    const createdUser = ref(null);
    const updatedUser = ref(null);
    const createCtx = ref(null);
    const deleteCtx = ref(null);
    const resetCtx = ref(null);

    onBeforeMount(() => {
      dbUsersStore.ensure();

      const db = currentDB();
      if (db.type_name !== "postgresql" && db.type_name !== "mysql") {
        router.push({ name: Routes.Db.Settings });
      }
    });

    async function confirmUserCreation(payload) {
      payload.database_id = currentDbStore.database.id;

      createCtx.value.error = null;

      try {
        const promise = dbUsersStore.create(payload);

        createCtx.value.info = promiseInfo(promise);

        createdUser.value = await promise;

        toastsStore.addOne({
          type: "success",
          title: t("creation.success.title").toString(),
          message: t("creation.success.message").toString(),
        });

        closeCurrentModal();
      } catch (e) {
        createCtx.value.error = e.data.error;
      }
    }

    async function confirmUserDeletion() {
      deleteCtx.value.error = null;

      try {
        const promise = dbUsersStore.destroy(
          currentDbStore.database.id,
          deleteCtx.value.user,
        );

        deleteCtx.value.info = promiseInfo(promise);

        await promise;
        await nextTick();

        toastsStore.addOne({
          type: "success",
          title: t("deletion.success.title").toString(),
          message: t("deletion.success.message").toString(),
        });

        closeCurrentModal();
      } catch (e) {
        deleteCtx.value.error = e;
      }
    }

    async function confirmPasswordReset() {
      const client = await currentDBClient();

      resetCtx.value.error = null;

      try {
        const promise = client.Users.reset(
          currentDbStore.database.id,
          resetCtx.value.user.name,
        );

        updatedUser.value = await promise;

        closeCurrentModal();
      } catch (e) {
        resetCtx.value.error = e.data.error;
      }
    }

    return {
      db: computed(() => currentDbStore.database),
      users: computed(() => {
        return {
          items: dbUsersStore.items,
          promiseInfo: dbUsersStore.promiseInfo,
          any: dbUsersStore.any,
        };
      }),
      startUserCreation() {
        createCtx.value = { info: voidPromiseInfo(), error: null };
      },
      cancelUserCreation() {
        createCtx.value = null;
      },
      confirmUserCreation,
      createCtx,
      createdUser,
      closePasswordCopyModal() {
        createdUser.value = null;
        updatedUser.value = null;
      },
      startUserDeletion(e) {
        deleteCtx.value = {
          info: voidPromiseInfo(),
          errors: null,
          user: e.database_user,
        };
      },
      cancelUserDeletion() {
        deleteCtx.value = null;
      },
      confirmUserDeletion,
      deleteCtx,
      startPasswordReset(e) {
        resetCtx.value = {
          info: voidPromiseInfo(),
          errors: null,
          user: e.database_user,
        };
      },
      cancelPasswordReset() {
        resetCtx.value = null;
      },
      confirmPasswordReset,
      resetCtx,
      updatedUser,
    };
  },
});
</script>

<i18n>
en:
  creation:
    success:
      title: "User created"
      message: "The user has been successfully created."
  deletion:
    success:
      title: "User deleted"
      message: "The user has been successfully deleted."
fr:
  creation:
    success:
      title: "Utilisateur créé"
      message: "L'utilisateur a été créé avec succès."
  deletion:
    success:
      title: "Utilisateur supprimé"
      message: "L'utilisateur a été supprimé avec succès."
</i18n>
