<template>
  <ViewComponent
    :app="app"
    :scmRepoLink="scmRepoLink"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/app/deploy/Root.vue";
import { ensureScmRepoLink, scmRepoLink } from "@/store/scm-repo-link";

export default defineComponent({
  name: "DeployRootContainer",
  components: { ViewComponent },
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  computed: {
    scmRepoLink() {
      return scmRepoLink(this.$store);
    },
  },
  beforeMount() {
    ensureScmRepoLink(this.$store, { staleAfter: "always" });
  },
});
</script>
