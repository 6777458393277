<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <NotifierDelGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("named") }}:
      <span class="font-medium mx-1">{{ event.type_data.notifier_name }}</span>
      {{ $t("type") }}:
      <span class="font-medium mx-1">{{ event.type_data.notifier_type }}</span>
      {{ $t("status") }}:
      <span class="font-medium ml-1">{{
        $t(event.type_data.active ? "enabled" : "disabled")
      }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import NotifierDelGlyph from "@/components/atoms/glyphs/NotifierDelGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "DeleteNotifierEvent",
  components: { NotifierDelGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Notifier deleted"
  named: "Named"
  type: "Type"
  status: "Status"
  enabled: "enabled"
  disabled: "disabled"
fr:
  title: "Notifier supprimé"
  named: "Nommé"
  type: "Type"
  status: "Statut"
  enabled: "activé"
  disabled: "désactivé"
</i18n>
