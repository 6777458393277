<template>
  <div
    class="bg-scale-0 border-t-4 rounded transition-shadow hover:shadow-lg border-scale-4 animate-pulse"
  >
    <div class="px-6 py-4 flex flex-col">
      <div class="flex mb-2">
        <div class="flex-grow text-2xl font-bold truncate text-scale-10">
          <div class="h-9 bg-scale-2 rounded" />
        </div>
      </div>
      <div class="flex justify-items-auto">
        <div class="flex flex-col">
          <div class="h-7 bg-scale-2 rounded w-64" />
          <div
            title="Scalingo/database-admin-service"
            class="font-medium truncate group"
          >
            <div class="h-6 bg-scale-2 rounded mt-1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingListingAppCard",
});
</script>
