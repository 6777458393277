<template>
  <SCBanner
    kind="warning"
    align="center"
  >
    <i18n
      tag="p"
      path="warning"
    >
      {{ $t("warning") }}

      <DocLink
        class="font-normal"
        href="https://doc.scalingo.com/platform/databases/access"
      >
        {{ $t("doclink") }}
      </DocLink>
    </i18n>
  </SCBanner>
</template>

<script>
import { defineComponent } from "vue";

import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import DocLink from "@/components/molecules/links/DocLink.vue";

export default defineComponent({
  name: "InternetAccessBanner",
  components: { SCBanner, DocLink },
});
</script>

<i18n>
  en:
    warning: "Scalingo does not recommend enabling direct internet access to your database. Our recommendation is to use secure connection methods. {link}"
    doclink: "Learn more"

  fr:
    warning: "Scalingo déconseille l'activation de l'accès à votre base de données par internet. Notre recommandation est d'utiliser des méthodes de connexion sécurisées. {link}"
    doclink: "En savoir plus"
</i18n>
