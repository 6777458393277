<template>
  <SCTable
    class="mt-6"
    layout="fixed"
    :headers="headers"
    :items="users.items"
  >
    <template #item.name="{ item }">
      <div class="flex flex-grow place-items-center">
        <span class="flex flex-row items-center">
          <div
            v-if="item.protected"
            class="mr-2"
            :title="$t('lock')"
          >
            <UserLockGlyph class="h-4 text-scale-5" />
          </div>
          {{ item.name }}
        </span>
      </div>
    </template>
    <template
      v-if="displayPasswordEncryption"
      #item.encryption="{ item }"
    >
      <div class="flex flex-grow place-items-center">
        <span>{{ item.dbms_attributes.password_encryption }}</span>
      </div>
    </template>
    <template #item.permissions="{ item }">
      <div class="flex flex-grow place-items-center">
        <span>{{ humanReadablePermissions(item.read_only) }}</span>
      </div>
    </template>

    <template #item.actions="{ item }">
      <HorizontalDottedDropdown
        v-if="!item.protected"
        :actions="actionsFor(item)"
        class="flex justify-end"
        @action="actionClicked"
      />
    </template>
  </SCTable>
</template>

<script>
import { defineComponent } from "vue";

import UserLockGlyph from "@/components/atoms/glyphs/UserLockGlyph.vue";
import HorizontalDottedDropdown from "@/components/molecules/dropdowns/HorizontalDottedDropdown.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";

export default defineComponent({
  name: "UsersList",
  components: {
    SCTable,
    UserLockGlyph,
    HorizontalDottedDropdown,
  },
  props: {
    users: Object,
  },
  emits: ["startUserDeletion", "startPasswordReset"],
  computed: {
    headers() {
      let headers = [
        { value: "name", text: this.$t("headers.name"), class: "w-64" },
        { value: "permissions", text: this.$t("headers.permissions") },
        { value: "actions", text: "" },
      ];

      const encryptionHeader = {
        value: "encryption",
        text: this.$t("headers.encryption"),
      };

      if (this.displayPasswordEncryption) {
        headers = headers.toSpliced(1, 0, encryptionHeader);
      }

      return headers;
    },
    displayPasswordEncryption() {
      return this.users.items.some((item) =>
        Object.hasOwn(item, "dbms_attributes"),
      );
    },
  },
  methods: {
    actionsFor(user) {
      const actions = [];
      const wrapperClass = "leading-5";

      actions.push({
        wrapperClass,
        text: this.$t("actions.reset"),
        key: "reset",
        target: user,
      });

      actions.push({
        wrapperClass,
        text: this.$t("actions.delete"),
        key: "delete",
        kind: "warning",
        target: user,
      });

      return actions;
    },
    actionClicked({ action }) {
      if (action.key === "delete") {
        this.$emit("startUserDeletion", {
          database_user: action.target,
        });
        return;
      } else if (action.key === "reset") {
        this.$emit("startPasswordReset", {
          database_user: action.target,
        });
      }
    },
    humanReadablePermissions(readOnly) {
      return readOnly
        ? this.$t("read")
        : `${this.$t("read")}, ${this.$t("write")}`;
    },
  },
});
</script>

<i18n>
en:
  headers:
    name: "User"
    encryption: "Password encryption"
    permissions: "Permissions"
  lock: "Protected user"
  read: "Read"
  write: "write"
  actions:
    delete: "Delete user"
    reset: "Reset password"
fr:
  headers:
    name: "Utilisateur"
    encryption: "Chiffrement du mot de passe"
    permissions: "Permissions"
  lock: "Utilisateur protégé"
  read: "Lecture"
  write: "écriture"
  actions:
    delete: "Supprimer l'utilisateur"
    reset: "Réinitialiser le mot de passe"
</i18n>
