<template>
  <a
    class="underline"
    :href="href"
    target="_blank"
  >
    <slot />

    <font-awesome-icon
      class="ml-1"
      icon="external-link-alt"
    />
  </a>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocLink",
  props: {
    href: {
      type: String,
    },
  },
});
</script>
