<template>
  <ViewComponent
    :order="order"
    :invoices="invoices"
  />
</template>

<script>
import { computed, defineComponent, onBeforeMount } from "vue";
import { useRoute } from "vue-router";

import ViewComponent from "@/components/views/billing/orders/Show.vue";
import { useOrderInvoicesStore } from "@/stores/order-invoices";
import { useOrdersStore } from "@/stores/orders";

export default defineComponent({
  name: "OrderContainer",
  components: { ViewComponent },
  setup() {
    const ordersStore = useOrdersStore();
    const orderInvoicesStore = useOrderInvoicesStore();
    const route = useRoute();

    onBeforeMount(() => {
      ordersStore.fetchOne(route.params.id);
      orderInvoicesStore.ensure({ staleAfter: "always" });
    });

    const order = computed(() => ordersStore.findItem(route.params.id));

    const invoices = computed(() => {
      const { items, promiseInfo, any, none } = orderInvoicesStore;
      return { items, latestFetch: promiseInfo, any, none };
    });

    return { order, invoices };
  },
});
</script>
