<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <DomainDelGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      Domain:
      <span class="font-medium ml-1">{{
        event.type_data.hostname
      }}</span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import DomainDelGlyph from "@/components/atoms/glyphs/DomainDelGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "DeleteDomainEvent",
  components: { DomainDelGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Domain deleted"
fr:
  title: "Domaine supprimé"
</i18n>
