<template>
  <div class="bg-scale-0">
    <div class="container px-4 flex items-center py-6">
      <div>
        <a
          href="#"
          :title="$t('back')"
          @click.prevent="$router.back()"
        ><ArrowLeft /></a>
      </div>
      <div class="text-center flex-grow font-bold">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import ArrowLeft from "@/components/atoms/glyphs/ArrowLeftGlyph.vue";

export default defineComponent({
  name: "NavBar",
  components: { ArrowLeft },
  props: {
    title: String,
  },
});
</script>

<i18n>
en:
  back: "Coming back to the previous page"
fr:
  back: "Revenir à la page précédente"
</i18n>
