<template>
  <div class="flex flex-col space-y-5">
    <MaintenanceWindowCard
      :db="db"
      @start-maintenance-configuration="
        (e) => $emit('startMaintenanceConfiguration', e)
      "
    />
    <EditMaintenanceSettingsModal
      v-if="updateCtx"
      :db="db"
      :context="updateCtx"
      @submit="(e) => $emit('updateMaintenanceConfiguration', e)"
      @close="$emit('cancelMaintenanceConfiguration')"
    />
    <MaintenanceListCard :dbMaintenance="dbMaintenance" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import EditMaintenanceSettingsModal from "@/components/parts/db/maintenance/EditMaintenanceSettingsModal.vue";
import MaintenanceListCard from "@/components/parts/db/maintenance/MaintenanceListCard.vue";
import MaintenanceWindowCard from "@/components/parts/db/maintenance/MaintenanceWindowCard.vue";

export default defineComponent({
  name: "DbMaintenance",
  components: {
    MaintenanceListCard,
    MaintenanceWindowCard,
    EditMaintenanceSettingsModal,
  },
  props: {
    db: Object,
    dbMaintenance: Object,
    updateCtx: Object,
  },
  emits: [
    "startMaintenanceConfiguration",
    "updateMaintenanceConfiguration",
    "cancelMaintenanceConfiguration",
  ],
});
</script>
