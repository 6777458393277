<template>
  <WarningModal
    :formHandler="formHandler"
    @modal-closed="$emit('modalClosed')"
    @form-submitted="$emit('warningAccepted')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      {{ $t("message") }}
    </template>
    <template #button>
      {{ $t("button") }}
    </template>
  </WarningModal>
</template>

<script>
import { defineComponent } from "vue";

import WarningModal from "@/components/molecules/modalsCollection/WarningModal.vue";

export default defineComponent({
  name: "ResetCertificateModal",
  components: { WarningModal },
  props: {
    name: String,
    formHandler: Object,
  },
  emits: ["modalClosed", "warningAccepted"],
});
</script>

<i18n>
en:
  title: "Use the Let’s Encrypt integration"
  button: "Confirm"
  message: "This will remove the certificate actually in use, then ask Let’s Encrypt to generate a new one."
fr:
  title: "Utiliser l'intégration Let’s Encrypt"
  button: "Confirmer"
  message: "Cela supprimera le certificat actuellement utilisé, puis demander à Let’s Encrypt d'en générer un nouveau."
</i18n>
