<template>
  <div class="text-scale-10">
    <div class="bg-scale-0 rounded px-8 py-6">
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      >
        <template #buttons>
          <SCButton :to="newAddonRoute">
            {{ $t("action") }}
          </SCButton>
        </template>
      </CardHeader>

      <template v-if="addons.promiseInfo.isLoading">
        <LoadingCardState />
      </template>

      <template v-if="addons.promiseInfo.isFinished">
        <div
          v-if="addons.items.length"
          class="py-4 flex-grow"
        >
          <AddonRow
            v-for="(item, index) in addons.items"
            :key="index"
            :app="app"
            :user="user"
            :addon="item"
            :busyAddons="busyAddons"
            :rowIndex="index"
            @resume-addon="(e) => $emit('resumeAddon', e)"
            @start-addon-deletion="(e) => $emit('startAddonDeletion', e)"
          />
        </div>
        <EmptyCardState v-else>
          <template #icon>
            <PuzzlePieceGlyph class="h-8 text-scale-5" />
          </template>

          {{ $t("none") }}
        </EmptyCardState>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import PuzzlePieceGlyph from "@/components/atoms/glyphs/PuzzlePieceGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import AddonRow from "@/components/parts/app/resources/AddonRow.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "AddonsCard",
  components: {
    AddonRow,
    SCButton,
    PuzzlePieceGlyph,
    EmptyCardState,
    LoadingCardState,
    CardHeader,
  },
  props: {
    withPrice: Boolean,
    app: Object,
    user: Object,
    addons: Object,
    busyAddons: Object,
  },
  emits: ["resumeAddon", "startAddonDeletion"],
  computed: {
    newAddonRoute() {
      return {
        name: Routes.App.Addon.Creation.Root,
        params: {
          id: this.app.name,
          region: this.app.region,
        },
      };
    },
  },
});
</script>

<i18n>
  en:
    title: "Addons"
    subtitle: "Manage linked extensions."
    action: "Add an addon"
    none: "No addon yet"
  fr:
    title: "Addons"
    subtitle: "Gestion des extensions liées."
    action: "Ajouter un addon"
    none: "Aucun addon"
</i18n>
