<template>
  <div
    class="fixed inset-0 flex px-1 py-6 xl:px-1 sm:px-6 mt-12 pointer-events-none flex-col items-end 2xl:mr-10 z-60"
  >
    <transition-group
      tag="div"
      enterActiveClass="transform ease-out duration-300 transition"
      enterClass="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterToClass="translate-y-0 opacity-100 sm:translate-x-0"
      leaveActiveClass="transition ease-in duration-100"
      leaveClass="opacity-100"
      leaveToClass="opacity-0"
    >
      <Notification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        class="mt-4"
        @notification-closed="(e) => $emit('notificationClosed', e)"
      />
    </transition-group>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Notification from "@/components/molecules/notifications/Notification.vue";

export default defineComponent({
  name: "Notifications",
  components: { Notification },
  props: {
    notifications: Array,
  },
  emits: ["notificationClosed"],
});
</script>
