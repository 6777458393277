<template>
  <div class="mt-1 relative rounded-md flex">
    <textarea
      v-model="inputValue"
      :readonly="readonly"
      :rows="rows"
      :class="allInputClass"
      :disabled="disabled"
      class="bg-scale-0 border-scale-2 rounded block w-full pr-10 placeholder-scale-5 focus:shadow-none focus:ring-0"
      :placeholder="placeholder"
    />

    <div
      v-if="withIcon"
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-color-2"
    >
      <DangerSignGlyph v-if="isErrorState" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import DangerSignGlyph from "@/components/atoms/glyphs/DangerSignGlyph.vue";
import {
  STATE_ERROR,
  STATE_SUCCESS,
  STATE_NONE,
} from "@/components/atoms/inputs/TextInput.vue";

export default defineComponent({
  name: "TextArea",
  components: { DangerSignGlyph },
  props: {
    modelValue: String,
    state: String,
    inputClass: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rows: [String, Number],
  },
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    withIcon: function () {
      return this.state !== STATE_NONE;
    },
    isErrorState: function () {
      return this.state === STATE_ERROR;
    },
    allInputClass: function () {
      const inputClass = this.inputClass || "";
      let borderColor = "";
      let textColor = "text-scale-10";
      let focusBorderColor = "focus:border-color-3";

      if (this.state === STATE_ERROR) {
        textColor = "text-color-2";
        borderColor = "border-color-2";
        focusBorderColor = "focus:border-color-2";
      }

      if (this.state === STATE_SUCCESS) {
        borderColor = "border-color-1";
      }

      let rounding = "";
      if (this.withButton === true) {
        rounding = "rounded-none rounded-r-md";
      }

      let cursor = "";
      if (this.disabled) {
        cursor = "cursor-not-allowed";
      }
      return `${textColor} ${borderColor} ${focusBorderColor} ${rounding} ${inputClass} ${cursor}`;
    },
  },
});
</script>
