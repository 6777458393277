<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      >
        <template #buttons>
          <SCButton
            :loading="pullRequestsAreLoading"
            @click="$emit('fetchScmRepoLinkPullRequests')"
          >
            {{ $t("refreshPulls") }}
          </SCButton>
        </template>
      </CardHeader>
    </template>
    <template #body>
      <div
        v-if="pullRequestsAreLoading"
        class="max-h-56 rounded-md p-8 ring-1 ring-black ring-opacity-5 flex items-center justify-center"
      >
        <Spinner class="h-10 w-10 text-scale-5" />
      </div>
      <div v-else>
        <SCTable
          :headers="headers"
          :items="pullRequestsOptions"
          :paginated="true"
          :searchable="true"
          layout="fixed"
        >
          <template #item.action="{ item }">
            <div class="flex flex-row justify-center ml-2">
              <CheckboxInputAtom
                :disabled="!!item.reviewApp"
                :modelValue="isSelected(item)"
                boxType="round"
                @update:model-value="(e) => select(item, e)"
              />
            </div>
          </template>

          <template #item.pullRequest="{ item }">
            <ExtLink
              class="text-sm text-scale-9"
              :class="{
                'font-bold': isSelected(item),
              }"
              :href="item.html_url"
            >
              #{{ item.number }} {{ item.title }}
            </ExtLink>
          </template>

          <template #item.reviewApp="{ item }">
            <div
              v-if="item.reviewApp"
              class="text-sm"
            >
              <router-link
                :to="routeForReviewApp(item.reviewApp)"
                class="underline"
              >
                {{ item.reviewApp.app_name }}
              </router-link>
            </div>
          </template>

          <template #item.repository="{ item }">
            <div
              :title="
                item.opened_from_a_forked_repo
                  ? $t('titles.forked_repository')
                  : ''
              "
            >
              <font-awesome-icon
                v-if="item.opened_from_a_forked_repo"
                class="mr-1 text-warning cursor-help"
                icon="code-fork"
              />
              <ExtLink :href="item.source_repo_html_url">
                {{
                  item.source_repo_name
                }}
              </ExtLink>
            </div>
          </template>
        </SCTable>
      </div>

      <SCButton
        class="mt-4"
        kind="primary"
        :disabled="!selectedPullRequest"
        :loading="deployHandler.isSubmitting"
        @click="$emit('manualReviewApp', selectedPullRequest)"
      >
        {{ $t("action") }}
      </SCButton>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import CheckboxInputAtom from "@/components/atoms/inputs/CheckboxInput.vue";
import Spinner from "@/components/atoms/spinners/Spinner.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ExtLink from "@/components/molecules/links/ExtLink.vue";
import SCTable from "@/components/organisms/tables/SCTable.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ManualReviewAppCard",
  components: {
    ExtLink,
    CardHeader,
    Card,
    Spinner,
    SCButton,
    SCTable,
    CheckboxInputAtom,
  },
  props: {
    app: Object,
    scmRepoLink: Object,
    scmPullRequests: Array,
    reviewApps: Object,
    deployHandler: Object,
    listPullRequestsInfo: Object,
  },
  emits: ["fetchScmRepoLinkPullRequests", "manualReviewApp"],
  data() {
    return {
      selectedPullRequest: null,
      headers: [
        { value: "action", text: "", class: "w-1/12" },
        {
          value: "pullRequest",
          text: this.$t("headers.pullRequest"),
          class: "w-5/12",
        },
        {
          value: "reviewApp",
          text: this.$t("headers.reviewApp"),
          class: "w-3/12",
        },
        {
          value: "repository",
          text: this.$t("headers.repository"),
          class: "w-3/12",
        },
      ],
      searchFn(row, search) {
        const cols = [row.title, row.number];

        return !!cols.find((col) => {
          return (
            col
              .toString()
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase()) !== -1
          );
        });
      },
    };
  },
  computed: {
    pullRequestsAreLoading() {
      return this.listPullRequestsInfo?.isLoading || false;
    },
    pullRequestsOptions() {
      if (this.pullRequestsAreLoading) {
        return [];
      }

      return this.scmPullRequests.map((pr) => {
        return {
          ...pr,
          reviewApp: this.reviewApps.items.find((reviewApp) => {
            return reviewApp.pull_request.number === pr.number;
          }),
        };
      });
    },
  },
  methods: {
    routeForReviewApp(item) {
      return {
        name: Routes.App.Overview,
        params: {
          id: item.app_name,
          // Region is not given in the review apps endoint, so we'll use the parent app's region
          region: this.app.region,
        },
      };
    },
    isSelected(item) {
      return this.selectedPullRequest?.id === item.id;
    },
    select(item, val) {
      if (val) {
        this.selectedPullRequest = item;
      } else {
        this.selectedPullRequest = null;
      }
    },
  },
});
</script>

<i18n>
en:
  title: "Manual deployment of review apps"
  subtitle: "Deploy review apps from pull requests related to your app repository and forks."
  action: "Deploy review app"
  refreshPulls: "Refresh pull requests"
  titles:
    forked_repository: "This is a fork from the linked repository, authored by a user outside of your organization."
  headers:
    repository: "Repository"
    reviewApp: "Review app"
    pullRequest: "Pull Request"
fr:
  title: "Déploiement manuel de review apps"
  subtitle: "Déployez des review apps à partir des pull requests liées au dépôt de l'app et à ses forks."
  action: "Déployer la review app"
  refreshPulls: "Rafraichir les pull requests"
  titles:
    forked_repository: "Il s'agit d'un fork du dépôt lié, créé par un utilisateur à l'extérieur de votre organisation."
  headers:
    repository: "Dépôt"
    reviewApp: "Review app"
    pullRequest: "Pull Request"
</i18n>
