<template>
  <Card>
    <template #header>
      <div class="border-b-2 pb-6 flex">
        <div class="flex-grow">
          <h3 class="font-bold text-xl flex">
            {{ $t("title") }}
          </h3>
          <div class="text-scale-7">
            {{ $t("subtitle") }}
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div>
        <div
          v-for="(detail, appName) in groupedPerApp"
          :key="'d' + appName"
        >
          <div class="text-xl font-medium mt-6">
            {{ appName }}
          </div>
          <table class="table-fixed w-full text-scale-10 mt-6">
            <thead class="border-b-2 font-medium">
              <tr>
                <td class="w-3/6">
                  {{ $t("labels.item") }}
                </td>
                <td class="w-2/6 text-right">
                  {{ $t("labels.duration") }}
                </td>
                <td class="w-1/6 text-right">
                  {{ $t("labels.price") }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in detail.items"
                :key="'i' + item.id"
              >
                <td class="py-2">
                  {{ item.label }}
                </td>
                <td class="text-right">
                  <template v-if="item.duration">
                    <span :title="humanizeDuration(item.duration * 60)">
                      {{ $tc("duration", item.duration) }}
                    </span>
                  </template>
                </td>
                <td class="text-right">
                  {{ formatAmount(item.price) }}
                </td>
              </tr>
            </tbody>
            <tfoot class="border-t-2 font-medium">
              <tr>
                <td colspan="2">
                  {{ $t("totalFor", { name: appName }) }}
                </td>
                <td class="text-right">
                  {{ formatAmount(detail.total) }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import Card from "@/components/molecules/card/Card.vue";
import { formatAmount } from "@/lib/utils/currency";
import { humanizeDuration } from "@/lib/utils/time";

export default defineComponent({
  name: "InvoiceDetails",
  components: { Card },
  props: {
    invoice: Object,
  },
  computed: {
    groupedPerApp() {
      const results = {};

      this.invoice.detailed_items.forEach((detailedItem) => {
        // Referral lines have no app, and they're in the summary. Skipping them.
        if (!detailedItem.app) return;

        if (!results[detailedItem.app]) {
          results[detailedItem.app] = { total: 0, items: [] };
        }

        results[detailedItem.app].total += detailedItem.price;
        results[detailedItem.app].items.push(detailedItem);
      });

      return results;
    },
  },
  methods: { formatAmount, humanizeDuration },
});
</script>

<i18n>
en:
  title: "Details"
  subtitle: "A break down of each billed elements"
  labels:
    item: "Item"
    duration: "Duration"
    price: "Price"
  totalFor: "Total for {name}"
  duration: "none | 1 minute | {count} minutes"
fr:
  title: "Détails"
  subtitle: "Un détail de tous les éléments facturés"
  labels:
    item: "Item"
    duration: "Durée"
    price: "Prix"
  totalFor: "Total for {name}"
  duration: "aucune | 1 minute | {count} minutes"
</i18n>
