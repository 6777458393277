<template>
  <div class="rounded bg-scale-0 px-8 py-6 flex flex-col">
    <CardHeader
      :title="$t('title')"
      :subtitle="$t('subtitle')"
    />
    <OperationsListBody
      v-if="displayOperations"
      :balanceOperations="balanceOperations"
      :meta="meta"
      @fetch-more="$emit('fetchMoreOperations')"
    />
    <EmptyCardState v-else>
      <template #icon>
        <BillGlyph class="h-8 text-scale-6" />
      </template>
      <span class="text-scale-6 text-lg font-medium">{{
        $t("emptyText")
      }}</span>
    </EmptyCardState>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BillGlyph from "@/components/atoms/glyphs/BillGlyph.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import EmptyCardState from "@/components/molecules/card/EmptyCardState.vue";
import OperationsListBody from "@/components/parts/billing/showBilling/OperationsListBody.vue";

export default defineComponent({
  name: "OperationsList",
  components: { OperationsListBody, BillGlyph, EmptyCardState, CardHeader },
  props: {
    balanceOperations: Object,
    meta: Object,
  },
  emits: ["fetchMoreOperations", "viewMoreButtonClicked"],
  computed: {
    displayOperations() {
      const op = this.balanceOperations.latestFetch;

      return op.isLoading || this.balanceOperations.items.length > 0;
    },
  },
  methods: {
    viewMoreButtonClicked(eventArgs) {
      this.$emit("viewMoreButtonClicked", eventArgs);
    },
  },
});
</script>

<i18n>
en:
  title: "Operations"
  subtitle: "Operations history"
  emptyText: "Nothing to show"
fr:
  title: "Opérations"
  subtitle: "Historique des opérations"
  emptyText: "Aucune entrée"
</i18n>
