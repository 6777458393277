<template>
  <div>
    <div
      class="rounded-sm border-b border-scale-3 px-2 py-3 bg-scale-1 font-medium flex items-center justify-between space-x-1 cursor-pointer"
      @click="open = !open"
    >
      <div class="flex items-center space-x-1">
        <font-awesome-icon
          v-if="!open"
          size="2x"
          icon="caret-right"
          class="h-5 w-5"
        />
        <font-awesome-icon
          v-if="open"
          size="2x"
          icon="caret-down"
          class="h-5 w-5"
        />
        <span :title="sku.sku">{{ sku.label }}</span>
      </div>
      <div class="text-scale-7">
        {{ price }}
      </div>
    </div>
    <div v-if="open">
      <table class="table-fixed w-full text-scale-10">
        <thead class="font-normal">
          <tr class="text-scale-6 text-sm h-8">
            <td class="bg-scale-1 rounded-l-sm w-3/6 px-4">
              {{ $t("label.app") }}
            </td>
            <td class="bg-scale-1 w-2/6 text-right">
              {{ $t("label.duration") }}
            </td>
            <td class="bg-scale-1 rounded-r-sm w-1/6 text-right">
              <div class="flex justify-end items-baseline">
                <div>{{ $t("label.price") }}</div>
                <div class="text-scale-6 text-xs ml-1">
                  {{ $t("exclVat") }}
                </div>
                ↓
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in sortedApps"
            :key="'s' + item.id"
          >
            <td class="px-4 py-2">
              {{ item.app }}
              <span
                v-if="multipleRegions"
                class="text-scale-7"
              >- {{ item.region }}</span>
            </td>
            <td class="text-right">
              <span :title="humanizeDuration(item.duration * 60)">
                {{ $t("duration", { count: item.duration }) }}
              </span>
            </td>
            <td class="text-right">
              <span
                v-if="item.future_price"
                class="flex flex-col"
              >
                <span class="line-through">{{
                  formatAmount(item.future_price)
                }}</span>
                <span class="text-scale-7">{{ $t("future_price") }}</span>
              </span>
              <span v-else>
                {{ formatAmount(item.price) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { formatAmount } from "@/lib/utils/currency";
import { humanizeDuration } from "@/lib/utils/time";

export default defineComponent({
  name: "ResourcePeriod",
  props: {
    sku: Object,
    multipleRegions: Boolean,
  },
  data() {
    return { open: false };
  },
  computed: {
    sortedApps() {
      return this.sku.apps.slice().sort((a, b) => b.price - a.price);
    },
    price() {
      let price = 0;
      for (const k in this.sku.apps) {
        price += this.sku.apps[k].price;
      }
      return formatAmount(price);
    },
  },
  methods: { formatAmount, humanizeDuration },
});
</script>

<i18n>
en:
  label:
    app: "App"
    duration: "Consumption"
    price: "Amount"
  duration: "none | 1 minute | {count} minutes"
  deleted: "Deleted"
  exclVat: "excl. VAT"
  future_price: "€0 (free until May 1st)"
fr:
  label:
    app: "App"
    duration: "Consommation"
    price: "Montant"
  duration: "aucune | 1 minute | {count} minutes"
  deleted: "Supprimée"
  exclVat: "excl. TVA"
  future_price: "0€ (offert jusqu’au 1er mai)"
</i18n>
