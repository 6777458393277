import { TokenManager } from "@/lib/auth/token-manager";
import { Fallback, MiddlewareErrors } from "@/lib/auth/utils";

export class Logout implements Fallback {
  name = "Logout";
  private tokenManager: TokenManager;

  constructor(readonly authBaseUrl: string) {
    this.tokenManager = TokenManager.getInstance();
  }

  get logoutUrl(): string {
    return `${this.authBaseUrl}/logout`;
  }

  call(_errors: MiddlewareErrors): void {
    this.tokenManager.removeToken();
    window.location.replace(this.logoutUrl);
  }
}
