<template>
  <div class="flex flex-col">
    <div>
      {{ $t("linker") }}:
      <span class="font-medium ml-1">{{
        event.type_data.linker_username
      }}</span>
    </div>
    <div>
      {{ $t("repository") }}:
      <span class="font-medium ml-1">{{ event.type_data.repo_name }}</span>
    </div>
    <div class="flex flex-row space-x-1 items-center">
      <span>{{ $t("auto_deployment") }}:</span>
      <TwoStatesIndicator
        :isOn="event.type_data.auto_deploy"
        :alternativeColors="true"
      />
      <span
        v-if="event.type_data.auto_deploy"
        class="text-scale-7"
      >({{ event.type_data.branch }})</span>
    </div>
    <div class="flex flex-row space-x-1 items-center">
      <span>{{ $t("auto_deployment_review_app") }}:</span>
      <TwoStatesIndicator
        :isOn="event.type_data.auto_deploy_review_apps"
        :alternativeColors="true"
      />
    </div>
    <div class="flex flex-row space-x-1 items-center">
      <span>{{ $t("allow_creation_from_forks") }}:</span>
      <TwoStatesIndicator
        :isOn="event.type_data.creation_from_forks_allowed"
        :alternativeColors="true"
      />
    </div>
    <div class="flex flex-row space-x-1 items-center">
      <span>{{ $t("auto_destroy_on_close") }}:</span>
      <TwoStatesIndicator
        :isOn="event.type_data.delete_on_close"
        :alternativeColors="true"
      />
      <span
        v-if="
          event.type_data.delete_on_close &&
            event.type_data.hours_before_delete_on_close > 0
        "
        class="text-scale-7"
      >({{ event.type_data.hours_before_delete_on_close }} h)</span>
      <span
        v-else
        class="text-scale-7"
      >({{ $t("instantly") }})</span>
    </div>
    <div class="flex flex-row space-x-1 items-center">
      <span>{{ $t("auto_destroy_on_stale") }}:</span>
      <TwoStatesIndicator
        :isOn="event.type_data.delete_stale"
        :alternativeColors="true"
      />
      <span
        v-if="
          event.type_data.delete_stale &&
            event.type_data.hours_before_delete_stale > 0
        "
        class="text-scale-7"
      >({{ event.type_data.hours_before_delete_stale }} h)</span>
      <span
        v-else
        class="text-scale-7"
      >({{ $t("instantly") }})</span>
    </div>
  </div>
</template>
<script>
import TwoStatesIndicator from "@/components/atoms/indicators/TwoStatesIndicator.vue";

export default {
  name: "ScmBody",
  components: { TwoStatesIndicator },
  props: {
    event: Object,
  },
};
</script>

<i18n>
en:
  repository: "Repository"
  linker: "Linker"
  auto_deployment: "Automatic deployments"
  auto_deployment_review_app: "Automatic deployment of review apps"
  allow_creation_from_forks: "Allow creation from forks"
  auto_destroy_on_close: "Auto destroy on close"
  auto_destroy_on_stale: "Auto destroy on stale"
  instantly: "instantly"
fr:
  repository: "Dépôt"
  linker: "Linker"
  auto_deployment : "Déploiements automatiques"
  auto_deployment_review_app : "Déploiement automatique des applications des review apps"
  allow_creation_from_forks : "Autoriser la création à partir de forks"
  auto_destroy_on_close : "Destruction automatique à la fermeture"
  auto_destroy_on_stale : "Destruction automatique sur inactivité"
  instantly: "immédiatement"
</i18n>
