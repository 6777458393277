<template>
  <div
    v-if="show"
    class="loader ease-linear rounded-full border-2 border-t-2 border-scale-3 h-4 w-4"
  />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Spinner",
  props: {
    immediate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { show: false };
  },
  created() {
    if (this.immediate) {
      this.show = true;
    } else {
      setTimeout(() => (this.show = true), 250);
    }
  },
});
</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
