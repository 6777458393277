<template>
  <router-view
    v-if="db"
    :app="app"
    :db="db"
  />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DbRoot",
  props: {
    app: {
      type: Object,
      required: true,
    },
    db: {
      type: Object,
      required: true,
    },
    dbMaintenance: {
      type: Object,
      required: false,
    },
    notifiedItems: {
      type: Object,
      required: false,
    },
  },
  computed: {
    accessAsAdmin() {
      // TODO
      return false;
    },
  },
});
</script>
