<template>
  <BaseEvent
    :event="event"
    :long="long"
    :displayAuthor="displayAuthor"
    :displayAppName="displayAppName"
    :withEventLink="withEventLink"
  >
    <template #name>
      <AutoscalerAddGlyph class="h-6 w-6 text-scale-6" />
      <div class="text-scale-7 pl-2 font-bold">
        {{ $t("title") }}
      </div>
    </template>
    <template #more>
      {{ $t("autoscaler") }}
      <span class="font-medium px-1">{{ event.type_data.metric }}</span>
      <span v-if="event.type_data.container_type">{{ $t("container_type") }}
        <span class="font-medium pr-1">{{
          event.type_data.container_type
        }}</span>
      </span>
    </template>
  </BaseEvent>
</template>

<script>
import { defineComponent } from "vue";

import AutoscalerAddGlyph from "@/components/atoms/glyphs/AutoscalerAddGlyph.vue";
import BaseEvent from "@/components/parts/events/BaseEvent.vue";

export default defineComponent({
  name: "DeleteAutoscalerEvent",
  components: { AutoscalerAddGlyph, BaseEvent },
  props: {
    event: Object,
    long: {
      type: Boolean,
      default: true,
    },
    displayAuthor: {
      type: Boolean,
      default: true,
    },
    displayAppName: {
      type: Boolean,
      default: false,
    },
    withEventLink: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<i18n>
en:
  title: "Autoscaler deleted"
  autoscaler: "Autoscaler was monitoring metric"
  container_type: "on the container type"
fr:
  title: "Autoscaler supprimé"
  autoscaler: "Autoscaler surveillant la métrique"
  container_type: "sur le type de container"
</i18n>
