<template>
  <WarningModal
    :formHandler="formHandler"
    @modal-closed="$emit('modalClosed')"
    @form-submitted="$emit('warningAccepted')"
  >
    <template #title>
      {{ $t("title") }}
    </template>
    <template #question>
      {{ $t("message") }}
    </template>
    <template #button>
      {{ $t("button") }}
    </template>
  </WarningModal>
</template>

<script>
import { defineComponent } from "vue";

import WarningModal from "@/components/molecules/modalsCollection/WarningModal.vue";

export default defineComponent({
  name: "MakeCanonicalWarning",
  components: { WarningModal },
  props: {
    name: String,
    formHandler: Object,
  },
  emits: ["modalClosed", "warningAccepted"],
});
</script>

<i18n>
en:
  title: "Make canonical"
  button: "Apply"
  message: "The redirection to the canonical domain will be made with a HTTP 301 status code."
fr:
  title: "Rendre canonique"
  button: "Appliquer"
  message: "La redirection au domaine canonique se fera via un code HTTP 301."
</i18n>
