<template>
  <div v-html="smartLogLine" />
</template>

<script>
import { AnsiUp } from "ansi_up";
import { escape } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SmartLogLine",
  props: {
    line: String,
  },
  data() {
    const colorizer = new AnsiUp();
    colorizer.use_classes = true;
    return { colorizer };
  },
  computed: {
    smartLogLine() {
      return this.transformLine(this.line);
    },
  },
  methods: {
    transformLine(line) {
      const time = this.sliceTime(line);
      const container = this.sliceContainer(line);
      const body = this.sliceBody(line);

      return (
        "<span class='text-blue-300'>" +
        escape(time) +
        "</span>" +
        "<span class='text-green-400 ml-2'>" +
        escape(container) +
        "</span>" +
        "<span>" +
        this.colorizer.ansi_to_html(body) +
        "</span>"
      );
    },
    sliceTime(line) {
      return line.slice(0, 40);
    },
    containerStart(line) {
      return line.indexOf("[");
    },
    containerEnd(line) {
      return line.indexOf("]") + 2;
    },
    sliceContainer(line) {
      const start = this.containerStart(line);
      const end = this.containerEnd(line);
      return line.slice(start, end);
    },
    sliceBody(line) {
      const end = this.containerEnd(line);
      return line.slice(end, line.length);
    },
  },
});
</script>
