<template>
  <ViewComponent
    v-if="currentUser"
    :user="currentUser"
    :stats="referralStats"
    @copy-referral-link="copyReferralLink"
  />
</template>

<script>
import { defineComponent } from "vue";

import ViewComponent from "@/components/views/account/Referrals.vue";
import { copyToClipboard } from "@/lib/clipboard";
import {
  ensureReferralStats,
  referralStatsAndFetch,
} from "@/store/referral-stats";

export default defineComponent({
  name: "ReferralsContainer",
  components: { ViewComponent },
  computed: {
    referralStats() {
      return referralStatsAndFetch(this.$store);
    },
  },
  beforeMount() {
    ensureReferralStats(this.$store);
  },
  methods: {
    copyReferralLink() {
      copyToClipboard(
        this.currentUser.referral_url +
          "?utm_source=scalingo&utm_medium=email&utm_campaign=referral",
      );
    },
  },
});
</script>
