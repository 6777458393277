export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing profile"])},
        "supportPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support plans"])},
        "vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
        "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
        "balance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Balance (", _interpolate(_named("balance")), ")"])},
        "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
        "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
        "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])}
      },
      "fr": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil de facturation"])},
        "supportPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres de support"])},
        "vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
        "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédits"])},
        "balance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Balance (", _interpolate(_named("balance")), ")"])},
        "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens de paiement"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
        "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
        "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations"])}
      }
    }
  })
}
