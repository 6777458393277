<template>
  <SimpleModal
    size="w-1/4"
    :dismissible="false"
  >
    <template #title>
      {{ $t("title") }}
    </template>

    <template #body>
      <div class="mt-6">
        {{ $t("description") }}
      </div>
      <div class="mt-6 flex">
        <SCButton
          size="lg"
          kind="warning"
          class="flex-grow"
          :block="true"
          :loading="isReloading"
          @click="submit"
        >
          {{ $t("action") }}
        </SCButton>
      </div>
    </template>
  </SimpleModal>
</template>

<script>
import { defineComponent } from "vue";

import SCButton from "@/components/molecules/buttons/SCButton.vue";
import SimpleModal from "@/components/molecules/modals/SimpleModal.vue";

export default defineComponent({
  name: "InformationsModal",
  components: {
    SimpleModal,
    SCButton,
  },
  emits: ["reloadRequested"],
  data() {
    return { isReloading: false };
  },
  methods: {
    submit() {
      this.isReloading = true;
      this.$emit("reloadRequested");
    },
  },
});
</script>

<i18n>
en:
  title: "Something went wrong"
  description: "It looks like something unplanned happened, and we can't recover from it."
  action: "Reload current page"
fr:
  title: "Quelque chose s'est mal passé"
  description: "Il semblerait que quelque chose se soit mal passé et que cela nécessite de recharger la fenêtre."
  action: "Recharger la page"
</i18n>
