<template>
  <ConfirmModal
    :immediate="false"
    :promiseInfo="context.info"
    :canConfirm="!hasHdsApps"
    :title="$t('title')"
    panelClasses="sm:w-full sm:max-w-lg"
    @close="(e) => $emit('close', e)"
    @confirm="(e) => $emit('confirm', e)"
  >
    <SCBanner
      v-if="hasHdsApps"
      kind="warning"
      align="center"
    >
      {{
        $t("warning", { count: hdsAppsCount })
      }}
    </SCBanner>
    <div v-else>
      {{ $t("message") }}
    </div>

    <SCAlert
      v-if="context.info?.isError"
      class="mt-4"
      kind="error"
      icon="title"
      :title="$t('error.generic.title')"
    >
      <template #icon>
        <ExclamationTriangleIcon class="h-6" />
      </template>

      <p>{{ $t("error.generic.message") }}</p>
    </SCAlert>
  </ConfirmModal>
</template>

<script>
import { ExclamationTriangleIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";

import SCAlert from "@/components/molecules/alerts/SCAlert.vue";
import SCBanner from "@/components/molecules/banners/SCBanner.vue";
import ConfirmModal from "@/components/molecules/modals/ConfirmModal.vue";

export default defineComponent({
  name: "DeleteHDSContactWarning",
  components: { SCBanner, SCAlert, ConfirmModal, ExclamationTriangleIcon },
  props: {
    context: { type: Object, required: true },
    hdsAppsCount: Number,
  },
  emits: ["close", "confirm"],
  computed: {
    hasHdsApps() {
      return this.hdsAppsCount > 0;
    },
  },
});
</script>

<i18n>
en:
  title: "Delete the point of contact"
  button: "Delete"
  message: "If you no longer wish to deploy HDH apps, you can remove the point of contact. You will need to add another one to deploy HDH apps on Scalingo."
  warning: "You cannot delete the point of contact while you still own HDH apps (currently: {count})."
  error:
    generic:
      title: "Failure"
      message: "Could not delete the contact."
fr:
  title: "Suppprimer le point de contact"
  button: "Supprimer"
  message: "Si vous ne souhaitez plus déployer d'applications HDS, vous pouvez supprimer le point de contact. Vous devrez en ajouter un autre pour déployer les applications HDS sur Scalingo."
  warning: "Vous ne pouvez pas supprimer le point de contact si vous possédez toujours des apps HDS (actuellement: {count})."
  error:
    generic:
      title: "Échec"
      message: "Impossible de supprimer le contact."
</i18n>
