<template>
  <section>
    <BackLinkButton
      class="my-2"
      :routeArgs="backLink"
    >
      {{ $t("backToActivity") }}
    </BackLinkButton>

    <Card>
      <template #body>
        <EventSwitch
          v-if="event"
          :event="event"
          :long="true"
          :displayAuthor="true"
          :displayAppName="false"
          :eventComplement="eventComplement"
          @body-opened="(e) => $emit('bodyOpened', e)"
        />

        <LoadingCardState v-else />
      </template>
    </Card>
  </section>
</template>

<script>
import { defineComponent } from "vue";

import BackLinkButton from "@/components/atoms/buttons/BackLinkButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import LoadingCardState from "@/components/molecules/card/LoadingCardState.vue";
import EventSwitch from "@/components/organisms/events/EventSwitch.vue";
import { Routes } from "@/router/names";

export default defineComponent({
  name: "ActivityShow",
  components: {
    BackLinkButton,
    EventSwitch,
    Card,
    LoadingCardState,
  },
  props: {
    app: Object,
    user: Object,
    event: Object,
    eventComplement: Object,
  },
  emits: ["bodyOpened"],
  computed: {
    backLink() {
      return {
        name: Routes.App.Activity.Root,
      };
    },
  },
});
</script>

<i18n>
en:
  backToActivity: "Back to activity"
fr:
  backToActivity: "Back to activity"
</i18n>
