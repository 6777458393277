<template>
  <Card>
    <template #header>
      <CardHeader
        :title="$t('title')"
        :subtitle="$t('subtitle')"
      />
    </template>

    <template #body>
      <div class="mt-4">
        <div
          v-if="dbVersion.promiseInfo.isSuccess"
          class="text-scale-10 font-medium text-base"
        >
          <div class="flex flex-row">
            <div class="humanize">
              {{ databaseName(dbName) }}
            </div>
            <div class="ml-1">
              {{ readableFullVersion(currentDbVersion) }}
            </div>
          </div>
          <div class="col-12">
            <SCButton
              v-if="currentDbVersion?.next_upgrade"
              kind="neutral"
              class="flex flex-grow mt-4"
              @click="$emit('startDbUpgrade')"
            >
              <span class="flex flex-row text-scale-8 font-normal">
                <ReloadGlyph
                  v-if="upgradeType === 'minor'"
                  class="h-4 mr-1"
                />
                <DownloadGlyph
                  v-else
                  class="h-4 mr-1.5"
                />
                {{
                  $t("upgrade", {
                    type: $t(upgradeType),
                    version: readableFullVersion(currentDbVersion.next_upgrade),
                  })
                }}
              </span>
            </SCButton>
          </div>
          <ConfirmDatabaseUpgrade
            v-if="upgradeCtx"
            :context="upgradeCtx"
            :dbName="dbName"
            @close="$emit('cancelDbUpgrade')"
            @confirm="$emit('confirmDbUpgrade')"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from "vue";

import DownloadGlyph from "@/components/atoms/glyphs/DownloadGlyph.vue";
import ReloadGlyph from "@/components/atoms/glyphs/ReloadGlyph.vue";
import SCButton from "@/components/molecules/buttons/SCButton.vue";
import Card from "@/components/molecules/card/Card.vue";
import CardHeader from "@/components/molecules/card/CardHeader.vue";
import ConfirmDatabaseUpgrade from "@/components/organisms/modals/ConfirmDatabaseUpgrade.vue";
import { databaseName } from "@/lib/utils/databaseName";

export default defineComponent({
  name: "DbVersionCard",
  components: {
    Card,
    CardHeader,
    SCButton,
    ConfirmDatabaseUpgrade,
    ReloadGlyph,
    DownloadGlyph,
  },
  props: {
    dbName: String,
    dbVersion: Object,
    upgradeCtx: Object,
  },
  emits: ["cancelDbUpgrade", "startDbUpgrade", "confirmDbUpgrade"],
  computed: {
    currentDbVersion() {
      return this.dbVersion?.item;
    },
    nextDbVersion() {
      return this.currentDbVersion?.next_upgrade;
    },
    upgradeType() {
      if (this.nextDbVersion?.major > this.currentDbVersion?.major) {
        return "major";
      } else if (this.nextDbVersion?.minor > this.currentDbVersion?.minor) {
        return "minor";
      } else {
        return "patch";
      }
    },
  },
  methods: {
    databaseName,
    readableFullVersion(dbVersion) {
      return `${dbVersion.major}.${dbVersion.minor}.${dbVersion.patch}-${dbVersion.build}`;
    },
  },
});
</script>

<i18n>
en:
  title: "Database Version"
  subtitle: "Keeping your database up-to-date helps to improve the security and availability of your data."
  upgrade: "@.capitalize:{type} Upgrade to {version}"
  major: "major"
  minor: "minor"
  patch: "patch"
fr:
  title: "Version de la base de données"
  subtitle: "Maintenir votre base de données à jour contribue à améliorer la sécurité et la disponibilité de vos données."
  upgrade: "Mise à jour {type} vers {version}"
  major: "majeure"
  minor: "mineure"
  patch: "patch"
</i18n>
