<template>
  <Alert
    styling="proTip"
    :text="$t('text')"
    :withIcon="true"
    :dismissible="dismissible"
  >
    <template #icon>
      <BulbGlyph />
    </template>
    <template #textComplement>
      <slot />
    </template>
  </Alert>
</template>
<script>
import { defineComponent } from "vue";

import BulbGlyph from "@/components/atoms/glyphs/BulbGlyph.vue";
import Alert from "@/components/molecules/alerts/Alert.vue";

export default defineComponent({
  name: "ProTipAlert",
  components: { BulbGlyph, Alert },
  props: {
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["dismissed"],
  methods: {
    onAlertDismissed() {
      this.$emit("dismissed");
    },
  },
});
</script>

<i18n>
  en:
    text: "Pro tip:"
  fr:
    text: "Pro tip:"
</i18n>
