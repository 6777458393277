import axios, { AxiosRequestConfig } from "axios";
import { reactive } from "vue";

const HOMEPAGE_HOST = process.env.VUE_APP_SCALINGO_BLOG_HOST;
const HOMEPAGE_AUTH = process.env.VUE_APP_SCALINGO_BLOG_AUTH;

export interface PlatformStatus {
  status: string;
}

export async function fetchPlatformStatus(): Promise<PlatformStatus> {
  const url = `https://${HOMEPAGE_HOST}/status.json`;

  const options: AxiosRequestConfig = {
    headers: {
      Accept: "application/json",
    },
  };

  if (HOMEPAGE_AUTH) {
    const [username, password] = HOMEPAGE_AUTH.split(":");
    options.auth = { username, password };
  }

  const response = await axios.get(url, options);

  return response.data;
}

export const platformStatus = reactive({
  OK: false,
  KO: false,
  Warning: false,
});

let platformStatusPollingID: NodeJS.Timeout;

export async function refreshPlatformStatus() {
  try {
    const { status } = await fetchPlatformStatus();

    platformStatus.OK = status === "is-success";
    platformStatus.KO = status === "is-danger";
    platformStatus.Warning = status === "is-warning";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // ignore
  }
}

export function setupPlatformStatusPolling() {
  if (platformStatusPollingID) return;

  refreshPlatformStatus();
  platformStatusPollingID = setInterval(refreshPlatformStatus, 600000);
}
